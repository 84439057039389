import useApiServices from './useApiServices';
import { globalAdminUrl } from './globalApi';
//
/**
 * @module PartnerServiceService
 * @returns  it return All DataSource Apis
 */

const masterConfigurationService = {
  // common apis
  GetFileData: (token) => useApiServices().getApi(`${globalAdminUrl}/admin/getFileData`, token),

  UpdateShopData: (header, credential) =>
    useApiServices().postMultiPartHeaderApi(
      `${globalAdminUrl}/admin/updateShopData`,
      header,
      credential
    )
};

export default masterConfigurationService;
