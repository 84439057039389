import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

const BASE_URL = process.env.REACT_APP_BASE_URL;

i18n
  .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['localStorage'],
      caches: ['localStorage']
    },
    backend: {
      loadPath: `${BASE_URL}/assets/locales/{{lng}}/translation.json`
    },
    fallbackLng: 'th',
    react: { useSuspense: true },
    debug: {
      Option: true
    },
    keySeparator: false,
    // nsSeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    }
  });

export default i18n;
