import useApiServices from './useApiServices';
import { globalSRP } from './globalApi';
//
/**
 * @module superAdminServiceService
 * @returns  it return All DataSource Apis
 */

const selfRegistrationPage = {
  // GetStartUpConfig: () => useApiServices().getApi(`${globalSRP}/getStartupConfig`),
  // GetTutorial: () => useApiServices().getApi(`${globalSRP}/getTutorial`),
  // GetReTakes: () => useApiServices().getApi(`${globalSRP}/getRetakes`),
  // GetLanguages: () => useApiServices().getApi(`${globalSRP}/getLanguages`),
  // GetFaq: () => useApiServices().getApi(`${globalSRP}/getFaq`),
  // GetConsents: () => useApiServices().getApi(`${globalSRP}/getConsents`),
  // GetNationality: (credential) =>
  //   useApiServices().getHeaderApi(`${globalSRP}/GetNationality`, credential),
  // GetConsentPDF: () =>
  //   useApiServices().getApi(`https://api.ntportal.com/dev/journey/getConsentPDF`),
  // GetFileFromUrl: (credential) =>
  //   useApiServices().getHeaderApi(`${globalSRP}/getFileFromUrl`, credential),
  // GetConsentFile: () => `${globalSRP}/getConsentFile`,
  // FetchSalutationAndGender: () => useApiServices().getApi(`${globalSRP}/fetchSalutationAndGender`),
  // FetchLookupAddress: () => useApiServices().getApi(`${globalSRP}/fetchLookupAddress`),
  // FileName: (fileName) => useApiServices().getApi(`${globalSRP}/file/${fileName}`),
  // GetOCR: (credential) => useApiServices().postFormDataApi(`${globalSRP}/getOCR`, credential),
  // GetPuk: (credential) => useApiServices().postApi(`${globalSRP}/getPuk`, credential),
  // FaceMatch: (credential) => useApiServices().postFormDataApi(`${globalSRP}/faceMatch`, credential),
  // // thai language list of address
  // GetListOfProvinces: (fileName) => useApiServices().getApi(`${globalSRP}/GetListOfProvinces`),
  // GetListOfDistrict: (credential) =>
  //   useApiServices().postApi(`${globalSRP}/GetListOfDistrict`, credential),
  // GetListOfSubDistrict: (credential) =>
  //   useApiServices().postApi(`${globalSRP}/GetListOfSubDistrict`, credential),
  // GetZipCodes: (credential) => useApiServices().postApi(`${globalSRP}/GetZipCodes`, credential),
  // // apis for address in english value
  // GetListOfProvincesManual: (fileName) =>
  //   useApiServices().getApi(`${globalSRP}/GetListOfProvincesManual`),
  // GetListOfDistrictManual: (credential) =>
  //   useApiServices().postApi(`${globalSRP}/GetListOfDistrictManual`, credential),
  // GetListOfSubDistrictManual: (credential) =>
  //   useApiServices().postApi(`${globalSRP}/GetListOfSubDistrictManual`, credential),
  // GetZipCodesManual: (credential) =>
  //   useApiServices().postApi(`${globalSRP}/GetZipCodesManual`, credential),

  // SaveUserInfo: (credential) => useApiServices().postApi(`${globalSRP}/saveUserInfo`, credential),
  // SaveMobileRelatedData: (credential) =>
  //   useApiServices().postApi(`${globalSRP}/saveMobileRelatedData`, credential),
  // GetNearByShops: (credential) =>
  //   useApiServices().postHeaderData(`${globalSRP}/getNearByShops`, credential),
  // UploadImage: (credential, query) =>
  //   useApiServices().postFormDataApi(`${globalSRP}/uploadImage?${query}`, credential),
  // OrderStatus: (credential) =>
  //   useApiServices().postApiWithHeaders(`${globalSRP}/orderStatus`, credential),
  // GetLocationForLatLong: (credential) =>
  //   useApiServices().getHeaderApi(`${globalSRP}/getLocationForLatLong`, credential),
  // SetFCMToken: (credential) =>
  //   useApiServices().postHeaderData(`${globalSRP}/setFCMToken`, credential),
  SendNotification: (credential) =>
    useApiServices().postHeaderData(`${globalSRP}/sendNotification`, credential)
};

export default selfRegistrationPage;
