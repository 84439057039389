import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

const BASE_URL = process.env.REACT_APP_BASE_URL;

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx, isOpenSidebar }) {
  return (
    <Box
      component="img"
      // src={`${BASE_URL}/admin/main-logo.svg`}
      src={isOpenSidebar ? `/static/logo/main-logo.svg` : `/static/logo/logo-icon.svg`}
      sx={{ width: isOpenSidebar ? 120 : 30, height: 28, ...sx }}
    />
  );
}
