import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export function makePDF(quotes, fileName, heatMap, orientationL) {
  // const quotes = window.document.getElementById('simregCount');
  // console.log('makePDF', quotes.clientHeight / heatMap ? 700 : 980);
  html2canvas(quotes).then((canvas) => {
    //! MAKE YOUR PDF
    const pdf = new jsPDF(orientationL ? 'l' : 'p', 'pt', orientationL ? [900, 400] : 'letter');

    let qLength = quotes.clientHeight / 980;
    if (heatMap) {
      qLength += 2;
    }

    for (let i = 0; i <= qLength; i++) {
      //! This is all just html2canvas stuff
      const srcImg = canvas;
      const sX = 0;
      const sY = orientationL ? 600 * i : 980 * i; // start 980 pixels down for every new page
      const sWidth = orientationL ? 2100 : 900;
      const sHeight = 980;
      const dX = 5;
      const dY = 5;
      const dWidth = orientationL ? 1390 : 890;
      const dHeight = orientationL ? 600 : 980;

      window.onePageCanvas = document.createElement('canvas');
      window.onePageCanvas.setAttribute('width', orientationL ? 1400 : 900);
      window.onePageCanvas.setAttribute('height', orientationL ? 600 : 980);
      const ctx = window.onePageCanvas.getContext('2d');
      // details on this usage of this function:
      // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
      ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);

      // document.body.appendChild(canvas);
      const canvasDataURL = window.onePageCanvas.toDataURL('image/png', 1.0);

      const width = window.onePageCanvas.width;
      const height = window.onePageCanvas.clientHeight;

      //! If we're on anything other than the first page,
      // add another page
      if (i > 0) {
        pdf.addPage(); //8.5" x 11" in pts (in*72)
      }
      //! now we declare that we're working on that page
      pdf.setPage(i + 1);
      //! now we add content to that page!
      pdf.addImage(canvasDataURL, 'PNG', 20, 40, width * 0.62, height * 0.62);
    }
    //! after the for loop is finished running, we save the pdf.
    pdf.save(`${fileName}.pdf`);
  });
}

export function getScreenShots(capture) {
  return html2canvas(document.querySelector(capture)).then((canvas) => {
    // console.log('getCanvas', canvas);
    return canvas;
  });
}
