import useApiServices from './useApiServices';
import { globalAdminUrl } from './globalApi';
//
/**
 * @module PartnerServiceService
 * @returns  it return All DataSource Apis
 */

const reportsServices = {
  // common apis
  Getregistrationreports: (header, credential) =>
    useApiServices().postHeaderBodyApi(
      `${globalAdminUrl}/onboarding-admin/getregistrationreports`,
      header,
      credential
    ),
  GetregistrationDetails: (header, credential) =>
    useApiServices().postHeaderBodyApi(
      `${globalAdminUrl}/onboarding-admin/getDetailsReport`,
      header,
      credential
    ),
  GetregistrationreportsPage: (header, credential) =>
    useApiServices().postHeaderBodyApi(
      `${globalAdminUrl}/onboarding-admin/getRegistrationReportPage`,
      header,
      credential
    ),
  GetFilteredReports: (header, credential) =>
    useApiServices().postHeaderBodyApi(
      `${globalAdminUrl}/onboarding-admin/getFilteredReports`,
      header,
      credential
    ),
  SetApplicationStatus: (header) =>
    useApiServices().postHeaderWithoutBodyApi(
      `${globalAdminUrl}/onboarding-admin/setApplicationStatus`,
      header
    ),
  GetImage: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/onboarding-admin/getImage`, token),
  GetZipDoc: (token, applicationNo) =>
    useApiServices().postHeaderWithoutBodyApi(
      `${globalAdminUrl}/onboarding-admin/all-doc/${applicationNo}`,
      token
    ),
  CreateNewOrderRequest: (credential, header) =>
    useApiServices().postApi(
      `${globalAdminUrl}/onboarding-admin/createNewOrderRequest`,
      credential,
      header
    ),
  // CreateNewOrderRequest: (credential, header) =>
  //   useApiServices().postApi(`${globalAdminUrl}/journey/createNewOrderRequest`, credential, header),
  SetCustomerData: (header, credential) =>
    useApiServices().postHeaderBodyApi(
      `${globalAdminUrl}/onboarding-admin/setCustomerData`,
      header,
      credential
    ),
  ListForAgent: (header, credential) =>
    useApiServices().postHeaderBodyApi(
      `${globalAdminUrl}/onboarding-v1/listForAgent`,
      header,
      credential
    ),
  GenerateTokenForEKYC: (header, credential) =>
    useApiServices().postHeaderBodyApi(
      `${globalAdminUrl}/onboarding-v1/generateTokenForEKYC`,
      header,
      credential
    ),
  SetFCMToken: (header, credential) =>
    useApiServices().postHeaderBodyApi(
      `${globalAdminUrl}/onboarding-v1/setFCMToken`,
      header,
      credential
    ),
  UploadEKYCScreenShots: (headerCredential, credential) =>
    useApiServices().postFormDataApiWithHeaders(
      `${globalAdminUrl}/onboarding-v1/uploadEKYCScreenShots`,
      headerCredential,
      credential
    ),
  GetKYCStatus: (headerCredential) =>
    useApiServices().getApi(`${globalAdminUrl}/onboarding-v1/getKYCStatus`, headerCredential),
  UpdateOrderStatus: (headerCredential) =>
    useApiServices().postHeaderWithoutBodyApi(
      `${globalAdminUrl}/onboarding-v1/updateOrderStatus`,
      headerCredential
    ),
  GenerateFormPdf: (credential) =>
    useApiServices().postBodyCredentialApi(
      `${globalAdminUrl}/onboarding-admin/generate-form-pdf`,
      credential
    )
};

export default reportsServices;
