import { Box, Button } from '@mui/material';
import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { userDataFromLocal } from './utils/getUserDetails';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL_ADMIN = process.env.REACT_APP_BASE_ADMIN_URL;

// capture reload option

function NoAccess() {
  const navigate = useNavigate();

  useEffect(() => {}, []);

  return (
    <Box textAlign="center">
      You do not have permission to access this page, please refer to your system administrator
      <br />
      <Button
        onClick={(e) => {
          if (userDataFromLocal().userTypeId === 1) {
            window.open('https://itid.cattelecom.com/adfs/oauth2/logout');
            localStorage.removeItem('adminUserDataValue');
            navigate(`${BASE_URL}/${BASE_URL_ADMIN}`, { replace: true });
          } else if (userDataFromLocal().userTypeId === 2) {
            localStorage.removeItem('adminUserDataValue');
            // window.location.href = `https://regisplus.nc.ntplc.co.th/dev/staff`;
            navigate(`${BASE_URL}/${BASE_URL_ADMIN}/`, { replace: true });
          } else {
            navigate(`${BASE_URL}/${BASE_URL_ADMIN}/`, { replace: true });
          }
        }}
      >
        Login
      </Button>
    </Box>
  );
}
export default NoAccess;
