import { useState, useEffect, useContext } from 'react';
// material
import {
  Container,
  Stack,
  Grid,
  Box,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card
} from '@mui/material';
import Typography from '@mui/material/Typography';
import CryptoJS from 'crypto-js';

import { styled } from '@mui/material/styles';
import { StyledTableRow, StyledTableCell, StyledIconButton } from '../utils/Styling';
// table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// icons

import { AiFillEdit } from 'react-icons/ai';
import ReactQuill from 'react-quill';

// components
import Page from '../Page';
import ConfigureHeader from './ConfigureHeader';
import PageTitle from '../PageHeading';
import BrudCrumbs from '../BreadCrumbs';

import configurationService from '../../services/configurationService';
import { userDataFromLocal } from '../utils/getUserDetails';
import Validation from '../../global/Validation';
import Loader from '../../global/Loader';
import CloseIcon from '@mui/icons-material/Close';

import 'react-quill/dist/quill.snow.css';
import { globalAdminUrl } from '../../services/globalApi';
import { GlobalInfo } from '../../../App';
import Scrollbar from '../Scrollbar';
import { fDate, fDateTime } from '../utils/formatTime';

const {
  GetConsentLanguageList,

  GetConsentConfiguration,
  SetConsentConfiguration
} = configurationService;

export default function TermsOfService() {
  const [consentLanguageList, setGetConsentLanguageList] = useState([]);
  const [loginDataDetails, setLoginDataDetails] = useState([]);
  const [language, setLanguage] = useState([]);
  const [getConsentConfiguration, setGetConsentConfiguration] = useState([]);

  // validation
  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');
  const [validationMsgTOS, setvalidationMsgTOS] = useState('');
  const [openloader, setOpenLoader] = useState(false);

  const [open, setOpen] = useState(false);
  const [consentObject, setConsentObject] = useState('');

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const [editStep, setEditStep] = useState(false);
  const [htmlData, setHtmlData] = useState('Please Paste Your Term of Service');
  const Input = styled('input')({
    display: 'none'
  });
  const handleClose = () => {
    setOpen(false);
  };

  const saveHtmlDataToApi = async () => {
    try {
      setOpenLoader(true);

      const base64 = btoa(unescape(encodeURIComponent(htmlData)));
      const credential = {
        id: getConsentConfiguration.id,
        consentTitle: getConsentConfiguration.consentTitle,
        consentDesc: getConsentConfiguration.consentDesc,
        consentContent: getConsentConfiguration.consentContent,
        consentFileUrl: getConsentConfiguration.consentFileUrl,
        consentSeq: getConsentConfiguration.consentSeq,
        isActive: getConsentConfiguration.isActive,
        isSkippable: getConsentConfiguration.isSkippable,
        acceptanceMandatory: getConsentConfiguration.acceptanceMendatory,
        fileContants: base64
      };

      const setConsentFile = await SetConsentConfiguration(
        { userId: loginDataDetails.userId },
        credential
      );
      setEditStep(false);
    } catch (error) {
      console.log(error);
    } finally {
      setOpenLoader(false);
    }
  };

  useEffect(() => {
    const adminUserDataValue = localStorage.getItem('adminUserDataValue');
    // const adminUserDataValue = {
    //   userId: 1,
    //   organizationId: 1,
    //   // languageId: 1,
    //   consentId: 2,
    //   configurationId: 125
    // };
    if (adminUserDataValue !== null) {
      const bytes = CryptoJS.AES.decrypt(adminUserDataValue, 'adminUserData');
      const originalAdmiuserData = bytes.toString(CryptoJS.enc.Utf8);
      const adminUserDate = originalAdmiuserData ? JSON.parse(originalAdmiuserData) : {};

      // const adminUserDate = adminUserDataValue;

      setLoginDataDetails(adminUserDate);

      // getting consent obj fro local strorage
      const localConstObj = localStorage.getItem(
        'e13ehdnf06b-72p6-4581-86a3-c0a465e63d75-consentObject'
      );
      if (localConstObj) {
        const decryptConsentObj = CryptoJS.AES.decrypt(JSON.parse(localConstObj), 'consentObject');

        const ObjTOString = decryptConsentObj.toString(CryptoJS.enc.Utf8);

        const parseObj = JSON.parse(ObjTOString);

        setConsentObject(parseObj);

        (async () => {
          setOpenLoader(true);
          const headerCredential = {
            userId: adminUserDate.userId,
            organizationId: adminUserDate.organizationId,
            consentId: 2,
            configurationId: parseObj?.configurationId
          };
          const GetConsentLanguageListResponse = await GetConsentLanguageList(headerCredential);
          if (GetConsentLanguageListResponse.code === 100) {
            setOpenLoader(false);
            setGetConsentLanguageList(GetConsentLanguageListResponse.data);
          }
        })();
      }
    }
  }, []);
  useEffect(() => {}, [consentObject]);
  return (
    <Page title="Configure">
      {/* header component   */}
      {/* <ConfigureHeader /> */}

      <Container maxWidth={false}>
        <Loader openloader={openloader} />
        <Validation
          openValidation={openValidation}
          validationMsg={validationMsg}
          handleClose={handleClose}
          setOpenValidation={setOpenValidation}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="pageHeading"
        >
          <PageTitle info="Terms of service" />
          <Box className="brudCrumbs">
            <BrudCrumbs />
          </Box>
        </Stack>

        <Card className="cardWrap" style={{ marginBottom: '15px' }}>
          <Scrollbar>
            <TableContainer
              sx={{
                minWidth: 800,
                overflow: 'auto',
                borderTop: '1px solid #eaedf1'
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>LANGUAGE</StyledTableCell>
                    <StyledTableCell>MODIFIED ON</StyledTableCell>
                    <StyledTableCell width="125">ACTION</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {consentLanguageList !== undefined && consentLanguageList.length > 0
                    ? consentLanguageList.map((lang, index) => {
                        console.log('lang', lang);
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell>
                              {/* {lang.languageName} */}
                              {lang.languageName.toLowerCase() === 'thai'
                                ? 'Thai'
                                : lang.languageName.toLowerCase() === 'english'
                                ? 'English'
                                : lang.languageName.toLowerCase() === 'chinese'
                                ? 'Chinese'
                                : lang.languageName.toLowerCase() === 'cambodian'
                                ? 'Cambodia'
                                : lang.languageName.toLowerCase() === 'burmese'
                                ? ' Myanmar'
                                : 'English'}
                            </StyledTableCell>
                            <StyledTableCell>
                              {lang.lastUpdatedOn !== null ? fDateTime(lang.lastUpdatedOn) : ''}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Tooltip title="View" placement="top" arrow>
                                <StyledIconButton
                                  className="squareIconButton actionButton"
                                  onClick={(e) => {
                                    setEditStep(true);
                                    setLanguage(lang.languageName);
                                    (async () => {
                                      setOpenLoader(true);
                                      const headerCredential = {
                                        userId: loginDataDetails.userId,
                                        organizationId: loginDataDetails.organizationId,
                                        languageId: lang.languageId,
                                        consentId: 2,
                                        configurationId: consentObject?.configurationId
                                      };
                                      const GetConsentConfigurationResponse =
                                        await GetConsentConfiguration(headerCredential);
                                      if (GetConsentConfigurationResponse.code === 100) {
                                        setGetConsentConfiguration(
                                          GetConsentConfigurationResponse.data
                                        );
                                        // const userDetils = userDataFromLocal();
                                        const userDetils = {
                                          sessionId:
                                            'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NzExODIzNDQsInVzZXJJZCI6NTI3fQ.3bUqNvAQS8i05B__-tP6YDTd71QMu5nGLWZDg1UJXow'
                                        };
                                        const headers = {
                                          'Content-Type': 'application/json',
                                          userId: loginDataDetails.userId,
                                          fileName:
                                            GetConsentConfigurationResponse.data.consentFileUrl
                                        };
                                        if (userDetils.sessionId) {
                                          headers.sessionid = userDetils.sessionId;
                                        }
                                        fetch(`${globalAdminUrl}/admin/getConsentFile`, {
                                          method: 'GET',
                                          headers: headers
                                        })
                                          .then((response) => {
                                            if (response.ok) {
                                              // setOpenLoader(false);
                                              return response.text();
                                            }
                                            return {
                                              status: 'error',
                                              msg: '',
                                              data: []
                                            };
                                          })
                                          .then((htmlText) => {
                                            setHtmlData(htmlText);
                                            setOpenLoader(false);
                                          })
                                          .catch((error) => {
                                            return {
                                              status: 'error',
                                              msg: 'Server not responding',
                                              data: []
                                            };
                                          });
                                      } else {
                                        // setEditStep(false);
                                        setOpenLoader(false);
                                        setOpen(true);
                                        setvalidationMsgTOS(
                                          GetConsentConfigurationResponse.message
                                        );
                                        setHtmlData('');
                                      }
                                    })();
                                  }}
                                >
                                  <AiFillEdit size={14} />
                                </StyledIconButton>
                              </Tooltip>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    : ''}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
        {/* {editStep === 'editInstruction' ? <>Hello</> : ''} */}
        {editStep === true ? (
          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={12} marginBottom={3}>
              <Box className="tableHeadingBox displayFlex" mb={2}>
                <Typography variant="subtitle1">Edit Terms of Service</Typography>
                <Box className="displayFlex">
                  {/* <Button className="mainBtn mr-2">Preview</Button> */}
                  <Button className="mainBtn" onClick={(e) => saveHtmlDataToApi()}>
                    Save
                  </Button>
                </Box>
              </Box>
              <Box className="cardOuter cardOuterGrayBorder cardNoHover" px={2} py={2}>
                <Typography variant="body2" className="formLabel">
                  Please Paste Your Term of Service in below box
                </Typography>

                <HtmlEditor htmlData={htmlData} setHtmlData={setHtmlData} />
              </Box>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle className="popupTitle">
            <Typography variant="h6">Confirmation</Typography>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="alert-dialog-description">{validationMsgTOS}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                handleClose(e);
              }}
              className="mainBtn"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}

function HtmlEditor({ htmlData, setHtmlData }) {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image', 'video'],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ['clean']
    ]
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
    'color',
    'background',
    'clean'
  ];
  return (
    <ReactQuill
      theme="snow"
      value={htmlData}
      onChange={setHtmlData}
      modules={modules}
      formats={formats}
      style={{ height: 400, paddingBottom: '50px' }}
    />
  );
}
