import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  Tabs,
  Tab
} from '@mui/material';
import { Box } from '@mui/system';

// components
import Page from '../Page';
import BrudCrumbs from '../BreadCrumbs';
import PageTitle from '../PageHeading';
import DecisionTab from './ViewTabs/DecisionTab';
import FCRReportTab from './ViewTabs/FCRReportTab';
import IdentityTab from './ViewTabs/IdentityTab';
import KYCFormTab from './ViewTabs/KYCFormTab';

export default function ViewPending() {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [selectSuspiciousDue, setSelectSuspiciousDue] = useState(' ');
  return (
    <Page title="View Pending || Onboard">
      <Container maxWidth={false}>
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="View Pending" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Tabs
            value={value}
            onChange={handleChange}
            className="tabOuter"
            aria-label="basic tabs example"
            classes={{ indicator: 'tabIndicator' }}
          >
            <Tab label="Decision" classes={{ selected: 'tabSelected', root: 'tabDefault' }} />
            <Tab label="KYC Form" classes={{ selected: 'tabSelected', root: 'tabDefault' }} />
            <Tab label="FCR Report" classes={{ selected: 'tabSelected', root: 'tabDefault' }} />
            <Tab label="Identity" classes={{ selected: 'tabSelected', root: 'tabDefault' }} />
          </Tabs>
          {/* first tab */}
          <TabPanel value={value} index={0}>
            <DecisionTab />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <KYCFormTab />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <FCRReportTab />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <IdentityTab />
          </TabPanel>
        </Box>
      </Container>
    </Page>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingTop: '1rem' }}>{children}</Box>}
    </div>
  );
}
