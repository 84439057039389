import { useEffect, useState } from 'react';
// material
import {
  Container,
  Stack,
  Grid,
  Box,
  IconButton,
  TextField,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card
} from '@mui/material';
import CryptoJS from 'crypto-js';
import Typography from '@mui/material/Typography';

import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { StyledTableRow, StyledTableCell, StyledIconButton } from '../utils/Styling';
// table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// icons

import { AiFillEdit } from 'react-icons/ai';

// images
import photoDummy from './photoDummy.jpg';
// components
import Page from '../Page';
import ConfigureHeader from './ConfigureHeader';
import PageTitle from '../PageHeading';
import BrudCrumbs from '../BreadCrumbs';
import configurationService from '../../services/configurationService';
import { fDate } from '../utils/formatTime';
import { userDataFromLocal } from '../utils/getUserDetails';
import { globalAdminUrl } from '../../services/globalApi';

import Validation from '../../global/Validation';
import Loader from '../../global/Loader';
import CloseIcon from '@mui/icons-material/Close';
import Scrollbar from '../Scrollbar';

const { GetTutorialLanguageList, GetTutorialByLanguageId } = configurationService;

export default function Instruction() {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const [editStep, setEditStep] = useState(false);
  const Input = styled('input')({
    display: 'none'
  });

  // Set Language state
  const [language, setLanguage] = useState([]);
  const [currentlangId, setCurrentlangId] = useState([]);

  // validation
  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');
  const [validationMsgTOS, setvalidationMsgTOS] = useState('');
  const [openloader, setOpenLoader] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpenValidation(false);
  };
  const handleCloseInstruction = () => {
    setOpenValidation(false);
  };

  const handleFileUpload = async (e, setPreview, files, setUploadImage) => {
    let file = '';
    if (files) {
      file = files;
    } else {
      file = e.target.files[0];
    }
    setUploadImage(file);
    setPreview(URL.createObjectURL(file));
  };
  const dragOver = (e) => {
    e.preventDefault();
  };
  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };
  const fileDrop = (e, setPreview, setUploadImage) => {
    e.preventDefault();
    const files = e.dataTransfer.files;

    handleFileUpload(e, setPreview, files[0], setUploadImage);
  };

  const [loginDataDetails, setLoginDataDetails] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [rowEditData, setRowEditData] = useState([]);

  const [refresh, setRefresh] = useState(null);

  useEffect(() => {
    const adminUserDataValue = localStorage.getItem('adminUserDataValue');
    // const adminUserDataValue = {
    //   userId: 1,
    //   organizationId: 1,
    //   consentId: 1
    // };
    if (adminUserDataValue !== null) {
      const bytes = CryptoJS.AES.decrypt(adminUserDataValue, 'adminUserData');
      // console.log('bytes', bytes);
      const originalAdmiuserData = bytes.toString(CryptoJS.enc.Utf8);
      // console.log('bytes', originalAdmiuserData);
      const adminUserDate = originalAdmiuserData ? JSON.parse(originalAdmiuserData) : {};

      // const adminUserDate = adminUserDataValue;
      // console.log('bytes', adminUserDate);
      setLoginDataDetails(adminUserDate);

      (async () => {
        setOpenLoader(true);
        const obj = {
          userId: userDataFromLocal().userId,
          organizationId: userDataFromLocal().organizationId
          // userId: 1,
          // organizationId: 1
        };
        const getInstructionData = await GetTutorialLanguageList(obj);

        if (getInstructionData.code === 100) {
          setOpenLoader(false);
          setRowData(getInstructionData.data);
        }
      })();
    }
  }, []);

  const submitfile = async (e, fileData) => {
    // validation

    rowEditData.forEach((item) => (item.isError = false));

    rowEditData.forEach((item) => {
      setOpenLoader(true);

      const formData = new FormData();
      if (!item.id) {
        setOpenValidation(true);
        setValidationMsg('Please get you id');
        setOpenLoader(false);
        item.isError = true;
        return;
      }

      if (!item.name) {
        setOpenValidation(true);
        setValidationMsg('Please enter your name');
        setOpenLoader(false);
        item.isError = true;
        return;
      }

      if (!item.tutorialSeq) {
        setOpenValidation(true);
        setValidationMsg('Please enter you tutorial sequence');
        setOpenLoader(false);
        item.isError = true;
        return;
      }
      if (!item.tutorialLogo) {
        setOpenValidation(true);
        setValidationMsg('Please upload image file');
        setOpenLoader(false);
        item.isError = true;
        return;
      }
    });

    const filtred = rowEditData.filter((item) => item.isError);

    setOpenLoader(false);

    if (filtred.length === 0) {
      const tutorialSeqArray = [];

      rowEditData.forEach((item, index) => {
        tutorialSeqArray.push(item.tutorialSeq);
      });

      const toFindDuplicates = (arry) => arry.filter((item, index) => arry.indexOf(item) !== index);

      if (toFindDuplicates(tutorialSeqArray).length > 0) {
        setOpenValidation(true);
        setValidationMsg('Dublicate tutorial sequence not allowed');
        return;
      }

      rowEditData.forEach((item, rowIndex) => {
        setOpenLoader(true);

        const formData = new FormData();
        formData.append(
          'body',
          new Blob(
            [
              JSON.stringify({
                id: item.id,
                tutorialHeading: item.tutorialHeading,
                tutorialName: item.name,
                tutorialDescription: item.description,
                tutorialSeq: item.tutorialSeq,
                isSkippable: item.isSkippable,
                isActive: item.isActive
              })
            ],
            {
              type: 'application/json'
            }
          )
        );
        formData.append('file', item.file ? item.file : '');

        const userDetils = userDataFromLocal();
        const headers = {
          userId: loginDataDetails.userId
        };
        if (userDetils.sessionId) {
          headers.sessionid = userDetils.sessionId;
        }

        return fetch(`${globalAdminUrl}/admin/setTutorialConfiguration`, {
          method: 'POST',
          headers: headers,
          body: formData
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.code === 100) {
              if (rowIndex === rowEditData.length - 1) {
                setOpenLoader(false);
                setEditStep(false);
              }
            } else {
            }
          })
          .catch((err) => console.log('isSuccess', err));
      });
    }
  };

  return (
    <Page title="Configure">
      {/* header component   */}
      {/* <ConfigureHeader /> */}

      <Container maxWidth={false}>
        <Box pt={3} pb={3} mb={4}>
          <Loader openloader={openloader} />
          <Validation
            openValidation={openValidation}
            validationMsg={validationMsg}
            handleClose={handleClose}
            setOpenValidation={setOpenValidation}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Instruction" />
            <Box className="brudCrumbs">
              <BrudCrumbs />
            </Box>
          </Stack>
          <Card className="cardWrap" style={{ marginBottom: '15px' }}>
            <Scrollbar>
              <TableContainer
                sx={{
                  minWidth: 800,
                  overflow: 'auto',
                  borderTop: '1px solid #eaedf1'
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>LANGUAGE</StyledTableCell>
                      <StyledTableCell>MODIFIED ON</StyledTableCell>
                      <StyledTableCell width="125">ACTION</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {rowData.length > 0 &&
                      rowData.map((rowItem, itemIndex) => {
                        console.log('rowItem', rowItem);
                        return (
                          <StyledTableRow>
                            <StyledTableCell>
                              {/* {IfKeyExits(rowItem.languageName)} */}
                              {rowItem.languageName.toLowerCase() === 'thai'
                                ? 'Thai'
                                : rowItem.languageName.toLowerCase() === 'english'
                                ? 'English'
                                : rowItem.languageName.toLowerCase() === 'chinese'
                                ? 'Chinese'
                                : rowItem.languageName.toLowerCase() === 'cambodian'
                                ? 'Cambodia'
                                : rowItem.languageName.toLowerCase() === 'burmese'
                                ? 'Myanmar'
                                : 'English'}
                            </StyledTableCell>
                            <StyledTableCell>
                              {IfKeyExits(rowItem.languageName)
                                ? isValiddate(rowItem.languageName)
                                  ? fDate(IfKeyExits(rowItem.languageName))
                                  : 'N/A'
                                : 'N/A'}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Tooltip title="View" placement="top" arrow>
                                <StyledIconButton
                                  className="squareIconButton actionButton"
                                  onClick={(e) => {
                                    setEditStep(false);
                                    setLanguage(rowItem.languageName);
                                    setCurrentlangId(rowItem.languageId);
                                    (async () => {
                                      setTimeout(() => {
                                        setEditStep(true);
                                      }, 1000);
                                      setOpenLoader(true);
                                      const obj = {
                                        userId: userDataFromLocal().userId,
                                        organizationId: userDataFromLocal().organizationId,
                                        languageId: rowItem.languageId
                                        // userId: 1,
                                        // organizationId: 1,
                                        // languageId: 1
                                      };

                                      const FiredApiGetTutorialByLanguageId =
                                        await GetTutorialByLanguageId(obj);
                                      if (FiredApiGetTutorialByLanguageId.code === 100) {
                                        setOpenLoader(false);

                                        FiredApiGetTutorialByLanguageId.data.forEach(
                                          (item) => (item.file = '')
                                        );
                                        setRowEditData(FiredApiGetTutorialByLanguageId.data);
                                      } else {
                                        // setEditStep(false);
                                        setOpenLoader(false);
                                        setOpen(true);
                                        setvalidationMsgTOS(
                                          FiredApiGetTutorialByLanguageId.message
                                        );
                                      }
                                    })();
                                  }}
                                >
                                  <AiFillEdit size={14} />
                                </StyledIconButton>
                              </Tooltip>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
          {/* {editStep === 'editInstruction' ? <>Hello</> : ''} */}
          {editStep === true ? (
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} sm={12} md={12} marginBottom={3}>
                <Box className="tableHeadingBox displayFlex" mb={2}>
                  <Typography variant="subtitle1">
                    Language :{' '}
                    {language.toLowerCase() === 'thai'
                      ? 'Thai'
                      : language.toLowerCase() === 'english'
                      ? 'English'
                      : language.toLowerCase() === 'chinese'
                      ? 'Chinese'
                      : language.toLowerCase() === 'cambodian'
                      ? 'Cambodia'
                      : language.toLowerCase() === 'burmese'
                      ? 'Myanmar'
                      : 'English'}
                  </Typography>
                  <Button className="mainBtn" onClick={(e) => submitfile(e)}>
                    Save
                  </Button>
                </Box>
                <Box className="cardOuter cardOuterGrayBorder cardNoHover">
                  <TableContainer sx={{ overflow: 'auto' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell width="100">SEQUENCE</StyledTableCell>
                          <StyledTableCell>HEADING</StyledTableCell>
                          <StyledTableCell>DESCRIPTION</StyledTableCell>
                          <StyledTableCell width="100">IMAGE</StyledTableCell>
                          <StyledTableCell width="50">SKIP</StyledTableCell>
                          <StyledTableCell width="85">REQUIRE</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rowEditData.length > 0
                          ? rowEditData.map((item, index) => (
                              <StyledTableRow key={index}>
                                <StyledTableCell>
                                  <InputComponent
                                    mainArray={rowEditData}
                                    fieldValue={item.tutorialSeq}
                                    index={index}
                                    setArrayObject={setRowEditData}
                                    key="tutorialSeq"
                                    objKey="tutorialSeq"
                                    type="number"
                                    isTexrea="false"
                                  />
                                </StyledTableCell>
                                <StyledTableCell>
                                  <InputComponent
                                    mainArray={rowEditData}
                                    fieldValue={item.tutorialHeading}
                                    index={index}
                                    setArrayObject={setRowEditData}
                                    key="tutorialHeading"
                                    objKey="tutorialHeading"
                                    type="text"
                                    isTexrea="true"
                                  />
                                </StyledTableCell>
                                <StyledTableCell>
                                  <InputComponent
                                    mainArray={rowEditData}
                                    fieldValue={item.description}
                                    index={index}
                                    setArrayObject={setRowEditData}
                                    objKey="description"
                                    type="text"
                                    isTexrea="true"
                                  />
                                </StyledTableCell>
                                <StyledTableCell>
                                  <FileUploadComp
                                    index={index}
                                    rowEditData={rowEditData}
                                    item={item}
                                    setArrayObject={setRowEditData}
                                    setRefresh={setRefresh}
                                  />
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Switch
                                    {...label}
                                    // defaultChecked
                                    checked={item.isSkippable === 1}
                                    color="success"
                                    size="small"
                                    disableRipple="true"
                                    onChange={(e) => {
                                      const prevArray = rowEditData;
                                      prevArray[index].isSkippable = e.target.checked ? 1 : 0;
                                      setRowEditData(prevArray);
                                      setRefresh(new Date());
                                    }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Switch
                                    {...label}
                                    checked={item.isActive === 1}
                                    color="success"
                                    size="small"
                                    disableRipple="true"
                                    onChange={(e) => {
                                      const prevArray = rowEditData;
                                      prevArray[index].isActive = e.target.checked ? 1 : 0;
                                      setRowEditData(prevArray);
                                      setRefresh(new Date());
                                    }}
                                  />
                                </StyledTableCell>
                              </StyledTableRow>
                            ))
                          : ''}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
          <Dialog
            open={open}
            onClose={handleCloseInstruction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
          >
            <DialogTitle className="popupTitle">
              <Typography variant="h6">Confirmation</Typography>
              <IconButton onClick={handleCloseInstruction} size="small">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <DialogContentText id="alert-dialog-description">
                {validationMsgTOS}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(e) => {
                  // fireSetOrganizationapi(e, targetItem);
                  handleCloseInstruction(e);
                }}
                className="mainBtn"
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </Page>
  );
}

const ifObjectBlank = (obj) => Object.keys(obj).length > 0;

const IfKeyExits = (objkey) => {
  if (objkey) {
    return objkey;
  }
  return 'N/A';
};

const isValiddate = (d) => {
  const timestamp = Date.parse(d);
  if (Number.isNaN(timestamp) === false) {
    return true;
  }
  return false;
};

function TextFieldComponent({ tutorialSeq, rowEditData, setArrayData, index, setRefresh }) {
  const [refreshInput, setRefreshInput] = useState(null);
  return (
    <TextField
      fullWidth
      type="number"
      inputProps={{
        className: 'textInputTable'
      }}
      value={tutorialSeq}
      onChange={(e) => {
        const prevArray = rowEditData;

        prevArray[index].tutorialSeq = e.target.value;

        setArrayData(prevArray);
        setRefreshInput(new Date());
      }}
    />
  );
}

const InputComponent = ({
  mainArray,
  fieldValue,
  index,
  setArrayObject,
  type,
  isTexrea,
  objKey
}) => {
  const isMultiLine = {};
  if (isTexrea === 'true') {
    isMultiLine.multiline = true;
    isMultiLine.rows = 4;
    isMultiLine.multiline = true;
    isMultiLine.InputProps = { className: 'textAreaOuter' };
  } else {
    isMultiLine.inputProps = {
      className: 'textInputTable'
    };
  }

  const [value, setValue] = useState('');

  useEffect(() => {
    // QuestionAnswer.faqAnswer
    setValue(fieldValue);
  }, [fieldValue]);
  return (
    <TextField
      fullWidth
      type={type}
      value={value}
      onChange={(e) => {
        if (!isMultiLine.multiline) {
          let txtLength = e.target.value;
          if (txtLength.length < 3) {
            setValue(e.target.value);
          }
        } else {
          setValue(e.target.value);
        }
      }}
      onBlur={(e) => {
        const prevObjectArray = mainArray;
        prevObjectArray[index][objKey] = e.target.value;
        prevObjectArray[index].isChange = true;
        setArrayObject(prevObjectArray);
      }}
      {...isMultiLine}
      placeholder="Write Answer"
    />
  );
};

function FileUploadComp({ index, rowEditData, item, setArrayObject, setRefresh }) {
  useEffect(() => {
    fetch(item.tutorialLogo)
      .then((response) => response.blob())
      .then((blob) => {
        const prevArray = rowEditData;
        prevArray[index].file = blob;
        setArrayObject(prevArray);
      });
  }, []);
  return (
    <>
      <input
        accept="image/png, image/gif, image/jpeg"
        className="fileInput"
        id={`contained-button-file-${index}`}
        multiple
        type="file"
        onChange={(e) => {
          const prevArray = rowEditData;
          prevArray[index].tutorialLogo = URL.createObjectURL(e.target.files[0]);
          prevArray[index].file = e.target.files[0];

          setArrayObject(prevArray);
          setRefresh(new Date());
        }}
      />
      <label htmlFor={`contained-button-file-${index}`} className="uploadDocBoxLabel">
        <Box
          variant="contained"
          color="primary"
          component="span"
          // onDragOver={dragOver}
          // onDragEnter={dragEnter}
          // onDragLeave={dragLeave}
          // onDrop={(e) => fileDrop(e, setPhotoPreview, setUploadImageFile)}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <img src={item.tutorialLogo} alt="Instruction" width="100%" className="previewImage" />
        </Box>
      </label>
    </>
  );
}
function binEncode(data) {
  const binArray = [];
  let datEncode = '';

  for (let i = 0; i < data.length; i++) {
    binArray.push(data[i].charCodeAt(0).toString(2));
  }
  for (let j = 0; j < binArray.length; j++) {
    const pad = padding_left(binArray[j], '0', 8);
    datEncode += pad + ' ';
  }
  function padding_left(s, c, n) {
    if (!s || !c || s.length >= n) {
      return s;
    }
    const max = (n - s.length) / c.length;
    for (let i = 0; i < max; i++) {
      s = c + s;
    }
    return s;
  }
}
function _base64ToArrayBuffer(base64) {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}
