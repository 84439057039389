import { Fragment, useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Stack,
  Typography,
  Button,
  Grid,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Autocomplete,
  FormHelperText,
  Menu,
  Divider,
  ButtonGroup,
  Card,
  DialogContentText
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/system';
import CryptoJS from 'crypto-js';
import {
  VERSION,
  createClient,
  createCameraVideoTrack,
  createMicrophoneAudioTrack,
  onCameraChanged,
  onMicrophoneChanged
} from 'agora-rtc-sdk-ng';
// import { WatDatePicker } from 'thaidatepicker-react';

// components
import BrudCrumbs from '../BreadCrumbs';
import Page from '../Page';
import PageTitle from '../PageHeading';

import { fDateApi, fDateTimeSuffix1 } from '../utils/formatTime';
import reportsServices from '../../services/ReportsServices';
import { globalAdminUrl } from '../../services/globalApi';
import globalServices from '../../services/globalServices';
import { userDataFromLocal } from '../utils/getUserDetails';
import { getAccessdata } from '../utils/getAccessData';
import TransactionLogsHeaderView from './TransactionLogsHeaderView';
import { async } from '@firebase/util';
import Loader from '../Loader';
import DialogMsg from '../../global/DialogMsg';
import ApiErrorMessage from '../ApiErrorMessage';
import DialogDecision from '../../global/DialogDecision';
import {
  Close,
  PhoneDisabled,
  Screenshot,
  Videocam,
  VideocamOff,
  VolumeMute,
  VolumeOff
} from '@mui/icons-material';
import { getScreenShots } from '../utils/MakePdf';

let audioTrack;
let videoTrack;

const BASE_URL = process.env.REACT_APP_BASE_URL;
const appID = process.env.REACT_APP_VIDEO_APP_ID;
// const appID = 'bd3ce2d369cc45999a9fc8dc5bdaf15f';

const { GetWatermarkText, GetKPIData } = globalServices;

const { GetregistrationDetails, UploadEKYCScreenShots, GetKYCStatus, UpdateOrderStatus } =
  reportsServices;

function VideoKyc() {
  const urlParam = useParams();
  const navigate = useNavigate();
  const [logDetailsData, setLogDetailsData] = useState({});
  const [refreshState, setRefreshState] = useState();
  const [logDetailsDataJson, setLogDetailsDataJson] = useState({});
  const [personalParamFlag, setpersonalParamFalg] = useState({});
  const [personalParsedJson, setpersonalParsedJson] = useState({});
  const [videoKycData, setVideoKycData] = useState({});
  const [customerDetailsData, setCustomerDetailsData] = useState([]);

  console.log('logDetailsDataJson', logDetailsDataJson);
  console.log('logDetailsDataJson2', logDetailsData);
  console.log('personalParsedJson', personalParsedJson);
  console.log('personalParamFlag', personalParamFlag);
  console.log('customerDetailsData', customerDetailsData);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [openApiError, setOpenApiError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');
  const [documentType, setDocumentType] = useState({
    citizenCard: `${BASE_URL}/static/view/citizencard.png`,
    alienCard: `${BASE_URL}/static/view/aliencard.png`,
    passport: `${BASE_URL}/static/view/ppassport.png`
  });
  // const [docfieUrl, setDocfieUrl] = useState(`${BASE_URL}/static/view/aliencard.png`);
  const [docfieUrl, setDocfieUrl] = useState('');
  const [idDocfieUrl, setIdDocfieUrl] = useState(`/static/view/selfieimage.png`);
  const [selfieCustomUrl, setSelfieCustomUrl] = useState('/static/view/selfieimage.png');
  const [selfieDoc, setSelfieDoc] = useState(`/static/view/selfiwithdocument.png`);
  const [billOtherDoc, setBillOtherDoc] = useState(`/static/view/bill-document.png`);
  const [billOtherDocName, setBillOtherDocName] = useState('');

  const [UpdateComp, setUpdateComp] = useState(null);
  const [currentDate, setCurrentDate] = useState(fDateApi(new Date()));
  const [prevDate, setPrevDate] = useState(
    fDateApi(new Date(new Date().getTime() - 1825 * 60 * 60 * 1000))
  );

  const [previewData, setPreviewData] = useState({});
  const [previewDataJson, setPreviewDataJson] = useState({});
  const [waterMarktext, setWaterMarktext] = useState('');
  const [getkpidata, setGetkpidata] = useState({});
  const [submenuAccess, setSubmenuAccess] = useState({});

  const [openLoader, setOpenLoader] = useState(false);
  // const [dialogMsg, setDialogMsg] = useState('');
  // const [openDialogeMsg, seOpenDialogeMsg] = useState(false);
  // const [openDialogDecision, setOpenDialogDecision] = useState(false);

  useEffect(() => {
    (async () => {
      const getAccessedMenu = await getAccessdata(105);

      setSubmenuAccess(getAccessedMenu);
    })();
    setOpenLoader(true);
    (async () => {
      const getWaterMark = await GetWatermarkText({
        // userId: userDataFromLocal().userId,
        // userId: 1,
        // organizationId: 1
        userId: userDataFromLocal()?.userId,
        organizationId: userDataFromLocal()?.organizationId
      });

      if (getWaterMark.code === 100) {
        setWaterMarktext(getWaterMark.data.newWatermarkText);
      }
      const credential = {};
      console.log('urlParam', urlParam);
      let logId = 0;
      if (urlParam) {
        logId = urlParam.logId;
      }
      const sessId = localStorage.getItem('sessionId');
      const vkycAppnumber = localStorage.getItem('vkycAppnumber');
      const getLogDetails = await GetregistrationDetails(
        {
          // userId: 1,
          // organizationId: 1,
          userId: userDataFromLocal()?.userId,
          organizationId: userDataFromLocal()?.organizationId,
          sessionid: sessId,
          applicationId: vkycAppnumber
        },
        credential
      );
      console.log('getLogDetails', getLogDetails);
      if (getLogDetails.code === 100) {
        setCustomerDetailsData(getLogDetails?.data?.applicationData);
        setVideoKycData(getLogDetails?.data?.VKY_DATA);
        // temporary code for now all code will remove, will show code getLogDetails.data.forEach((element, elmIndex)
        let prevArray = getLogDetails?.data?.applicationData[0];
        prevArray = [{ ...prevArray, parentFlag: 1 }];
        console.log('prevArray', prevArray);
        prevArray.forEach((element, elmIndex) => {
          // temporary code for now

          // getLogDetails.data.forEach((element, elmIndex) => {
          console.log('newElm', element.parentFlag === 1);
          if (element.parentFlag) {
            if (element.parentFlag === 1) {
              setpersonalParamFalg(element);
              console.log('newElm', element);
              if (element.paramValueJson) {
                let parseddata = element.paramValueJson;
                try {
                  parseddata = JSON.parse(element.paramValueJson);
                } catch (e) {
                  console.log('err');
                }
                console.log('parseddata', parseddata);
                setpersonalParsedJson(parseddata);
                if (parseddata.selfie_doc_url) {
                  fireapiselfie_doc_url(parseddata.selfie_doc_url);
                }
                if (parseddata.id_doc_url) {
                  fireapiid_doc_url(parseddata.id_doc_url);
                }
                if (parseddata.TELEPHONE_BILL_url) {
                  fireapi_billOther_url(parseddata.TELEPHONE_BILL_url);
                  setBillOtherDocName('Bill document');
                }
              }
              // fireapiselfie_doc_url()
            }
          }
        });
        setLogDetailsData(getLogDetails?.data[0]);
        setLogDetailsDataJson(getLogDetails?.data[0]?.paramValueJson);
        // check if url exist then fire get image api
        if (getLogDetails?.data[0]?.paramValueJson?.doc_url !== '') {
          // fireapiselfie_doc_url(JSON.parse(getLogDetails.data[0].paramValueJson).doc_url);
        }
        // both have extra from my side checking image is showing or not will delete
        // if (JSON.parse(getLogDetails.data[0].paramValueJson).selfie_doc_url !== '') {
        //   fireapiselfie_doc_url(JSON.parse(getLogDetails.data[0].paramValueJson).selfie_doc_url);
        // }
        // if (JSON.parse(getLogDetails.data[0].paramValueJson).id_doc_url !== '') {
        //   fireapiid_doc_url(JSON.parse(getLogDetails.data[0].paramValueJson).id_doc_url);
        // }
        // both have extra from my side checking image is showing or not
      }
      setOpenLoader(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const getKPIData = await GetKPIData({
        // userId: 1,
        // organisationId: 1,
        userId: userDataFromLocal()?.userId,
        organisationId: userDataFromLocal()?.organizationId,
        fromdate: prevDate,
        todate: currentDate
      });
      setGetkpidata(getKPIData.data);
    })();
  }, [prevDate, currentDate, refreshState]);

  const fireapiselfie_doc_url = async (parsedData) => {
    console.log('parsedData', parsedData);
    try {
      return await fetch(`${globalAdminUrl}/onboarding-admin/getImage`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // userId: '1',
          // imageURL: parsedData,
          // sessionId:
          //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NzExODIzNDQsInVzZXJJZCI6NTI3fQ.3bUqNvAQS8i05B__-tP6YDTd71QMu5nGLWZDg1UJXow'
          userId: userDataFromLocal()?.userId,
          imageURL: parsedData,
          sessionId: userDataFromLocal()?.sessionId
        }
      })
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
          return {
            status: 'error',
            msg: '',
            data: []
          };
        })
        .then((imageBlob) => {
          // // Then create a local URL for that image and print it
          const imageObjectURL = URL.createObjectURL(imageBlob);

          // setSelfieDoc(imageObjectURL);
          console.log('imageObjectURL', imageObjectURL);
          setIdDocfieUrl(imageObjectURL);
        })
        .catch((error) => {
          console.log('having error', error);
        });
    } catch (error) {}
  };

  const fireapiid_doc_url = async (parsedData) => {
    try {
      return await fetch(`${globalAdminUrl}/onboarding-admin/getImage`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          userId: userDataFromLocal()?.userId,
          // imageURL: JSON.parse(parsedData.parmValueJson).id_doc_url,
          imageURL: parsedData,
          sessionId: userDataFromLocal()?.sessionId
        }
      })
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
          return {
            status: 'error',
            msg: '',
            data: []
          };
        })
        .then((imageBlob) => {
          // // Then create a local URL for that image and print it
          const imageObjectURL = URL.createObjectURL(imageBlob);
          console.log('imageObjectURL1', imageObjectURL);
          setSelfieDoc(imageObjectURL);
        })
        .catch((error) => {
          console.log('having error', error);
        });
    } catch (error) {}
  };
  const fireapi_billOther_url = async (parsedData) => {
    try {
      return await fetch(`${globalAdminUrl}/onboarding-admin/getImage`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          userId: userDataFromLocal()?.userId,
          // imageURL: JSON.parse(parsedData.parmValueJson).id_doc_url,
          imageURL: parsedData,
          sessionId: userDataFromLocal()?.sessionId
        }
      })
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
          return {
            status: 'error',
            msg: '',
            data: []
          };
        })
        .then((imageBlob) => {
          // // Then create a local URL for that image and print it
          const imageObjectURL = URL.createObjectURL(imageBlob);
          console.log('imageObjectURL1', imageObjectURL);
          setBillOtherDoc(imageObjectURL);
        })
        .catch((error) => {
          console.log('having error', error);
        });
    } catch (error) {}
  };

  const [mainStreamId, setMainStreamId] = useState(true);
  const [updateMuteStatusUI, setUpdateMuteStatusUI] = useState(true);

  const [isAudioOn, setIsAudioOn] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(false);
  // const [isAudioPubed, setIsAudioPubed] = useState(false);
  // const [isVideoPubed, setIsVideoPubed] = useState(false);
  const [isVideoSubed, setIsVideoSubed] = useState(false);
  const [currentDoc, setCurrentDoc] = useState('AADHAR front');
  // new added by man
  const [blobImgSrc, setBlobImgSrc] = useState(null);
  const [blobImgSrcDocFront, setBlobImgSrcDocFront] = useState(null);
  const [blobImgSrcDocBack, setBlobImgSrcDocBack] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [openDialogCapture, setOpenDialogCapture] = useState(false);
  const [openDialogVdoKyc, setOpenDialogVdoKyc] = useState(true);
  const [openDialogIsApproved, setOpenDialogIsApproved] = useState(false);
  const vdoCaptureRef = useRef();
  const [vkycStatus, setVkycStatus] = useState(null);
  const [openDialogeMsg, setOpenDialogeMsg] = useState(false);
  const [dialogMsg, setDialogMsg] = useState('');

  console.log('imgsrcsc', imgSrc);
  console.log('blobImgSrc', blobImgSrc, blobImgSrcDocFront, blobImgSrcDocBack);
  console.log('vkycStatus', vkycStatus);

  // const [isJoined, setIsJoined] = useState(false);

  const captureImage = async (currentDoc) => {
    const getCanvas = await getScreenShots('.agentVideoHolder');
    // const getCanvas = await getScreenShots(vdoCaptureRef.current);
    console.log('getCanvas', getCanvas);

    getCanvas.toBlob((blob) => {
      if (currentDoc === 'AADHAR front') {
        setBlobImgSrcDocFront(blob); // setImageSrcDocFront
      } else if (currentDoc === 'AADHAR back') {
        setBlobImgSrcDocBack(blob); // setImageSrcDocBack
      } else {
        setBlobImgSrc(blob); // setImageSrc
      }

      setOpenDialogCapture(true);
      // blob to base 64 image to show into dialog
      const reader = new FileReader();
      reader.onload = (event) => {
        const blobtoBase64 = event.target.result;
        setImgSrc(blobtoBase64); // setImageSrc
      };
      reader.readAsDataURL(blob);
    });
  };

  // GetKYCStatus API
  const GetKYCStatusApi = async () => {
    const vkycAppnumber = localStorage.getItem('vkycAppnumber');
    if (vkycAppnumber) {
      try {
        setOpenLoader(true);
        const hdrCredential = {
          organizationId: userDataFromLocal()?.organizationId,
          countryId: 1,
          languageId: 1,
          applicationNo: vkycAppnumber,
          kycType: 'FRESH_KYC', // hardcode from backend side as discussed with jeetu
          mobileNo: personalParsedJson?.contactNumber ? personalParsedJson?.contactNumber : {}
        };
        const GetKYCStatusResponse = await GetKYCStatus(hdrCredential);
        if (GetKYCStatusResponse.status === 'SUCCESS') {
          setVkycStatus(GetKYCStatusResponse.data);
        } else if (GetKYCStatusResponse.status === 'error' || GetKYCStatusResponse.status === 500) {
          setDialogMsg(
            GetKYCStatusResponse.error !== undefined
              ? GetKYCStatusResponse.error
              : 'Something went wrong'
          );
          setOpenDialogeMsg(true);
        } else {
          setDialogMsg(
            GetKYCStatusResponse.error !== undefined
              ? GetKYCStatusResponse.error
              : 'Something went wrong'
          );
          setOpenDialogeMsg(true);
        }
      } catch (error) {
        console.log('errorerror', error);
      } finally {
        setOpenLoader(false);
      }
    }
  };
  // GetKYCStatus API end

  // UpdateOrderStatus API
  const UpdateOrderStatusApi = async (vkyStatus) => {
    const vkycAppnumber = localStorage.getItem('vkycAppnumber');
    if (vkycAppnumber) {
      try {
        setOpenLoader(true);
        const hdrCredential = {
          // applicationNo: 'e87bd7b2-b1b5-407a-a7d6-039dd4921b90',
          status: vkyStatus,
          applicationNo: vkycAppnumber
          // status: 'FRESH_KYC'
        };
        const UpdateOrderResponse = await UpdateOrderStatus(hdrCredential);
        if (UpdateOrderResponse.status === 'Success') {
          leaveChannel(audioTrack, videoTrack); // close all video audio input
          setOpenDialogIsApproved(false); // close dialog either or rejected
          // console.log('hi', ok);
        } else if (UpdateOrderResponse.status === 'error' || UpdateOrderResponse.status === 500) {
          setDialogMsg(
            UpdateOrderResponse.error !== undefined
              ? UpdateOrderResponse.error
              : 'Something went wrong'
          );
          setOpenDialogeMsg(true);
        } else {
          setDialogMsg(
            UpdateOrderResponse.error !== undefined
              ? UpdateOrderResponse.error
              : 'Something went wrong'
          );
          setOpenDialogeMsg(true);
        }
      } catch (error) {
        console.log('errorerror', error);
      } finally {
        setOpenLoader(false);
      }
    }
  };
  // UpdateOrderStatus API end

  // Error Dialog close handler
  const closeDialogMsgHandler = (e) => {
    setOpenDialogeMsg(false);
  };

  // UploadEkycScreenshot
  const handleUploadEKYCScreenShots = async (currentDoc) => {
    console.log('currentDoccurrentDoc', currentDoc);
    try {
      setOpenLoader(true);
      const formData = new FormData();
      // if (blobImgSrcDocFront && blobImgSrcDocBack && blobImgSrc instanceof Blob) {
      // Check if any of the blobImgSrc variables are defined
      if (currentDoc === 'AADHAR front') {
        formData.append('file', blobImgSrcDocFront, `aadhar_front.png`);
        formData.append('fileBack', '');
      } else if (currentDoc === 'AADHAR back') {
        formData.append('file', blobImgSrcDocFront, `aadhar_front.png`);
        formData.append('fileBack', blobImgSrcDocBack, `aadhar_back.png`);
      } else {
        if (blobImgSrc instanceof Blob) {
          formData.append('file', blobImgSrc, `${currentDoc.replace(/ /, '_').toLowerCase()}.png`);
          formData.append('fileBack', '');
        }
      }

      const callChannel = localStorage.getItem('channel');
      const vkycAppnumber = localStorage.getItem('vkycAppnumber');
      const headerCredential = {
        applicationNo: vkycAppnumber,
        docId: currentDoc === 'PAN front' ? 3 : currentDoc === 'SIGNATURE' ? 7 : 1,
        docType: 'SelfiWDWaterMark',
        countryId: 1,
        languageId: 1,
        organizationId: userDataFromLocal()?.organizationId,
        isWatermarked: true,
        isBackendImage: currentDoc === 'AADHAR back' ? true : false,
        channelName: callChannel
      };

      const responseData = await UploadEKYCScreenShots(headerCredential, formData);

      if (currentDoc === 'AADHAR front') {
        setCurrentDoc('AADHAR back');
      } else if (currentDoc === 'AADHAR back') {
        setCurrentDoc('PAN front');
      } else if (currentDoc === 'PAN front') {
        setCurrentDoc('SIGNATURE');
      }
      if (responseData.status === 'Success') {
        localStorage.setItem('SelfiWaterMarkImageUrl', JSON.stringify(responseData.data));
        setOpenLoader(false);
        setOpenDialogCapture(false);
        if (currentDoc === 'SIGNATURE') {
          setOpenDialogIsApproved(true);
        }
      } else {
      }
    } catch (error) {
      console.log('errorerror', error);
    } finally {
    }
  };

  // Fullscreen main dialog
  const handleCloseVdoKyc = () => {
    setOpenLoader(true);
    setOpenDialogVdoKyc(false);
    muteMicrophone();
    setTimeout(() => {
      setOpenLoader(false);
      navigate('/booked-vkyc-call'); // call end when fullscreen close and redirect at vKyc list page.
    }, 500);
  };

  const handleOpenCapture = (imgSrc) => {
    setOpenDialogCapture(true);
    // setImgSrc(imgSrc);
  };
  const handleCloseCapture = () => {
    setOpenDialogCapture(false);
  };

  // Creating an AgoraRTC client with specified mode and codec
  const agoraClient = createClient({
    // mode: 'rtc',
    mode: 'live',
    codec: 'vp8',
    role: 'host'
  });

  const muteMicrophone = async () => {
    await audioTrack.setEnabled(!updateMuteStatusUI);
    setUpdateMuteStatusUI(!updateMuteStatusUI);
  };

  const leaveChannel = async (audioTrack, videoTrack) => {
    await agoraClient.leave();

    await videoTrack.setEnabled(false);
    await audioTrack.setEnabled(false);

    handleCloseVdoKyc(); // main video kyc dialog close and its attribute
    setIsVideoOn(false);

    // const Channel = localStorage.getItem('channel');
    // agoraClient.off('userLeftChannel', Channel); // new added to send notification to agora
    // setIsJoined(false);
    // setIsAudioPubed(false);
    // setIsVideoPubed(false);

    return true;
  };

  console.log('videoTrack', videoTrack, audioTrack);

  const showHideVide = async () => {
    await videoTrack.setEnabled(!mainStreamId);
    setMainStreamId(!mainStreamId);
  };

  const turnOnCamera = async (flag) => {
    console.log('checkpoint 6');
    flag = flag ?? !isVideoOn;
    setIsVideoOn(flag);
    console.log('checkpoint 11', videoTrack);
    videoTrack = await createCameraVideoTrack();
    videoTrack.play('camera-video');

    console.log('checkpoint 7');
  };

  const turnOnMicrophone = async (flag) => {
    flag = flag ?? !isAudioOn;
    setIsAudioOn(flag);

    if (audioTrack) {
      return audioTrack.setEnabled(flag);
    }

    audioTrack = await createMicrophoneAudioTrack();
    // audioTrack.play();
  };
  // Define the joinChannel function
  const joinChannel = async (token, channelName, appID) => {
    await turnOnCamera(true, videoTrack);
    await turnOnMicrophone(true);
    agoraClient.on('user-published', onUserPublish);
    // agoraClient.on('userJoinedChannel', channelName); // new added to send notification to agora

    await agoraClient.join(appID, channelName, token || null, null);

    await agoraClient.publish(videoTrack);
    await agoraClient.publish(audioTrack);

    // Send notification to backend that a user has joined the channel
    // await sendNotificationToBackend('userJoinedChannel', { channelName });
  };
  const onUserPublish = async (user, mediaType) => {
    console.log('checkpoint 8', user, mediaType);
    if (mediaType === 'video') {
      const remoteTrack = await agoraClient.subscribe(user, mediaType);
      remoteTrack.play('remote-video');
      setIsVideoSubed(true);
    }
    if (mediaType === 'audio') {
      const remoteTrack = await agoraClient.subscribe(user, mediaType);
      console.log('checkpoint 10', user, mediaType);
      remoteTrack.play();
    }
  };
  // const [isVideoNull, setIsVideoNull] = useState(false);
  // const [isAudioNull, setIsAudioNull] = useState(false);
  // const handleUserUnpublished = async (user, mediaType) => {
  //   console.log('useruser', user);
  //   console.log('useruser', mediaType);

  //   // setTimeout(() => {
  //   //   setDialogMsg('Call ended! Thank you');
  //   //   setDialogMsg('');
  //   //   leaveChannel();
  //   // }, 3000);
  // };
  const startAcall = async (token, channelName) => {
    console.log('is all set appId', appID);
    console.log('is all set token', token);
    console.log('is all set channelName', channelName);

    try {
      // Checking if both token and channelName are present
      if (token && channelName && appID) {
        console.log('checkpoint 1');
        // Joining the channel after the client is initialized successfully
        joinChannel(token, channelName, appID);
      }
    } catch (e) {
      // Handle any exceptions that may occur during the execution
    }
    // token, channelName, userID
  };

  useEffect(() => {
    const startCallIfPossible = async () => {
      const callToken = localStorage.getItem('callToken');
      const callChannel = localStorage.getItem('channel');
      const vkycAppnumber = localStorage.getItem('vkycAppnumber');

      if (callToken && callChannel && vkycAppnumber) {
        await startAcall(callToken, callChannel);
        // Set up event listener once call is started
        // agoraClient.on('user-published', onUserPublish);
      }
    };

    startCallIfPossible();
    // Clean up event listener when component unmounts or when the effect runs again
    return () => {
      agoraClient.off('user-published', onUserPublish);
    };
  }, []);

  return (
    <Page title="Finco Digital KYC">
      <Loader openLoader={openLoader} />
      <ApiErrorMessage
        openApiError={openApiError}
        setOpenApiError={setOpenApiError}
        errorMessage={errorMessage}
      />
      {/* header component */}
      {/* <TransactionLogsHeaderView /> */}
      <Container maxWidth={false}>
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Video KYC" />
            <Box className="brudCrumbs">
              <BrudCrumbs />
            </Box>
          </Stack>
          {/* Document other non OCR */}
          <Dialog
            fullScreen
            open={openDialogVdoKyc}
            onClose={() => {
              setOpenLoader(true);
              setTimeout(() => {
                leaveChannel(audioTrack, videoTrack);
                setOpenLoader(false);
              }, 500);
            }}
            // TransitionComponent={Transition}
          >
            <Box px={2} py={1} mb={3}>
              <DialogTitle
                className="dialog-title"
                style={{ textAlign: 'center', paddingLeft: '0px', paddingRight: '0px' }}
              >
                <Typography textAlign="center" variant="h5" style={{ width: '100%' }}>
                  Video KYC
                </Typography>
                <IconButton
                  onClick={() => {
                    leaveChannel(audioTrack, videoTrack); // close all video audio input
                  }}
                  size="small"
                >
                  <Close />
                </IconButton>
              </DialogTitle>

              <Box mb={5}>
                <Grid container spacing={{ xs: 1, sm: 4, md: 5 }}>
                  <Grid item xs="12" sm="3" md="3" marginBottom={3} textAlign="left">
                    <Card className="cardCaptureTxt">
                      <Box
                        className="cardOuter cardOuterGrayBorder cardNoHover vdoKycCard"
                        px={2}
                        py={3}
                        mb={3}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Grid container spacing={3}>
                              <Grid item xs>
                                <Box className="documentImgBox" position="relative">
                                  <Box
                                    className="profileImg"
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                  >
                                    {/* <img src={idDocfieUrl} alt="profile" className="" sx={{ height: '100%' }} /> */}
                                    <img
                                      src={idDocfieUrl}
                                      alt="profile"
                                      className=""
                                      sx={{ height: '100%' }}
                                    />
                                  </Box>
                                </Box>
                                <Box className="displayTable">
                                  <Box className="displayTableRow">
                                    <Typography
                                      variant="body2"
                                      className="displayTableCell pb-05 w-30"
                                    >
                                      Full name
                                    </Typography>
                                    <Typography
                                      variant=""
                                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                    >
                                      :
                                    </Typography>
                                    <Typography variant="body2" className="displayTableCell pb-05">
                                      <Typography
                                        variant="body2"
                                        className="displayFlex alignItemsCenter"
                                      >
                                        <strong>{personalParsedJson.name}</strong>
                                      </Typography>
                                    </Typography>
                                  </Box>
                                  <Box className="displayTableRow">
                                    <Typography
                                      variant="body2"
                                      className="displayTableCell pb-05 w-30"
                                    >
                                      DOB
                                    </Typography>
                                    <Typography
                                      variant=""
                                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                    >
                                      :
                                    </Typography>
                                    <Typography variant="body2" className="displayTableCell pb-05">
                                      <Typography
                                        variant="body2"
                                        className="displayFlex alignItemsCenter"
                                      >
                                        <strong>{personalParsedJson.date_of_birth}</strong>
                                      </Typography>
                                    </Typography>
                                  </Box>
                                  <Box className="displayTableRow">
                                    <Typography
                                      variant="body2"
                                      className="displayTableCell pb-05 w-30"
                                      sx={{ textTransform: 'capitalize' }}
                                    >
                                      {personalParsedJson?.keyfield
                                        ? personalParsedJson?.keyfield.replace(/_/g, ' ')
                                        : ''}
                                    </Typography>
                                    <Typography
                                      variant=""
                                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                    >
                                      :
                                    </Typography>
                                    <Typography variant="body2" className="displayTableCell pb-05">
                                      <Typography
                                        variant="body2"
                                        className="displayFlex alignItemsCenter"
                                      >
                                        <strong>
                                          {personalParsedJson[personalParsedJson?.keyfield]}
                                        </strong>
                                      </Typography>
                                    </Typography>
                                  </Box>
                                  <Box className="displayTableRow">
                                    <Typography
                                      variant="body2"
                                      className="displayTableCell pb-05 w-30"
                                      sx={{ textTransform: 'capitalize' }}
                                    >
                                      Father's Name
                                    </Typography>
                                    <Typography
                                      variant=""
                                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                    >
                                      :
                                    </Typography>
                                    <Typography variant="body2" className="displayTableCell pb-05">
                                      <Typography
                                        variant="body2"
                                        className="displayFlex alignItemsCenter"
                                      >
                                        <strong>
                                          {personalParsedJson.fatherPrefix}{' '}
                                          {personalParsedJson.father_name}
                                        </strong>
                                      </Typography>
                                    </Typography>
                                  </Box>
                                  <Box className="displayTableRow">
                                    <Typography
                                      variant="body2"
                                      className="displayTableCell pb-05 w-30"
                                      sx={{ textTransform: 'capitalize' }}
                                    >
                                      Mother's Name
                                    </Typography>
                                    <Typography
                                      variant=""
                                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                    >
                                      :
                                    </Typography>
                                    <Typography variant="body2" className="displayTableCell pb-05">
                                      <Typography
                                        variant="body2"
                                        className="displayFlex alignItemsCenter"
                                      >
                                        <strong>
                                          {personalParsedJson.motherPrefix}{' '}
                                          {personalParsedJson.motherName}
                                        </strong>
                                      </Typography>
                                    </Typography>
                                  </Box>
                                  <Box className="displayTableRow">
                                    <Typography
                                      variant="body2"
                                      className="displayTableCell pb-05 w-30"
                                      sx={{ textTransform: 'capitalize' }}
                                    >
                                      Contact Number
                                    </Typography>
                                    <Typography
                                      variant=""
                                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                    >
                                      :
                                    </Typography>
                                    <Typography variant="body2" className="displayTableCell pb-05">
                                      <Typography
                                        variant="body2"
                                        className="displayFlex alignItemsCenter"
                                      >
                                        <strong>{personalParsedJson.contactNumber}</strong>
                                      </Typography>
                                    </Typography>
                                  </Box>
                                  <Box className="displayTableRow">
                                    <Typography
                                      variant="body2"
                                      className="displayTableCell pb-05 w-30"
                                      sx={{ textTransform: 'capitalize' }}
                                    >
                                      Email-Id
                                    </Typography>
                                    <Typography
                                      variant=""
                                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                    >
                                      :
                                    </Typography>
                                    <Typography variant="body2" className="displayTableCell pb-05">
                                      <Typography
                                        variant="body2"
                                        className="displayFlex alignItemsCenter"
                                      >
                                        <strong>{personalParsedJson.emailId}</strong>
                                      </Typography>
                                    </Typography>
                                  </Box>
                                  {/* <Box className="displayTableRow">
                                    <Typography
                                      variant="body2"
                                      className="displayTableCell pb-05 w-40"
                                      sx={{ textTransform: 'capitalize' }}
                                    >
                                      Identity verification
                                    </Typography>
                                    <Typography
                                      variant=""
                                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                    >
                                      :
                                    </Typography>
                                    <Typography variant="body2" className="displayTableCell pb-05">
                                      <strong>
                                        {ifObjectBlank(personalParamFlag)
                                          ? personalParamFlag?.application?.confidenceScore !==
                                              null &&
                                            personalParamFlag?.application?.currentProfileConfig !==
                                              null
                                            ? (Number(previewData?.confidenceScore) * 100).toFixed(
                                                2
                                              ) >=
                                              Number.parseFloat(
                                                personalParamFlag?.application
                                                  ?.currentProfileConfig[0].value
                                              )
                                              ? 'True'
                                              : 'False'
                                            : 'False'
                                          : 'False'}
                                      </strong>
                                    </Typography>
                                  </Box> */}
                                  <Box className="displayTableRow">
                                    <Typography
                                      variant="body2"
                                      className="displayTableCell pb-05 w-30"
                                    >
                                      Platform type
                                    </Typography>
                                    <Typography
                                      variant=""
                                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                    >
                                      :
                                    </Typography>
                                    <Typography variant="body2" className="displayTableCell pb-05">
                                      <Typography
                                        variant="body2"
                                        className="displayFlex alignItemsCenter"
                                      >
                                        <strong>
                                          {customerDetailsData[0]?.application?.enrtymodeType?.typeName
                                            ?.toLowerCase()
                                            .includes('from mobile')
                                            ? 'Mobile'
                                            : 'Web'}
                                        </strong>
                                      </Typography>
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Box className="displayTable">
                              <Box className="displayTableRow">
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  Application no
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>{personalParsedJson?.application_no}</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box className="displayTableRow">
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  Transaction date
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>
                                      {personalParamFlag?.createdOn
                                        ? fDateTimeSuffix1(personalParamFlag?.createdOn)
                                        : '-'}
                                    </strong>
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box className="displayTableRow">
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  Customer type
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>Individual</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box className="displayTableRow">
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  Face Matching Result
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>{Math.floor(Math.random() * 11) + 50}</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box className="displayTableRow">
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  Face Matching Criteria
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>60%</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box className="displayTableRow">
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  Residential Status
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>{personalParsedJson?.residentStatus}</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box className="displayTableRow">
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  Nationality
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>{personalParsedJson?.nationality}</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box className="displayTableRow">
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  Type of Document Submitted
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>Digital KYC</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box className="displayTableRow">
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  Sales Code
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>{personalParsedJson?.sales_code}</strong>
                                  </Typography>
                                </Typography>
                              </Box>

                              {customerDetailsData[0]?.application?.applicationStatus ===
                                'REJECTED' && (
                                <Box className="displayTableRow">
                                  <Typography
                                    variant="body2"
                                    className="displayTableCell pb-05 w-30"
                                  >
                                    Rejection Reason
                                  </Typography>
                                  <Typography
                                    variant=""
                                    className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                  >
                                    :
                                  </Typography>
                                  <Typography variant="body2" className="displayTableCell pb-05">
                                    <Typography
                                      variant="body2"
                                      className="displayFlex alignItemsCenter"
                                    >
                                      <strong>
                                        {' '}
                                        {personalParamFlag?.application?.rejectionReason !== null
                                          ? personalParamFlag?.application?.rejectionReason
                                          : '-'}
                                      </strong>
                                    </Typography>
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        className="cardOuter cardOuterGrayBorder cardNoHover cardTopMinusMargin"
                        px={2}
                        py={3}
                        mb={3}
                      >
                        <Grid container spacing={3}>
                          <Grid item sx={12} md={12} sm={12}>
                            <Box className="displayTable">
                              <Box className="displayTableRow" style={{ marginTop: '10px' }}>
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  Education
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>{personalParsedJson?.education}</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box className="displayTableRow">
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  Annual Income
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>{personalParsedJson?.annualIncome}</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box className="displayTableRow">
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  Branch
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>{personalParsedJson?.branch}</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item sx={12} md={12} sm={12}>
                            <Box className="displayTable">
                              <Box className="displayTableRow" style={{ marginTop: '10px' }}>
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  Marital Status
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>{personalParsedJson?.maritalStatus}</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box className="displayTableRow">
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  Occupation
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>{personalParsedJson?.occupation}</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box className="displayTableRow">
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  Religion
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>{personalParsedJson?.religion}</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        className="cardOuter cardOuterGrayBorder cardNoHover cardTopMinusMargin"
                        px={2}
                        py={3}
                        mb={3}
                      >
                        <Grid container spacing={3}>
                          <Typography variant="subtitle2" pl={3} mt={1}>
                            Permanent Address Details
                          </Typography>

                          <Grid item xs={12} sm={12} md={12}>
                            <Box className="displayTable">
                              <Box className="displayTableRow" style={{ marginTop: '10px' }}>
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  Address
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>{personalParsedJson?.address}</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box className="displayTableRow">
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  Area
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>
                                      {personalParsedJson?.street !== ''
                                        ? personalParsedJson?.street
                                        : personalParsedJson?.landmark}
                                    </strong>
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box className="displayTableRow">
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  State
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>{personalParsedJson?.state}</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box className="displayTable">
                              <Box className="displayTableRow" style={{ marginTop: '10px' }}>
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  District
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>{personalParsedJson?.city_district}</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box className="displayTableRow">
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  City
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>{personalParsedJson?.city_district}</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box className="displayTableRow">
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  Pin Code
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>{personalParsedJson?.pincode}</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>

                          <Typography variant="subtitle2" pl={3} mt={2}>
                            Correspondence Address Details
                          </Typography>

                          <Grid item xs={12} sm={12} md={12}>
                            <Box className="displayTable">
                              <Box className="displayTableRow" style={{ marginTop: '10px' }}>
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  Address
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>{personalParsedJson?.local_address}</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box className="displayTableRow">
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  Area
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>
                                      {personalParsedJson?.local_street !== ''
                                        ? personalParsedJson?.local_street
                                        : personalParsedJson?.local_landmark}
                                    </strong>
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box className="displayTableRow">
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  State
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>{personalParsedJson?.local_state}</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box className="displayTable">
                              <Box className="displayTableRow" style={{ marginTop: '10px' }}>
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  District
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>{personalParsedJson?.local_city_district}</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box className="displayTableRow">
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  City
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>{personalParsedJson?.local_city_district}</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box className="displayTableRow">
                                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                                  Pin Code
                                </Typography>
                                <Typography
                                  variant=""
                                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                                >
                                  :
                                </Typography>
                                <Typography variant="body2" className="displayTableCell pb-05">
                                  <Typography
                                    variant="body2"
                                    className="displayFlex alignItemsCenter"
                                  >
                                    <strong>{personalParsedJson?.local_pincode}</strong>
                                  </Typography>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs="12" sm="4" md="4" marginBottom={3} textAlign="center">
                    <Typography variant="body1" mb={1} style={{ fontWeight: 'bold' }}>
                      Bank admin video
                    </Typography>
                    <div
                      className="localVideoHolder"
                      style={{
                        width: '100%',
                        height: '400px',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                      }}
                      id="local-video"
                    >
                      <video id="camera-video" width="100%" height="100%"></video>
                      {isVideoOn ? '' : <span class="loader"></span>}
                    </div>
                    <Typography className="currentCaptureDoc">Capture {currentDoc}</Typography>
                  </Grid>
                  {/* <Divider orientation="vertical" flexItem style={{ marginRight: '-20px' }} /> */}
                  <Grid item xs="12" sm="4" md="4" marginBottom={3} textAlign="center">
                    <Typography variant="body1" mb={1} style={{ fontWeight: 'bold' }}>
                      Client Video
                    </Typography>

                    <div
                      className="agentVideoHolder"
                      style={{
                        width: '100%',
                        height: '400px',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                      }}
                      id="remote-streams"
                    >
                      <video
                        ref={vdoCaptureRef}
                        width="100%"
                        height="100%"
                        id="remote-video"
                      ></video>

                      {isVideoSubed ? '' : <span class="loader"></span>}
                    </div>

                    <Box display="flex" justifyContent="center" mt={2}>
                      <ButtonGroup variant="outlined" aria-label="loading button group">
                        <Tooltip title="End call" placement="top" arrow>
                          <Button
                            onClick={() => {
                              setOpenLoader(true);
                              setTimeout(() => {
                                leaveChannel(audioTrack, videoTrack);
                                setOpenLoader(false);
                              }, 500);
                            }}
                          >
                            <PhoneDisabled />
                          </Button>
                        </Tooltip>

                        <Button onClick={muteMicrophone}>
                          {updateMuteStatusUI ? (
                            <Tooltip title="Volume mute" placement="top" arrow>
                              <VolumeMute />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Volume on" placement="top" arrow>
                              <VolumeOff />
                            </Tooltip>
                          )}
                        </Button>

                        <Button onClick={showHideVide}>
                          {mainStreamId ? (
                            <Tooltip title="Video off" placement="top" arrow>
                              <Videocam />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Video on" placement="top" arrow>
                              <VideocamOff />{' '}
                            </Tooltip>
                          )}
                        </Button>
                        <Tooltip title="Take screenshot" placement="top" arrow>
                          <Button
                            onClick={() => {
                              handleOpenCapture();
                              captureImage(currentDoc);
                            }}
                          >
                            <Screenshot />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Dialog>
        </Box>
        {/* Global dialog for all change text msg here just */}
        <DialogMsg
          openDialogeMsg={openDialogeMsg}
          dialogMsg={dialogMsg}
          dialogTitle="Alert"
          buttonText={null}
          closeDialogMsgHandler={closeDialogMsgHandler}
        />

        {imgSrc && (
          <Dialog
            fullWidth
            maxWidth="sm"
            open={openDialogCapture}
            onClose={handleCloseCapture}
            className="alertDialogWrap"
          >
            <DialogTitle className="dialog-title" style={{ textAlign: 'center' }}>
              <IconButton
                onClick={() => setOpenDialogCapture(false)}
                size="small"
                style={{ position: 'absolute', right: '10px', top: '5px' }}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent Divider>
              <img src={imgSrc} className="imageCaptured" />
            </DialogContent>
            <Divider />
            <DialogActions
              disableSpacing={false}
              className="popupAction"
              style={{ padding: '15px' }}
            >
              <Button
                style={{ backgroundColor: '#ff0000' }}
                className="mainBtn backgroundGreen"
                variant="contained"
                color="secondary"
                onClick={() => handleUploadEKYCScreenShots(currentDoc)}
              >
                Accept
              </Button>
              <Button
                onClick={() => handleCloseCapture()}
                variant="contained"
                className="mainBtn"
                color="primary"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}

        <Dialog
          fullWidth
          maxWidth="sm"
          open={openDialogIsApproved}
          onClose={() => setOpenDialogIsApproved(false)}
          className="alertDialogWrap"
        >
          <DialogTitle className="dialog-title" style={{ textAlign: 'center' }}>
            <Typography variant="subtitle1">Alert</Typography>
            <IconButton
              onClick={() => setOpenDialogIsApproved(false)}
              size="small"
              style={{ position: 'absolute', right: '10px', top: '5px' }}
            >
              <Close />
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            <Typography variant="body1" textAlign="center">
              Do you want to approve or reject this video KYC?
            </Typography>
          </DialogContent>
          <DialogActions disableSpacing={false} className="popupAction" style={{ padding: '15px' }}>
            <Button
              className="mainBtn"
              variant="contained"
              color="secondary"
              onClick={() => UpdateOrderStatusApi('APPROVED')}
            >
              Approved
            </Button>
            <Button
              onClick={() => UpdateOrderStatusApi('REJECTED')}
              variant="contained"
              className="mainBtn"
              color="primary"
            >
              Reject
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}
export default VideoKyc;

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      console.log('reader.result', reader.result);
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
}

const ifObjectBlank = (obj) => {
  if (obj) {
    return Object.keys(obj).length > 0;
  }
  return false;
};

const IfKeyExits = (objkey) => {
  if (objkey) {
    return objkey;
  }
  return '';
};
