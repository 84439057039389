import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import settingFilled from '@iconify/icons-ant-design/setting-filled';
import { Task, PendingActionsOutlined, SimCardAlert, TouchApp } from '@mui/icons-material';
import { BiListCheck, BiListMinus } from 'react-icons/bi';
import { FiFileMinus, FiFilePlus, FiFileText } from 'react-icons/fi';

// ----------------------------------------------------------------------

const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL_ADMIN = process.env.REACT_APP_BASE_ADMIN_URL;

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  // {
  //   title: 'Dashboard',
  //   path: `${BASE_URL}/dashboard/app`,
  //   // path: `${BASE_URL}/${BASE_URL_ADMIN}/dashboard/app`,
  //   icon: getIcon(pieChart2Fill),
  //   id: 100,
  //   indexId: 0
  // },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon(shoppingBagFill)
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon(fileTextFill)
  //  },
  // {
  //   title: 'Dashboard',
  //   path: `/dashboard`,
  //   icon: getIcon(pieChart2Fill),
  //   id: 105,
  //   indexId: 1
  // },
  {
    title: 'KYC Logs',
    // path: `${BASE_URL}/transaction-logs`,
    path: `/kyc-logs`,
    icon: <FiFileText className="sideBarIcon" />,
    id: 101,
    indexId: 1
  },
  {
    title: 'Approved KYC',
    // path: `${BASE_URL}/transaction-logs`,
    path: `/approved-kyc`,
    icon: <FiFilePlus className="sideBarIcon" />,
    id: 102,
    indexId: 2
  },
  {
    title: 'Rejected KYC',
    // path: `${BASE_URL}/transaction-logs`,
    path: `/rejected-kyc`,
    icon: <FiFileMinus className="sideBarIcon" />,
    id: 103,
    indexId: 3
  },
  {
    title: 'vKYC',
    // path: `${BASE_URL}/transaction-logs`,
    path: `/booked-vkyc-call`,
    icon: getIcon(lockFill),
    id: 104,
    indexId: 4
  },
  // {
  //   title: 'Transaction order',
  //   path: `${BASE_URL}/${BASE_URL_ADMIN}/transaction-order`,
  //   icon: getIcon(lockFill),
  //   id: 130,
  //   indexId: 2
  // },
  {
    title: 'Configure',
    path: `/configure`,
    // path: `${BASE_URL}/${BASE_URL_ADMIN}/configure`,
    icon: getIcon(settingFilled),
    id: 110,
    indexId: 33
  },
  // // {
  // //   title: 'Master data',
  // //   path: `${BASE_URL}/${BASE_URL_ADMIN}/master-data`,
  // //   icon: getIcon(personAddFill),
  // //   id: 120,
  // //   indexId: 4
  // // },
  {
    title: 'Users',
    path: `/users`,
    icon: getIcon(peopleFill),
    id: 105,
    indexId: 5
  },
  {
    title: 'Manage Access',
    path: `/manage-access`,
    icon: <TouchApp />,
    id: 106,
    indexId: 6
  }
  // {
  //   title: 'Pending',
  //   path: `${BASE_URL}/pending`,
  //   // path: `${BASE_URL}/${BASE_URL_ADMIN}/manage-access`,
  //   icon: <PendingActionsOutlined />,
  //   id: 115,
  //   indexId: 6
  // },
  // {
  //   title: 'Alert',
  //   path: `${BASE_URL}/alert`,
  //   // path: `${BASE_URL}/${BASE_URL_ADMIN}/manage-access`,
  //   icon: <SimCardAlert />,
  //   id: 115,
  //   indexId: 6
  // },
  // {
  //   title: 'Approved',
  //   path: `${BASE_URL}/Approved`,
  //   // path: `${BASE_URL}/${BASE_URL_ADMIN}/manage-access`,
  //   icon: <Task />,
  //   id: 115,
  //   indexId: 6
  // }
];

export default sidebarConfig;
