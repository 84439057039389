import { useState, useEffect } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  Paper,
  Checkbox,
  Autocomplete,
  FormHelperText
} from '@mui/material';
import styled from '@emotion/styled';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { Box } from '@mui/system';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { userDataFromLocal } from '../utils/getUserDetails';
import BrudCrumbs from '../BreadCrumbs';
import Page from '../Page';
import PageTitle from '../PageHeading';
import Scrollbar from '../Scrollbar';

// importing APi Services
import globalServices from '../../services/globalServices';
import DialogMsg from '../../global/DialogMsg';

// Destructuring services from servces
const {
  GetRolesAccess,
  UpdateUserData,
  GetDefaultData,
  GetInternalUserList,
  SearchInternalUserList,
  GetInternalUserDetails
} = globalServices;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

function AddEditUser() {
  const navigate = useNavigate();
  const { rowId } = useParams();
  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [showHide, setShowHide] = useState('1');
  // console.log('showHideshowHide', showHide);
  const handleShowHide = (event) => {
    const getUserType = event.target.value;
    setShowHide(String(getUserType));
  };
  const [userType, setUserType] = useState('1');
  const [updateUserData, setUpdateUserData] = useState([]);
  const [selectBranch, setSelectBranch] = useState(' ');
  const [selectDepartment, setSelectDepartment] = useState(' ');
  const [selectDepartmentLevel, setSelectDepartmentLevel] = useState(' ');
  const [selectReportingManager, setSelectReportingManager] = useState(' ');
  const [selectAccess, setSelectAccess] = useState(' ');
  const [selectUserOrganization, setSelectUserOrganization] = useState(' ');
  const [selectInternalSalesManager, setSelectInternalSalesManager] = useState(' ');
  const [getRolesAccess, setGetRolesAccess] = useState([]);
  const [allUserType, setAllUserType] = useState([]);
  const [singleUserData, setSingleUserData] = useState('');

  const [openDialogeMsg, setOpenDialogeMsg] = useState(false);
  const [dialogMsg, setDialogMsg] = useState('');
  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const [openloader, setOpenLoader] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [internalUserEmail, setInternalUserEmail] = useState([]);
  const [internalUserObj, setInternalUserObj] = useState({
    emailAddress: '',
    selectAccessType: ' ',
    userFirstName: '',
    userMiddleName: '',
    userLastName: '',
    userEmailId: '',
    cathrPartyId: '',
    cathrGroupId: '',
    cathrSectionId: ''
  });
  const [externalUserObj, setExternalUserObj] = useState({
    emailAddress: '',
    selectAccessType1: ' ',
    userFirstName: '',
    userMiddleName: '',
    userLastName: '',
    mobileNumber: '',
    contactNumber: '',
    dob: '',
    password: '',
    userName: ''
  });

  console.log('externalUserObj', externalUserObj);

  // const addMoreMasterValue = () => {
  //   const prevValue = masterValue;
  //   const obj = { masterValue: '' };
  //   prevValue.push(obj);
  //   setMasterValue(prevValue);
  //   setRefreshComp(new Date());
  // };
  // const removeMasterValue = (index) => {
  //   const prevValue = masterValue;
  //   const removedItem = prevValue.filter((elm, ind) => ind !== index);
  //   setMasterValue(removedItem);
  //   setRefreshComp(new Date());
  // };

  const handleClose = () => {
    setOpenValidation(false);
  };

  const fireinternalHandler = (e, fieldName, ...otherValue) => {
    if (fieldName === 'emailAddress') {
      setInternalUserObj((prevState) => ({
        ...prevState,
        [fieldName]: otherValue[0]
      }));
    } else {
      setInternalUserObj((prevState) => ({
        ...prevState,
        [fieldName]: e.target.value
      }));
    }
  };
  const fireExternalHandler = (e, fieldName) => {
    setExternalUserObj((prevState) => ({
      ...prevState,
      [fieldName]: e.target.value
    }));
  };

  const fireInternalEmailAPi = async () => {
    // get user email

    const header = {
      userid: userDataFromLocal().userId,
      organizationId: userDataFromLocal().organizationId
    };
    const GetInternalUserListApi = await GetInternalUserList(header);
    if (GetInternalUserListApi.code === 100) {
      setInternalUserEmail(GetInternalUserListApi.data);
    }
  };

  const addUpdateUserInterNal = async () => {
    // validation of fields
    // debugger
    if (userType === '1') {
      if (!internalUserObj.emailAddress) {
        setOpenValidation(true);
        // setValidationMsg('Please enter your email address');
        return;
      }
      if (errorMsg) {
        setOpenValidation(true);
        setValidationMsg(errorMsg);
        return;
      }
      if (internalUserObj.selectAccessType === ' ') {
        setOpenValidation(true);
        // setValidationMsg('Please enter your access type');
        return;
      }
      if (!internalUserObj.userFirstName) {
        setOpenValidation(true);
        // setValidationMsg('Please enter your first name');
        return;
      }
      if (!internalUserObj.userLastName) {
        setOpenValidation(true);
        // setValidationMsg('Please enter your last name');
        return;
      }
    }

    if (userType === '2') {
      if (!externalUserObj.userFirstName) {
        setOpenValidation(true);
        // setValidationMsg('Please enter your first name');
        return;
      }
      if (!externalUserObj.userLastName) {
        setOpenValidation(true);
        // setValidationMsg('Please enter your last name');
        return;
      }
      if (!externalUserObj.dob) {
        setOpenValidation(true);
        // setValidationMsg('Please enter your date of birth');
        return;
      }
      if (!externalUserObj.mobileNumber) {
        setOpenValidation(true);
        // setValidationMsg('Please enter your mobile number');
        return;
      }
      if (externalUserObj.selectAccessType1 === ' ') {
        setOpenValidation(true);
        // setValidationMsg('Please enter your access type');
        return;
      }

      if (!externalUserObj.userName) {
        setOpenValidation(true);
        // setValidationMsg('Please enter your user name');
        return;
      }
      if (!externalUserObj.password) {
        setOpenValidation(true);
        // setValidationMsg('Please enter your password');
        return;
      }
    }
    setOpenLoader(true);
    const credential = {
      roleId:
        userType === '1'
          ? Number(internalUserObj.selectAccessType)
          : Number(externalUserObj.selectAccessType1),
      userLastName: userType === '1' ? internalUserObj.userLastName : externalUserObj.userLastName,
      organizationId: userDataFromLocal().organizationId,
      departmentId: userDataFromLocal().departmentId
        ? String(userDataFromLocal().departmentId)
        : '',
      userFirstName:
        userType === '1' ? internalUserObj.userFirstName : externalUserObj.userFirstName,
      userMiddleName:
        userType === '1' ? internalUserObj.userMiddleName : externalUserObj.userMiddleName,
      userTypeId: Number(userType),
      requestType: rowId !== undefined ? 'E' : 'A'
    };
    if (userType === '1') {
      credential.emailAddress = internalUserObj.emailAddress;
      credential.userName = internalUserObj.emailAddress;
    }
    if (userType === '2') {
      credential.dob = externalUserObj.dob;
      credential.password =
        externalUserObj.password === singleUserData.password ? '' : externalUserObj.password;
      credential.userName = externalUserObj.userName;
      credential.contactNumber = externalUserObj.mobileNumber;
      credential.mobileNumber = externalUserObj.mobileNumber;
      credential.emailAddress = externalUserObj.userName;
    }

    const header = {
      userid: userDataFromLocal().userId,
      organizationId: userDataFromLocal().organizationId
    };
    const FiredUpdateUserDataApi = await UpdateUserData(header, credential);
    if (FiredUpdateUserDataApi.code === 100) {
      setOpenLoader(false);

      setUpdateUserData(FiredUpdateUserDataApi.data);
      navigate(`/users`);
    } else if (FiredUpdateUserDataApi.code === 101) {
      setOpenLoader(false);
      // add a popup when api failed
      // setOpenValidation(true);
      // setValidationMsg(FiredUpdateUserDataApi.message);
      setOpenDialogeMsg(true);
      setDialogMsg(FiredUpdateUserDataApi.message);
    } else {
      setOpenDialogeMsg(true);
      setDialogMsg(FiredUpdateUserDataApi.message);
    }
  };

  // Error Dialog close handler
  const closeDialogMsgHandler = (e) => {
    setOpenDialogeMsg(false);
  };

  // setUserData Add/Edit/Delete Api
  useEffect(() => {
    (async () => {
      const hdrCredential = {
        userid: userDataFromLocal().userId
        // userid: 1
      };
      const FiredGetRolesAccessApi = await GetRolesAccess(hdrCredential);
      if (FiredGetRolesAccessApi.code === 100) {
        setGetRolesAccess(FiredGetRolesAccessApi.data);
      }
      hdrCredential.organizationId = userDataFromLocal().organizationId;
      // hdrCredential.organizationId = 1;

      const GetUserType = await GetDefaultData(hdrCredential);
      if (GetUserType.code === 100) {
        setAllUserType(GetUserType.data.userType);
      } else if (GetUserType.code == 101) {
        // add a popup when api failed
        setOpenValidation(true);
        setValidationMsg(GetUserType.message);
      }
    })();
    // setAllUserType();
    // edit mode

    if (rowId !== undefined) {
      const SingleUserData = localStorage.getItem(
        'e13ehdnf06b-72p6-4581-86a3-c0a465e63d75-UserData'
      );
      let UserData = {};
      if (SingleUserData !== null) {
        const UserDataDecrypt = CryptoJS.AES.decrypt(JSON.parse(SingleUserData), 'UserData');
        const UserDataDecrypt123 = UserDataDecrypt.toString(CryptoJS.enc.Utf8);
        UserData = UserDataDecrypt123 ? JSON.parse(UserDataDecrypt123) : {};
        // const UserData = JSON.parse(UserDataDecrypt123);
        setSingleUserData(UserData);

        // fill data in edit mode
        setUserType(String(UserData?.userTypeId));
        setShowHide(String(UserData?.userTypeId));
      }

      if (String(UserData.userTypeId) === '1') {
        // check if emailAddress is found
        if (UserData?.emailAddress) {
          (async () => {
            const header = {
              userId: userDataFromLocal().userId,
              organizationId: userDataFromLocal().organizationId,
              uid: UserData?.emailAddress
            };
            const getUserDataByUID = await GetInternalUserDetails(header);

            if (getUserDataByUID.code === 100) {
              setInternalUserObj({
                ...internalUserObj,
                userFirstName: getUserDataByUID.data.first_name,
                userLastName: getUserDataByUID.data.last_name,
                userEmailId: getUserDataByUID.data.email_id,
                emailAddress: UserData?.emailAddress,
                cathrPartyId:
                  getUserDataByUID.data.cathrPartyId !== null
                    ? getUserDataByUID.data.cathrPartyId
                    : '',
                cathrGroupId:
                  getUserDataByUID.data.cathrGroupId !== null
                    ? getUserDataByUID.data.cathrGroupId
                    : '',
                cathrSectionId:
                  getUserDataByUID.data.cathrSectionId !== null
                    ? getUserDataByUID.data.cathrSectionId
                    : ''
              });
            }
          })();
        }
      } else if (String(UserData.userTypeId) === '2') {
        // edit info for external user

        setExternalUserObj((prevState) => ({
          ...prevState,
          emailAddress: UserData?.emailAddress === null ? '' : UserData?.emailAddress,
          selectAccessType1: UserData?.roleId === null ? '' : UserData?.roleId,
          userFirstName: UserData?.userFirstName === null ? '' : UserData?.userFirstName,
          userMiddleName: UserData?.userMiddleName === null ? '' : UserData?.userMiddleName,
          userLastName: UserData?.userLastName === null ? '' : UserData?.userLastName,
          mobileNumber: UserData?.mobileNumber === null ? '' : UserData?.mobileNumber,
          contactNumber: '',
          dob: UserData.dob === null ? '' : UserData.dob,
          // password: UserData.password ? '' : UserData.password ,
          password: UserData.password,
          // password:  '',
          userName: UserData.emailAddress
        }));
      }
    }
  }, []);
  // setUserData Add/Edit/Delete Api

  return (
    <Page title="User">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info={rowId ? 'Edit User' : 'Add New User'} />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Box className="mb-3">
                <Typography variant="h6" className="formLabel">
                  User type
                </Typography>
                <Select
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  value={userType}
                  onChange={(e) => {
                    handleShowHide(e);
                    setUserType(String(e.target.value));
                  }}
                >
                  <MenuItem disabled="disabled" value="~">
                    Select
                  </MenuItem>
                  {allUserType.length > 0 &&
                    allUserType.map((userType, userIndex) => (
                      <MenuItem value={userType.id}>
                        {userType.typeName}
                        {/* {userType.typeCode === 'AADHAR_CARD'
                          ? 'Internal'
                          : userType.typeCode === 'PAN_CARD'
                          ? 'External'
                          : // : userType.typeCode === 'PASSPORT'
                            // ? 'PASSPORT'
                            // : userType.typeCode === 'DRIVING_LICENSE'
                            // ? 'DRIVING LICENSE'
                            ''} */}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
            </Grid>
          </Grid>
          <Box className="">
            {showHide === '1' && (
              <>
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  General Information
                </Typography>
                <Box
                  className="cardOuter cardOuterGrayBorder cardNoHover cardTopMinusMargin"
                  px={2}
                  py={3}
                  mb={3}
                >
                  <Grid container spacing={3} marginBottom={3}>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          User name
                        </Typography>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo1"
                          options={internalUserEmail}
                          size="small"
                          renderInput={(params) => (
                            <TextField {...params} fullWidth placeholder="Choose a username" />
                          )}
                          onOpen={(e, inputVal) => {
                            if (e.target.value === '') {
                              fireInternalEmailAPi();
                            }
                            // fire email list api here
                          }}
                          onClose={(e) => {}}
                          // setUserName(newVal)
                          // value={internalUserObj.emailAddress}
                          onHighlightChange={(e, value, reason) => {}}
                          onChange={async (e, newVal) => {
                            fireinternalHandler(e, 'emailAddress', newVal);
                            if (newVal === null) {
                              setInternalUserObj({
                                ...internalUserObj,
                                userFirstName: '',
                                userLastName: '',
                                userEmailId: '',
                                emailAddress: ''
                              });
                            } else {
                              const header = {
                                userId: userDataFromLocal().userId,
                                organizationId: userDataFromLocal().organizationId,
                                uid: newVal
                              };
                              const getUserDataByUID = await GetInternalUserDetails(header);

                              if (getUserDataByUID.code === 100) {
                                setInternalUserObj({
                                  ...internalUserObj,
                                  userFirstName: getUserDataByUID.data.first_name,
                                  userLastName: getUserDataByUID.data.last_name,
                                  userEmailId: getUserDataByUID.data.email_id,
                                  emailAddress: newVal,
                                  cathrPartyId:
                                    getUserDataByUID.data.cathrPartyId !== null
                                      ? getUserDataByUID.data.cathrPartyId
                                      : '',
                                  cathrGroupId:
                                    getUserDataByUID.data.cathrGroupId !== null
                                      ? getUserDataByUID.data.cathrGroupId
                                      : '',
                                  cathrSectionId:
                                    getUserDataByUID.data.cathrSectionId !== null
                                      ? getUserDataByUID.data.cathrSectionId
                                      : ''
                                });
                              } else if (getUserDataByUID.code == 101) {
                                // add a popup when api failed
                                setOpenValidation(true);
                                setValidationMsg(getUserDataByUID.message);
                              }
                            }
                          }}
                          // inputValue={userId !== undefined ? inputValue : internalUserObj.emailAddress}
                          inputValue={
                            internalUserObj.emailAddress ? internalUserObj.emailAddress : ''
                          }
                          onInputChange={async (e, value) => {
                            fireinternalHandler(e, 'emailAddress', e.target.value);

                            setInputValue(value);
                            const header = {
                              userid: userDataFromLocal().userId,
                              organizationId: userDataFromLocal().organizationId
                            };
                            const keywordCredential = {
                              searchText: e.target.value
                            };
                            const SearchInternalUserListApi = await SearchInternalUserList(
                              header,
                              keywordCredential
                            );
                            if (SearchInternalUserListApi.code === 100) {
                              setInternalUserEmail(SearchInternalUserListApi.data);
                              setErrorMsg('');
                            } else if (SearchInternalUserListApi.code === 104) {
                              setErrorMsg(
                                `${SearchInternalUserListApi.message} and enter a valid email address`
                              );
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Access type
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: `textInput customSelectBox dissabledMenu${internalUserObj.selectAccessType}`
                          }}
                          value={internalUserObj.selectAccessType}
                          onChange={(e) => fireinternalHandler(e, 'selectAccessType')}
                        >
                          <MenuItem value=" " disabled>
                            Select access type
                          </MenuItem>
                          {getRolesAccess.map((item, index) => (
                            <MenuItem value={item.id}>{item.roleName}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Real name
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Please enter your real name."
                          value={internalUserObj.userFirstName}
                          onChange={(e) => fireinternalHandler(e, 'userFirstName')}
                          disabled
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Surname
                        </Typography>
                        <TextField
                          fullWidth
                          placeholder="Please enter last name"
                          inputProps={{
                            className: 'textInput'
                          }}
                          value={internalUserObj.userLastName}
                          onChange={(e) => fireinternalHandler(e, 'userLastName')}
                          disabled
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Email
                        </Typography>
                        <TextField
                          fullWidth
                          placeholder="Please enter email"
                          inputProps={{
                            className: 'textInput'
                          }}
                          value={internalUserObj.userEmailId}
                          onChange={(e) => fireinternalHandler(e, 'userLastName')}
                          disabled
                        />
                      </Box>
                    </Grid>
                    {/* new added textfield */}
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Affiliation
                        </Typography>
                        <TextField
                          fullWidth
                          placeholder="Affiliation"
                          inputProps={{
                            className: 'textInput'
                          }}
                          value={internalUserObj.cathrGroupId}
                          disabled
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Cotton
                        </Typography>
                        <TextField
                          fullWidth
                          placeholder="cotton"
                          inputProps={{
                            className: 'textInput'
                          }}
                          value={internalUserObj.cathrPartyId}
                          disabled
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Part
                        </Typography>
                        <TextField
                          fullWidth
                          placeholder="Part"
                          inputProps={{
                            className: 'textInput'
                          }}
                          value={internalUserObj.cathrSectionId}
                          disabled
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box className="" textAlign="right">
                  <Button className="mainBtn" onClick={(e) => addUpdateUserInterNal(e)}>
                    {rowId !== undefined ? 'Update' : 'Submit'}
                  </Button>
                </Box>
              </>
            )}
            {showHide === '2' && (
              <>
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  General Information
                </Typography>
                <Box
                  className="cardOuter cardOuterGrayBorder cardNoHover cardTopMinusMargin"
                  px={2}
                  py={3}
                  mb={3}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Real name
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          className="frmHelperAlign"
                          placeholder="Please enter your real name"
                          value={externalUserObj.userFirstName}
                          onChange={(e) => fireExternalHandler(e, 'userFirstName')}
                          error={externalUserObj.userFirstName === '' ? openValidation : false}
                          helperText={
                            (externalUserObj.userFirstName === undefined ||
                              externalUserObj.userFirstName === '') &&
                            openValidation === true
                              ? 'Please fill real name'
                              : ''
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Surname
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          className="frmHelperAlign"
                          placeholder="Please enter last name."
                          value={externalUserObj.userLastName}
                          onChange={(e) => fireExternalHandler(e, 'userLastName')}
                          error={externalUserObj.userLastName === '' ? openValidation : false}
                          helperText={
                            (externalUserObj.userLastName === undefined ||
                              externalUserObj.userLastName === '') &&
                            openValidation === true
                              ? 'Please fill surname'
                              : ''
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Birthday
                        </Typography>
                        <TextField
                          type="date"
                          fullWidth
                          placeholder="Select Date of Birth"
                          inputProps={{
                            className: `textInput customSelectBox dissabledMenu${externalUserObj.dob}`
                          }}
                          className="frmHelperAlign"
                          value={externalUserObj.dob}
                          onChange={(e) => fireExternalHandler(e, 'dob')}
                          error={externalUserObj.dob === '' ? openValidation : false}
                          helperText={
                            (externalUserObj.dob === undefined || externalUserObj.dob === '') &&
                            openValidation === true
                              ? 'Please fill DOB'
                              : ''
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Telephone number
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          className="frmHelperAlign"
                          placeholder="Please enter your phone number"
                          value={externalUserObj.mobileNumber}
                          onChange={(e) => fireExternalHandler(e, 'mobileNumber')}
                          error={externalUserObj.mobileNumber === '' ? openValidation : false}
                          helperText={
                            (externalUserObj.mobileNumber === undefined ||
                              externalUserObj.mobileNumber === '') &&
                            openValidation === true
                              ? 'Please fill number'
                              : ''
                          }
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Access type
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: `textInput customSelectBox dissabledMenu${externalUserObj.selectAccessType1}`
                          }}
                          value={externalUserObj.selectAccessType1}
                          onChange={(e) => fireExternalHandler(e, 'selectAccessType1')}
                          error={
                            externalUserObj.selectAccessType1.toString().trim() === ''
                              ? validationMsg
                              : false
                          }
                        >
                          <MenuItem value=" " disabled>
                            Select access type
                          </MenuItem>
                          {getRolesAccess.map((item, index) => (
                            <MenuItem value={item.id}>{item.roleName}</MenuItem>
                          ))}
                        </Select>

                        <FormHelperText className="selectHelper" error>
                          {externalUserObj.selectAccessType1.toString().trim() === '' &&
                          validationMsg
                            ? 'Please select access Type'
                            : ''}
                        </FormHelperText>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Typography variant="body1" className="headingLineUp headingLineUpOut">
                  Creating a User Account
                </Typography>
                <Box
                  className="cardOuter cardOuterGrayBorder cardNoHover cardTopMinusMargin"
                  px={2}
                  py={3}
                  mb={3}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          ID User
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          className="frmHelperAlign"
                          placeholder="Type your email"
                          value={externalUserObj.userName}
                          onChange={(e) => fireExternalHandler(e, 'userName')}
                          error={externalUserObj.userName === '' ? openValidation : false}
                          helperText={
                            (externalUserObj.userName === undefined ||
                              externalUserObj.userName === '') &&
                            openValidation === true
                              ? 'Please fill user id'
                              : ''
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="h6" className="formLabel">
                          Password
                        </Typography>
                        <TextField
                          fullWidth
                          type="password"
                          inputProps={{
                            className: 'textInput'
                          }}
                          className="frmHelperAlign"
                          placeholder="Type your password"
                          value={externalUserObj.password}
                          onChange={(e) => {
                            fireExternalHandler(e, 'password');
                          }}
                          defaultValue="1234567890"
                          error={externalUserObj.password === '' ? openValidation : false}
                          helperText={
                            (externalUserObj.password === undefined ||
                              externalUserObj.password === '') &&
                            openValidation === true
                              ? 'Please fill password'
                              : ''
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box className="" textAlign="right">
                  <Button
                    className="mainBtn"
                    onClick={(e) => {
                      addUpdateUserInterNal(e);
                    }}
                  >
                    {rowId !== undefined ? 'Update' : 'Submit'}
                  </Button>
                </Box>
              </>
            )}
            {/* <Box className="" textAlign="right">
              <Button variant="contained" className="mainBtn">
                Save
              </Button>
            </Box> */}
          </Box>
        </Box>
        {/* Dialog msg when if error */}
        <DialogMsg
          openDialogeMsg={openDialogeMsg}
          dialogMsg={dialogMsg}
          dialogTitle="Alert"
          buttonText="Close"
          closeDialogMsgHandler={closeDialogMsgHandler}
        />
      </Container>
    </Page>
  );
}
export default AddEditUser;
