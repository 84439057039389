import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router';
// import globalServices from '../admin/pages/Services/globalServices';

// const { RefreshToken } = globalServices;

const REACT_APP_BASE_ADMIN_URL = process.env.REACT_APP_BASE_ADMIN_URL;
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

export function userDataFromLocal() {
  const adminUserDataValue = localStorage.getItem('adminUserDataValue');
  console.log('adminUserDataValue', adminUserDataValue);
  if (adminUserDataValue !== null) {
    const bytes = CryptoJS.AES.decrypt(adminUserDataValue, 'adminUserData');
    // console.log('userDataFromLocal', bytes);
    const originalAdmiuserData = bytes.toString(CryptoJS.enc.Utf8);
    console.log('originalAdmiuserData', JSON.parse(originalAdmiuserData));
    const parsedUserData = JSON.parse(originalAdmiuserData);

    // check the user session date and compare it with current time
    if (localStorage.getItem('intervalId') !== undefined) {
      // setTimeout(() => {
      clearInterval(localStorage.getItem('intervalId'));
      localStorage.removeItem('intervalId');
      // },);
    }

    // fire refresh api
    //fire refresh api token if time is more then expiresTime
    // console.log('inside setinterval');

    // const RefreshTokenCredentials = {
    //   expiredToken: parsedUserData.sessionId,
    //   userId: parsedUserData.userId,
    //   organizationId: parsedUserData.organizationId
    // };

    // const RefreshTokenApi = async () => {
    //   const RefreshTokenApiResponse = await RefreshToken(RefreshTokenCredentials);
    //   if (RefreshTokenApiResponse.code === 100) {
    //     console.log('userDetilsuserDetilsAccess3 entering ');
    //     // set new token then update localStorage
    //     parsedUserData.sessionId = RefreshTokenApiResponse.data.sessionId;
    //     const cipherText = CryptoJS.AES.encrypt(
    //       JSON.stringify(parsedUserData),
    //       'adminUserData'
    //     ).toString();
    //     localStorage.setItem('adminUserDataValue', cipherText);
    //   }
    // };

    // const intervalId = setInterval(() => {
    //   const currentTime = new Date();
    //   const userSesion = new Date(parsedUserData.sessionStart);
    //   const diffMs = userSesion - currentTime; // milliseconds between currentTime & userSesion
    //   const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    //   let totalSecSpend = -Number(diffMins);
    //   const compareMin = 29;

    //   const userexpiresTime = new Date(parsedUserData.expiresTime).getTime() - 300000;
    //   console.log('userexpiresTime', Number(totalSecSpend), Number(compareMin));
    //   console.log('userexpiresTime', Number(currentTime.getTime()) > Number(userexpiresTime));
    //   console.log('userexpiresTime', Number(currentTime.getTime()), Number(userexpiresTime));

    //   if (currentTime.getTime() > userexpiresTime) {
    //     RefreshTokenApi();
    //     totalSecSpend = 0;
    //     console.log('wqeweew');
    //   }
    //   if (Number(totalSecSpend) > Number(compareMin)) {
    //     // clear localStroage and navigate to login screen
    //     if (parsedUserData.userTypeId == 2) {
    //       localStorage.removeItem('adminUserDataValue');
    //       window.location = `${REACT_APP_BASE_URL}/external/?session=true`;
    //     } else {
    //       localStorage.removeItem('adminUserDataValue');
    //       window.location = `${REACT_APP_BASE_URL}/${REACT_APP_BASE_ADMIN_URL}/?session=true`;
    //     }
    //   }
    // }, 2000);

    // localStorage.setItem('intervalId', intervalId);
    // console.log('intervalid', intervalId);

    // addd new date when this function called
    console.log('comparingTime', new Date(parsedUserData.sessionStart).getTime());
    parsedUserData.sessionStart = new Date();
    console.log('comparingTime', new Date(parsedUserData.sessionStart).getTime());
    const cipherText = CryptoJS.AES.encrypt(
      JSON.stringify(parsedUserData),
      'adminUserData'
    ).toString();
    localStorage.setItem('adminUserDataValue', cipherText);
    return JSON.parse(originalAdmiuserData);
  }
  // window.location = `/${REACT_APP_BASE_ADMIN_URL}`;
  return {};
}
