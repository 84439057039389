import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';

import add from '@iconify/icons-ic/add';

// import export from '@iconify/icons-ic/export';
// material
import { styled } from '@mui/material/styles';
import { Box, Toolbar, Tooltip, IconButton, OutlinedInput, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL_ADMIN = process.env.REACT_APP_BASE_ADMIN_URL;

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 45,
  display: 'flex',
  justifyContent: 'space-between'
  // padding: '0 !important'
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  paddingTop: 2,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

export default function ManageAccessToolbar({
  numSelected,
  filterName,
  onFilterName,
  parent,
  setDrawOpen
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [file, setFile] = useState();
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const Input = styled('input')({
    display: 'none'
  });
  return (
    <RootStyle
      sx={{
        minHeight: 45,
        borderBottom: '1px solid #eaedf1'
      }}
    >
      <Box>
        <Tooltip title="Add new access type" placement="top" arrow>
          <IconButton
            className="squareIconButton"
            onClick={(e) => {
              localStorage.removeItem('e13ehdnf06b-72p6-4581-86a3-c0a465e63d75-AccessData');
              navigate(`${BASE_URL}/${parent}/add`);
            }}
          >
            <Icon icon={add} width={18} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder="Search"
          classes={{
            root: 'searchHolder',
            input: 'searchInput'
          }}
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      </Box>
    </RootStyle>
  );
}
