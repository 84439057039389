// material
import { Container, Grid, Box, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
// table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// icons

import { AiFillDelete, AiFillEye } from 'react-icons/ai';
// components
import Page from '../Page';
import ConfigureHeader from './ConfigureHeader';

export default function ScreenLanguage() {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  // Styled icon button in Action
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 16,
      padding: '0.5rem 1rem',
      fontWeight: 500,
      textTransform: 'uppercase',
      color: '#F37021'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      padding: '0.5rem 1rem'
    }
  }));
  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    margin: '0 3px',
    [`&:hover`]: {
      color: theme.palette.primary.main
    }
  }));
  return (
    <Page title="Configure">
      {/* header component   */}
      <ConfigureHeader />

      <Container maxWidth={false}>
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={12} marginBottom={3}>
            <Box className="cardOuter cardOuterGrayBorder cardNoHover">
              <TableContainer sx={{ overflow: 'auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Language</StyledTableCell>
                      <StyledTableCell>Modified On</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell>Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell>English</StyledTableCell>
                      <StyledTableCell>27 April, 2022</StyledTableCell>
                      <StyledTableCell>Active</StyledTableCell>
                      <StyledTableCell>
                        <Tooltip title="Delete" placement="top" arrow>
                          <StyledIconButton className="squareIconButton actionButton">
                            <AiFillDelete size={14} />
                          </StyledIconButton>
                        </Tooltip>
                        <Tooltip title="View" placement="top" arrow>
                          <StyledIconButton className="squareIconButton actionButton">
                            <AiFillEye size={14} />
                          </StyledIconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>Thai</StyledTableCell>
                      <StyledTableCell>27 April, 2022</StyledTableCell>
                      <StyledTableCell>Active</StyledTableCell>
                      <StyledTableCell>
                        <Tooltip title="Delete" placement="top" arrow>
                          <StyledIconButton className="squareIconButton actionButton">
                            <AiFillDelete size={14} />
                          </StyledIconButton>
                        </Tooltip>
                        <Tooltip title="View" placement="top" arrow>
                          <StyledIconButton className="squareIconButton actionButton">
                            <AiFillEye size={14} />
                          </StyledIconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>Mandarian</StyledTableCell>
                      <StyledTableCell>27 April, 2022</StyledTableCell>
                      <StyledTableCell>Active</StyledTableCell>
                      <StyledTableCell>
                        <Tooltip title="Delete" placement="top" arrow>
                          <StyledIconButton className="squareIconButton actionButton">
                            <AiFillDelete size={14} />
                          </StyledIconButton>
                        </Tooltip>
                        <Tooltip title="View" placement="top" arrow>
                          <StyledIconButton className="squareIconButton actionButton">
                            <AiFillEye size={14} />
                          </StyledIconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>Combodia</StyledTableCell>
                      <StyledTableCell>27 April, 2022</StyledTableCell>
                      <StyledTableCell>Active</StyledTableCell>
                      <StyledTableCell>
                        <Tooltip title="Delete" placement="top" arrow>
                          <StyledIconButton className="squareIconButton actionButton">
                            <AiFillDelete size={14} />
                          </StyledIconButton>
                        </Tooltip>
                        <Tooltip title="View" placement="top" arrow>
                          <StyledIconButton className="squareIconButton actionButton">
                            <AiFillEye size={14} />
                          </StyledIconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
