import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Stack,
  Tab,
  Tabs,
  Typography,
  Grid,
  Button,
  Card,
  Switch,
  Tooltip,
  IconButton,
  TextField,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions
} from '@mui/material';
import { Box } from '@mui/system';
import { RiFileInfoLine, RiFireLine, RiUserStarLine } from 'react-icons/ri';
import { AiFillEdit, AiOutlineFileText } from 'react-icons/ai';
import { MdCreditScore } from 'react-icons/md';
import { BsAspectRatio, BsCompass } from 'react-icons/bs';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
// table component
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// components
import Scrollbar from '../../Scrollbar';
import { StyledTableRow, StyledTableCell } from '../../utils/Styling';

function FCRReportTab() {
  const navigate = useNavigate();
  // Styled icon button in Action
  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    margin: '0 3px',
    [`&:hover`]: {
      color: theme.palette.primary.main
    }
  }));
  const [editFCROverview, setEditFCROverview] = useState(false);
  const [editPopUp, setEditPopUp] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');
  return (
    <>
      {/* applicant details */}
      <Grid container columns={13} spacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={13} sm={5} md={5}>
          <Box className="viewLeadCard" mb={3} sx={{ height: 'calc(100% - 24px)' }}>
            <Grid item container spacing={{ xs: 1, sm: 2, md: 2 }}>
              <Grid item xs={12} sm={6} md={6}>
                <Box className="">
                  <Typography variant="body2" className="reviewLabel">
                    Customer ID
                  </Typography>
                  <Typography variant="body2" className="reviewValue fontBold">
                    23758954612
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <Typography variant="body2" className="reviewLabel">
                    Occupation
                  </Typography>
                  <Typography variant="body2" className="reviewValue fontBold">
                    Business
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <Typography variant="body2" className="reviewLabel">
                    Customer Name
                  </Typography>
                  <Typography variant="body2" className="reviewValue fontBold">
                    Synergy Telchnofin
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <Typography variant="body2" className="reviewLabel">
                    Industry
                  </Typography>
                  <Typography variant="body2" className="reviewValue fontBold">
                    Professional Services
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <Typography variant="body2" className="reviewLabel">
                    Number of Product
                  </Typography>
                  <Typography variant="body2" className="reviewValue fontBold">
                    4
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <Typography variant="body2" className="reviewLabel">
                    Sub Industry
                  </Typography>
                  <Typography variant="body2" className="reviewValue fontBold">
                    Professional Services
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <Typography variant="body2" className="reviewLabel">
                    Customer Type
                  </Typography>
                  <Typography variant="body2" className="reviewValue fontBold">
                    Retail/SME/Corporate
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <Typography variant="body2" className="reviewLabel">
                    Status
                  </Typography>
                  <Typography variant="body2" className="reviewValue fontBold">
                    Active
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={13} sm={3} md={3}>
          <Box className="viewLeadCard" mb={3} sx={{ height: 'calc(100% - 24px)' }}>
            <Grid item container spacing={{ xs: 1, sm: 2, md: 2 }}>
              <Grid item xs={12} sm={12} md={12}>
                <Box className="">
                  <Typography variant="body2" className="reviewLabel">
                    Relationship Manager
                  </Typography>
                  <Typography variant="body2" className="reviewValue fontBold">
                    Sunil Singh
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box>
                  <Typography variant="body2" className="reviewLabel">
                    Branch
                  </Typography>
                  <Typography variant="body2" className="reviewValue fontBold">
                    Greater Kailash
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box>
                  <Typography variant="body2" className="reviewLabel">
                    Relationship Starts
                  </Typography>
                  <Typography variant="body2" className="reviewValue fontBold">
                    2013
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box>
                  <Typography variant="body2" className="reviewLabel">
                    Last Update
                  </Typography>
                  <Typography variant="body2" className="reviewValue fontBold">
                    3 Days Ago
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {/* Critical Parameters card */}
        <Grid item xs={13} sm={5} md={5}>
          <Box className="viewLeadCard" mb={3} sx={{ height: 'calc(100% - 24px)' }}>
            <Typography variant="subtitle2" className="colorBlue" mb={2}>
              Previous Alerts
            </Typography>
            <Box className="displayFlex justifyContentBetween mb-2">
              <Box
                className="displayFlex alertsHover"
                onClick={(e) => {
                  window.open(`overview`, `_blank`);
                }}
              >
                <Box sx={{ color: '#EA4335', width: '24px', marginRight: '0.5rem' }}>
                  <RiFireLine className="greenColor" size={24} />
                </Box>
                <Typography variant="body2" className="displayFlex mr-1">
                  202
                </Typography>
                <Typography variant="body2" className="fontBold">
                  Address Verification is Pending
                </Typography>
              </Box>
              {/* <Box ml={1}>
                      <Button className="mainBtnSec">Review</Button>
                    </Box> */}
            </Box>
            <Box className="displayFlex justifyContentBetween mb-2">
              <Box
                className="displayFlex alertsHover"
                onClick={(e) => {
                  window.open(`overview`, `_blank`);
                }}
              >
                <Box sx={{ color: '#EA4335', width: '24px', marginRight: '0.5rem' }}>
                  <RiFireLine className="" size={24} />
                </Box>
                <Typography variant="body2" className="displayFlex mr-1">
                  121
                </Typography>
                <Typography variant="body2" className="fontBold">
                  Income Doesn't Match with Profile
                </Typography>
              </Box>
              {/* <Box ml={1}>
                      <Button className="mainBtnSec">Review</Button>
                    </Box> */}
            </Box>
            <Box className="displayFlex justifyContentBetween" mb={2}>
              <Box
                className="displayFlex alertsHover"
                onClick={(e) => {
                  window.open(`overview`, `_blank`);
                }}
              >
                <Box sx={{ color: '#EA4335', width: '24px', marginRight: '0.5rem' }}>
                  <RiFireLine className="" size={24} />
                </Box>
                <Typography variant="body2" className="displayFlex mr-1">
                  405
                </Typography>
                <Typography variant="body2" className="fontBold">
                  AML Flag is Raised
                </Typography>
              </Box>
            </Box>
            <Box
              className="reviewBtnBox"
              sx={{ position: 'absolute', bottom: '1rem', right: '1rem' }}
            >
              <Button className="mainBtnSec reviewBtn">Review</Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Typography variant="body1" className="headingLineUp headingLineUpOut">
        FCR Overview
      </Typography>
      <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
        <Box className="squareSmallIcon" mb={1}>
          <Tooltip title="Edit" placement="top" arrow>
            <IconButton className="squareIconButton" onClick={(e) => setEditFCROverview(true)}>
              <AiFillEdit size={18} />
            </IconButton>
          </Tooltip>
        </Box>

        {/* edit FCR Overview table popup start */}
        <Dialog open={editFCROverview} onClose={(e) => setEditFCROverview(false)} fullWidth>
          <DialogTitle className="popupTitle">
            <Typography variant="h6">Edit FCR Overview</Typography>
            <IconButton onClick={(e) => setEditFCROverview(false)} size="small">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Box className="displayFlex alignItemCenter" mb={2}>
              <Typography variant="body2" className="" width={200}>
                FCR Score
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter Amount"
                defaultValue="879"
                inputProps={{
                  className: 'textInput'
                }}
              />
            </Box>
            <Box className="displayFlex alignItemCenter" mb={2}>
              <Typography variant="body2" className="" width={200}>
                FCR Bucket
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter Amount"
                defaultValue="Grey Listed"
                inputProps={{
                  className: 'textInput'
                }}
              />
            </Box>
            <Box className="displayFlex alignItemCenter" mb={2}>
              <Typography variant="body2" className="" width={200}>
                No of Alerts/Cases
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter Amount"
                defaultValue="Negative"
                inputProps={{
                  className: 'textInput'
                }}
              />
            </Box>
            <Box className="displayFlex alignItemCenter" mb={2}>
              <Typography variant="body2" className="" width={200}>
                Screening
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter Amount"
                defaultValue="Negative"
                inputProps={{
                  className: 'textInput'
                }}
              />
            </Box>
            <Box className="displayFlex alignItemCenter" mb={2}>
              <Typography variant="body2" className="" width={200}>
                Relationship
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter Amount"
                defaultValue="Negative"
                inputProps={{
                  className: 'textInput'
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => setEditFCROverview(false)}
              variant="contained"
              className="mainBtn"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        {/* edit FCR Overview table popup end */}

        <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs="auto">
            <Box className="cardSmallNew">
              <Box mb={0.75}>
                <AiOutlineFileText className="viewLabelIcon" size={24} />
              </Box>
              <Typography variant="body2">FCR Score</Typography>
              <Typography variant="subtitle1">879</Typography>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Box className="cardSmallNew">
              <Box mb={0.75}>
                <MdCreditScore className="viewLabelIcon" size={24} />
              </Box>
              <Typography variant="body2">FCR Bucket</Typography>
              <Typography variant="subtitle1">Grey Listed</Typography>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Box className="cardSmallNew">
              <Box mb={0.75}>
                <BsAspectRatio className="viewLabelIcon" size={24} />
              </Box>
              <Typography variant="body2">No of Alerts/Cases</Typography>
              <Typography variant="subtitle1">Negative</Typography>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Box className="cardSmallNew">
              <Box mb={0.75}>
                <RiFileInfoLine className="viewLabelIcon" size={24} />
              </Box>
              <Typography variant="body2">Screening</Typography>
              <Typography variant="subtitle1">Negative</Typography>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Box className="cardSmallNew">
              <Box mb={0.75}>
                <RiUserStarLine className="viewLabelIcon" size={24} />
              </Box>
              <Typography variant="body2">Relationship</Typography>
              <Typography variant="subtitle1">Negative</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Typography variant="body1" className="headingLineUp headingLineUpOut">
        Propensity Segment
      </Typography>
      <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
        <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs="auto">
            <Box className="cardSmallNew">
              <Box mb={0.75}>
                <AiOutlineFileText className="viewLabelIcon" size={24} />
              </Box>
              <Typography variant="body2">Cash Transaction Propensity</Typography>
              <Typography variant="subtitle1">High</Typography>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Box className="cardSmallNew">
              <Box mb={0.75}>
                <MdCreditScore className="viewLabelIcon" size={24} />
              </Box>
              <Typography variant="body2">Large Transaction Propensity</Typography>
              <Typography variant="subtitle1">Grey Listed</Typography>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Box className="cardSmallNew">
              <Box mb={0.75}>
                <BsAspectRatio className="viewLabelIcon" size={24} />
              </Box>
              <Typography variant="body2">ATM Withdrawals Propensity</Typography>
              <Typography variant="subtitle1">High</Typography>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Box className="cardSmallNew">
              <Box mb={0.75}>
                <RiFileInfoLine className="viewLabelIcon" size={24} />
              </Box>
              <Typography variant="body2">Inward Remittances Propensity</Typography>
              <Typography variant="subtitle1">High</Typography>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Box className="cardSmallNew">
              <Box mb={0.75}>
                <RiUserStarLine className="viewLabelIcon" size={24} />
              </Box>
              <Typography variant="body2">Outward Remittance Propensity</Typography>
              <Typography variant="subtitle1">High</Typography>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Box className="cardSmallNew">
              <Box mb={0.75}>
                <BsCompass className="viewLabelIcon" size={24} />
              </Box>
              <Typography variant="body2">Digital Channel Propensity</Typography>
              <Typography variant="subtitle1">High</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Typography variant="body1" className="headingLineUp headingLineUpOut">
        Related Party
      </Typography>
      <Box className="viewLeadCard viewLeadCardTableInner cardTopMinusMargin" mb={3}>
        <Scrollbar>
          <TableContainer sx={{ overflow: 'auto' }} aria-label="simple table">
            <Table>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Account Number</StyledTableCell>
                  <StyledTableCell>Account Name</StyledTableCell>
                  <StyledTableCell>Account Type</StyledTableCell>
                  <StyledTableCell>Relationship</StyledTableCell>
                  <StyledTableCell>FCR Score</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>125634789</StyledTableCell>
                  <StyledTableCell>Synergy Telchnofin</StyledTableCell>
                  <StyledTableCell>Current</StyledTableCell>
                  <StyledTableCell>--</StyledTableCell>
                  <StyledTableCell>820</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Box>

      <Typography variant="body1" className="headingLineUp headingLineUpOut">
        Alerts Threshold
      </Typography>
      <Box className="viewLeadCard viewLeadCardTableInner cardTopMinusMargin">
        <Scrollbar>
          <TableContainer sx={{ overflow: 'auto' }} aria-label="simple table">
            <Table>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Rule ID</StyledTableCell>
                  <StyledTableCell>Rules</StyledTableCell>
                  <StyledTableCell>Segment Column</StyledTableCell>
                  <StyledTableCell>Segment Threshold</StyledTableCell>
                  <StyledTableCell>Custom Threshold</StyledTableCell>
                  <StyledTableCell>End Date</StyledTableCell>
                  <StyledTableCell>Custom Threshold</StyledTableCell>
                  <StyledTableCell width={55}>Action</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>101</StyledTableCell>
                  <StyledTableCell>Single Cash Deposits</StyledTableCell>
                  <StyledTableCell>High</StyledTableCell>
                  <StyledTableCell>25000</StyledTableCell>
                  <StyledTableCell>210000</StyledTableCell>
                  <StyledTableCell>31st Nov 2021</StyledTableCell>
                  <StyledTableCell>
                    <Switch defaultChecked size="small" />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Tooltip title="Edit" placement="top" arrow>
                      <StyledIconButton
                        className="squareIconButton actionButton"
                        onClick={(e) => setEditPopUp(true)}
                      >
                        <AiFillEdit size={14} />
                      </StyledIconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>103</StyledTableCell>
                  <StyledTableCell>Single Cash withdrawals</StyledTableCell>
                  <StyledTableCell>High</StyledTableCell>
                  <StyledTableCell>20000</StyledTableCell>
                  <StyledTableCell>20000</StyledTableCell>
                  <StyledTableCell>17th Nov 2021</StyledTableCell>
                  <StyledTableCell>
                    <Switch defaultChecked size="small" />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Tooltip title="Edit" placement="top" arrow>
                      <StyledIconButton
                        className="squareIconButton actionButton"
                        onClick={(e) => setEditPopUp(true)}
                      >
                        <AiFillEdit size={14} />
                      </StyledIconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>105</StyledTableCell>
                  <StyledTableCell>Large Deposit Transaction</StyledTableCell>
                  <StyledTableCell>High</StyledTableCell>
                  <StyledTableCell>15000</StyledTableCell>
                  <StyledTableCell>70000</StyledTableCell>
                  <StyledTableCell>15th Nov 2021</StyledTableCell>
                  <StyledTableCell>
                    <Switch defaultChecked size="small" />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Tooltip title="Edit" placement="top" arrow>
                      <StyledIconButton
                        className="squareIconButton actionButton"
                        onClick={(e) => setEditPopUp(true)}
                      >
                        <AiFillEdit size={14} />
                      </StyledIconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>106</StyledTableCell>
                  <StyledTableCell>Large Withdrawal Transaction</StyledTableCell>
                  <StyledTableCell>High</StyledTableCell>
                  <StyledTableCell>10000</StyledTableCell>
                  <StyledTableCell>50000</StyledTableCell>
                  <StyledTableCell>13th Nov 2021</StyledTableCell>
                  <StyledTableCell>
                    <Switch defaultChecked size="small" />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Tooltip title="Edit" placement="top" arrow>
                      <StyledIconButton
                        className="squareIconButton actionButton"
                        onClick={(e) => setEditPopUp(true)}
                      >
                        <AiFillEdit size={14} />
                      </StyledIconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>108</StyledTableCell>
                  <StyledTableCell>High Velocity ATM Withdrawals</StyledTableCell>
                  <StyledTableCell>High</StyledTableCell>
                  <StyledTableCell>5000</StyledTableCell>
                  <StyledTableCell>50000</StyledTableCell>
                  <StyledTableCell>9th Nov 2021</StyledTableCell>
                  <StyledTableCell>
                    <Switch defaultChecked size="small" />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Tooltip title="Edit" placement="top" arrow>
                      <StyledIconButton
                        className="squareIconButton actionButton"
                        onClick={(e) => setEditPopUp(true)}
                      >
                        <AiFillEdit size={14} />
                      </StyledIconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>109</StyledTableCell>
                  <StyledTableCell>Exceesive ATM Withdrawals Denials</StyledTableCell>
                  <StyledTableCell>High</StyledTableCell>
                  <StyledTableCell>10000</StyledTableCell>
                  <StyledTableCell>50000</StyledTableCell>
                  <StyledTableCell>5th Nov 2021</StyledTableCell>
                  <StyledTableCell>
                    <Switch defaultChecked size="small" />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Tooltip title="Edit" placement="top" arrow>
                      <StyledIconButton
                        className="squareIconButton actionButton"
                        onClick={(e) => setEditPopUp(true)}
                      >
                        <AiFillEdit size={14} />
                      </StyledIconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>110</StyledTableCell>
                  <StyledTableCell>High Value Inward Remittance</StyledTableCell>
                  <StyledTableCell>High</StyledTableCell>
                  <StyledTableCell>15000</StyledTableCell>
                  <StyledTableCell>50000</StyledTableCell>
                  <StyledTableCell>1st Nov 2021</StyledTableCell>
                  <StyledTableCell>
                    <Switch defaultChecked size="small" />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Tooltip title="Edit" placement="top" arrow>
                      <StyledIconButton
                        className="squareIconButton actionButton"
                        onClick={(e) => setEditPopUp(true)}
                      >
                        <AiFillEdit size={14} />
                      </StyledIconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>111</StyledTableCell>
                  <StyledTableCell>High Value Outward Remittance</StyledTableCell>
                  <StyledTableCell>High</StyledTableCell>
                  <StyledTableCell>18000</StyledTableCell>
                  <StyledTableCell>50000</StyledTableCell>
                  <StyledTableCell>25th Oct 2021</StyledTableCell>
                  <StyledTableCell>
                    <Switch defaultChecked size="small" />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Tooltip title="Edit" placement="top" arrow>
                      <StyledIconButton
                        className="squareIconButton actionButton"
                        onClick={(e) => setEditPopUp(true)}
                      >
                        <AiFillEdit size={14} />
                      </StyledIconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>123</StyledTableCell>
                  <StyledTableCell>Large Transaction in New Client</StyledTableCell>
                  <StyledTableCell>High</StyledTableCell>
                  <StyledTableCell>20000</StyledTableCell>
                  <StyledTableCell>60000</StyledTableCell>
                  <StyledTableCell>17th Nov 2021</StyledTableCell>
                  <StyledTableCell>
                    <Switch defaultChecked size="small" />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Tooltip title="Edit" placement="top" arrow>
                      <StyledIconButton
                        className="squareIconButton actionButton"
                        onClick={(e) => setEditPopUp(true)}
                      >
                        <AiFillEdit size={14} />
                      </StyledIconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Box>
      {/* Edit popup start */}
      <Dialog open={editPopUp} onClose={(e) => setEditPopUp(false)} fullWidth>
        <DialogTitle className="popupTitle">
          <Typography variant="h6">Edit Threshold</Typography>
          <IconButton onClick={(e) => setEditPopUp(false)} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box className="" mb={2}>
            <Typography variant="body2" className="formLabel">
              Current Threshold
            </Typography>
            <TextField
              inputProps={{ className: 'textInput' }}
              placeholder="Type Current Threshold"
              defaultValue="992"
              fullWidth
            />
          </Box>
          <Box className="" mb={2}>
            <Typography variant="body2" className="formLabel">
              New Threshold
            </Typography>
            <TextField
              inputProps={{ className: 'textInput' }}
              placeholder="Type New Threshold"
              fullWidth
            />
          </Box>
          <Box className="">
            <Typography variant="body2" className="formLabel">
              Threshold Date
            </Typography>
            <TextField
              inputProps={{ className: 'textInput' }}
              fullWidth
              placeholder="Select Threshold Date"
              type="text"
              onFocus={(e) => (e.target.type = 'date')}
              onBlur={(e) => (e.target.type = 'text')}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => setEditPopUp(false)} variant="contained" className="mainBtn">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* Edit popup end */}
    </>
  );
}
export default FCRReportTab;
