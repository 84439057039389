import useApiServices from './useApiServices';
import { globalAdminUrl } from './globalApi';
//
const configurationService = {
  // common apis
  GetDefaultData: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/admin/getdefaultdata`, token),
  AdminUserData: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/admin/userdata`, credential, token),
  // configuration page
  GetConfiguration: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/admin/getconfiguration`, token),
  GetConfigurationDetails: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/admin/getConfigurationDetails`, token),

  Setconfiguration: (header, credential) =>
    useApiServices().postHeaderBodyApi(
      `${globalAdminUrl}/admin/setconfiguration`,
      header,
      credential
    ),
  GetConfigurationsData: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/admin/getConfigurationsData`, token),
  SetConfigurationData: (header, credential) =>
    useApiServices().postHeaderBodyApi(
      `${globalAdminUrl}/admin/setConfigurationsData`,
      header,
      credential
    ),
  // consents
  GetConsentLanguageList: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/admin/getConsentLanguageList`, token),
  GetConsentById: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/admin/getconsentbyid`, token),
  GetConsentConfiguration: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/admin/getConsentConfiguration`, token),
  SetConsentConfiguration: (header, credential) =>
    useApiServices().postHeaderBodyApi(
      `${globalAdminUrl}/admin/setConsentConfiguration`,
      header,
      credential
    ),

  // faq
  GetFAQLanguageList: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/admin/getFAQLanguageList`, token),
  GetFaqFromLanguage: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/admin/getFaqFromLanguage`, token),
  SetFaqConfiguration: (header, credential) =>
    useApiServices().postHeaderBodyApi(
      `${globalAdminUrl}/admin/setFaqConfiguration`,
      header,
      credential
    ),
  DeleteFaqQuestionAnswer: (credential, header) =>
    useApiServices().postApi(`${globalAdminUrl}/admin/setFaqConfiguration`, credential, header),

  // watermark
  GetWatermarkText: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/admin/getWatermarkText`, token),
  // tutorial list or instruction list
  GetTutorialLanguageList: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/admin/getTutorialLanguageList`, token),
  GetTutorialByLanguageId: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/admin/getTutorialByLanguageId`, token),
  SetTutorialConfiguration: (header, credential) =>
    useApiServices().postHeaderBodyApi(
      `${globalAdminUrl}/admin/setTutorialConfiguration`,
      header,
      credential
    ),
  // face Match
  GetFaceMatchData: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/admin/getFaceMatchData`, token),
  UpdateWatermark: (header, credential) =>
    useApiServices().postHeaderBodyApi(
      `${globalAdminUrl}/admin/updateWatermark`,
      header,
      credential
    ),
  // validation and setting
  GetOrganizationProfileData: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/admin/getOrganizationProfileData`, token),
  SetOrganizationProfileData: (header, credential) =>
    useApiServices().postHeaderBodyApi(
      `${globalAdminUrl}/admin/setOrganizationProfileData`,
      header,
      credential
    ),
  // validation and setting
  GetInstructionData: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/admin/getInstructionData`, token),
  GetConsentFile: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/admin/getConsentFile`, token),
  // validation and setting
  GetNotification: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/admin/getNotificationData`, token),
  GetAppIdForMsisdn: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/admin/getAppIdForMsisdn`, token),
  SetNotification: (header, credential) =>
    useApiServices().postHeaderBodyApi(
      `${globalAdminUrl}/admin/setNotificationData`,
      header,
      credential
    ),
  SendNotification: (header, credential) =>
    useApiServices().postHeaderBodyApi(
      `${globalAdminUrl}/admin/sendNotification`,
      header,
      credential
    ),
  AddFaqToLanguage: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/admin/addFaqToLanguage`, credential, token)
};

export default configurationService;
