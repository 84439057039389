import * as React from 'react';
import {
  Container,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Button,
  Grid,
  Paper,
  TableRow,
  TableCell,
  Checkbox,
  tableCellClasses,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card
} from '@mui/material';
import { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import styled from '@emotion/styled';
import { StyledTableRow, StyledTableCell, StyledIconButton } from '../utils/Styling';

import { Box } from '@mui/system';

import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

import MuiAccordion from '@mui/material/Accordion';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BrudCrumbs from '../BreadCrumbs';
import Page from '../Page';
import PageTitle from '../PageHeading';

import ManageAccessHeader from './ManageAccessHeader';
import { userDataFromLocal } from '../utils/getUserDetails';
import Validation from '../../global/Validation';
import Loader from '../../global/Loader';

import globalServices from '../../services/globalServices';
import Scrollbar from '../Scrollbar';
import DialogMsg from '../../global/DialogMsg';
import DeleteDialog from '../../global/DeleteDialog';

const { GetRolesGroup, GetDefaultData, SetRolesGroup } = globalServices;

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 1)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL_ADMIN = process.env.REACT_APP_BASE_ADMIN_URL;

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(3)
}));
function AddEditManageAccess() {
  const navigate = useNavigate();
  const { rowId } = useParams();

  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [selectUser, setSelectUser] = useState('1');
  const [loginDataDetails, setLoginDataDetails] = useState('');
  const [accessTypeName, setAccessTypeName] = useState('');
  const [setRolesGroup, setSetRolesGroup] = useState('');

  const [getDefaultDataUserType, setGetDefaultDataUserType] = useState([]);
  const [getDefaultDataMenuOption, setGetDefaultDataMenuOption] = useState([]);
  const [selectedMenuPageList, setSelectedMenuPageList] = useState([]);

  const [getRolesGroupData, setGetRolesGroupData] = useState([]);
  const [checked, setChecked] = useState(true); // for checkbox

  const [accessDataFromLocal, setAccessDataFromLocal] = useState(''); // for checkbox
  console.log('accessDataFromLocal', accessDataFromLocal);

  // validation
  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');
  const [openloader, setOpenLoader] = useState(false);
  const [targetItem, setTargetItem] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const addMoreMasterValue = () => {
    const prevValue = masterValue;
    const obj = { masterValue: '' };
    prevValue.push(obj);
    setMasterValue(prevValue);
    setRefreshComp(new Date());
  };
  const removeMasterValue = (index) => {
    const prevValue = masterValue;
    const removedItem = prevValue.filter((elm, ind) => ind !== index);
    setMasterValue(removedItem);
    setRefreshComp(new Date());
  };
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSelectUser = (e) => {
    // setSelectUser(e.target.value);
    setSelectUser(String(e.target.value));
  };

  // for checkbox
  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };

  const handleClose = () => {
    setOpenValidation(false);
  };
  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
  };

  const fireSaveGetRoleGroupApi = async () => {
    // validation
    if (selectUser === '1') {
      if (!accessTypeName) {
        setOpenValidation(true);
        setValidationMsg('Please enter your access name');
        return;
      }
    }

    if (selectUser === '2') {
      if (!accessTypeName) {
        setOpenValidation(true);
        setValidationMsg('Please enter your access name');
        return;
      }
    }

    setOpenLoader(true);
    const headerCredential = {
      userId: loginDataDetails.userId,
      organizationId: loginDataDetails.organizationId
    };
    //   status A for add , E for edit , D for delete
    const setRoleCredential = {
      roleId: rowId !== undefined ? accessDataFromLocal.id : '',
      roleName: accessTypeName.trim(),
      userType: String(selectUser),
      isActive: rowId !== undefined ? accessDataFromLocal.isActive : 1,
      requestType: rowId !== undefined ? 'E' : 'A',
      menuOtions: getDefaultDataMenuOption
    };

    const SetRolesGroupApiResponse = await SetRolesGroup(headerCredential, setRoleCredential);
    navigate(`${BASE_URL}/manage-access`);
    if (SetRolesGroupApiResponse.code === 100) {
      setOpenLoader(false);
      setSetRolesGroup(SetRolesGroupApiResponse.data);
      navigate(`${BASE_URL}/manage-access`);
    }
  };

  useEffect(() => {
    // const adminUserDataValue = { userId: 1403, organizationId: 1 };
    const adminUserDataValue = localStorage.getItem('adminUserDataValue');
    if (adminUserDataValue !== null) {
      const bytes = CryptoJS.AES.decrypt(adminUserDataValue, 'adminUserData');
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      // setLoginDataDetails(adminUserDataValue);
      setLoginDataDetails(originalText ? JSON.parse(originalText) : {});

      (async () => {
        setOpenLoader(true);
        const authToken = {
          userId: 1403
        };
        const GetDefaultDataApiResponse = await GetDefaultData(authToken);
        if (GetDefaultDataApiResponse.code === 100) {
          setOpenLoader(false);
          setGetDefaultDataUserType(GetDefaultDataApiResponse.data.userType);
          setGetDefaultDataMenuOption(GetDefaultDataApiResponse.data.menuOption);
          const SingleAccessData = localStorage.getItem(
            'e13ehdnf06b-72p6-4581-86a3-c0a465e63d75-AccessData'
          );
          // console.log('AccessDataparse.us', GetDefaultDataApiResponse, SingleAccessData);
          if (SingleAccessData) {
            const AccessDataDecrypt = CryptoJS.AES.decrypt(
              JSON.parse(SingleAccessData),
              'AccessData'
            );
            // console.log('AccessDataparse.us', SingleAccessData);
            const AccessDataDecrypt123 = AccessDataDecrypt.toString(CryptoJS.enc.Utf8);

            const AccessDataparse = JSON.parse(AccessDataDecrypt123);
            // console.log('AccessDataparse.us', AccessDataparse);

            setAccessDataFromLocal(AccessDataparse);

            setSelectUser(AccessDataparse.userTypeId);
            setAccessTypeName(AccessDataparse.roleName);
            (async () => {
              const authToken1 = {
                userId: 1403,
                roleId: rowId
              };

              const GetRolesGroupApi = await GetRolesGroup(authToken1);
              if (GetRolesGroupApi.code === 100) {
                setOpenLoader(false);
                setGetRolesGroupData(GetRolesGroupApi.data);
                setGetDefaultDataMenuOption(GetRolesGroupApi.data);
              }
            })();
          }
        }
      })();
    }
  }, []);

  // useEffect(() => {
  //   const adminUserDataValue = localStorage.getItem('adminUserDataValue');
  //   if (adminUserDataValue !== null) {
  //     const bytes = CryptoJS.AES.decrypt(adminUserDataValue, 'adminUserData');
  //     const originalText = bytes.toString(CryptoJS.enc.Utf8);

  //     setLoginDataDetails(JSON.parse(originalText));

  //     (async () => {
  //       setOpenLoader(true);
  //       const authToken = {
  //         userId: JSON.parse(originalText).userId
  //       };
  //       const GetDefaultDataApiResponse = await GetDefaultData(authToken);
  //       if (GetDefaultDataApiResponse.code === 100) {
  //         setOpenLoader(false);
  //         setGetDefaultDataUserType(GetDefaultDataApiResponse.data.userType);
  //         setGetDefaultDataMenuOption(GetDefaultDataApiResponse.data.menuOption);
  //         const SingleAccessData = localStorage.getItem(
  //           'e13ehdnf06b-72p6-4581-86a3-c0a465e63d75-AccessData'
  //         );
  //         if (SingleAccessData) {
  //           const AccessDataDecrypt = CryptoJS.AES.decrypt(
  //             JSON.parse(SingleAccessData),
  //             'AccessData'
  //           );

  //           const AccessDataDecrypt123 = AccessDataDecrypt.toString(CryptoJS.enc.Utf8);

  //           const AccessDataparse = JSON.parse(AccessDataDecrypt123);

  //           setAccessDataFromLocal(AccessDataparse);

  //           setSelectUser(AccessDataparse.userTypeId);
  //           setAccessTypeName(AccessDataparse.roleName);
  //           (async () => {
  //             const authToken1 = {
  //               userId: userDataFromLocal().userId,
  //               roleId: rowId
  //             };

  //             const GetRolesGroupApi = await GetRolesGroup(authToken1);
  //             if (GetRolesGroupApi.code === 100) {
  //               setOpenLoader(false);
  //               setGetRolesGroupData(GetRolesGroupApi.data);
  //               setGetDefaultDataMenuOption(GetRolesGroupApi.data);
  //             }
  //           })();
  //         }
  //       }
  //     })();
  //   }
  // }, []);

  useEffect(() => {}, [loginDataDetails, selectedMenuPageList, getRolesGroupData]);

  return (
    <Page title="Manage Access">
      {/* header component */}
      {/* <ManageAccessHeader /> */}
      <Container maxWidth="xl">
        <Loader openloader={openloader} />
        <Validation
          openValidation={openValidation}
          validationMsg={validationMsg}
          handleClose={handleClose}
          setOpenValidation={setOpenValidation}
          dialogTitle="Alert"
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="pageHeading"
        >
          <PageTitle info="ADD ACCESS TYPE" />
          <Box className="brudCrumbs">
            <BrudCrumbs />
          </Box>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Box className="mb-3">
              <Typography variant="h6" className="formLabel">
                User type
              </Typography>
              {getDefaultDataUserType.length > 0 && (
                <Select
                  fullWidth
                  inputProps={{
                    className: `textInput customSelectBox dissabledMenu${selectUser}`
                  }}
                  value={selectUser}
                  onChange={(e) => handleSelectUser(e)}
                  // renderValue={(selected) => (selected === '' ? 'Internal' : selected)}
                >
                  <MenuItem value="" disabled>
                    Select user type
                  </MenuItem>
                  {getDefaultDataUserType !== undefined && getDefaultDataUserType.length > 0
                    ? getDefaultDataUserType.map((item, index) => {
                        console.log('getDefaultDataUserType1', item, item.id, selectUser);
                        return (
                          <MenuItem value={item.id} key={index}>
                            {item.typeName}
                            {/* {item.typeCode === 'AADHAR_CARD'
                              ? 'Internal user'
                              : item.typeCode === 'PAN_CARD'
                              ? 'External user'
                              : ''} */}
                          </MenuItem>
                          // <MenuItem value={item.id} key={index}>
                          //   {/* {item.typeName} */}
                          //   {item.typeName === 'INTERNAL_USER'
                          //     ? 'Internal user'
                          //     : item.typeName === 'EXTERNAL_USER'
                          //     ? 'External user'
                          //     : ''}
                          // </MenuItem>
                        );
                      })
                    : ''}
                </Select>
              )}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="formRowHolder fullrow">
              <Typography variant="h6" className="formLabel">
                Access name
              </Typography>
              <TextField
                fullWidth
                value={accessTypeName}
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Enter access name"
                onChange={(e) => setAccessTypeName(e.target.value)}
              />
            </Box>
          </Grid>
        </Grid>
        {/* {JSON.stringify(getDefaultDataMenuOption)} */}
        {getDefaultDataMenuOption !== undefined
          ? getDefaultDataMenuOption.map((item, mainIndex) => {
              console.log('elementelements 1', item);
              return (
                <Box key={mainIndex} className="cardOuter cardOuterGrayBorder cardNoHover mb-2">
                  <Accordion
                  // expanded={expanded === 'panel1'}
                  // onChange={handleChange('panel1')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography
                        sx={{
                          width: '100%',
                          flexGrow: 1,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Typography>{item.menuName}</Typography>

                        <Checkbox
                          checked={item.isActive === 1}
                          inputProps={{ 'aria-label': 'controlled' }}
                          onChange={(e) => {
                            e.stopPropagation();
                            const prevArray = getDefaultDataMenuOption;
                            prevArray[mainIndex].isActive = e.target.checked ? 1 : 0;
                            if (prevArray[mainIndex].isActive === 1) {
                              prevArray[mainIndex].menuPageList.map(
                                (pageList) => (pageList.isActive = 1)
                              );
                              setGetDefaultDataMenuOption(prevArray);
                            }

                            if (prevArray[mainIndex].isActive === 0) {
                              prevArray[mainIndex].menuPageList.map(
                                (pageList) => (pageList.isActive = 0)
                              );
                              setGetDefaultDataMenuOption(prevArray);
                            }

                            setGetDefaultDataMenuOption(prevArray);
                            setRefreshComp(new Date());
                          }}
                        />
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingTop: '0px', paddingBottom: '10px' }}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <StyledTableRow>
                              <StyledTableCell>List</StyledTableCell>
                              <StyledTableCell width={80}>Access</StyledTableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            {item.menuPageList !== undefined && item.menuPageList.length > 0
                              ? item.menuPageList.map((element, childIndex) => {
                                  console.log('elementelement', element);
                                  return (
                                    <StyledTableRow
                                      key={childIndex}
                                      hover
                                      tabIndex={-1}
                                      role="checkbox"
                                    >
                                      <StyledTableCell align="left" value={element.id}>
                                        <strong>{element.menuPageName}</strong>
                                      </StyledTableCell>
                                      <TableCell padding="checkbox">
                                        <Checkbox
                                          checked={element.isActive === 1}
                                          onChange={(e) => {
                                            const preArray = getDefaultDataMenuOption;

                                            preArray[mainIndex].menuPageList[childIndex].isActive =
                                              e.target.checked ? 1 : 0;

                                            if (
                                              preArray[mainIndex].menuPageList[childIndex]
                                                .isActive === 1
                                            ) {
                                              preArray[mainIndex].isActive = 1;
                                            }
                                            const falseArray = preArray[
                                              mainIndex
                                            ].menuPageList.filter(
                                              (pageList) => pageList.isActive === 0
                                            );
                                            if (
                                              falseArray.length ===
                                              preArray[mainIndex].menuPageList.length
                                            ) {
                                              preArray[mainIndex].isActive = 0;
                                            }
                                            setGetDefaultDataMenuOption(preArray);
                                            setRefreshComp(new Date());
                                          }}
                                          inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                      </TableCell>
                                    </StyledTableRow>
                                  );
                                })
                              : ''}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              );
            })
          : 'ok'}

        <Box mb={6} textAlign="right">
          <Button
            variant="contained"
            className="mainBtn"
            onClick={(e) => fireSaveGetRoleGroupApi(e)}
          >
            {rowId !== undefined ? 'Update' : 'Submit'}
          </Button>
        </Box>

        {/* <Dialog
          open={openDeleteDialog}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle className="popupTitle">
            <Typography variant="h6">Confirmation</Typography>
            <IconButton onClick={handleCloseDelete} size="small">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                handleCloseDelete(e);
              }}
              className="mainBtn"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog> */}
      </Container>
    </Page>
  );
}
export default AddEditManageAccess;
