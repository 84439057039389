import React, { useState, useEffect } from 'react';
// material
import { Container, Stack, Grid, Box, IconButton, TablePagination } from '@mui/material';

import { styled } from '@mui/material/styles';
// drawer components

// table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// components
import Page from '../Page';
import MasterDataHeader from './MasterDataHeader';
import PageTitle from '../PageHeading';
import BrudCrumbs from '../BreadCrumbs';
import MasterDataToolbar from './MasterDataToolbar';

import { userDataFromLocal } from '../utils/getUserDetails';
import Validation from '../../global/Validation';
import Loader from '../../global/Loader';
import { fDate } from '../utils/formatTime';

import masterConfigurationService from '../../services/masterConfigurationService';
import { getAccessdata } from '../utils/getAccessData';
import NoAccess from '../NoAccessComp';

const { GetFileData } = masterConfigurationService;

export default function MasterData() {
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState('');
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  // Styled icon button in Action
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 14,
      padding: '0.5rem 0.5rem',
      fontWeight: 500,
      textTransform: 'uppercase',
      color: '#F37021',
      whiteSpace: 'nowrap'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '0.5rem 0.5rem',
      whiteSpace: 'nowrap'
    }
  }));
  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginRight: '6px',
    [`&:hover`]: {
      // color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.blackgray
    }
  }));

  const Input = styled('input')({
    display: 'none'
  });
  // drawer
  const [drawerOpen, setDrawOpen] = useState(false);

  const [getFileDataResponse, setGetFileDataResponse] = useState([]);
  // validation
  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');
  const [openloader, setOpenLoader] = useState(false);

  const [refreshComp, setRefreshComp] = useState('');
  const [submenuAccess, setSubmenuAccess] = useState({});
  const [isPageAccess, setIsPageAccess] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleClose = () => {
    setOpenValidation(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    (async () => {
      const getAccessedMenu = await getAccessdata(120);

      const ifNoSubmenuFound = getAccessedMenu.menuPageList?.filter(
        (submenu) => submenu.isActive === 1
      );
      if (getAccessedMenu.isActive === 0) {
        setIsPageAccess(false);
      }
      setSubmenuAccess(getAccessedMenu);
    })();
    (async () => {
      setOpenLoader(true);
      const header = {
        userId: userDataFromLocal().userId,
        organizationId: userDataFromLocal().organizationId
      };
      setOpenLoader(false);
      const GetFileDataResponse = await GetFileData(header);

      if (GetFileDataResponse.code === 100) {
        setOpenLoader(false);
        // setRefreshComp(new Date());
        setGetFileDataResponse(GetFileDataResponse.data);
      }
    })();
  }, [refreshComp]);

  if (isPageAccess) {
    return (
      <Page title="Master Data">
        {/* header component */}
        <MasterDataHeader />
        <Container maxWidth={false}>
          <Loader openloader={openloader} />
          <Validation
            openValidation={openValidation}
            validationMsg={validationMsg}
            handleClose={handleClose}
            setOpenValidation={setOpenValidation}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="จัดการข้อมูล Master" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={12} marginBottom={3}>
              <Box className="cardOuter cardNoHover cardOuterTable">
                {/* table toolbar */}
                <MasterDataToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                  parent="partners"
                  setDrawOpen={setDrawOpen}
                  setOpenLoader={setOpenLoader}
                  setRefreshComp={setRefreshComp}
                  setOpenValidation={setOpenValidation}
                  setValidationMsg={setValidationMsg}
                />
                {/* table start */}
                <TableContainer sx={{ overflow: 'auto', minWidth: 800, maxHeight: '54vh' }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead className="fixedHeader">
                      <TableRow>
                        <StyledTableCell>ชื่อไฟล์</StyledTableCell>
                        <StyledTableCell>วันที่อัปโหลด</StyledTableCell>
                        <StyledTableCell>จำนวนข้อมูล</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getFileDataResponse !== undefined && getFileDataResponse.length > 0
                        ? getFileDataResponse
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                            .map((master, index) => {
                              return (
                                <StyledTableRow key={index}>
                                  <StyledTableCell>{master.fileName}</StyledTableCell>
                                  <StyledTableCell>{fDate(master.uploadedOn)}</StyledTableCell>
                                  <StyledTableCell>{master.recordsCount}</StyledTableCell>
                                </StyledTableRow>
                              );
                            })
                        : ''}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[10, 50, 100]}
                  component="div"
                  count={getFileDataResponse.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
  return <NoAccess />;
}
