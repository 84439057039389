import { useState } from 'react';
import {
  Table,
  Stack,
  TableBody,
  Container,
  Typography,
  TableContainer,
  Grid,
  Tooltip,
  IconButton,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
  Button,
  Checkbox,
  Select,
  MenuItem,
  Divider
} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import { useNavigate } from 'react-router-dom';
import { AiFillEdit, AiFillStar } from 'react-icons/ai';
import CloseIcon from '@mui/icons-material/Close';
import { MdAdd } from 'react-icons/md';
//
import Scrollbar from '../../Scrollbar';
import { StyledTableRow, StyledTableCell } from '../../utils/Styling';
import profileImg from './profileImg.png';
import { CircularProgressWithLabel } from '../../_dashboard/app';

function KYCFormTab() {
  const navigate = useNavigate();
  const [sharePopUp, setSharePopUp] = useState(false);
  const [selectRiskCategory, setSelectRiskCategory] = useState(' ');
  const [selectDecision, setSelectDecision] = useState(' ');
  const [selectYourDecision, setSelectYourDecision] = useState(' ');
  // Styled icon button in Action
  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    margin: '0 3px',
    [`&:hover`]: {
      color: theme.palette.primary.main
    }
  }));
  const [signUpload, setSignUpload] = useState(false);
  return (
    <>
      {/* <Box className="viewLeadCard" mb={3}>
        <Grid item container spacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="">
              <Typography variant="body2" className="reviewLabel">
                Name
              </Typography>
              <Typography variant="body2" className="reviewValue fontBold">
                Mohit Tyagi
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="">
              <Typography variant="body2" className="reviewLabel">
                Father Name
              </Typography>
              <Typography variant="body2" className="reviewValue fontBold">
                Ramesh Tyagi
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="">
              <Typography variant="body2" className="reviewLabel">
                Mother Name
              </Typography>
              <Typography variant="body2" className="reviewValue fontBold">
                Seema Tyagi
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="">
              <Typography variant="body2" className="reviewLabel">
                Spouse Name
              </Typography>
              <Typography variant="body2" className="reviewValue fontBold">
                Shivani Tyagi
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="">
              <Typography variant="body2" className="reviewLabel">
                Date of Birth
              </Typography>
              <Typography variant="body2" className="reviewValue fontBold">
                20h Dec 1986
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="">
              <Typography variant="body2" className="reviewLabel">
                Gender
              </Typography>
              <Typography variant="body2" className="reviewValue fontBold">
                Male
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="">
              <Typography variant="body2" className="reviewLabel">
                Marital Status
              </Typography>
              <Typography variant="body2" className="reviewValue fontBold">
                Married
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="">
              <Typography variant="body2" className="reviewLabel">
                Residential Status
              </Typography>
              <Typography variant="body2" className="reviewValue fontBold">
                Resident Individual
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="">
              <Typography variant="body2" className="reviewLabel">
                Occupation
              </Typography>
              <Typography variant="body2" className="reviewValue fontBold">
                Self Employed
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="">
              <Typography variant="body2" className="reviewLabel">
                Mobile Number
              </Typography>
              <Typography variant="body2" className="reviewValue fontBold">
                8965745213
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="">
              <Typography variant="body2" className="reviewLabel">
                Email
              </Typography>
              <Typography variant="body2" className="reviewValue fontBold">
                info@kalolytic.com
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="">
              <Typography variant="body2" className="reviewLabel">
                Residential Address
              </Typography>
              <Typography variant="body2" className="reviewValue fontBold">
                Ghaziabad, Uttar Pradesh-201014
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="">
              <Typography variant="body2" className="reviewLabel">
                Business Address
              </Typography>
              <Typography variant="body2" className="reviewValue fontBold">
                Sec-62, Noida, Uttar Pradesh-201309
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box> */}
      <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Box className="viewLeadCard darkBGCard" mb={3} sx={{ height: 'calc(100% - 24px)' }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs="auto">
                <Box variant="image" className="profileImageBox mb-1">
                  <img className="profileImage" src={profileImg} alt="Profile" />
                  <Typography variant="body2" className="profileName">
                    Salaried
                  </Typography>
                </Box>
                {/* <Typography variant="body2">24 05, 1986</Typography> */}
              </Grid>
              <Grid item xs="auto">
                <Typography variant="subtitle2">Mr. Ramesh Gupta, 36</Typography>
                <Typography variant="body2">Male</Typography>
                <Typography variant="body2">New Delhi</Typography>
                <Typography variant="body2">+91 9829784596</Typography>
                <Typography variant="body2">rajesh86@gmail.com</Typography>
              </Grid>
            </Grid>

            <Divider color="white" sx={{ marginBottom: '0.5rem', marginTop: '0.5rem' }} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="body2">Father Name</Typography>
                <Typography variant="body2">
                  <strong>Mohan Gupta</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body2">Mother Name</Typography>
                <Typography variant="body2">
                  <strong>Madhu Gupta</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body2">Spouse Name</Typography>
                <Typography variant="body2">
                  <strong>Manisha Gupta</strong>
                </Typography>
              </Grid>
            </Grid>
            <Divider color="white" sx={{ marginBottom: '0.5rem', marginTop: '0.5rem' }} />
            <Typography variant="body2">
              No. F-13/A, Anna nagar east, Opp.Chinthamani super market, Chennai
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            className="viewLeadCard"
            mb={3}
            sx={{
              height: 'calc(100% - 24px)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Box textAlign="center">
              <Typography className="colorBlue" variant="subtitle2" mb={2}>
                Low Risk
              </Typography>
              <CircularProgressWithLabel />
              <Typography className="" variant="body2">
                As on 1st June 2020
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box className="viewLeadCard" mb={3} sx={{ height: 'calc(100% - 24px)' }}>
            <Grid item container spacing={{ xs: 1, sm: 2, md: 2 }}>
              <Grid item xs={12} sm={6} md={6}>
                <Box className="">
                  <Typography variant="body2" className="reviewLabel">
                    KYC No
                  </Typography>
                  <Typography variant="body2" className="reviewValue fontBold">
                    554236
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <Typography variant="body2" className="reviewLabel">
                    Customer ID
                  </Typography>
                  <Typography variant="body2" className="reviewValue fontBold">
                    5874695321
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <Typography variant="body2" className="reviewLabel">
                    PAN No
                  </Typography>
                  <Typography variant="body2" className="reviewValue fontBold">
                    AVDL5415A
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <Typography variant="body2" className="reviewLabel">
                    Driving License No
                  </Typography>
                  <Typography variant="body2" className="reviewValue fontBold">
                    MH43 21020014201
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <Typography variant="body2" className="reviewLabel">
                    Aadhaar No
                  </Typography>
                  <Typography variant="body2" className="reviewValue fontBold">
                    4523 6789 6525
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Typography variant="body1" className="headingLineUp headingLineUpOut">
        Address
      </Typography>
      <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
        <Box className="squareSmallIcon" mb={1}>
          <Tooltip title="Add Address" placement="top" arrow>
            <IconButton className="squareIconButton">
              <MdAdd size={18} />
            </IconButton>
          </Tooltip>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body2" className="mb-1 displayFlex alignItemCenter">
              <AiFillStar size={18} color="yellow" className="mr-1" />
              <strong>Residence</strong>
              <Tooltip title="Edit" placement="top" arrow>
                <IconButton className="ml-2">
                  <AiFillEdit size={16} />
                </IconButton>
              </Tooltip>
            </Typography>
            <Typography variant="body2" className="mb-1">
              142-B, Pocket-1 Mayur Vihar
              <br />
              New Delhi-110019
            </Typography>
            <Typography variant="body2" className="">
              Source: Application, Bureau, Bank
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body2" className="mb-1">
              <strong>Work</strong>
              <Tooltip title="Edit" placement="top" arrow>
                <IconButton className="ml-2">
                  <AiFillEdit size={16} />
                </IconButton>
              </Tooltip>
            </Typography>
            <Typography variant="body2" className="mb-1">
              B 302 Vasuandhra Enclave Mayur Vihar
              <br />
              New Delhi-110019
            </Typography>
            <Typography variant="body2" className="">
              Source: Application, Bureau
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Typography variant="body1" className="headingLineUp headingLineUpOut">
        Contact
      </Typography>
      <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
        <Box className="squareSmallIcon" mb={1}>
          <Tooltip title="Add Address" placement="top" arrow>
            <IconButton className="squareIconButton">
              <MdAdd size={18} />
            </IconButton>
          </Tooltip>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body2" className="formLabel">
              Email
            </Typography>
            <TextField
              placeholder="Type Email"
              inputProps={{ className: 'textInput' }}
              defaultValue="rajesh86@gmail.com"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body2" className="formLabel">
              Phone No
            </Typography>
            <TextField
              placeholder="Type Phone No"
              inputProps={{ className: 'textInput' }}
              defaultValue="9829784596"
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <Typography variant="body1" className="headingLineUp headingLineUpOut">
        Employment
      </Typography>
      <Box className="viewLeadCard cardTopMinusMargin">
        <Box className="squareSmallIcon" mb={1}>
          <Tooltip title="Add Address" placement="top" arrow>
            <IconButton className="squareIconButton">
              <MdAdd size={18} />
            </IconButton>
          </Tooltip>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body2" className="displayFlex alignItemCenter">
              <AiFillStar size={18} color="yellow" className="mr-1" />
              <strong>Synergy Consultants Pvt Ltd </strong>
              <Tooltip title="Edit" placement="top" arrow>
                <IconButton className="ml-2">
                  <AiFillEdit size={16} />
                </IconButton>
              </Tooltip>
            </Typography>
            <Typography variant="body2" mb={1}>
              Vice President - Marketing
            </Typography>
            <Typography variant="body2" className="mb-1">
              B108, Sector 63, NOIDA,
              <br />
              UP-201301
            </Typography>
            <Typography variant="body2" className="">
              Since 12th March 2019
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body2" className="">
              <strong>Synergy Consultants Pvt Ltd</strong>
              <Tooltip title="Edit" placement="top" arrow>
                <IconButton className="ml-2">
                  <AiFillEdit size={16} />
                </IconButton>
              </Tooltip>
            </Typography>
            <Typography variant="body2" mb={1}>
              Vice President - Marketing
            </Typography>
            <Typography variant="body2" className="mb-1">
              B108, Sector 63, NOIDA,
              <br />
              UP-201301
            </Typography>
            <Typography variant="body2" className="">
              Since 12th March 2019
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
export default KYCFormTab;
