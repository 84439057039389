import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Stack,
  Grid,
  Box,
  IconButton,
  Tooltip,
  Button,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card
} from '@mui/material';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';
import { StyledTableRow, StyledTableCell, StyledIconButton } from '../utils/Styling';
// drawer components

import CloseIcon from '@mui/icons-material/Close';
// table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// icons

// import InboxIcon from '@mui/icons-material/Inbox';

import { AiFillDelete, AiFillEdit } from 'react-icons/ai';

import CryptoJS from 'crypto-js';
// components
import Page from '../Page';
import ManageAccessHeader from './ManageAccessHeader';
import PageTitle from '../PageHeading';
import BrudCrumbs from '../BreadCrumbs';
import ManageAccessToolbar from './ManageAccessToolbar';

import { fDate, fDateApi } from '../utils/formatTime';

import Validation from '../../global/Validation';
import Loader from '../../global/Loader';

import globalServices from '../../services/globalServices';
import { t } from 'i18next';
import Scrollbar from '../Scrollbar';
import DeleteDialog from '../../global/DeleteDialog';
import TransactionLogsHeader from '../TransactionLogs/TransactionLogsHeader';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL_ADMIN = process.env.REACT_APP_BASE_ADMIN_URL;

const { GetRoles, SetRolesGroup } = globalServices;

export default function ManageAccess() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loginDataDetails, setLoginDataDetails] = useState({});
  const [getRolesData, setGetRolesData] = useState([]);
  const [filteredRolesData, setFilteredRolesData] = useState([]);
  const [deleteRolesGroup, setDeleteRolesGroup] = useState([]);
  const [getRolesGroupData, setGetRolesGroupData] = useState([]);
  const [refresh, setRefresh] = useState('');
  console.log('filteredRolesData', filteredRolesData, filterName);

  // validation
  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');
  const [openloader, setOpenLoader] = useState(false);
  const [targetItem, setTargetItem] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const Input = styled('input')({
    display: 'none'
  });
  // drawer
  const [drawerOpen, setDrawOpen] = useState(false);
  // header date time
  const [currentDate, setCurrentDate] = useState(fDateApi(new Date(new Date().getTime())));
  const [prevDate, setPrevDate] = useState(
    fDateApi(new Date(new Date().getTime() - 15 * 24 * 60 * 60 * 1000))
  );
  // header date time

  const handleClose = () => {
    setOpenValidation(false);
  };
  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeletion = () => {
    fireDeleteApi(targetItem);
    handleCloseDelete();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getRolesData.length) : 0;

  const fireGetRolesApi = async (originalText) => {
    setOpenLoader(true);
    const authToken = {
      userId: originalText.userId
      // userId: originalText.userId
    };
    const GetRolesApiResponse = await GetRoles(authToken);
    if (GetRolesApiResponse.code === 100) {
      setOpenLoader(false);
      setGetRolesData(GetRolesApiResponse.data);
      setFilteredRolesData(GetRolesApiResponse.data);
    }
  };

  const handleFilterByName = (e) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = filteredRolesData.filter((user) => {
        return (
          user?.roleName?.toLowerCase().includes(keyword?.toLowerCase()) ||
          user?.id?.toString()?.toLowerCase().includes(keyword?.toString())
        );
      });

      setGetRolesData(results);
    } else {
      fireGetRolesApi(loginDataDetails);
    }
    setFilterName(keyword);
  };

  const fireDeleteApi = async (item) => {
    try {
      // fire get role api to get the data of menupageList
      const headerCredential = {
        userId: loginDataDetails.userId,
        organizationId: loginDataDetails.organizationId
      };
      const setRoleCredential = {
        roleId: String(item.id),
        roleName: item.roleName,
        userType: item.userTypeId,
        isActive: 0,
        requestType: 'D'
      };

      const SetRolesGroupApiResponse = await SetRolesGroup(headerCredential, setRoleCredential);

      if (SetRolesGroupApiResponse.code === 100) {
        setRefresh(new Date());
        setDeleteRolesGroup(SetRolesGroupApiResponse.data);
      } else {
        setOpenValidation(true);
        setValidationMsg(SetRolesGroupApiResponse.message);
        // setValidationMsg(
        //   'This access cannot be removed. Because this permission has been granted to the current user.'
        // );
      }
    } catch (error) {
      console.log('error', error);
    } finally {
    }
  };

  useEffect(() => {
    const adminUserDataValue = localStorage.getItem('adminUserDataValue');
    if (adminUserDataValue !== null) {
      const bytes = CryptoJS.AES.decrypt(adminUserDataValue, 'adminUserData');
      const originalText = bytes.toString(CryptoJS.enc.Utf8);

      setLoginDataDetails(originalText ? JSON.parse(originalText) : {});
      const originalText1 = originalText ? JSON.parse(originalText) : {};

      fireGetRolesApi(originalText1);
    }
  }, [refresh]);

  // useEffect(() => {
  //   const originalText = { userId: 1403 };
  //   // const adminUserDataValue = localStorage.getItem('adminUserDataValue');
  //   setLoginDataDetails(originalText);
  //   fireGetRolesApi(originalText);
  // }, [refresh]);

  useEffect(() => {}, [getRolesGroupData]);

  return (
    <Page title="Manage Access">
      {/* header component */}
      <TransactionLogsHeader
        // getkpidata={getkpidata}
        prevDate={prevDate}
        setPrevDate={setPrevDate}
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        setRefresh={setRefresh}
      />
      {/* <ManageAccessHeader /> */}
      <Container maxWidth={false}>
        <Loader openloader={openloader} />
        <Validation
          openValidation={openValidation}
          validationMsg={validationMsg}
          handleClose={handleClose}
          setOpenValidation={setOpenValidation}
          dialogTitle="Alert"
        />
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Manage Access" />
            <Box className="brudCrumbs">
              <BrudCrumbs />
            </Box>
          </Stack>
          <Card className="cardWrap" style={{ marginBottom: '15px' }}>
            <Scrollbar>
              <ManageAccessToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                parent="manage-access"
                setDrawOpen={setDrawOpen}
              />
              {/* table start */}

              <TableContainer
                sx={{
                  minWidth: 800,
                  overflow: 'auto',
                  borderTop: '1px solid #eaedf1'
                }}
              >
                <Table stickyHeader>
                  <TableHead className="fixedHeader">
                    <StyledTableRow>
                      <StyledTableCell>Access ID</StyledTableCell>
                      <StyledTableCell>ACCESS TYPE</StyledTableCell>
                      <StyledTableCell>CREATION DATE</StyledTableCell>
                      <StyledTableCell>UPDATE DATE</StyledTableCell>
                      <StyledTableCell>STATUS</StyledTableCell>
                      <StyledTableCell width="40">EDIT</StyledTableCell>
                      <StyledTableCell width="40">DELETE</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {console.log('getRolesData', getRolesData)}
                    {getRolesData !== undefined && getRolesData.length > 0
                      ? getRolesData
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .sort((a, b) => new Date(b.lastUpdatedOn) - new Date(a.lastUpdatedOn))
                          .map((item, index) => {
                            const isItemSelected = selected.indexOf(item.id) !== -1;

                            return (
                              <StyledTableRow
                                key={index}
                                hover
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <StyledTableCell>{item.id}</StyledTableCell>
                                <StyledTableCell>{item.roleDesc}</StyledTableCell>
                                <StyledTableCell>
                                  {item?.lastUpdatedOn !== null ? fDate(item?.lastUpdatedOn) : ''}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {item?.lastUpdatedOn !== null ? fDate(item?.lastUpdatedOn) : ''}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {item.isActive === 1 ? 'Yes' : 'No'}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Tooltip title="Edit" placement="top" arrow>
                                    <StyledIconButton
                                      className="squareIconButton actionButton"
                                      onClick={(e) => {
                                        const accessDetails = CryptoJS.AES.encrypt(
                                          JSON.stringify(item),
                                          'AccessData'
                                        ).toString();

                                        localStorage.setItem(
                                          'e13ehdnf06b-72p6-4581-86a3-c0a465e63d75-AccessData',
                                          JSON.stringify(accessDetails)
                                        );

                                        navigate(`${BASE_URL}/manage-access/edit/${item.id}`);
                                      }}
                                    >
                                      <AiFillEdit size={14} />
                                    </StyledIconButton>
                                  </Tooltip>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Tooltip title="Delete" placement="top" arrow>
                                    <StyledIconButton
                                      className="squareIconButton actionButton"
                                      onClick={(e) => {
                                        setTargetItem(item);
                                        setOpenDeleteDialog(true);
                                      }}
                                    >
                                      <AiFillDelete size={14} />
                                    </StyledIconButton>
                                  </Tooltip>
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })
                      : ''}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[10, 50, 100]}
                component="div"
                count={getRolesData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Scrollbar>
          </Card>
        </Box>

        <DeleteDialog
          openDeleteDialog={openDeleteDialog}
          dialogMsg="Are you sure you want to delete?"
          dialogTitle="Confirmation"
          buttonText="Delete"
          handleDelete={handleDeletion}
          closeDeleteHandler={handleCloseDelete}
        />

        {/* <Dialog
          open={openDeleteDialog}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle className="popupTitle">
            <Typography variant="h6">Confirmation</Typography>
            <IconButton onClick={handleCloseDelete} size="small">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                fireDeleteApi(e, targetItem);
                handleCloseDelete(e);
              }}
              className="mainBtn"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog> */}
      </Container>
    </Page>
  );
}
