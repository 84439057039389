import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Divider,
  DialogActions,
  Button,
  Typography
} from '@mui/material';
import { AiOutlineFrown, AiOutlineWarning } from 'react-icons/ai';

function DialogMsg({
  openDialogeMsg,
  closeDialogMsgHandler,
  dialogMsg,
  dialogTitle,
  buttonText,
  styles = {}
}) {
  return (
    <Box>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openDialogeMsg}
        onClose={(e) => {
          closeDialogMsgHandler(e);
        }}
        className="alertDialogWrap"
        style={styles}
      >
        <DialogTitle className="dialog-title" style={{ textAlign: 'center' }}>
          <Typography variant="subtitle1">{dialogTitle}</Typography>
          <IconButton
            onClick={(e) => closeDialogMsgHandler()}
            size="small"
            style={{ position: 'absolute', right: '10px', top: '5px' }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" textAlign="center">
            {dialogMsg}
          </Typography>
        </DialogContent>
        <DialogActions style={{ marginRight: '10px', marginBottom: '10px' }}>
          <Button onClick={(e) => closeDialogMsgHandler()} variant="contained" className="mainBtn">
            {buttonText !== null ? buttonText : 'Close'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
export default DialogMsg;
