import {
  Box,
  Button,
  Grid,
  Stack,
  CssBaseline,
  Container,
  Typography,
  CircularProgress
} from '@mui/material';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import DateFilterComp from '../DateFilterComp';
import UserComp from '../UserComp';

const TransactionLogsHeader = ({
  getkpidata,
  setPrevDate,
  currentDate,
  prevDate,
  setCurrentDate,
  setRefresh
}) => {
  return (
    <>
      {/* <CssBaseline /> */}
      <Box className="headerBox">
        <Container maxWidth={false}>
          <Stack>
            <Grid
              container
              columnSpacing={{ xs: 1, sm: 1, md: 1 }}
              alignContent="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto" className="">
                <DateFilterComp
                  setPrevDate={setPrevDate}
                  currentDate={currentDate}
                  setCurrentDate={setCurrentDate}
                  prevDate={prevDate}
                  setRefresh={setRefresh}
                />
              </Grid>
              {/* <Grid item xs>
                <Box className="displayFlex">
                  <Box className="kpiBox kpiBoxG displayFlex mr-3">
                    <Typography variant="body2">Succeed</Typography>
                    <Typography variant="body2" className="ml-2">
                      <strong>
                        {
                          getkpidata?.APPLICATION_INTERNAL_COMPLETED
                            ? Number(getkpidata?.APPLICATION_INTERNAL_COMPLETED)
                            : ''
                          // <CircularProgress size={20} />
                        }
                      </strong>
                    </Typography>
                  </Box>
                  <Box className="kpiBox kpiBoxR displayFlex">
                    <Typography variant="body2">Unsuccessful</Typography>
                    <Typography variant="body2" className="ml-2">
                      <strong>
                        {
                          getkpidata?.APPLICATION_TOTAL_COUNT &&
                          getkpidata?.APPLICATION_INTERNAL_COMPLETED
                            ? Number(getkpidata?.APPLICATION_TOTAL_COUNT) -
                              Number(getkpidata?.APPLICATION_INTERNAL_COMPLETED)
                            : ''
                          // <CircularProgress size={20} />
                        }
                      </strong>
                    </Typography>
                  </Box>
                </Box>
              </Grid> */}
              {/* <Grid item xs="auto" className="superAdmin">
                <UserComp />
              </Grid> */}
            </Grid>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default TransactionLogsHeader;
