import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItem
} from '@mui/material';
import { userDataFromLocal } from './utils/getUserDetails';
import globalServices from '../services/globalServices';
import { getAccessAlldata } from './utils/getAccessData';
import NoAccess from './NoAccessComp';

const { GetDefaultData } = globalServices;

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 75,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    color: 'rgba(255, 255, 255, 0.6)',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    '&:before': {
      top: 0,
      right: 0,
      width: 4,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '0',
  marginBottom: '0.5rem'
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
};

function NavItem({ item, active }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: '#fff',
    // fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          {/* icons and text of sidebar menus */}
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle)
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main'
                        })
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const [sidebarMainMenu, setSideBarMainMenu] = useState([]);

  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);
  // useEffect(() => {
  //   (async () => {
  //     const getAccessedMenu = await getAccessAlldata();

  //     const mainMenuId = [];
  //     getAccessedMenu.forEach((Main) =>
  //       Main.menuPageList.forEach((submenu) => {
  //         if (
  //           submenu.menuPageCode.toLowerCase() === 'Page View'.toLowerCase() &&
  //           submenu.isActive === 1
  //         ) {
  //           mainMenuId.push(Main.id);
  //         }
  //       })
  //     );

  //     const sidebarMenu = [];
  //     mainMenuId.forEach((arr) => {
  //       const filterMenu = navConfig.filter((menu) => menu.id === arr);
  //       sidebarMenu.push(filterMenu[0]);
  //     });
  //     sidebarMenu.sort((a, b) => a.indexId - b.indexId);
  //     setSideBarMainMenu(sidebarMenu);
  //   })();
  // }, []);
  // if (sidebarMainMenu.length > 0) {
  if (navConfig.length > 0) {
    return (
      <Box {...other}>
        <List disablePadding>
          {navConfig.map((item) => {
            return (
              <ListItem className="dashboardSidebar">
                <NavItem key={item.title} item={item} active={match} />
              </ListItem>
            );
          })}
          {/* {sidebarMainMenu.map((item) => {
            return <NavItem key={item.title} item={item} active={match} />;
          })} */}
        </List>
      </Box>
    );
  } else {
    return (
      <Box
        position="fixed"
        display="flex"
        justifyItems="center"
        justifyContent="center"
        alignItems="center"
        style={{
          width: '100%',
          height: '100%',
          left: '0',
          top: '0',
          backgroundColor: 'rgba(0,0,0,0.9)',
          color: '#fff'
        }}
      >
        <NoAccess />
      </Box>
    );
  }
}
