import { useEffect, useState } from 'react';
// material
import { Container, Stack, Grid, Box, IconButton, TextField, Button, Card } from '@mui/material';
import CryptoJS from 'crypto-js';

import { styled } from '@mui/material/styles';
// styles
import { StyledTableRow, StyledTableCell } from '../utils/Styling';
// table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Page from '../Page';
import ConfigureHeader from './ConfigureHeader';
import PageTitle from '../PageHeading';
import BrudCrumbs from '../BreadCrumbs';
import configurationService from '../../services/configurationService';

import Validation from '../../global/Validation';
import Loader from '../../global/Loader';
import Scrollbar from '../Scrollbar';

const { GetOrganizationProfileData, SetOrganizationProfileData } = configurationService;

export default function ValidationConfig() {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const Input = styled('input')({
    display: 'none'
  });

  const [loginDataDetails, setLoginDataDetails] = useState([]);
  const [rowData, setRowData] = useState([]);

  // validation
  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');

  const [openloader, setOpenLoader] = useState(false);

  const handleClose = () => {
    setOpenValidation(false);
  };

  const setOrganizationReq = async () => {
    setOpenLoader(true);
    // validation
    if (rowData.filter((item) => item.isBlank === true).length > 0) {
      setOpenValidation(true);
      setValidationMsg('Enter the value in empty field');
      setOpenLoader(false);
      return;
    }

    // const adminUserDataValue = localStorage.getItem('adminUserDataValue');
    const adminUserDataValue = {
      userId: 1,
      organizationId: 1
    };
    // const bytes = CryptoJS.AES.decrypt(adminUserDataValue, 'adminUserData');
    // const originalAdmiuserData = bytes.toString(CryptoJS.enc.Utf8);
    // const adminUserDate = JSON.parse(originalAdmiuserData);
    const adminUserDate = adminUserDataValue;
    const header = {
      userId: adminUserDate.userId
    };
    const isDataSubmited = await SetOrganizationProfileData(header, rowData);
    setOpenLoader(false);
  };

  useEffect(() => {
    // const adminUserDataValue = localStorage.getItem('adminUserDataValue');
    const adminUserDataValue = {
      userId: 1,
      organizationId: 1
    };
    if (adminUserDataValue !== null) {
      // const bytes = CryptoJS.AES.decrypt(adminUserDataValue, 'adminUserData');
      // const originalAdmiuserData = bytes.toString(CryptoJS.enc.Utf8);
      // const adminUserDate = JSON.parse(originalAdmiuserData);

      const adminUserDate = adminUserDataValue;

      setLoginDataDetails(adminUserDate);

      (async () => {
        setOpenLoader(true);
        const obj = {
          userId: adminUserDate.userId,
          requestType: 'a',
          organizationId: adminUserDate.organizationId
        };
        const getOrganizationProfileData = await GetOrganizationProfileData(obj);
        setOpenLoader(false);

        if (getOrganizationProfileData.code === 100) {
          getOrganizationProfileData.data.forEach(
            (item) => (item.systemEnable = item.systemEnabled)
          );
          setRowData(getOrganizationProfileData.data);
        }
      })();
    }
  }, []);

  return (
    <Page title="Configure">
      {/* header component   */}
      {/* <ConfigureHeader /> */}

      <Container maxWidth={false}>
        <Box pt={3} pb={3} mb={4}>
          <Loader openloader={openloader} />
          <Validation
            openValidation={openValidation}
            validationMsg={validationMsg}
            handleClose={handleClose}
            setOpenValidation={setOpenValidation}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="VALIDATION" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Card className="cardWrap" style={{ marginBottom: '15px' }}>
            <Scrollbar>
              <TableContainer
                sx={{
                  minWidth: 800,
                  overflow: 'auto',
                  borderTop: '1px solid #eaedf1'
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell width="320">CRITERIA</StyledTableCell>
                      <StyledTableCell>DESCRIPTION</StyledTableCell>
                      <StyledTableCell width="100">VALUES</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {rowData.length > 0 &&
                      rowData.map((rowItem, itemIndex) => {
                        if (
                          rowItem.profileCode.toLowerCase() !== 'WATER-MARK'.toLowerCase() &&
                          rowItem.profileCode.toLowerCase() !== 'FACE_MATCH_THRESHOLD'.toLowerCase()
                        ) {
                          return (
                            <StyledTableRow>
                              <StyledTableCell>{IfKeyExits(rowItem.profileCode)}</StyledTableCell>
                              <StyledTableCell>{IfKeyExits(rowItem.profileCode)}</StyledTableCell>
                              <StyledTableCell>
                                <SetValueInput
                                  rowData={rowData}
                                  setRowData={setRowData}
                                  index={itemIndex}
                                />
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        }
                        return '';
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
          <Box className="" textAlign="right">
            <Button className="mainBtn" onClick={(e) => setOrganizationReq()}>
              Update
            </Button>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}

const ifObjectBlank = (obj) => Object.keys(obj).length > 0;

const IfKeyExits = (objkey) => {
  if (objkey) {
    return objkey;
  }
  return 'N/A';
};

function SetValueInput({ rowData, setRowData, index }) {
  const [refreshComp, setRefreshComp] = useState(null);
  useEffect(() => {}, [rowData]);
  return (
    <TextField
      fullWidth
      type="number"
      inputProps={{
        className: 'textInputTable'
      }}
      value={rowData[index].customProfileValue}
      onChange={(e) => {
        const value = e.target.value;
        const prevData = rowData;
        if (value.length < 3) {
          prevData[index].customProfileValue = value;
        }
        prevData[index].systemEnabled = 'TRUE';
        if (!value) {
          prevData[index].isBlank = true;
        } else {
          prevData[index].isBlank = false;
        }
        setRowData(prevData);
        setRefreshComp(new Date());
      }}
    />
  );
}
