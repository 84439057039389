import { useEffect, useState } from 'react';
// material
import {
  Container,
  Stack,
  Grid,
  Box,
  IconButton,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
// table
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Page from '../Page';
import ConfigureHeader from './ConfigureHeader';
import PageTitle from '../PageHeading';
import BrudCrumbs from '../BreadCrumbs';
import { userDataFromLocal } from '../utils/getUserDetails';
import Loader from '../../global/Loader';
import CloseIcon from '@mui/icons-material/Close';
import configurationService from '../../services/configurationService';
import selfRegistrationPage from '../../services/selfRegistrationPage';
import DialogMsg from '../../global/DialogMsg';

const { GetNotification, SetNotification, GetAppIdForMsisdn } = configurationService;
const { SendNotification } = selfRegistrationPage;

export default function Notification() {
  const [allRecords, setAllrecord] = useState([]);
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');

  const [openloader, setOpenLoader] = useState(false);

  const [refreshComp, setRefreshComp] = useState('');

  // state
  const [approveValue, setApproveValue] = useState('');
  const [rejectValue, setRejectValue] = useState('');
  const [currentApproved, setCurrentApproved] = useState('');
  const [currentReject, setCurrentReject] = useState('');
  const [notificationId, setNotificationId] = useState('');
  const [openNotification, setOpenNotification] = useState(null);
  const [msisdnNumber, setMsisdnNumber] = useState('');
  const isNotificationOpen = Boolean(openNotification);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('sm');
  // error
  const [notificationError, setNotificationError] = useState(false);
  const [notificationHelperText, setNotificationHelperText] = useState('');

  const Input = styled('input')({
    display: 'none'
  });

  const handleClose = () => {
    setOpenValidation(false);
  };
  const fireUpdateNotificationApi = async () => {
    setOpenLoader(true);
    // validation
    if (!approveValue) {
      setOpenValidation(true);
      setValidationMsg('Please enter your approved value');
      setOpenLoader(false);
      return;
    }
    if (!rejectValue) {
      setOpenValidation(true);
      setValidationMsg('Please enter your rejected value');
      setOpenLoader(false);
      return;
    }
    const credential = {
      notificationId: notificationId,
      notificationApprovedText: approveValue,
      notificationRejectedText: rejectValue,
      organizationId: userDataFromLocal().organizationId,
      isActive: 1
    };
    // const header = {
    //   userId: userDataFromLocal().userId,
    //   organizationId: userDataFromLocal().organizationId
    // };
    const header = {
      userId: 1,
      organizationId: 1
    };
    const isNotificationSaved = await SetNotification(header, credential);
    if (isNotificationSaved.code === 100) {
      setOpenLoader(false);
      getNotificationDataApi();
    }
  };
  const getNotificationDataApi = async () => {
    // const token = {
    //   userId: userDataFromLocal().userId,
    //   organizationId: userDataFromLocal().organizationId
    // };
    const token = {
      userId: 1,
      organizationId: 1
    };
    const isNotificationAvailable = await GetNotification(token);

    if (isNotificationAvailable.code === 100) {
      setCurrentApproved(isNotificationAvailable.data.notificationApprovedText);
      setCurrentReject(isNotificationAvailable.data.notificationRejectedText);
      setNotificationId(isNotificationAvailable.data.notificationId);
    }
  };
  useEffect(() => {
    getNotificationDataApi();
  }, []);
  return (
    <Page title="Configure">
      {/* header component   */}
      {/* <ConfigureHeader /> */}

      <Container maxWidth={false}>
        <Box pt={3} pb={3} mb={4}>
          <Loader openloader={openloader} />

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="NOTIFICATION" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Box className="cardOuter cardOuterGrayBorder cardNoHover" px={2} py={3} mb={3}>
            <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="subtitle1" className="mb-2">
                  Existing Message
                </Typography>
                <Typography variant="body2" className="formLabel">
                  Approved
                </Typography>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput',
                    readOnly: true
                  }}
                  value={currentApproved}
                  className="mb-2"
                />
                <Typography variant="body2" className="formLabel">
                  Rejected
                </Typography>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput',
                    readOnly: true
                  }}
                  value={currentReject}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="subtitle1" className="mb-2">
                  New Message
                </Typography>
                <Typography variant="body2" className="formLabel">
                  Approved
                </Typography>
                <TextField
                  fullWidth
                  value={approveValue}
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="  Write Approved Text"
                  className="mb-3"
                  onChange={(e) => setApproveValue(e.target.value)}
                />
                <Typography variant="body2" className="formLabel">
                  Rejected
                </Typography>
                <TextField
                  fullWidth
                  value={rejectValue}
                  onChange={(e) => setRejectValue(e.target.value)}
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Write Rejected Text Put Here"
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="" display="flex" justifyContent="space-between">
            <Button
              className="mainBtn mainBtnBlack"
              onClick={(e) => setOpenNotification(e)}
              style={{ marginRight: '10px' }}
            >
              Test notifications
            </Button>
            <Button className="mainBtn" onClick={(e) => fireUpdateNotificationApi(e)}>
              Update
            </Button>
          </Box>

          <Dialog
            className="PopupBox"
            onClose={(e) => setOpenNotification(null)}
            open={isNotificationOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={fullWidth}
            maxWidth={maxWidth}
          >
            <DialogTitle className="popupTitle">
              <Typography variant="h6" className="">
                Test notifications
              </Typography>
              <IconButton
                onClick={(e) => {
                  setOpenNotification(false);
                  setMsisdnNumber('');
                  setAllrecord([]);
                }}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers className="">
              <TextField
                fullWidth
                value={msisdnNumber}
                onChange={(e) => {
                  setNotificationError(false);
                  setNotificationHelperText('');
                  setMsisdnNumber(e.target.value);
                  setAllrecord([]);
                }}
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Please enter the last 9 digits of the phone number that has been registered through the my Regis Plus system."
                marginRight={1}
                error={notificationError}
                helperText={notificationHelperText}
              />
              <Box mt={1} mb={1}>
                {allRecords.length > 0
                  ? allRecords.map((record, recordIndex) => {
                      console.log('records', record);
                      return (
                        <Grid container spacing={1}>
                          <Grid item md={7}>
                            {record.applicationId}
                          </Grid>
                          <Grid item md={3}>
                            <FormControl required fullWidth className="formGroup mb-1">
                              <Select
                                value={record.messageType}
                                onChange={(e) => {
                                  const prevRecords = allRecords;
                                  prevRecords[recordIndex].messageType = e.target.value;
                                  setAllrecord(prevRecords);
                                  setRefreshComp(new Date());
                                }}
                                fullWidth
                                inputProps={{
                                  className: `textInput customSelectBox dissabledMenuAdmin${record.messageType}`
                                }}
                              >
                                <MenuItem value="passed">Passed</MenuItem>
                                <MenuItem value="failed">Failed</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item md={2}>
                            <Button
                              className="mainBtn"
                              onClick={async (e) => {
                                setOpenLoader(true);
                                const header = {
                                  organizationId: userDataFromLocal().organizationId,
                                  mobileNo: record.MSISDN,
                                  applicationNo: record.applicationId,
                                  messageType: record.messageType
                                };
                                const isNotificationSend = await SendNotification(header);
                                setOpenLoader(false);
                                setOpenValidation(true);
                                if (isNotificationSend.status === 'Success') {
                                  setValidationMsg(
                                    'The notification message was sent successfully.'
                                  );
                                } else {
                                  setValidationMsg(
                                    'Unable to send notification message Please try again later.'
                                  );
                                }
                              }}
                            >
                              send
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    })
                  : allRecords}
              </Box>
            </DialogContent>
            <DialogActions textAlign="center">
              <Button
                className="mainBtn"
                onClick={async (e) => {
                  if (msisdnNumber.length === 0 || msisdnNumber.length >= 11) {
                    setNotificationError(true);
                    setNotificationHelperText(
                      'Please enter the last 9 digits of the phone number that has been registered through the Finco kyc.'
                    );
                    return;
                  }
                  setOpenLoader(true);
                  const token = {
                    userId: userDataFromLocal().userId,
                    organisationId: userDataFromLocal().organizationId,
                    msisdn: msisdnNumber
                  };
                  const isNotificationAvail = await GetAppIdForMsisdn(token);
                  setOpenLoader(false);

                  if (isNotificationAvail.code === 100 && isNotificationAvail.data.length > 0) {
                    isNotificationAvail.data.forEach((element) => {
                      element.messageType = 'passed';
                    });
                    setAllrecord(isNotificationAvail.data);
                  }

                  if (isNotificationAvail.code === 104) {
                    setAllrecord(<Typography> {`${isNotificationAvail.message}`}</Typography>);
                  }
                }}
              >
                Search
              </Button>
            </DialogActions>
          </Dialog>

          <DialogMsg
            openDialogeMsg={openValidation}
            dialogMsg={validationMsg}
            dialogTitle="Acknowledge"
            buttonText={null}
            closeDialogMsgHandler={handleClose}
          />

          {/* <Dialog
            className="PopupBox"
            onClose={handleClose}
            open={openValidation}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle className="popupTitle">
              <Typography variant="h6" className="">
                &nbsp;
              </Typography>
              <IconButton onClick={handleClose} size="small">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers className="">
              <Box mt={1} mb={1}>
                {validationMsg}
              </Box>
            </DialogContent>
            <DialogActions textAlign="center">
              <Button onClick={handleClose} className="mainBtn">
                Acknowledge
              </Button>
            </DialogActions>
          </Dialog> */}
        </Box>
      </Container>
    </Page>
  );
}
