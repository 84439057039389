import { useEffect, useState } from 'react';
// material
import {
  Container,
  Stack,
  Grid,
  Box,
  IconButton,
  TextField,
  Button,
  InputAdornment,
  Card
} from '@mui/material';
import CryptoJS from 'crypto-js';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';
import { StyledTableRow, StyledTableCell } from '../utils/Styling';
// table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// icons

// utils
import { fDateTime } from '../utils/formatTime';
// components
import Page from '../Page';
import ConfigureHeader from './ConfigureHeader';
import PageTitle from '../PageHeading';
import BrudCrumbs from '../BreadCrumbs';
import configurationService from '../../services/configurationService';
import Loader from '../Loader';

import Validation from '../../global/Validation';
import Scrollbar from '../Scrollbar';

const { GetFaceMatchData, SetOrganizationProfileData, GetOrganizationProfileData } =
  configurationService;

export default function FaceMatching() {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const Input = styled('input')({
    display: 'none'
  });

  const [loginDataDetails, setLoginDataDetails] = useState([]);
  const [rowData, setRowData] = useState({});
  const [openLoader, setOpenLoader] = useState(false);
  const [organizationData, setOrganization] = useState({});
  const [refreshComp, setRefreshComp] = useState(null);

  // validation
  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');

  const handleClose = () => {
    setOpenValidation(false);
  };

  const setOrganizationReq = async () => {
    setOpenLoader(true);
    const adminUserDataValue = {
      userId: 1,
      organizationId: 1
    };
    // const adminUserDataValue = localStorage.getItem('adminUserDataValue');
    // const bytes = CryptoJS.AES.decrypt(adminUserDataValue, 'adminUserData');
    // const originalAdmiuserData = bytes.toString(CryptoJS.enc.Utf8);
    // const adminUserDate = JSON.parse(originalAdmiuserData);
    const adminUserDate = adminUserDataValue;

    //validation
    if (!organizationData.newValue) {
      setOpenValidation(true);
      setValidationMsg('Please enter your threshold limit value');
      setOpenLoader(false);
      return;
    }
    if (!organizationData.customProfileValue) {
      setOpenValidation(true);
      setValidationMsg('Please enter your threshold limit value');
      setOpenLoader(false);
      return;
    }
    const reqArray = [
      {
        profileId: organizationData.profileId,
        profileName: organizationData.profileName,
        languageId: '1',
        customProfileValue: organizationData.newValue,
        defaultProfileValue: organizationData.customProfileValue,
        systemEnable: organizationData.systemEnabled,
        organizationId: adminUserDate.organizationId,
        isActive: organizationData.isActive
      }
    ];
    const header = {
      // userId: adminUserDate.userId
      userId: 1
    };
    const isDataSubmited = await SetOrganizationProfileData(header, reqArray);
    setOpenLoader(false);
    setRefreshComp(new Date());
  };

  useEffect(() => {
    setOpenLoader(true);
    // const adminUserDataValue = localStorage.getItem('adminUserDataValue');
    const adminUserDataValue = {
      userId: 1,
      organizationId: 1
    };
    if (adminUserDataValue !== null) {
      // const bytes = CryptoJS.AES.decrypt(adminUserDataValue, 'adminUserData');
      // const originalAdmiuserData = bytes.toString(CryptoJS.enc.Utf8);
      // const adminUserDate = JSON.parse(originalAdmiuserData);

      const adminUserDate = adminUserDataValue;
      setLoginDataDetails(adminUserDate);

      (async () => {
        const obj = {
          userId: adminUserDate.userId,
          organizationId: adminUserDate.organizationId
          // userId: 1,
          // organizationId: 1
        };
        const GetFaceMatchDataApiResponse = await GetFaceMatchData(obj);
        setOpenLoader(false);

        if (GetFaceMatchDataApiResponse.code === 100) {
          setRowData(GetFaceMatchDataApiResponse.data);
        }

        const obj2 = {
          requestType: 'a',
          ...obj
        };
        const GetOrganizationProfileDataResponse = await GetOrganizationProfileData(obj2);
        if (GetOrganizationProfileDataResponse.code === 100) {
          const filtredData = GetOrganizationProfileDataResponse.data.filter(
            (data) => data.profileCode === 'FACE_MATCH_THRESHOLD'
          )[0];
          setOrganization(filtredData);
        }
      })();
    }
  }, [refreshComp]);

  return (
    <Page title="Configure">
      {/* header component   */}
      {/* <ConfigureHeader /> */}

      <Container maxWidth={false}>
        <Box pt={3} pb={3} mb={4}>
          <Validation
            openValidation={openValidation}
            validationMsg={validationMsg}
            handleClose={handleClose}
            setOpenValidation={setOpenValidation}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="FACE MATCHING" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Box className="cardOuter cardOuterGrayBorder cardNoHover" px={2} py={2} mb={3}>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="body2" className="formLabel">
                  Existing Threshold Limits
                </Typography>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput',
                    readOnly: true
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                  value={
                    ifObjectBlank(rowData)
                      ? `${rowData.faceMatchHistoryResponseDto.newValue}`
                      : 'N/A'
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="body2" className="formLabel">
                  New Threshold Limits
                </Typography>
                <SetValueInput
                  organizationData={organizationData}
                  setOrganization={setOrganization}
                />
              </Grid>
            </Grid>
          </Box>
          <Box className="" textAlign="right" mb={3}>
            <Button className="mainBtn" onClick={(e) => setOrganizationReq()}>
              Update
            </Button>
          </Box>
          {/* previous upadte list table */}
          <Card className="cardWrap" style={{ marginBottom: '15px' }}>
            <Scrollbar>
              <TableContainer
                sx={{
                  minWidth: 800,
                  overflow: 'auto',
                  borderTop: '1px solid #eaedf1'
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>UPDATE ON</StyledTableCell>
                      <StyledTableCell>EXISTING</StyledTableCell>
                      <StyledTableCell>NEW</StyledTableCell>
                      <StyledTableCell>UPDATED BY</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell>
                        {ifObjectBlank(rowData)
                          ? IfKeyExits(fDateTime(rowData.faceMatchHistoryResponseDto.updatedOn))
                          : 'N/A'}
                      </StyledTableCell>
                      <StyledTableCell>
                        {ifObjectBlank(rowData)
                          ? `${rowData.faceMatchHistoryResponseDto.oldValue}%`
                          : 'N/A'}
                      </StyledTableCell>
                      <StyledTableCell>
                        {ifObjectBlank(rowData)
                          ? `${rowData.faceMatchHistoryResponseDto.newValue}%`
                          : 'N/A'}
                      </StyledTableCell>
                      <StyledTableCell>
                        {' '}
                        {ifObjectBlank(rowData)
                          ? IfKeyExits(rowData.faceMatchHistoryResponseDto.updatedBy)
                          : 'N/A'}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Box>
      </Container>
      <Loader openLoader={openLoader} />
    </Page>
  );
}

const ifObjectBlank = (obj) => Object.keys(obj).length > 0;

const IfKeyExits = (objkey) => {
  if (objkey) {
    return objkey;
  }
  return 'N/A';
};

function SetValueInput({ organizationData, setOrganization }) {
  const [refreshComp, setRefreshComp] = useState(null);
  useEffect(() => {}, [organizationData]);
  return (
    <>
      <TextField
        fullWidth
        type="number"
        inputProps={{
          className: 'textInput'
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>
        }}
        // placeholder="Update Threshold Limits"
        value={ifObjectBlank(organizationData) ? `${IfKeyExits(organizationData.newValue)}` : ''}
        onChange={(e) => {
          const value = e.target.value;

          const prevData = organizationData;
          prevData.newValue = value;
          prevData.systemEnabled = 'TRUE';

          setOrganization(prevData);
          setRefreshComp(new Date());
        }}
      />
    </>
  );
}
