import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Divider,
  DialogActions,
  Button,
  Typography
} from '@mui/material';
import { useEffect } from 'react';
import { AiOutlineFrown, AiOutlineWarning } from 'react-icons/ai';

function DeleteDialog({
  openDeleteDialog,
  buttonText,
  closeDeleteHandler,
  dialogMsg,
  dialogTitle,
  handleDelete
}) {
  // useEffect(() => {}, [refreshComp]);
  return (
    <Box>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openDeleteDialog}
        onClose={(e) => {
          closeDeleteHandler(e);
        }}
        className="DeleteDialogWrap"
      >
        <DialogTitle className="dialog-title" style={{ textAlign: 'center' }}>
          <Typography variant="subtitle1">{dialogTitle}</Typography>
          <IconButton
            onClick={(e) => closeDeleteHandler(e)}
            size="small"
            style={{ position: 'absolute', right: '10px', top: '5px' }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" textAlign="center">
            {dialogMsg}
          </Typography>
        </DialogContent>
        <DialogActions disableSpacing={false} className="popupAction" style={{ padding: '15px' }}>
          <Button
            style={{ backgroundColor: '#ff0000' }}
            className="mainBtn deleteBtn"
            variant="contained"
            color="secondary"
            onClick={(e) => {
              handleDelete(e);
            }}
          >
            {buttonText}
          </Button>
          <Button
            onClick={(e) => closeDeleteHandler(e)}
            variant="contained"
            className="mainBtn"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
export default DeleteDialog;
