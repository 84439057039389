import { Box, Grid, Stack, CssBaseline, Container } from '@mui/material';

import UserComp from '../UserComp';

const ManageAccessHeader = () => {
  console.log();
  return (
    <>
      <CssBaseline />
      <Box className="headerBox">
        <Container maxWidth={false}>
          <Stack>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} alignContent="center">
              <Grid item xs={10} className="">
                &nbsp;
              </Grid>
              <Grid item xs={2} className="superAdmin">
                <UserComp />
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default ManageAccessHeader;
