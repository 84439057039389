import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Stack,
  Grid,
  Box,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@mui/material';
import { GlobalInfo } from '../../../App';
import Typography from '@mui/material/Typography';
import CryptoJS from 'crypto-js';
import Switch from '@mui/material/Switch';
// icons
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../../global/Loader';
import { userDataFromLocal } from '../utils/getUserDetails';
// react icons
import {
  MdNotificationsNone,
  MdOutlineBrandingWatermark,
  MdTagFaces,
  MdOutlineSettings,
  MdHelpOutline,
  MdOutlineDocumentScanner
} from 'react-icons/md';
import { BsCardChecklist, BsJournalText } from 'react-icons/bs';
import { ImFileText2 } from 'react-icons/im';
// components
import Page from '../Page';
import ConfigureHeader from './ConfigureHeader';
import PageTitle from '../PageHeading';
import BrudCrumbs from '../BreadCrumbs';
import configurationService from '../../services/configurationService';
import NoAccess from '../NoAccessComp';
import { getAccessdata } from '../utils/getAccessData';
import DialogMsg from '../../global/DialogMsg';
import DialogMsgAction from '../../global/DialogMsgAction';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL_ADMIN = process.env.REACT_APP_BASE_ADMIN_URL;
const { SetConfigurationData, GetConfigurationsData } = configurationService;

export default function Configure() {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const { consentObj, setConsentObj } = useContext(GlobalInfo);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [getConfigurationsDataResponse, setGetConfigurationsDataResponse] = useState([]);
  const [targetItem, setTargetItem] = useState('');
  const [refreshComp, setRefreshComp] = useState('');
  const [openloader, setOpenLoader] = useState(false);
  const [submenuAccess, setSubmenuAccess] = useState({});
  const [isPageAccess, setIsPageAccess] = useState(true);

  console.log('getConfigurationsDataResponse', getConfigurationsDataResponse, submenuAccess);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const dialogMsgHandler = () => {
    fireSetOrganizationapi(targetItem);
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const fireSetOrganizationapi = async (targetItem) => {
    console.log('targetItemtargetItem', targetItem);
    setOpenLoader(true);
    const header = {
      // userId: 1,
      // organizationId: 1
      userId: userDataFromLocal().userId,
      organizationId: userDataFromLocal().organizationId
    };
    const credential = {
      configurationId: targetItem?.configurationId,
      // configurationId: targetItem.configurationId,
      isActive: targetItem.isActive === 1 ? 0 : 1
    };

    const SetConfigurationDataResponse = await SetConfigurationData(header, credential);
    if (SetConfigurationDataResponse.code === 100) {
      setOpenLoader(false);
      setRefreshComp(new Date());
      if (
        SetConfigurationDataResponse.data?.length > 0 &&
        SetConfigurationDataResponse.data !== null
      ) {
        SetConfigurationDataResponse(SetConfigurationDataResponse.data);
      }
    }
  };

  useEffect(() => {
    (async () => {
      const getAccessedMenu = await getAccessdata(110);
      console.log('getAccessedMenu', getAccessedMenu);

      const ifNoSubmenuFound = getAccessedMenu.menuPageList?.filter(
        (submenu) => submenu.isActive === 1
      );
      if (getAccessedMenu.isActive === 0) {
        setIsPageAccess(false);
      }
      setSubmenuAccess(getAccessedMenu);
    })();
    (async () => {
      setOpenLoader(true);
      const header = {
        // userId: 1403,
        // organizationId: 1
        userId: userDataFromLocal().userId,
        organizationId: userDataFromLocal().organizationId
      };
      const GetConfigurationsDataResponse = await GetConfigurationsData(header);
      if (GetConfigurationsDataResponse.code === 100) {
        setOpenLoader(false);
        setGetConfigurationsDataResponse(GetConfigurationsDataResponse.data);
      } else {
        setOpenLoader(false);
      }
    })();
  }, [refreshComp]);
  // if (isPageAccess) {
  return (
    <Page title="Configuration">
      {/* header component   */}
      {/* <ConfigureHeader /> */}

      <Container maxWidth={false}>
        <Box pt={3} pb={3} mb={4}>
          <Loader openloader={openloader} />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Configuration" />
            <Box className="brudCrumbs">
              <BrudCrumbs />
            </Box>
          </Stack>
          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {/* Screen Language */}
            {/* Terms of Service */}
            {Object.keys(submenuAccess).length > 0 ? (
              submenuAccess.menuPageList.filter(
                (menuItem) =>
                  menuItem.menuPageCode.toLowerCase() === 'Terms of Services'.toLowerCase()
              ).length > 0 ? (
                submenuAccess.menuPageList.filter(
                  (menuItem) =>
                    menuItem.menuPageCode.toLowerCase() === 'Terms of Services'.toLowerCase()
                )[0].isActive === 1 ? (
                  <Grid item xs={12} sm={6} md={4} lg={3} marginBottom={3}>
                    {/* cardOuterRedBorder */}
                    <Box
                      className={`cardOuter ${
                        getConfigurationsDataResponse?.length > 0
                          ? getConfigurationsDataResponse.filter(
                              (item) => item.configurationCode === 'TERMS_OF_SERVICE'
                            )[0].isActive === 1
                            ? 'cardOuterGreenBorder'
                            : 'cardOuterGrayBorder'
                          : 'cardOuterRedBorder'
                      }`}
                    >
                      <Box className="cardTop" container>
                        <Box className="cardIconLeft">
                          <Typography variant="caption" className="cardIconInner">
                            <ImFileText2 className="cardIconIcon" />
                          </Typography>
                        </Box>
                        <Box className="cardIconRight">
                          <Typography className="cardConfigureText" variant="h6" component="div">
                            Terms of Service
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="cardBottom">
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xs="6">
                            <IconButton
                              className="cardIconFooter"
                              onClick={(e) => {
                                if (getConfigurationsDataResponse?.length > 0) {
                                  if (
                                    getConfigurationsDataResponse.filter(
                                      (item) => item.configurationCode === 'TERMS_OF_SERVICE'
                                    ).length > 0
                                  ) {
                                    setConsentObj(
                                      getConfigurationsDataResponse.filter(
                                        (item) => item.configurationCode === 'TERMS_OF_SERVICE'
                                      )[0]
                                    );
                                    //setting into local storage consent all object
                                    const consentObject = CryptoJS.AES.encrypt(
                                      JSON.stringify(
                                        getConfigurationsDataResponse.filter(
                                          (item) => item.configurationCode === 'TERMS_OF_SERVICE'
                                        )[0]
                                      ),
                                      'consentObject'
                                    ).toString();

                                    localStorage.setItem(
                                      'e13ehdnf06b-72p6-4581-86a3-c0a465e63d75-consentObject',
                                      JSON.stringify(consentObject)
                                    );
                                  }
                                }

                                navigate(`${BASE_URL}/configure/terms-of-service`);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs="6" textAlign="right">
                            <Switch
                              {...label}
                              checked={
                                getConfigurationsDataResponse?.length > 0
                                  ? getConfigurationsDataResponse.filter(
                                      (item) => item.configurationCode === 'TERMS_OF_SERVICE'
                                    )[0].isActive === 1
                                    ? true
                                    : false
                                  : false
                              }
                              color="success"
                              disableRipple="true"
                              size="small"
                              onChange={(e) => {
                                const gettingTargetItem = getConfigurationsDataResponse.filter(
                                  (item) => item.configurationCode === 'TERMS_OF_SERVICE'
                                )[0];

                                setTargetItem(gettingTargetItem);

                                handleClickOpen(e);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                ) : (
                  ''
                )
              ) : (
                ''
              )
            ) : (
              ''
            )}

            {/* Terms of Use */}
            {Object.keys(submenuAccess).length > 0 ? (
              submenuAccess.menuPageList.filter(
                (menuItem) => menuItem.menuPageCode.toLowerCase() === 'Terms of Use'.toLowerCase()
              ).length > 0 ? (
                submenuAccess.menuPageList.filter(
                  (menuItem) => menuItem.menuPageCode.toLowerCase() === 'Terms of Use'.toLowerCase()
                )[0].isActive === 1 ? (
                  <Grid item xs={12} sm={6} md={4} lg={3} marginBottom={3}>
                    <Box
                      className={`cardOuter ${
                        getConfigurationsDataResponse?.length > 0
                          ? getConfigurationsDataResponse.filter(
                              (item) => item.configurationCode === 'TERMS_OF_USE'
                            )[0].isActive === 1
                            ? 'cardOuterGreenBorder'
                            : 'cardOuterGrayBorder'
                          : 'cardOuterRedBorder'
                      }`}
                    >
                      <Box className="cardTop" container>
                        <Box className="cardIconLeft">
                          <Typography variant="caption" className="cardIconInner">
                            <ImFileText2 className="cardIconIcon" />
                          </Typography>
                        </Box>
                        <Box className="cardIconRight">
                          <Typography className="cardConfigureText" variant="h6" component="div">
                            Terms of Use
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="cardBottom">
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xs="6">
                            <IconButton
                              className="cardIconFooter"
                              onClick={(e) => {
                                if (getConfigurationsDataResponse?.length > 0) {
                                  if (
                                    getConfigurationsDataResponse.filter(
                                      (item) => item.configurationCode === 'TERMS_OF_USE'
                                    ).length > 0
                                  ) {
                                    setConsentObj(
                                      getConfigurationsDataResponse.filter(
                                        (item) => item.configurationCode === 'TERMS_OF_USE'
                                      )[0]
                                    );
                                    //setting into local storage consent all object
                                    const consentObject = CryptoJS.AES.encrypt(
                                      JSON.stringify(
                                        getConfigurationsDataResponse.filter(
                                          (item) => item.configurationCode === 'TERMS_OF_USE'
                                        )[0]
                                      ),
                                      'consentObject'
                                    ).toString();

                                    localStorage.setItem(
                                      'e13ehdnf06b-72p6-4581-86a3-c0a465e63d75-consentObject',
                                      JSON.stringify(consentObject)
                                    );
                                  }
                                }
                                navigate(`${BASE_URL}/configure/terms-of-use`);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs="6" textAlign="right">
                            <Switch
                              {...label}
                              checked={
                                getConfigurationsDataResponse?.length > 0
                                  ? getConfigurationsDataResponse.filter(
                                      (item) => item.configurationCode === 'TERMS_OF_USE'
                                    )[0].isActive === 1
                                    ? true
                                    : false
                                  : false
                              }
                              color="success"
                              disableRipple="true"
                              size="small"
                              onChange={(e) => {
                                const gettingTargetItem = getConfigurationsDataResponse.filter(
                                  (item) => item.configurationCode === 'TERMS_OF_USE'
                                )[0];

                                setTargetItem(gettingTargetItem);
                                // changeColor('')
                                handleClickOpen(e);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                ) : (
                  ''
                )
              ) : (
                ''
              )
            ) : (
              ''
            )}
            {/* Terms of Privacy Protection */}
            {Object.keys(submenuAccess).length > 0 ? (
              submenuAccess.menuPageList.filter(
                (menuItem) =>
                  menuItem.menuPageCode.toLowerCase() ===
                  'Terms of Privacy Protection'.toLowerCase()
              ).length > 0 ? (
                submenuAccess.menuPageList.filter(
                  (menuItem) =>
                    menuItem.menuPageCode.toLowerCase() ===
                    'Terms of Privacy Protection'.toLowerCase()
                )[0].isActive === 1 ? (
                  <Grid item xs={12} sm={6} md={4} lg={3} marginBottom={3}>
                    <Box
                      className={`cardOuter ${
                        getConfigurationsDataResponse?.length > 0
                          ? getConfigurationsDataResponse.filter(
                              (item) => item.configurationCode === 'TERMS_OF_PRIVACY_PROTECTION'
                            )[0].isActive === 1
                            ? 'cardOuterGreenBorder'
                            : 'cardOuterGrayBorder'
                          : 'cardOuterRedBorder'
                      }`}
                    >
                      <Box className="cardTop" container>
                        <Box className="cardIconLeft">
                          <Typography variant="caption" className="cardIconInner">
                            <BsJournalText className="cardIconIcon" />
                          </Typography>
                        </Box>
                        <Box className="cardIconRight">
                          <Typography className="cardConfigureText" variant="h6" component="div">
                            Terms of Privacy Protection
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="cardBottom">
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xs="6">
                            <IconButton
                              className="cardIconFooter"
                              onClick={(e) => {
                                if (getConfigurationsDataResponse?.length > 0) {
                                  if (
                                    getConfigurationsDataResponse.filter(
                                      (item) =>
                                        item.configurationCode === 'TERMS_OF_PRIVACY_PROTECTION'
                                    ).length > 0
                                  ) {
                                    setConsentObj(
                                      getConfigurationsDataResponse.filter(
                                        (item) =>
                                          item.configurationCode === 'TERMS_OF_PRIVACY_PROTECTION'
                                      )[0]
                                    );
                                    //setting into local storage consent all object
                                    const consentObject = CryptoJS.AES.encrypt(
                                      JSON.stringify(
                                        getConfigurationsDataResponse.filter(
                                          (item) =>
                                            item.configurationCode === 'TERMS_OF_PRIVACY_PROTECTION'
                                        )[0]
                                      ),
                                      'consentObject'
                                    ).toString();

                                    localStorage.setItem(
                                      'e13ehdnf06b-72p6-4581-86a3-c0a465e63d75-consentObject',
                                      JSON.stringify(consentObject)
                                    );
                                  }
                                }
                                navigate(`${BASE_URL}/configure/consent`);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs="6" textAlign="right">
                            <Switch
                              {...label}
                              checked={
                                getConfigurationsDataResponse?.length > 0
                                  ? getConfigurationsDataResponse.filter(
                                      (item) =>
                                        item.configurationCode === 'TERMS_OF_PRIVACY_PROTECTION'
                                    )[0].isActive === 1
                                    ? true
                                    : false
                                  : false
                              }
                              color="success"
                              disableRipple="true"
                              size="small"
                              onChange={(e) => {
                                const gettingTargetItem = getConfigurationsDataResponse.filter(
                                  (item) => item.configurationCode === 'TERMS_OF_PRIVACY_PROTECTION'
                                )[0];

                                setTargetItem(gettingTargetItem);
                                handleClickOpen(e);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                ) : (
                  ''
                )
              ) : (
                ''
              )
            ) : (
              ''
            )}
            {/* FAQs */}
            {Object.keys(submenuAccess).length > 0 ? (
              submenuAccess.menuPageList.filter(
                (menuItem) => menuItem.menuPageCode.toLowerCase() === 'FAQs'.toLowerCase()
              ).length > 0 ? (
                submenuAccess.menuPageList.filter(
                  (menuItem) => menuItem.menuPageCode.toLowerCase() === 'FAQs'.toLowerCase()
                )[0].isActive === 1 ? (
                  <Grid item xs={12} sm={6} md={4} lg={3} marginBottom={3}>
                    <Box className="cardOuter cardOuterGreenBorder">
                      <Box className="cardTop" container>
                        <Box className="cardIconLeft">
                          <Typography variant="caption" className="cardIconInner">
                            <MdHelpOutline className="cardIconIcon" />
                          </Typography>
                        </Box>
                        <Box className="cardIconRight">
                          <Typography className="cardConfigureText" variant="h6" component="div">
                            FAQs
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="cardBottom">
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xs="6">
                            <IconButton
                              className="cardIconFooter"
                              onClick={(e) => navigate(`${BASE_URL}/configure/faq`)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs="6" textAlign="right"></Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                ) : (
                  ''
                )
              ) : (
                ''
              )
            ) : (
              ''
            )}
            {/* Instructions */}
            {Object.keys(submenuAccess).length > 0 ? (
              submenuAccess.menuPageList.filter(
                (menuItem) => menuItem.menuPageCode.toLowerCase() === 'Instruction'.toLowerCase()
              ).length > 0 ? (
                submenuAccess.menuPageList.filter(
                  (menuItem) => menuItem.menuPageCode.toLowerCase() === 'Instruction'.toLowerCase()
                )[0].isActive === 1 ? (
                  <Grid item xs={12} sm={6} md={4} lg={3} marginBottom={3}>
                    <Box
                      className={`cardOuter ${
                        getConfigurationsDataResponse?.length > 0
                          ? getConfigurationsDataResponse.filter(
                              (item) => item.configurationCode === 'INSTRUCTION'
                            )[0].isActive === 1
                            ? 'cardOuterGreenBorder'
                            : 'cardOuterGrayBorder'
                          : 'cardOuterRedBorder'
                      }`}
                    >
                      <Box className="cardTop" container>
                        <Box className="cardIconLeft">
                          <Typography variant="caption" className="cardIconInner">
                            <MdOutlineDocumentScanner className="cardIconIcon" />
                          </Typography>
                        </Box>
                        <Box className="cardIconRight">
                          <Typography className="cardConfigureText" variant="h6" component="div">
                            Instructions
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="cardBottom">
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xs="6">
                            <IconButton
                              className="cardIconFooter"
                              onClick={(e) => navigate(`${BASE_URL}/configure/instruction`)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs="6" textAlign="right">
                            <Switch
                              {...label}
                              checked={
                                getConfigurationsDataResponse?.length > 0
                                  ? getConfigurationsDataResponse.filter(
                                      (item) => item.configurationCode === 'INSTRUCTION'
                                    )[0].isActive === 1
                                    ? true
                                    : false
                                  : false
                              }
                              color="success"
                              disableRipple="true"
                              size="small"
                              onChange={(e) => {
                                const gettingTargetItem = getConfigurationsDataResponse.filter(
                                  (item) => item.configurationCode === 'INSTRUCTION'
                                )[0];

                                setTargetItem(gettingTargetItem);
                                handleClickOpen(e);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                ) : (
                  ''
                )
              ) : (
                false
              )
            ) : (
              false
            )}
            {/* Setting */}
            {Object.keys(submenuAccess).length > 0 ? (
              submenuAccess.menuPageList.filter(
                (menuItem) => menuItem.menuPageCode.toLowerCase() === 'Settings'.toLowerCase()
              ).length > 0 ? (
                submenuAccess.menuPageList.filter(
                  (menuItem) => menuItem.menuPageCode.toLowerCase() === 'Settings'.toLowerCase()
                )[0].isActive === 1 ? (
                  <Grid item xs={12} sm={6} md={4} lg={3} marginBottom={3}>
                    <Box className="cardOuter cardOuterGreenBorder">
                      <Box className="cardTop" container>
                        <Box className="cardIconLeft">
                          <Typography variant="caption" className="cardIconInner">
                            <MdOutlineSettings className="cardIconIcon" />
                          </Typography>
                        </Box>
                        <Box className="cardIconRight">
                          <Typography className="cardConfigureText" variant="h6" component="div">
                            Setting
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="cardBottom">
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xs="6">
                            <Tooltip title="Edit Setting" placement="top" arrow>
                              <IconButton
                                className="cardIconFooter"
                                onClick={(e) => navigate(`${BASE_URL}/configure/setting`)}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item xs="6" textAlign="right">
                            {/* <Switch {...label} defaultChecked color="success" disableRipple="disable" /> */}
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                ) : (
                  ''
                )
              ) : (
                false
              )
            ) : (
              false
            )}
            {/* Face Matching */}
            {Object.keys(submenuAccess).length > 0 ? (
              submenuAccess.menuPageList.filter(
                (menuItem) => menuItem.menuPageCode.toLowerCase() === 'Face Matching'.toLowerCase()
              ).length > 0 ? (
                submenuAccess.menuPageList.filter(
                  (menuItem) =>
                    menuItem.menuPageCode.toLowerCase() === 'Face Matching'.toLowerCase()
                )[0].isActive === 1 ? (
                  <Grid item xs={12} sm={6} md={4} lg={3} marginBottom={3}>
                    <Box className="cardOuter cardOuterGreenBorder">
                      <Box className="cardTop" container>
                        <Box className="cardIconLeft">
                          <Typography variant="caption" className="cardIconInner">
                            <MdTagFaces className="cardIconIcon" />
                          </Typography>
                        </Box>
                        <Box className="cardIconRight">
                          <Typography className="cardConfigureText" variant="h6" component="div">
                            Face Matching
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="cardBottom">
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xs="6">
                            <IconButton
                              className="cardIconFooter"
                              onClick={(e) => navigate(`${BASE_URL}/configure/face-matching`)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs="6" textAlign="right">
                            <Switch
                              {...label}
                              defaultChecked
                              color="success"
                              disableRipple="true"
                              size="small"
                              onChange={handleClickOpen}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                ) : (
                  ''
                )
              ) : (
                false
              )
            ) : (
              false
            )}
            {/* Watermark */}
            {Object.keys(submenuAccess).length > 0 ? (
              submenuAccess.menuPageList.filter(
                (menuItem) => menuItem.menuPageCode.toLowerCase() === 'Watermark'.toLowerCase()
              ).length > 0 ? (
                submenuAccess.menuPageList.filter(
                  (menuItem) => menuItem.menuPageCode.toLowerCase() === 'Watermark'.toLowerCase()
                )[0].isActive === 1 ? (
                  <Grid item xs={12} sm={6} md={4} lg={3} marginBottom={3}>
                    <Box
                      className={`cardOuter ${
                        getConfigurationsDataResponse?.length > 0
                          ? getConfigurationsDataResponse.filter(
                              (item) => item.configurationCode === 'WATER-MARK'
                            )[0].isActive === 1
                            ? 'cardOuterGreenBorder'
                            : 'cardOuterGrayBorder'
                          : 'cardOuterRedBorder'
                      }`}
                    >
                      <Box className="cardTop" container>
                        <Box className="cardIconLeft">
                          <Typography variant="caption" className="cardIconInner">
                            <MdOutlineBrandingWatermark className="cardIconIcon" />
                          </Typography>
                        </Box>
                        <Box className="cardIconRight">
                          <Typography className="cardConfigureText" variant="h6" component="div">
                            Watermark
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="cardBottom">
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xs="6">
                            <IconButton
                              className="cardIconFooter"
                              onClick={(e) => navigate(`${BASE_URL}/configure/watermark`)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs="6" textAlign="right"></Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                ) : (
                  ''
                )
              ) : (
                false
              )
            ) : (
              false
            )}
            {/* Notification */}
            {Object.keys(submenuAccess).length > 0 ? (
              submenuAccess.menuPageList.filter(
                (menuItem) => menuItem.menuPageCode.toLowerCase() === 'Notification'.toLowerCase()
              ).length > 0 ? (
                submenuAccess.menuPageList.filter(
                  (menuItem) => menuItem.menuPageCode.toLowerCase() === 'Notification'.toLowerCase()
                )[0].isActive === 1 ? (
                  <Grid item xs={12} sm={6} md={4} lg={3} marginBottom={3}>
                    <Box
                      className={`cardOuter ${
                        getConfigurationsDataResponse?.length > 0
                          ? getConfigurationsDataResponse.filter(
                              (item) => item.configurationCode === 'NOTIFICATION'
                            )[0].isActive === 1
                            ? 'cardOuterGreenBorder'
                            : 'cardOuterGrayBorder'
                          : 'cardOuterRedBorder'
                      }`}
                    >
                      <Box className="cardTop" container>
                        <Box className="cardIconLeft">
                          <Typography variant="caption" className="cardIconInner">
                            <MdNotificationsNone className="cardIconIcon" />
                          </Typography>
                        </Box>
                        <Box className="cardIconRight">
                          <Typography className="cardConfigureText" variant="h6" component="div">
                            Notification
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="cardBottom">
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xs="6">
                            <IconButton
                              className="cardIconFooter"
                              onClick={(e) => navigate(`${BASE_URL}/configure/notification`)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs="6" textAlign="right">
                            <Switch
                              {...label}
                              checked={
                                getConfigurationsDataResponse?.length > 0
                                  ? getConfigurationsDataResponse.filter(
                                      (item) => item.configurationCode === 'NOTIFICATION'
                                    )[0].isActive === 1
                                    ? true
                                    : false
                                  : false
                              }
                              color="success"
                              disableRipple="true"
                              size="small"
                              onChange={(e) => {
                                const gettingTargetItem = getConfigurationsDataResponse.filter(
                                  (item) => item.configurationCode === 'NOTIFICATION'
                                )[0];

                                setTargetItem(gettingTargetItem);
                                handleClickOpen(e);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                ) : (
                  ''
                )
              ) : (
                false
              )
            ) : (
              false
            )}

            {/* Validation Box */}
            {Object.keys(submenuAccess).length > 0 ? (
              submenuAccess.menuPageList.filter(
                (menuItem) => menuItem.menuPageCode.toLowerCase() === 'Validation'.toLowerCase()
              ).length > 0 ? (
                submenuAccess.menuPageList.filter(
                  (menuItem) => menuItem.menuPageCode.toLowerCase() === 'Validation'.toLowerCase()
                )[0].isActive === 1 ? (
                  <Grid item xs={12} sm={6} md={4} lg={3} marginBottom={3}>
                    <Box className="cardOuter cardOuterGreenBorder">
                      <Box className="cardTop" container>
                        <Box className="cardIconLeft">
                          <Typography variant="caption" className="cardIconInner">
                            <BsCardChecklist className="cardIconIcon" />
                          </Typography>
                        </Box>
                        <Box className="cardIconRight">
                          <Typography className="cardConfigureText" variant="h6" component="div">
                            Validation
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="cardBottom">
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xs="6">
                            <IconButton
                              className="cardIconFooter"
                              onClick={(e) => navigate(`${BASE_URL}/configure/validation`)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs="6" textAlign="right"></Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                ) : (
                  ''
                )
              ) : (
                false
              )
            ) : (
              false
            )}
          </Grid>

          <DialogMsgAction
            // openDialogeMsg={openDialoge}
            openDialogeMsg={open}
            dialogMsg="Are you sure ?"
            dialogTitle="Confirmation"
            buttonText="Confirm"
            // targetItem={targetItem} // dialogMsgHandler argument item, which is required to fire function
            buttonPrimary="Close"
            dialogMsgHandler={dialogMsgHandler}
            closeDialogMsgHandler={handleClose}
          />

          {/* <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle className="popupTitle">
            <Typography variant="h6">Confirmation</Typography>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="alert-dialog-description">Are you sure ?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                fireSetOrganizationapi(e, targetItem);
                handleClose(e);
              }}
              className="mainBtn"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog> */}
        </Box>
      </Container>
    </Page>
  );
  // }
  // return <NoAccess />;
}
