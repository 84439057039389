import { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createContext } from 'react';
import Routes from './Routes';
import ThemeConfig from './components/pages/theme';
import GlobalStyles from './components/pages/theme/globalStyles';

export const GlobalInfo = createContext();
function App() {
  const [imageData, setImageData] = useState('');
  const [MSISDNum, setMSISDNum] = useState('');
  const [simSummaryDetails, setSimSummaryDetails] = useState('');
  const [ocrDetail, setOcrDetail] = useState();
  const [personDetail, setPersonDetail] = useState();
  const [pukDetail, setPukDetail] = useState();
  const [ocrCounter, setOcrCounter] = useState(1);
  const [faceMatchCounter, setfaceMatchCounter] = useState(0);
  const [consentObj, setConsentObj] = useState('');
  return (
    <>
      <GlobalInfo.Provider
        value={{
          imageData,
          setImageData,
          MSISDNum,
          setMSISDNum,
          simSummaryDetails,
          setSimSummaryDetails,
          ocrDetail,
          setOcrDetail,
          personDetail,
          setPersonDetail,
          pukDetail,
          setPukDetail,
          ocrCounter,
          setOcrCounter,
          faceMatchCounter,
          setfaceMatchCounter,
          consentObj,
          setConsentObj
        }}
      >
        <Suspense fallback={<div>Loading...</div>} maxDuration={5000}>
          <ThemeConfig>
            <GlobalStyles />
            <Router>
              <Routes />
            </Router>
          </ThemeConfig>
        </Suspense>
      </GlobalInfo.Provider>
    </>
  );
}

export default App;
