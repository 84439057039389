import React, { useState } from 'react';

import { MdUploadFile } from 'react-icons/md';

import CloseIcon from '@mui/icons-material/Close';
import { BiCloudUpload } from 'react-icons/bi';
// import export from '@iconify/icons-ic/export';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { userDataFromLocal } from '../utils/getUserDetails';

import masterConfigurationService from '../../services/masterConfigurationService';

const { UpdateShopData } = masterConfigurationService;

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 45,
  display: 'flex',
  justifyContent: 'space-between'
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  paddingTop: 2,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

export default function MasterDataToolbar({
  numSelected,
  filterName,
  onFilterName,
  parent,
  setDrawOpen,
  setOpenLoader,
  setRefreshComp,
  setValidationMsg,
  setOpenValidation
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [file, setFile] = useState('');
  const [updateShopDataResponse, setUpdateShopDataResponse] = useState();
  function handleChange(e) {
    if (e.target.files[0].name !== 'CAT_ORG_LOCATION.csv') {
      setOpenLoader(false);
      setValidationMsg(
        'File name or type not supported. File  name should be CAT_ORG_LOCATION.csv'
      );
      setOpenValidation(true);
    }
    // setFile(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  }
  const Input = styled('input')({
    display: 'none'
  });
  const fireUpdateShopDateApi = async () => {
    setOpenLoader(true);
    const header = {
      userId: userDataFromLocal().userId,
      organizationId: userDataFromLocal().organizationId
    };
    const formData = new FormData();
    formData.append('file', file);
    const UpdateShopDataResponse = await UpdateShopData(header, formData);
    setOpenLoader(false);
    if (UpdateShopDataResponse.code === 100) {
      setOpenLoader(false);
      setRefreshComp(new Date());
      setUpdateShopDataResponse(UpdateShopDataResponse.data);
    } else {
      setValidationMsg(UpdateShopDataResponse.message);
      setOpenValidation(true);
    }
  };

  return (
    <RootStyle
      sx={{
        minHeight: 45,
        borderBottom: '1px solid #eaedf1'
      }}
    >
      <Box></Box>
      <Box>
        <Tooltip title="Upload Master File" placement="top" arrow>
          <IconButton className="squareIconButton" onClick={handleClickOpen}>
            <MdUploadFile size={18} />
          </IconButton>
        </Tooltip>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle className="popupTitle">
          <Typography variant="h6">Upload Master Records</Typography>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <Box variant="Container" className="uploadBox">
              <label htmlFor="contained-button-file">
                <Input
                  accept=".csv"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={(e) => handleChange(e)}
                />
                <Box>
                  <BiCloudUpload className="upladIcon" />
                  <Typography variant="h5">Upload Master File</Typography>
                </Box>
              </label>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              fireUpdateShopDateApi(e);
              handleClose(e);
            }}
            className="mainBtn"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </RootStyle>
  );
}
