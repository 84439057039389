import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// components
import Logo from '../../Logo';
import Scrollbar from '../../Scrollbar';
import NavSection from '../../NavSection';
import { MHidden } from '../../@material-extend';
//
import sidebarConfig from './SidebarConfig';
// import account from '../../_mocks_/account';

// ----------------------------------------------------------------------
const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL_ADMIN = process.env.REACT_APP_BASE_ADMIN_URL;

// const DRAWER_WIDTH = 160;

// const RootStyle = styled('div')(({ theme }) => ({
//   [theme.breakpoints.up('lg')]: {
//     flexShrink: 0,
//     width: DRAWER_WIDTH
//   }
// }));

// const AccountStyle = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(2, 2.5),
//   borderRadius: theme.shape.borderRadiusSm,
//   backgroundColor: theme.palette.grey[200]
// }));

// // ----------------------------------------------------------------------

// DashboardSidebar.propTypes = {
//   isOpenSidebar: PropTypes.bool,
//   onCloseSidebar: PropTypes.func
// };

const DRAWER_WIDTH = 200;
const DRAWER_WIDTH_CLOSE = 50;

const RootStyle = styled('div')(({ theme, isOpenSidebar }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: isOpenSidebar === true ? DRAWER_WIDTH : DRAWER_WIDTH_CLOSE
  }
}));

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  console.log('isOpenSidebar', isOpenSidebar);
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  // console.log('document.location.pathname', document.location.pathname.split('/'));
  // const getUrlPath = document.location.pathname.split('/');

  useEffect(() => {
    console.log('isOpenSidebar', isOpenSidebar);
    if (isOpenSidebar === true) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        background: '#0d4689',
        '& .simplebar-content': {
          background: '#0d4689',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Box
        sx={{
          px: 2,
          py: 1,
          display: 'flex',
          alignItems: 'center',
          // background: 'rgb(79 187 200 / 10%)'
          background: 'rgb(255 255 255 / 100%)',
          borderBottom: '1px solid #eff1f5',
          paddingTop: '9px'
        }}
      >
        <Box
          component={RouterLink}
          to={`${BASE_URL}/dashboard/app`}
          sx={{ display: 'inline-flex' }}
        >
          <Logo isOpenSidebar={isOpenSidebar} />
        </Box>
      </Box>

      <NavSection navConfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <>
      {/* <Drawer
        open={isOpenSidebar}
        // onClose={onCloseSidebar}
        PaperProps={{
          // eslint-disable-next-line
          sx: { width: isOpenSidebar ? DRAWER_WIDTH : '80px' }
        }}
        variant="permanent"
      >
        <Box sx={{ px: 2, py: 2, textAlign: 'center', backgroundColor: '#111B53' }}>
          <Box
            component={RouterLink}
            to={`${BASE_URL}/${BASE_URL_ADMIN}/dashboard/app`}
            sx={{ display: 'inline-flex' }}
          >
            <Logo />
          </Box>
        </Box>
        {renderContent}
      </Drawer> */}

      <RootStyle isOpenSidebar={isOpenSidebar}>
        <MHidden width="lgUp">
          <Drawer
            open={isOpen}
            onClose={onCloseSidebar}
            PaperProps={{
              width: isOpenSidebar === true ? DRAWER_WIDTH : DRAWER_WIDTH_CLOSE
            }}
          >
            {renderContent}
          </Drawer>
        </MHidden>

        <MHidden width="lgDown">
          <Drawer
            open
            variant="persistent"
            PaperProps={{
              sx: {
                width: isOpenSidebar === true ? DRAWER_WIDTH : DRAWER_WIDTH_CLOSE,
                bgcolor: 'background.default'
              },
              className: 'sliderPaperB'
            }}
          >
            {renderContent}
          </Drawer>
        </MHidden>
      </RootStyle>
    </>
  );
}
