import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Box, Button, Menu } from '@mui/material';
import { useEffect, useState } from 'react';
import { fDateApi, getThaidate } from './utils/formatTime';

import { th } from 'date-fns/locale';
import { fData } from './utils/formatNumber';

function DateFilterComp({
  setPrevDate,
  currentDate,
  prevDate,
  setCurrentDate,
  setRefresh,
  refreshHeatMap
}) {
  console.log('currentDatecurrentDate', currentDate);
  const [openCalendar, setOpenCalendar] = useState(null);
  const [refreshThis, setRefreshThis] = useState(null);
  const isOpenCal = Boolean(openCalendar);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [currentSelection, setCurrentSelection] = useState('');
  const setWeeksWise = () => {
    const weeks = 24 * 7;
    setPrevDate(fDateApi(new Date(new Date().getTime() - weeks * 60 * 60 * 1000)));
    setCurrentDate(fDateApi(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)));
  };
  const setDaysWise = () => {
    const weeks = 24;
    setPrevDate(fDateApi(new Date(new Date().getTime() + 1 * 60 * 60 * 1000)));
    setCurrentDate(fDateApi(new Date(new Date().getTime() + 1 * 60 * 60 * 1000)));
  };
  const setYearsWise = () => {
    const weeks = 24 * 365;
    setPrevDate(fDateApi(new Date(new Date().getTime() - weeks * 60 * 60 * 1000)));
    setCurrentDate(fDateApi(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)));
  };
  const setTillWise = () => {
    const weeks = 24 * 1825;
    setPrevDate(fDateApi(new Date(new Date().getTime() - weeks * 60 * 60 * 1000)));
    setCurrentDate(fDateApi(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)));
  };
  const applyCustomrangeFilter = (item) => {
    setState([item.selection]);
    // setOpenCalendar(null);
    console.log('[item.selection]', item.selection);
    setPrevDate(fDateApi(item.selection.startDate));
    setCurrentDate(fDateApi(item.selection.endDate));
    setRefreshThis(new Date());
    setRefresh(new Date());
    // setRefreshHeatMap(new Date());
  };
  const onPreviewChange = (dateRange) => {};
  useEffect(() => {}, [currentDate, prevDate, refreshHeatMap, refreshThis]);
  return (
    <>
      <Button
        className={currentSelection === 'day' ? 'filterLink selected' : 'filterLink'}
        onClick={(e) => {
          setDaysWise();
          setCurrentSelection('day');
        }}
      >
        day
      </Button>
      <Button
        className={currentSelection === 'week' ? 'filterLink selected' : 'filterLink'}
        onClick={(e) => {
          setWeeksWise();
          setCurrentSelection('week');
        }}
      >
        week
      </Button>
      <Button
        className={currentSelection === 'year' ? 'filterLink selected' : 'filterLink'}
        onClick={(e) => {
          setYearsWise();
          setCurrentSelection('year');
        }}
      >
        year
      </Button>
      <Button
        className={currentSelection === 'till' ? 'filterLink selected' : 'filterLink'}
        onClick={(e) => {
          setCurrentSelection('till');
          setOpenCalendar(e.currentTarget);
        }}
      >
        {getAplyeddates(currentDate, prevDate, fDateApi)}
      </Button>

      <Menu
        id="zoomMenu"
        className="globalZoomMenuSlider globalGrayscale"
        elevation={0}
        getContentAnchorEl={openCalendar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        anchorEl={openCalendar}
        open={isOpenCal}
        onClose={(e) => setOpenCalendar(null)}
      >
        <DateRangePicker
          retainEndDateOnFirstSelection={true}
          onChange={(item) => applyCustomrangeFilter(item)}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction="horizontal"
          // locale={th}
          staticRanges={[
            {
              label: 'Today',
              range: () => ({
                startDate: new Date(),
                endDate: new Date()
              }),
              isSelected() {
                return true;
              }
            },
            {
              label: 'Yesterday',
              range: () => ({
                startDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
                endDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
              }),
              isSelected() {
                return false;
              }
            },
            {
              label: '7 days ago',
              range: () => ({
                startDate: new Date(new Date().getTime() - 24 * 6 * 60 * 60 * 1000),
                endDate: new Date()
              }),
              isSelected() {
                return false;
              }
            },
            {
              label: '30 days ago',
              range: () => ({
                startDate: new Date(new Date().getTime() - 24 * 29 * 60 * 60 * 1000),
                endDate: new Date()
              }),
              isSelected() {
                return false;
              }
            },
            {
              label: 'This month',
              range: () => ({
                startDate: getStartDate(0),
                endDate: getEndDate(0)
              }),
              isSelected() {
                return false;
              }
            },
            {
              label: 'Last month',
              range: () => ({
                startDate: getStartDate(1),
                endDate: getEndDate(1)
              }),
              isSelected() {
                return false;
              }
            }
          ]}
        />
        <Box display="flex" justifyContent="right" p={2}>
          <Button
            onClick={(e) => {
              setOpenCalendar(null);
              setState([
                {
                  startDate: new Date(),
                  endDate: new Date(),
                  key: 'selection'
                }
              ]);
            }}
          >
            Cancel
          </Button>
          <Button
            className="mainBtn"
            onClick={(e) => {
              setOpenCalendar(null);

              setPrevDate(fDateApi(state[0].startDate));
              setCurrentDate(fDateApi(state[0].endDate));
            }}
          >
            Apply
          </Button>
        </Box>
      </Menu>
    </>
  );
}

export default DateFilterComp;

function getStartDate(val) {
  const date = new Date();

  const firstDay = new Date(date.getFullYear(), date.getMonth() - val, 1);

  return firstDay;
}

function getEndDate(val) {
  const date = new Date();

  const firstDay = new Date(date.getFullYear(), date.getMonth() - val + 1, 0);

  return firstDay;
}

function getAplyeddates(currentDate, prevDate, fDateApi) {
  console.log('currentDatecurrentDate2', currentDate);
  const prevDateType = fDateApi(new Date(prevDate));
  const currentDateType = fDateApi(new Date(currentDate));
  console.log(console.log('currentDatecurrentDate3', currentDate));
  const dateString = `${prevDateType} - ${currentDateType}`;
  console.log(console.log('currentDatecurrentDate4', dateString));
  return dateString;
}
