import { userDataFromLocal } from '../pages/utils/getUserDetails';

const statusMessages = {
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  207: 'Multi-Status (WebDAV)',
  208: 'Already Reported (WebDAV)',
  226: 'IM Used',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  306: '(Unused)',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect (experimental)',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Request Entity Too Large',
  414: 'Request-URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Requested Range Not Satisfiable',
  417: 'Expectation Failed',
  418: 'I am a teapot (RFC 2324)',
  420: 'Enhance Your Calm (Twitter)',
  422: 'Unprocessable Entity (WebDAV)',
  423: 'Locked (WebDAV)',
  424: 'Failed Dependency (WebDAV)',
  425: 'Reserved for WebDAV',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  444: 'No Response (Nginx)',
  449: 'Retry With (Microsoft)',
  450: 'Blocked by Windows Parental Controls (Microsoft)',
  451: 'Unavailable For Legal Reasons',
  499: 'Client Closed Request (Nginx)',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates (Experimental)',
  507: 'Insufficient Storage (WebDAV)',
  508: 'Loop Detected (WebDAV)',
  509: 'Bandwidth Limit Exceeded (Apache)',
  510: 'Not Extended',
  511: 'Network Authentication Required',
  598: 'Network read timeout error',
  599: 'Network connect timeout error'
};
async function fireLoginApi(endpoint, credentials) {
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}
async function firePostTokenApi(endpoint, token) {
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
    .then((response) => {
      console.log('response', response);
      if (response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function firePostTokenApiLogo(endpoint, token) {
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
    .then((response) => {
      console.log('response', response);
      if (response.ok) {
        return response;
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

// async function fireApi(endpoint, credentials, authToken) {
//   return fetch(endpoint, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${authToken}`
//     },
//     body: JSON.stringify(credentials)
//   })
//     .then((response) => {
//       if (response.ok) {
//         return response.json();
//       }
//       return {
//         status: 'error',
//         msg: statusMessages[response.status],
//         data: []
//       };
//     })
//     .catch((error) => {
//       console.log(error);
//       if (error === 'Forbidden') {
//         localStorage.clear();
//       }
//       return {
//         status: 'error',
//         msg: 'Server not responding',
//         data: []
//       };
//     });
// }

// userdata api, admin

async function firePostFormDataApiWithHeaders(endpoint, headerCredential, credentials) {
  let returnedData = {
    status: 'error',
    msg: 'Server not responding',
    data: []
  };

  return fetch(endpoint, {
    method: 'POST',
    headers: headerCredential,
    body: credentials
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);

      return (returnedData = {
        status: 'error',
        msg: 'Server not responding',
        data: [error]
      });
    });
}

async function fireApi(endpoint, credentials, authToken) {
  const userDetils = userDataFromLocal();
  console.log('userDetilsuserDetilsAccess3', userDetils);
  if (userDetils.sessionId) {
    authToken.sessionid = userDetils.sessionId;
  }
  // if (authToken.hasOwnProperty("expiredToken")) {
  //   console.log("inside authtoken");
  //   delete authToken.sessionid;
  // }

  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authToken
    }
    // body: JSON.stringify(credentials)
  })
    .then((response) => {
      console.log('responseFromApi', response);
      if (response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log('responseFromApierror', error);

      if (error === 'Forbidden') {
        localStorage.clear();
      }
      return {
        status: 'error',
        msg: statusMessages[error.status],
        data: []
      };
    });
}
async function fireApiWithBody(endpoint, header, credentials) {
  const userDetils = userDataFromLocal();
  console.log('userDetilsuserDetilsAccess3', userDetils);
  header.sessionid = userDetils.sessionId;
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...header
    },
    body: JSON.stringify(credentials)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      if (error === 'Forbidden') {
        localStorage.clear();
      }
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function fireApiWithOutBody(endpoint, header) {
  const userDetils = userDataFromLocal();
  console.log('userDetilsuserDetilsAccess3', userDetils);
  header.sessionid = userDetils.sessionId;
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...header
    }
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      if (error === 'Forbidden') {
        localStorage.clear();
      }
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}
async function fireApiMultiHeader(endpoint, header, credentials) {
  const userDetils = userDataFromLocal();
  console.log('userDetilsuserDetilsAccess3', userDetils);
  header.sessionid = userDetils.sessionId;
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      ...header
    },
    body: credentials
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      if (error === 'Forbidden') {
        localStorage.clear();
      }
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function postFileApi(endpoint, credentials, authToken) {
  const userDetils = userDataFromLocal();
  console.log('userDetilsuserDetilsAccess3', userDetils);
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    body: credentials
  })
    .then((response) => {
      console.log(response);

      if (response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}
async function putToApi(endpoint, credentials, authToken) {
  return fetch(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    body: JSON.stringify(credentials)
  })
    .then((response) => {
      console.log(response);
      if (response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}
async function deleteToApi(endpoint, credentials, authToken) {
  return fetch(endpoint, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    }
  })
    .then((response) => {
      console.log(response);

      if (response.ok && response.status === '200') {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}
// async function getToApi(endpoint, authToken) {
//   return fetch(endpoint, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${authToken}`
//     }
//   })
//     .then((response) => {
//       console.log(response);

//       if (response.ok) {
//         return response.json();
//       }
//       return {
//         status: 'error',
//         msg: statusMessages[response.status],
//         data: []
//       };
//     })
//     .catch((error) => {
//       console.log(error);
//       return {
//         status: 'error',
//         msg: 'Server not responding',
//         data: []
//       };
//     });
// }

async function getToApi(endpoint, authToken) {
  const userDetils = userDataFromLocal();
  console.log('userDetilsuserDetilsAccess3 4', userDetils);
  // if (authToken.sessionId !== undefined) {
  //   authToken.sessionid = '';
  //   authToken.sessionid = userDetils?.sessionId;
  // }
  if (authToken) {
    authToken.sessionid = '';
    authToken.sessionid = userDetils.sessionId;
  } else {
    authToken = {};
    authToken.sessionid = userDetils.sessionId;
  }

  return fetch(endpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authToken
    }
  })
    .then((response) => {
      console.log(response);

      if (response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function getToApiCredHdr(endpoint, credential, authToken) {
  const userDetils = userDataFromLocal();
  console.log('userDetilsuserDetilsAccess3', userDetils);
  authToken.sessionid = userDetils.sessionId;
  return fetch(endpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authToken
    },
    body: JSON.stringify(credential)
  })
    .then((response) => {
      console.log(response);

      if (response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}
async function getApiWithQueryStringCredHdr(endpoint, credential, authToken) {
  // const userDetils = userDataFromLocal();
  // console.log("userDetilsuserDetilsAccess3", userDetils);
  // authToken.sessionid = userDetils.sessionId;
  return fetch(endpoint, {
    method: 'GET'
    // headers: {
    //   "Content-Type": "application/json",
    //   // ...authToken
    // },
    // body: JSON.stringify(credential)
  })
    .then((response) => {
      console.log(response);

      if (response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function postHeaderCredApi(endpoint, credential) {
  let returnedData = {
    status: 'error',
    msg: 'Server not responding',
    data: []
  };

  const userDetils = userDataFromLocal();
  console.log('userDetilsuserDetilsAccess3', userDetils);
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      ...credential
    }
  })
    .then((response) => {
      console.log(response);

      if (response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);

      return (returnedData = {
        status: 'error',
        msg: 'Server not responding',
        data: [error]
      });
    });
}

async function postBodyCredApi(endpoint, credential) {
  let returnedData = {
    status: 'error',
    msg: 'Server not responding',
    data: []
  };
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credential)
  })
    .then((response) => {
      console.log(response);

      if (response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);

      return (returnedData = {
        status: 'error',
        msg: 'Server not responding',
        data: [error]
      });
    });
}

export default function useApiServices() {
  const postWithoutTokenApi = async (endPoint, credential) => {
    const isDataPosted = await fireLoginApi(endPoint, credential);
    return isDataPosted;
  };
  const postTokenApi = async (endPoint, token) => {
    const isDataPosted = await firePostTokenApi(endPoint, token);
    return isDataPosted;
  };
  const postTokenApiLogo = async (endPoint, token) => {
    const isDataPosted = await firePostTokenApiLogo(endPoint, token);
    return isDataPosted;
  };
  const postHeaderBodyApi = async (endPoint, header, credential) => {
    const isDataPosted = await fireApiWithBody(endPoint, header, credential);
    return isDataPosted;
  };

  const postHeaderWithoutBodyApi = async (endPoint, header) => {
    const isDataPosted = await fireApiWithOutBody(endPoint, header);
    return isDataPosted;
  };
  const postMultiPartHeaderApi = async (endPoint, header, credential) => {
    const isDataPosted = await fireApiMultiHeader(endPoint, header, credential);
    return isDataPosted;
  };

  const postFormDataApiWithHeaders = async (endPoint, headerCredential, credential) => {
    const isDataPosted = await firePostFormDataApiWithHeaders(
      endPoint,
      headerCredential,
      credential
    );
    return isDataPosted;
  };
  const postApi = async (endPoint, credential, authToken) => {
    const isDataPosted = await fireApi(endPoint, credential, authToken);
    return isDataPosted;
  };

  const postFileData = async (endPoint, credential, authToken) => {
    const isDataPosted = await postFileApi(endPoint, credential, authToken);
    return isDataPosted;
  };

  const putApi = async (endPoint, credential, authToken) => {
    const isDataPosted = await putToApi(endPoint, credential, authToken);
    return isDataPosted;
  };

  const getApi = async (endPoint, authToken) => {
    const isDataPosted = await getToApi(endPoint, authToken);
    return isDataPosted;
  };
  // it was before so.
  //  const getApi = async (endPoint, credential, authToken) => {
  //   const isDataPosted = await getToApi(endPoint, credential, authToken);
  //   return isDataPosted;
  // };

  const getApiCredHdr = async (endPoint, credential, authToken) => {
    const isDataPosted = await getToApiCredHdr(endPoint, credential, authToken);
    return isDataPosted;
  };
  const getApiQueryString = async (endPoint, credential, authToken) => {
    const isDataPosted = await getApiWithQueryStringCredHdr(endPoint, credential, authToken);
    return isDataPosted;
  };

  const deleteApi = async (endPoint, credential, authToken) => {
    const isDataPosted = await deleteToApi(endPoint, credential, authToken);
    return isDataPosted;
  };

  const postHeaderData = async (endPoint, credential) => {
    const isDataPosted = await postHeaderCredApi(endPoint, credential);
    return isDataPosted;
  };
  const postBodyCredentialApi = async (endPoint, credential) => {
    const isDataPosted = await postBodyCredApi(endPoint, credential);
    return isDataPosted;
  };

  return {
    postApi,
    putApi,
    getApi,
    getApiCredHdr,
    getApiQueryString,
    deleteApi,
    postFileData,
    postWithoutTokenApi,
    postTokenApi,
    postTokenApiLogo,
    postHeaderBodyApi,
    postMultiPartHeaderApi,
    postHeaderWithoutBodyApi,
    postFormDataApiWithHeaders,
    postHeaderData,
    postBodyCredentialApi
  };
}
