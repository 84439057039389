import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  Container,
  Typography,
  Grid,
  TableRow,
  Card,
  tableCellClasses,
  TableCell,
  Button,
  IconButton,
  Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
// table component
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// react icons
import { FcApproval } from 'react-icons/fc';
import { MdTransgender, MdEmail } from 'react-icons/md';
import { BsTelephone } from 'react-icons/bs';
import { GiModernCity } from 'react-icons/gi';
import { RiFireLine } from 'react-icons/ri';
import { AiFillStar } from 'react-icons/ai';
// material icons
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import GppBadIcon from '@mui/icons-material/GppBad';
import VerifiedIcon from '@mui/icons-material/Verified';
// components

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    textTransform: 'uppercase',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    fontSize: 12,
    padding: '0.4rem',
    fontWeight: 500,
    // color: '#F37021',
    whiteSpace: 'nowrap',
    height: '40.2px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: '0.4rem',
    whiteSpace: 'nowrap',
    height: '40.2px'
  }
}));

function IdentityTab() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      {/* Basic details start */}
      <Box className="viewLeadCard darkBGCard" mb={3}>
        <Grid container spacing={2}>
          <Grid item xs="auto">
            <img src="'../../../static/profileImg.jpg" alt="profile" className="profileImg" />
            <Button className="mainBtn mainBtnSec" fullWidth>
              Approved
            </Button>
          </Grid>
          <Grid item xs>
            <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body2" className="">
                  Full name
                </Typography>
                <Typography variant="subtitle2" className="labelValue">
                  Sanjay Singh Yadav
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body2" className="">
                  DOB
                </Typography>
                <Typography variant="subtitle2" className="labelValue">
                  9th Sep 1991
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body2" className="">
                  Aadhaar Number
                </Typography>
                <Typography variant="subtitle2" className="labelValue">
                  6130 2910 5917
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body2" className="">
                  Identity Verification
                </Typography>
                <Typography variant="subtitle2" className="labelValue">
                  False
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body2" className="">
                  Platform Type
                </Typography>
                <Typography variant="subtitle2" className="labelValue">
                  Mobile
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body2" className="">
                  Comparison Results
                </Typography>
                <Typography variant="subtitle2" className="labelValue">
                  60.78%
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body2" className="">
                  Comparison Criteria
                </Typography>
                <Typography variant="subtitle2" className="labelValue">
                  60%
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body2" className="">
                  Application No
                </Typography>
                <Typography variant="subtitle2" className="labelValue">
                  7e104f2b-7c34
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body2" className="">
                  Transaction date
                </Typography>
                <Typography variant="subtitle2" className="labelValue">
                  10th June 2023 09:24:58 AM
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body2" className="">
                  Customer type
                </Typography>
                <Typography variant="subtitle2" className="labelValue">
                  Individual
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="body2" className="">
                  GPS Address
                </Typography>
                <Typography variant="subtitle2" className="labelValue">
                  178, Railway Station Road Bhur Bharat Nagar, Block A, Industrial Area, Vijay
                  Nagar, Ghaziabad, Uttar Pradesh 201009
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* Basic details end */}

      {/* Document Verfication start */}
      <Typography variant="body1" className="headingLineUp headingLineUpOut">
        Adhar Card Verfication
      </Typography>
      <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
        {/* top icons start */}
        <Box className="reviewEditIcon" mb={1}>
          <Tooltip title="Approved" placement="top" arrow>
            <IconButton className="squareIconButton">
              <VerifiedIcon sx={{ fontSize: '18px' }} />
            </IconButton>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;
          <Tooltip title="Rejected" placement="top" arrow>
            <IconButton className="squareIconButton">
              <GppBadIcon sx={{ fontSize: '18px' }} />
            </IconButton>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;
          <Tooltip title="Ignore" placement="top" arrow>
            <IconButton className="squareIconButton">
              <DoNotDisturbOnIcon sx={{ fontSize: '18px' }} />
            </IconButton>
          </Tooltip>
        </Box>
        {/* top icons end */}
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} sm={4} md={3}>
            <Typography
              variant="body2"
              className="displayFlex alignItemsCenter justifyContentBetween mb-1"
            >
              <strong>Aadhar Card Image</strong>
              <FcApproval size={20} className="ml-1" />
            </Typography>
            <Box className="documentImgBox">
              <img
                className="documentImg"
                src="../../static/dummy/dummy-aadhaar-card.jpg"
                alt="Aadhar Card"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            <Typography variant="body2" className="mb-1">
              <strong>Aadhar Card Details</strong>
            </Typography>
            <Box className="displayTable w-100">
              <Box className="displayTableRow">
                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                  Aadhaar Number
                </Typography>
                <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                  :
                </Typography>
                <Typography variant="body2" className="displayTableCell pb-05">
                  <strong>6130 2910 5917</strong>
                </Typography>
              </Box>
              <Box className="displayTableRow">
                <Typography variant="body2" className="displayTableCell pb-05">
                  Aadhaar Verification
                </Typography>
                <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                  :
                </Typography>
                <Typography variant="body2" className="displayTableCell pb-05">
                  <strong>Verified</strong>
                </Typography>
              </Box>
              <Box className="displayTableRow">
                <Typography variant="body2" className="displayTableCell pb-05">
                  Gender
                </Typography>
                <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                  :
                </Typography>
                <Typography variant="body2" className="displayTableCell pb-05">
                  <strong>Male</strong>
                </Typography>
              </Box>
              <Box className="displayTableRow">
                <Typography variant="body2" className="displayTableCell pb-05">
                  Full name
                </Typography>
                <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                  :
                </Typography>
                <Typography variant="body2" className="displayTableCell pb-05">
                  <strong>Sanjay Singh Yadav</strong>
                </Typography>
              </Box>
              <Box className="displayTableRow">
                <Typography variant="body2" className="displayTableCell pb-05">
                  DOB
                </Typography>
                <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                  :
                </Typography>
                <Typography variant="body2" className="displayTableCell pb-05">
                  <strong>Ch9th Sep 1991L</strong>
                </Typography>
              </Box>
              <Box className="displayTableRow">
                <Typography variant="body2" className="displayTableCell pb-05">
                  Father name
                </Typography>
                <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                  :
                </Typography>
                <Typography variant="body2" className="displayTableCell pb-05">
                  <strong>JS Yadav</strong>
                </Typography>
              </Box>
              <Box className="displayTableRow">
                <Typography variant="body2" className="displayTableCell pb-05">
                  Address
                </Typography>
                <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                  :
                </Typography>
                <Typography variant="body2" className="displayTableCell pb-05">
                  <strong>Sikar</strong>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Typography variant="body1" className="headingLineUp headingLineUpOut">
        PAN Card Verfication
      </Typography>
      <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
        {/* top icons start */}
        <Box className="reviewEditIcon" mb={1}>
          <Tooltip title="Approved" placement="top" arrow>
            <IconButton className="squareIconButton">
              <VerifiedIcon sx={{ fontSize: '18px' }} />
            </IconButton>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;
          <Tooltip title="Rejected" placement="top" arrow>
            <IconButton className="squareIconButton">
              <GppBadIcon sx={{ fontSize: '18px' }} />
            </IconButton>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;
          <Tooltip title="Ignore" placement="top" arrow>
            <IconButton className="squareIconButton">
              <DoNotDisturbOnIcon sx={{ fontSize: '18px' }} />
            </IconButton>
          </Tooltip>
        </Box>
        {/* top icons end */}
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} sm={4} md={3}>
            <Typography
              variant="body2"
              className="displayFlex alignItemsCenter justifyContentBetween mb-1"
            >
              <strong>Pan Card Image</strong>
              <FcApproval size={20} className="ml-1" />
            </Typography>
            <Box className="documentImgBox">
              <img
                className="documentImg"
                src="../../static/dummy/dummy-pan-card.jpg"
                alt="PAN Card"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            <Typography variant="body2" className="mb-1">
              <strong>Pan Card Details</strong>
            </Typography>
            <Box className="displayTable w-100">
              <Box className="displayTableRow">
                <Typography variant="body2" className="displayTableCell pb-05 w-30">
                  Pan Card Number
                </Typography>
                <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                  :
                </Typography>
                <Typography variant="body2" className="displayTableCell pb-05">
                  <strong>AIHPY2202M</strong>
                </Typography>
              </Box>
              <Box className="displayTableRow">
                <Typography variant="body2" className="displayTableCell pb-05">
                  Pan Card Verification
                </Typography>
                <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                  :
                </Typography>
                <Typography variant="body2" className="displayTableCell pb-05">
                  <strong>Verified</strong>
                </Typography>
              </Box>
              <Box className="displayTableRow">
                <Typography variant="body2" className="displayTableCell pb-05">
                  Full name
                </Typography>
                <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                  :
                </Typography>
                <Typography variant="body2" className="displayTableCell pb-05">
                  <strong>SANJAY SINGH YADAV</strong>
                </Typography>
              </Box>
              <Box className="displayTableRow">
                <Typography variant="body2" className="displayTableCell pb-05">
                  DOB
                </Typography>
                <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                  :
                </Typography>
                <Typography variant="body2" className="displayTableCell pb-05">
                  <strong>09/09/1991</strong>
                </Typography>
              </Box>
              <Box className="displayTableRow">
                <Typography variant="body2" className="displayTableCell pb-05">
                  Father name
                </Typography>
                <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                  :
                </Typography>
                <Typography variant="body2" className="displayTableCell pb-05">
                  <strong>N/A</strong>
                </Typography>
              </Box>
              <Box className="displayTableRow">
                <Typography variant="body2" className="displayTableCell pb-05">
                  Address
                </Typography>
                <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                  :
                </Typography>
                <Typography variant="body2" className="displayTableCell pb-05">
                  <strong>N/A</strong>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* Face Match Liveness Result start */}
      <Typography variant="body1" className="headingLineUp headingLineUpOut">
        Face Match &#38; Liveness Result
      </Typography>
      <Box className="viewLeadCard cardTopMinusMargin">
        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body2" className="colorBlue mb-1">
              <strong>Live Image</strong>
            </Typography>
            <img src="'../../../static/profileImg.jpg" alt="profile" className="profileImgSec" />
            <Box className="displayTableRow">
              <Typography variant="body2" className="displayTableCell pb-05 w-40">
                Live Photo
              </Typography>
              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                :
              </Typography>
              <Typography variant="body2" className="displayTableCell pb-05">
                <strong>Yes</strong>
              </Typography>
            </Box>
            <Box className="displayTableRow">
              <Typography variant="body2" className="displayTableCell pb-05 w-40">
                Live Side Photo
              </Typography>
              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                :
              </Typography>
              <Typography variant="body2" className="displayTableCell pb-05">
                <strong>Yes</strong>
              </Typography>
            </Box>
            <Box className="displayTableRow">
              <Typography variant="body2" className="displayTableCell pb-05 w-40">
                Live Test Duration
              </Typography>
              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                :
              </Typography>
              <Typography variant="body2" className="displayTableCell pb-05">
                <strong>3 Sec</strong>
              </Typography>
            </Box>
            <Box className="displayTableRow">
              <Typography variant="body2" className="displayTableCell pb-05 w-40">
                Live Test Timestamp
              </Typography>
              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                :
              </Typography>
              <Typography variant="body2" className="displayTableCell pb-05">
                <strong>12:05:20PM, 10052021, 192.68.43.32, Delhi</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body2" className="colorBlue mb-1">
              <strong>Photo</strong>
            </Typography>
            <img src="'../../../static/profileImg.jpg" alt="profile" className="profileImgSec" />
            <Box className="displayTableRow">
              <Typography variant="body2" className="displayTableCell pb-05 w-40">
                Face Matching
              </Typography>
              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                :
              </Typography>
              <Typography variant="body2" className="displayTableCell pb-05">
                <strong>Yes</strong>
              </Typography>
            </Box>
            <Box className="displayTableRow">
              <Typography variant="body2" className="displayTableCell pb-05 w-40">
                Threshold
              </Typography>
              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                :
              </Typography>
              <Typography variant="body2" className="displayTableCell pb-05">
                <strong>92%</strong>
              </Typography>
            </Box>
            <Box className="displayTableRow">
              <Typography variant="body2" className="displayTableCell pb-05 w-40">
                Decision
              </Typography>
              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                :
              </Typography>
              <Typography variant="body2" className="displayTableCell pb-05">
                <strong>Approved</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body2" className="colorBlue mb-1">
              <strong>Aadhar</strong>
            </Typography>
            <img src="'../../../static/profileImg.jpg" alt="profile" className="profileImgSec" />
            <Box className="displayTableRow">
              <Typography variant="body2" className="displayTableCell pb-05 w-40">
                Face Matching
              </Typography>
              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                :
              </Typography>
              <Typography variant="body2" className="displayTableCell pb-05">
                <strong>Yes</strong>
              </Typography>
            </Box>
            <Box className="displayTableRow">
              <Typography variant="body2" className="displayTableCell pb-05 w-40">
                Threshold
              </Typography>
              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                :
              </Typography>
              <Typography variant="body2" className="displayTableCell pb-05">
                <strong>92%</strong>
              </Typography>
            </Box>
            <Box className="displayTableRow">
              <Typography variant="body2" className="displayTableCell pb-05 w-40">
                Decision
              </Typography>
              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                :
              </Typography>
              <Typography variant="body2" className="displayTableCell pb-05">
                <strong>Approved</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body2" className="colorBlue mb-1">
              <strong>PAN</strong>
            </Typography>
            <img src="'../../../static/profileImg.jpg" alt="profile" className="profileImgSec" />
            <Box className="displayTableRow">
              <Typography variant="body2" className="displayTableCell pb-05 w-40">
                Face Matching
              </Typography>
              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                :
              </Typography>
              <Typography variant="body2" className="displayTableCell pb-05">
                <strong>Yes</strong>
              </Typography>
            </Box>
            <Box className="displayTableRow">
              <Typography variant="body2" className="displayTableCell pb-05 w-40">
                Threshold
              </Typography>
              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                :
              </Typography>
              <Typography variant="body2" className="displayTableCell pb-05">
                <strong>92%</strong>
              </Typography>
            </Box>
            <Box className="displayTableRow">
              <Typography variant="body2" className="displayTableCell pb-05 w-40">
                Decision
              </Typography>
              <Typography variant="" className="displayTableCell pr-05 pl-05 pb-05">
                :
              </Typography>
              <Typography variant="body2" className="displayTableCell pb-05">
                <strong>Approved</strong>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
export default IdentityTab;
