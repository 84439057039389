import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  TableBody,
  Container,
  Typography,
  TableContainer,
  Grid,
  Tooltip,
  IconButton,
  Divider,
  Tabs,
  Tab,
  Checkbox
} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import { FaFolderOpen, FaCommentAlt } from 'react-icons/fa';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import { GiSwapBag } from 'react-icons/gi';
import { FiEye } from 'react-icons/fi';
import CloseIcon from '@mui/icons-material/Close';
//
import { StyledTableRow, StyledTableCell } from '../utils/Styling';
import ApprovedToolbar from './ApprovedToolbar';

// components
import Page from '../Page';
import Label from '../Label';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
import { UserListHead, UserMoreMenu } from '../_dashboard/user';
import BrudCrumbs from '../BreadCrumbs';
import PageTitle from '../PageHeading';

export default function ApprovedList() {
  const navigate = useNavigate();
  const [sharePopUp, setSharePopUp] = useState(false);
  // Styled icon button in Action
  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    margin: '0 3px',
    [`&:hover`]: {
      color: theme.palette.primary.main
    }
  }));
  return (
    <Page title="Approved || Onboard">
      <Container maxWidth={false}>
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Approved" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Card>
            <ApprovedToolbar parent="submitted" />
            <Scrollbar>
              <TableContainer
                sx={{
                  minWidth: 800,
                  height: '67.5vh',
                  overflow: 'auto'
                }}
              >
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell style={{ padding: '0' }}>
                        <Checkbox />
                      </StyledTableCell>
                      <StyledTableCell>KYC Number</StyledTableCell>
                      <StyledTableCell>Updated Date</StyledTableCell>
                      <StyledTableCell>Customer Name</StyledTableCell>
                      <StyledTableCell>KYC Mode</StyledTableCell>
                      <StyledTableCell>Risk Category</StyledTableCell>
                      <StyledTableCell>Risk Score</StyledTableCell>
                      <StyledTableCell>Next Renew Date</StyledTableCell>
                      <StyledTableCell>Completion %</StyledTableCell>
                      <StyledTableCell width={60}>Action</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell style={{ padding: '0' }}>
                        <Checkbox />
                      </StyledTableCell>
                      <StyledTableCell>176891</StyledTableCell>
                      <StyledTableCell>19th Jun 2023</StyledTableCell>
                      <StyledTableCell>Ramesh Mishra</StyledTableCell>
                      <StyledTableCell>Video KYC</StyledTableCell>
                      <StyledTableCell>Low</StyledTableCell>
                      <StyledTableCell>50</StyledTableCell>
                      <StyledTableCell>17th Jul 2023</StyledTableCell>
                      <StyledTableCell>70%</StyledTableCell>
                      <StyledTableCell>
                        <Tooltip title="View" placement="top" arrow>
                          <StyledIconButton
                            className="squareIconButton actionButton"
                            onClick={(e) => navigate('view-approved')}
                          >
                            <FiEye size={14} />
                          </StyledIconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell style={{ padding: '0' }}>
                        <Checkbox />
                      </StyledTableCell>
                      <StyledTableCell>176892</StyledTableCell>
                      <StyledTableCell>17th Jun 2023</StyledTableCell>
                      <StyledTableCell>Mohit Tyagi</StyledTableCell>
                      <StyledTableCell>E-KYC</StyledTableCell>
                      <StyledTableCell>Low</StyledTableCell>
                      <StyledTableCell>50</StyledTableCell>
                      <StyledTableCell>19th Jul 2023</StyledTableCell>
                      <StyledTableCell>82%</StyledTableCell>
                      <StyledTableCell>
                        <Tooltip title="View" placement="top" arrow>
                          <StyledIconButton
                            className="squareIconButton actionButton"
                            onClick={(e) => navigate('view-approved')}
                          >
                            <FiEye size={14} />
                          </StyledIconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Box>
      </Container>
    </Page>
  );
}
