import { useState, useEffect, useCallback, useRef } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
// import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
// import Header from './Header';
import Header from '../../../global/header/Header';
import Footer from '../../../global/footer/Footer';
import moment from 'moment';
import { userDataFromLocal } from '../../utils/getUserDetails';
// ----------------------------------------------------------------------
const REACT_APP_BASE_ADMIN_URL = process.env.REACT_APP_BASE_ADMIN_URL;
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  background: '#eff1f5'
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(true);
  const toggle = Boolean(!open);

  return (
    <RootStyle>
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(toggle)} />
      <MainStyle>
        <Header isOpenSidebar={open} setOpen={setOpen} />
        <Outlet />
        <Footer />
      </MainStyle>
    </RootStyle>
  );
}
