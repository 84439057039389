import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Stack,
  Grid,
  Box,
  IconButton,
  TextField,
  Tooltip,
  Button,
  FormControl,
  TablePagination,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card
} from '@mui/material';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import { styled } from '@mui/material/styles';
// drawer components
import Drawer from '@mui/material/Drawer';

// table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { AiFillAlert, AiFillEye, AiFillFire, AiFillRest, AiFillSwitcher } from 'react-icons/ai';
import { RiFilterOffFill } from 'react-icons/ri';
import { FiMoreHorizontal } from 'react-icons/fi';

import CryptoJS from 'crypto-js';
// components
import Page from '../Page';
// import { WatDatePicker } from 'thaidatepicker-react';
import TransactionLogsHeader from './TransactionLogsHeader';
import PageTitle from '../PageHeading';
import BrudCrumbs from '../BreadCrumbs';
import TransactionLogsToolbar from './TransactionLogsToolbar';

import reportsServices from '../../services/ReportsServices';
import { fDateApi, fDateTimeSuffix1 } from '../utils/formatTime';
import Loader from '../Loader';
import { userDataFromLocal } from '../utils/getUserDetails';
import globalServices from '../../services/globalServices';
import { getAccessdata } from '../utils/getAccessData';
import NoAccess from '../NoAccessComp';
import { CalendarToday } from '@mui/icons-material';
import DialogMsg from '../../global/DialogMsg';

// styles
import { StyledTableRow, StyledTableCell, StyledIconButton } from '../utils/Styling';
import { fDateTimeStamp } from '../utils/formatTime';
import DialogDecision from '../../global/DialogDecision';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL_ADMIN = process.env.REACT_APP_BASE_ADMIN_URL;

const { GetregistrationreportsPage, GetFilteredReports } = reportsServices;
const { GetKPIData } = globalServices;

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

export default function TransactionLogs({ data }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isFilterddata, setIsFilterddata] = useState(false);
  const [selectedFilterDate, setSelectedFilterDate] = useState('');

  const [totalNoOfPage, setTotalNoOfPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [searchAndFiter, setSearchAndFilter] = useState({
    applicationCode: '',
    documentType: '-',
    documentNumber: '',
    transactionDate: '',
    kycStatus: '',
    vkycStatus: '',
    applicationStatus: '',
    latestUpdate: '',
    pageIndex: 0,
    pageIndex: 10
  });
  // const [searchAndFiter, setSearchAndFilter] = useState({
  //   applicationCode: '',
  //   mobileNumber: '',
  //   nameSurname: '',
  //   documentType: '-',
  //   documentNumber: '',
  //   birthday: '',
  //   typeOfRegistration: '-',
  //   status: '-',
  //   note: '',
  //   orderId: '',
  //   orderStatus: '-',
  //   orderNote: '',
  //   transactionDate: '',
  //   latestUpdate: '',
  //   packageName: '',
  //   contactMobileNumber: '',
  //   contactEmail: '',
  //   // new keys
  //   departmentDesc: '',
  //   divisionDesc: '',
  //   partnerName: '',
  //   contractName: '',
  //   saaName: '',
  //   districtWeir: '',
  //   augSection: '',
  //   salesRepresentative: '',
  //   contract: '',
  //   salesArea: '',
  //   province: '',
  //   district: '',
  //   subDistrict: '',
  //   zipCode: '',
  //   provinceLocation: '',
  //   districtLocation: '',
  //   subDistrictLocation: '',
  //   pageIndex: 0,
  //   pageSize: 10,
  //   reportType: 'ALL'
  // });
  const [getkpidata, setGetkpidata] = useState({});
  const [refresh, setRefresh] = useState(null);

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const [editStep, setEditStep] = useState(false);
  const Input = styled('input')({
    display: 'none'
  });
  // Loader
  const [openLoader, setOpenLoader] = useState(false);
  // drawer
  const [drawerOpen, setDrawOpen] = useState(false);
  const [selectDate, setSelectDate] = useState(' ');
  const [currentDate, setCurrentDate] = useState(fDateApi(new Date(new Date().getTime())));
  const [prevDate, setPrevDate] = useState('2023-12-28');
  const navigate = useNavigate();
  const [transactionLogsData, setTransactionLogsData] = useState([]);
  const [transactionLogsFilter, setTransactionLogsFilter] = useState([]);
  console.log('transactionParsedLogsData', transactionLogsData);

  const [submenuAccess, setSubmenuAccess] = useState({});
  const [isPageAccess, setIsPageAccess] = useState(true);
  const [UpdateComp, setUpdateComp] = useState(null);
  const [isShowAll, setShowAll] = useState(false);
  const [PopUuText, setPopUuText] = useState('');

  const [openDialoge, setOpenDialoge] = useState(null);
  // const isPopUpOpen = Boolean(openDialoge);
  const [dialogMsg, setDialogMsg] = useState(''); // Dialog Message

  const [openDialogDecision, setOpenDialogDecision] = useState(false);

  // Handle open decision dialog
  const handleOpenDecisionDialog = () => {
    setOpenDialogDecision(true);
  };
  // Handle open decision dialog
  const closeOpenDecisionDialog = () => {
    setOpenDialogDecision(false);
    // setValidationMsg(false);
  };
  // Handle Decision Api calling
  const handleStatusChange = async () => {
    // if (selectDecision === ' ') {
    //   setValidationMsg(true);
    //   return;
    // }
    // setValidationMsg(false);
    try {
      setOpenLoader(true);

      const credential = {
        // decision: selectDecision
      };

      // const isDecisionUpdate = await DecisionUpdate(credential);

      // if (isDecisionUpdate.status === 'success') {

      // } else if (isDecisionUpdate.status === 'error') {
      //   setOpenAlertDialog(true);
      //   setDialogMsg(isDecisionUpdate.msg);
      // } else {
      //   setOpenAlertDialog(true);
      //   setDialogMsg(isDecisionUpdate.msg || 'An error occurred');
      // }
    } catch (error) {
      console.log(error);
      // setOpenAlertDialog(true);
      setDialogMsg('Something went wrong');
    } finally {
      setOpenLoader(false);
    }
  };

  // Error Dialog close handler
  const closeDialogMsgHandler = (e) => {
    setOpenDialoge(false);
    setDialogMsg('');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    setRefresh(new Date());
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - transactionLogsData.length) : 0;

  const sessionId = localStorage.getItem('sessionId');
  console.log('sessionId1232', sessionId);
  useEffect(() => {
    // get date from localstorage
    // const SelectedDate = JSON.parse(localStorage.getItem('selectedDate'));
    // if (SelectedDate) {
    //   setSelectedFilterDate(SelectedDate);
    // }
    // localStorage.setItem(
    //   'selectedDate',
    //   JSON.stringify({
    //     fromDate: prevDate,
    //     toDate: currentDate
    //   })
    // );
    setOpenLoader(true);
    if (sessionId !== null && sessionId !== 'null' && sessionId !== undefined) {
      (async () => {
        const getAccessedMenu = await getAccessdata(105);
        console.log('getAccessedMenu', getAccessedMenu);

        if (getAccessedMenu && getAccessedMenu.length) {
          const ifNoSubmenuFound = getAccessedMenu?.menuPageList.filter(
            (submenu) => submenu.isActive === 1
          );
        }

        if (getAccessedMenu.isActive === 0) {
          setIsPageAccess(false);
        }
        setSubmenuAccess(getAccessedMenu);
      })();

      if (isFilterddata) {
        filterAndSearchData(page, rowsPerPage);
      } else {
        getalltransectionlogs(prevDate, currentDate);
      }
      (async () => {
        const getKPIData = await GetKPIData({
          userId: 1,
          organisationId: 1,
          // userId: userDataFromLocal().userId,
          // organisationId: userDataFromLocal().organizationId,
          fromdate: prevDate,
          todate: currentDate
        });
        // setOpenLoader(false);
        setGetkpidata(getKPIData.data);
      })();
    }
  }, [prevDate, currentDate, refresh, page, data, sessionId]);

  // Filter and search API
  const filterAndSearchData = async (page1, rowsPerPage1) => {
    setOpenLoader(true);
    const header = {
      userId: 1,
      organizationId: 1
    };
    // const header = {
    //   userId: userDataFromLocal().userId,
    //   organizationId: userDataFromLocal().organizationId
    // };

    // map keys with from date and todate

    searchAndFiter.fromDate = prevDate;
    searchAndFiter.toDate = currentDate;
    searchAndFiter.reportType = data;

    Object.keys(searchAndFiter).forEach((item, index) => {
      if (searchAndFiter[item] === '-') {
        searchAndFiter[item] = '';
      }
    });
    searchAndFiter.pageIndex = page1;
    searchAndFiter.pageSize = rowsPerPage1;

    const isDataFiltered = await GetFilteredReports(header, searchAndFiter);

    const restObj = { reportType: data };
    if (searchAndFiter.documentType === '') {
      restObj.documentType = '-';
    }
    if (searchAndFiter.typeOfRegistration === '') {
      restObj.typeOfRegistration = '-';
    }
    if (searchAndFiter.orderStatus === '') {
      restObj.orderStatus = '-';
    }
    if (searchAndFiter.status === '') {
      restObj.status = '-';
    }

    setSearchAndFilter({
      ...searchAndFiter,
      ...restObj
    });
    if (isDataFiltered.code === 100) {
      setTransactionLogsData(isDataFiltered.data.applicationViewEntityList);
      setTotalNoOfPage(isDataFiltered.data.maxRecords);
      const csvData = [];
      isDataFiltered.data.applicationViewEntityList.forEach((element) => {
        const csvObj = [
          element.applicationNumber,
          String(element.msisdnNumber),
          `${JSON.parse(element.parmValueJson)?.title_thai} ${
            JSON.parse(element.parmValueJson)?.first_name_thai
          } ${JSON.parse(element.parmValueJson)?.last_name_thai}`,
          element.docType !== null
            ? element.docType === 1
              ? 'Citizen'
              : element.docType === 6
              ? 'Alien'
              : element.docType === 5
              ? 'Passport'
              : 'N/A'
            : 'N/A',
          String(element.docRefId),
          JSON.parse(element.parmValueJson)?.date_of_birth_eng,
          element.entryModeType !== null
            ? element.entryModeType === '125'
              ? 'Mobile'
              : element.entryModeType === '120'
              ? 'Web'
              : 'N/A'
            : 'N/A',

          String(element.apiStatusCode),
          String(element.apiName),
          String(element.orderId),
          String(element.orderStatus),

          String(element.orderStatusDesc),

          fDateTimeSuffix1(element.createdOn),
          fDateTimeSuffix1(element.lastUpdatedOn),

          String(element.offerDesc),

          String(element.missdn),

          String(element.emailAddress),
          String(element.departmentDesc),
          String(element.divisionDesc),
          String(element.partnerName),
          String(element.contractName),
          String(element.saaName),
          String(JSON.parse(element.parmValueJson)?.state),
          String(JSON.parse(element.parmValueJson)?.district),
          String(JSON.parse(element.parmValueJson)?.['sub-district']),
          getZipCodedata(JSON.parse(element.parmValueJson)?.zipcode),

          String(element.gprAddress),
          String(element.gpsLong),

          String(element.gpsLat)
        ];
        csvData.push(csvObj);
      });
      setCsvReportObj({
        ...csvReportObj,
        data: csvData
      });
      setOpenLoader(false);
    } else {
      setOpenLoader(false);
      setTransactionLogsFilter([]);
      setTransactionLogsData([]);
      setTotalNoOfPage(0);
      setCsvReportObj({
        ...csvReportObj,
        data: []
      });
    }
  };

  const Headtransaction = [
    'APPLICATION NUMBER	',
    'DOC REF ID',
    'DOC TYPE',
    'LATITUDE',
    'LONGITUDE',
    'ADDRESS',
    'CREATED ON',
    'UPDATED ON',
    'ACTION'
  ];

  const [csvReportObj, setCsvReportObj] = useState({
    data: [],
    headers: Headtransaction,
    filename: 'csvFile.csv'
  });

  // File export code end

  const getalltransectionlogs = async (prevDate, currentDate) => {
    const credential = {
      fromDate: prevDate,
      toDate: currentDate,
      reportType: data,
      pageNumber: page,
      pageSize: rowsPerPage
    };
    const transLogs = await GetregistrationreportsPage(
      {
        userId: 1, // userDataFromLocal().userId,
        organizationId: 1, // userDataFromLocal().organizationId,
        sessionid:
          'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NzExODIzNDQsInVzZXJJZCI6NTI3fQ.3bUqNvAQS8i05B__-tP6YDTd71QMu5nGLWZDg1UJXow',
        countryId: 1,
        languageId: 1
      },
      credential
    );
    setOpenLoader(false);
    if (transLogs.code === 100) {
      setTransactionLogsData(transLogs.data.applicationViewEntities);
      setTransactionLogsFilter(transLogs.data.applicationViewEntities);
      setTotalNoOfPage(transLogs.data.maxRecords);

      const csvData = [];
      // parsed data of parmValueJson
      const blankArray = [];
      let updateData = {};
      setTransactionLogsData(blankArray);
      // parsed data of parmValueJson end
      transLogs.data.applicationViewEntities.forEach((element, index) => {
        if (element.parmValueJson) {
          // parsed data of parmValueJson
          try {
            const parsedJson = JSON.parse(element?.parmValueJson);
            if (parsedJson && Object.keys(parsedJson).length > 0) {
              // setTransactionLogsData((prevData) => {
              //   const updateData = [...prevData];
              //   updateData[index] = { ...updateData[index], parsedJsonData: parsedJson };
              //   return updateData;
              // });
              updateData = { ...element, parsedJsonData: parsedJson };
              blankArray.push(updateData);
            } else {
              updateData = { ...element, parsedValueJson: {} };
            }
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
          // parsed data of parmValueJson end
          const csvObj = [
            element.applicationNumber,
            String(element.msisdnNumber),
            `${JSON.parse(element.parmValueJson)?.title_thai} ${
              JSON.parse(element.parmValueJson)?.first_name_thai
            } ${JSON.parse(element.parmValueJson)?.last_name_thai}`,
            element.docType !== null
              ? element.docType === 1
                ? 'Citizen'
                : element.docType === 6
                ? 'Alien'
                : element.docType === 5
                ? 'Passport'
                : 'N/A'
              : 'N/A',
            String(element.docRefId),
            JSON.parse(element.parmValueJson)?.date_of_birth_eng,
            element.entryModeType !== null
              ? element.entryModeType === '125'
                ? 'Mobile'
                : element.entryModeType === '120'
                ? 'Web'
                : 'N/A'
              : 'N/A',

            String(element.apiStatusCode),
            String(element.apiName),
            String(element.orderId),
            String(element.orderStatus),

            String(element.orderStatusDesc),

            fDateTimeSuffix1(element.createdOn),
            fDateTimeSuffix1(element.lastUpdatedOn),

            String(element.offerDesc),

            String(element.missdn),

            String(element.emailAddress),
            String(element.departmentDesc),
            String(element.divisionDesc),
            String(element.partnerName),
            String(element.contractName),
            String(element.saaName),
            String(JSON.parse(element.parmValueJson)?.state),
            String(JSON.parse(element.parmValueJson)?.district),
            String(JSON.parse(element.parmValueJson)?.['sub-district']),
            getZipCodedata(JSON.parse(element.parmValueJson)?.zipcode),

            String(element.gprAddress),
            String(element.gpsLong),

            String(element.gpsLat)
          ];
          csvData.push(csvObj);
        }
      });
      setCsvReportObj({
        ...csvReportObj,
        data: csvData
      });
    } else {
      setTransactionLogsData([]);
      setTransactionLogsFilter([]);
    }
  };

  const handleFilterByName = (e) => {
    const keyword = e.target.value;

    if (keyword !== '') {
      const results = transactionLogsFilter.filter((user) => {
        return (
          user.docRefId?.toLowerCase().includes(keyword.toLowerCase()) ||
          encodeURIComponent(user.ocrFirstNameThai).includes(encodeURIComponent(keyword)) ||
          encodeURIComponent(user.ocrLastNameThai).includes(encodeURIComponent(keyword)) ||
          user.msisdnNumber?.toLowerCase().includes(keyword.toLowerCase())
        );
      });

      setTransactionLogsData(results);
    } else {
      setTransactionLogsData(transactionLogsFilter);
    }
    setFilterName(keyword);
  };

  console.log('selectedFilterDate', selectedFilterDate);
  if (isPageAccess) {
    return (
      <Page title="Finco || KYC Logs">
        {/* header component */}
        <TransactionLogsHeader
          getkpidata={getkpidata}
          setPrevDate={setPrevDate}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          prevDate={prevDate}
          setRefresh={setRefresh}
        />
        <Drawer anchor="right" open={drawerOpen} onClose={(e) => setDrawOpen(false)}>
          <Box className="sideFilterHeadingBox">
            <Typography variant="h6" className="sideFilterHeading">
              Advanced Search
            </Typography>
            <IconButton
              className="filterResetIcon"
              onClick={(e) => {
                Object.keys(searchAndFiter).forEach((item, index) => (searchAndFiter[item] = ''));
                searchAndFiter.documentType = '-';
                searchAndFiter.typeOfRegistration = '-';
                searchAndFiter.orderStatus = '-';
                searchAndFiter.status = '-';
                searchAndFiter.status = '-';
                searchAndFiter.pageIndex = 0;
                searchAndFiter.pageSize = 10;
                searchAndFiter.reportType = data;
                setRefresh(new Date());
                setDrawOpen(false);
                setIsFilterddata(false);
              }}
            >
              <RiFilterOffFill size={20} />
            </IconButton>
          </Box>
          <Box
            className="sideFilterBoxOut"
            sx={{
              overflowY: 'auto',
              maxHeight: 'calc(100% - 100px)',
              borderTop: '1px solid #eeeee4',
              borderBottom: '1px solid #eeeee4'
            }}
          >
            <FormControl required fullWidth className="formGroup mb-1">
              <CustomInput
                mainVal={searchAndFiter.applicationCode}
                setMainVal={setSearchAndFilter}
                prop="applicationCode"
                searchAndFiter={searchAndFiter}
                placeholder="Application Number"
              />
            </FormControl>
            <FormControl required fullWidth className="formGroup mb-1">
              <Select
                value={searchAndFiter.documentType}
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenuAdmin${String(
                    searchAndFiter.documentType
                  ).replace('-', ' ')}`
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  setSearchAndFilter({
                    ...searchAndFiter,
                    documentType: value
                  });
                  // Document type
                }}
                // label="ประเภทเอกสาร"
              >
                <MenuItem value="-" disabled>
                  Document type
                </MenuItem>
                <MenuItem value={1}>Aadhar card</MenuItem> {/* citizen */}
                <MenuItem value={3}>Pan card</MenuItem> {/* alien */}
                {/* passport */}
              </Select>
            </FormControl>

            <FormControl required fullWidth className="formGroup mb-1">
              <CustomInput
                mainVal={searchAndFiter.documentNumber}
                setMainVal={setSearchAndFilter}
                prop="documentNumber"
                searchAndFiter={searchAndFiter}
                placeholder="Document Number"
              />
            </FormControl>
            <FormControl required fullWidth className="formGroup mb-1">
              <CustomInput
                mainVal={searchAndFiter.kycStatus}
                setMainVal={setSearchAndFilter}
                prop="kycStatus"
                searchAndFiter={searchAndFiter}
                placeholder="Kyc Status"
              />
            </FormControl>
            <FormControl required fullWidth className="formGroup mb-1">
              <CustomInput
                mainVal={searchAndFiter.vkycStatus}
                setMainVal={setSearchAndFilter}
                prop="vkycStatus"
                searchAndFiter={searchAndFiter}
                placeholder="VKyc Status"
              />
            </FormControl>
            <FormControl required fullWidth className="formGroup mb-1">
              <CustomInput
                mainVal={searchAndFiter.applicationStatus}
                setMainVal={setSearchAndFilter}
                prop="applicationStatus"
                searchAndFiter={searchAndFiter}
                placeholder="Application Status"
              />
            </FormControl>

            <FormControl required fullWidth className="formGroup mb-1">
              {searchAndFiter.transactionDate === '' ? (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 7,
                    left: 10,
                    zIndex: 99,
                    width: '80%',
                    background: '#fff',
                    color: '#919eab'
                  }}
                >
                  Created on
                </Box>
              ) : (
                ''
              )}
              <TextField
                type="date"
                fullWidth
                inputProps={{
                  // className: `textInput dissabledMenu${selectDate}`
                  className: `textInput customSelectBox dissabledMenuAdmin${String(
                    searchAndFiter.transactionDate
                  ).replace('-', ' ')}`
                }}
                placeholder="Created on"
                value={searchAndFiter.transactionDate}
                onChange={(e) => {
                  const value = e.target.value;
                  setSearchAndFilter({
                    ...searchAndFiter,
                    transactionDate: value
                  });
                }}
              />
            </FormControl>
            <FormControl required fullWidth className="formGroup mb-1">
              {searchAndFiter.latestUpdate === '' ? (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 7,
                    left: 10,
                    zIndex: 99,
                    width: '80%',
                    background: '#fff',
                    color: '#919eab'
                  }}
                >
                  Update on
                </Box>
              ) : (
                ''
              )}
              <TextField
                type="date"
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenuAdmin${String(
                    searchAndFiter.latestUpdate
                  ).replace('-', ' ')}`
                }}
                placeholder="Update on"
                value={searchAndFiter.latestUpdate}
                onChange={(e) => {
                  const value = e.target.value;
                  setSearchAndFilter({
                    ...searchAndFiter,
                    latestUpdate: value
                  });
                }}
              />
            </FormControl>
          </Box>
          <Box className="" sx={{ padding: '0.5rem 1rem' }}>
            <Button
              className="mainBtn"
              fullWidth
              onClick={(e) => {
                setPage(0);
                setRowsPerPage(10);
                setIsFilterddata(true);
                filterAndSearchData(0, 10);
                setDrawOpen(false);
              }}
            >
              Search
            </Button>
          </Box>
        </Drawer>
        <Container maxWidth={false}>
          <Box pt={3} pb={3} mb={4}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className="pageHeading"
            >
              <PageTitle info="KYC Logs" />
              <Box className="brudCrumbs">
                <BrudCrumbs />
              </Box>
            </Stack>
            <Card className="cardWrap">
              {/* table toolbar */}
              <TransactionLogsToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                parent="partners"
                setDrawOpen={setDrawOpen}
                csvReportObj={csvReportObj}
                submenuAccess={submenuAccess}
              />
              {/* table start */}
              <TableContainer sx={{ overflow: 'auto', minWidth: 800, height: '68vh' }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className="fixedHeader">
                    {
                      // verifiedBy	verifiedOn	msisdnNumber	docType	blackListStat	confidenceScore	serviceNoCheck
                      // notificationStatus	gprLat	gpsLong	gpsAddress	gpsDistinct	offerDesc	currentProfileConfig
                      // fcmToken	status	startDate	endDate	createdBy	createdOn	updatedBy	lastUpdatedOn	applicationType
                      // organizationEntity	enrtymodeType
                    }
                    <TableRow>
                      <StyledTableCell>Application Number</StyledTableCell>
                      <StyledTableCell>Aadhaar number</StyledTableCell>
                      <StyledTableCell>Pan number</StyledTableCell>
                      <StyledTableCell>Customer name</StyledTableCell>
                      {/*<StyledTableCell>Latitude</StyledTableCell>
                      <StyledTableCell>longitude</StyledTableCell>
                      <StyledTableCell>Address</StyledTableCell> */}
                      <StyledTableCell>Kyc Status</StyledTableCell>
                      <StyledTableCell>Vkyc Status</StyledTableCell>
                      <StyledTableCell>Application Status</StyledTableCell>
                      <StyledTableCell>Created On</StyledTableCell>
                      <StyledTableCell>Updated On</StyledTableCell>
                      {/* <StyledTableCell>Status</StyledTableCell> */}
                      <StyledTableCell>Action</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {console.log('transactionLogsData', transactionLogsData)}
                    {transactionLogsData.map((logItem, index) => {
                      const parmValueJson = {};
                      // const parmValueJson = JSON.parse(logItem.parmValueJson);

                      // const { id, name, role, status, company, avatarUrl, isVerified } = row;
                      const isItemSelected = selected.indexOf(logItem.applicationNumber) !== -1;

                      return (
                        <StyledTableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <StyledTableCell>
                            {isShowAll ? logItem.applicationNumber : logItem.applicationNumber}
                          </StyledTableCell>
                          {/* don't remove the code, below code is usefull when text length is exceed then cut the text.
                          <StyledTableCell
                            sx={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              setPopUuText(logItem.applicationNumber);
                              setOpenDialoge(e.target);
                            }}
                          >
                            {isShowAll
                              ? logItem.applicationNumber
                              : logItem.applicationNumber.substr(
                                  logItem.applicationNumber.length - 12
                                )}
                            <br />
                            <FiMoreHorizontal size={14} />
                          </StyledTableCell> 
                          don't remove the code, below code is usefull when text length is exceed then cut the text.
                          */}
                          <StyledTableCell>
                            {/* {JSON.parse(logItem?.parmValueJson)?.aadhaar_number !== null
                              ? JSON.parse(logItem?.parmValueJson)?.aadhaar_number
                              : 'N/A'} */}
                            {logItem?.parsedJsonData?.aadhaar_number !== null
                              ? logItem?.parsedJsonData?.aadhaar_number
                              : 'N/A'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {logItem?.parsedJsonData?.pan_card_number !== null
                              ? logItem?.parsedJsonData?.pan_card_number
                              : 'N/A'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {/* {logItem.customerId !== null ? logItem.customerId : 'N/A'} */}
                            {logItem.customerId !== null
                              ? logItem.customerId
                              : logItem?.parsedJsonData?.name}
                          </StyledTableCell>
                          <StyledTableCell>
                            {logItem?.kycStatus !== null ? logItem?.kycStatus : 'N/A'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {logItem?.vkycStatus !== null ? logItem?.vkycStatus : 'N/A'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {logItem?.applicationStatus !== null
                              ? logItem?.applicationStatus
                              : 'N/A'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {logItem.createdOn !== null ? fDateTimeStamp(logItem.createdOn) : 'N/A'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {logItem.lastUpdatedOn !== null
                              ? fDateTimeStamp(logItem.lastUpdatedOn)
                              : 'N/A'}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Tooltip title="View" placement="top" arrow>
                              <StyledIconButton
                                className="squareIconButton actionButton"
                                disabled={
                                  Object.keys(submenuAccess).length > 0
                                    ? submenuAccess.menuPageList.filter(
                                        (menuItem) =>
                                          menuItem.menuPageCode.toLowerCase() ===
                                          'Detailed View'.toLowerCase()
                                      ).length > 0
                                      ? submenuAccess.menuPageList.filter(
                                          (menuItem) =>
                                            menuItem.menuPageCode.toLowerCase() ===
                                            'Detailed View'.toLowerCase()
                                        )[0].isActive === 0
                                      : false
                                    : false
                                }
                                onClick={(e) => {
                                  const cipherText = CryptoJS.AES.encrypt(
                                    JSON.stringify(logItem),
                                    'logItem'
                                  ).toString();
                                  localStorage.setItem('reportPreview', cipherText);
                                  localStorage.setItem(
                                    'applicationNumber1',
                                    logItem?.applicationNumber
                                  );
                                  // navigate(`${BASE_URL}/transaction-logs/view/${logItem.id}`);
                                  navigate(`/kyc-logs/view/${logItem?.id}`);
                                }}
                              >
                                <AiFillEye size={14} />
                              </StyledIconButton>
                            </Tooltip>
                            {/* <Tooltip title="Decision" placement="top" arrow>
                              <StyledIconButton
                                className="squareIconButton actionButton"
                                onClick={() => handleOpenDecisionDialog()}
                              >
                                <AiFillSwitcher size={14} />
                              </StyledIconButton>
                            </Tooltip> */}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={getPerpageOp(totalNoOfPage)}
                component="div"
                count={totalNoOfPage}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
            <DialogMsg
              openDialogeMsg={openDialoge}
              dialogMsg={PopUuText}
              dialogTitle="Application Number"
              buttonText={null}
              closeDialogMsgHandler={closeDialogMsgHandler}
            />

            {/* <Dialog
            open={openDialoge}
            // onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className=""
            classes={{
              paper: 'popUpOuter'
            }}
          >
            <DialogTitle className="popUpHead">
              <Typography variant="h6">&nbsp;</Typography>
              <IconButton
                onClick={(e) => {
                  setOpenDialoge(null);
                  setPopUuText('');
                }}
                size="small"
                className="popUpCloseIcon"
              >
                <CloseIcon size={20} />
              </IconButton>
            </DialogTitle>
            <DialogContent className="popUpContentB">
              <DialogContentText id="alert-dialog-description" className="popUpBody">
                <Typography variant="subtitle1" className="popUpInnerText">
                  {PopUuText}
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="popUpFoot popUpFootCenter">
              <Button
                className="primaryButton primaryButtonBlack"
                onClick={(e) => {
                  setOpenDialoge(null);
                  setPopUuText('');
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog> */}
            <Loader openLoader={openLoader} />
          </Box>
        </Container>
      </Page>
    );
  }
  return <NoAccess />;
}

const gpsString = (wordString, index) => {
  if (wordString) {
    const strArray = wordString !== null ? wordString.split(',') : '';

    if (strArray[index]) {
      return strArray[index];
    }

    return '';
  }
  return '';
};
const ifObjectBlank = (obj) => Object.keys(obj).length > 0;

const IfKeyExits = (objkey) => {
  if (objkey) {
    return objkey;
  }
  return '';
};

function CustomInput({ mainVal, setMainVal, prop, searchAndFiter, placeholder, inputType }) {
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    setInputValue(mainVal);
  }, [mainVal]);
  let otherProps = {};
  if (inputType) {
    otherProps = { type: inputType };
  }
  return (
    <TextField
      fullWidth
      inputProps={{
        className: 'textInput'
      }}
      placeholder={placeholder} // application code
      value={inputValue}
      onChange={(e) => {
        setInputValue(e.target.value.trim());
      }}
      onBlur={(e) => {
        const value = e.target.value;
        setMainVal({ ...searchAndFiter, [prop]: value });
      }}
      {...otherProps}
    />
  );
}

function getPerpageOp(totalNoOfPage) {
  const optionArray = [10];
  if (totalNoOfPage > 10) {
    optionArray.push(50);
  }
  if (totalNoOfPage > 50) {
    optionArray.push(100);
  }
  if (totalNoOfPage > 100) {
    optionArray.push(150);
  }
  if (totalNoOfPage > 250) {
    optionArray.push(totalNoOfPage);
  } else if (totalNoOfPage < 250 && totalNoOfPage > 150) {
    optionArray.push(250);
  }

  return optionArray;
}

function getZipCodedata(zipcodes) {
  if (zipcodes) {
    const zipArra = zipcodes.split(',');
    let string = '';
    zipArra.forEach((item, index) => {
      if (item !== '') {
        string += `${item}${index < zipArra.length - 1 ? ', ' : ' '}`;
      }
    });

    return zipArra[0];
  }
  return '';
}
function getZipCXodeFormate(code) {
  const zipcodeArra = code.split(',');

  let ziparrayString = '';
  if (zipcodeArra.length > 0) {
    zipcodeArra.forEach((item, index) => {
      ziparrayString += `${item}${index < zipcodeArra.length - 1 ? ',' : ''} `;
    });
  }

  return ziparrayString;
}
