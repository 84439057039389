import { Icon } from '@iconify/react';
import { Box } from '@mui/system';
import menuFill from '@iconify/icons-eva/menu-fill';
import { IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import NotificationsPopover from '../../pages/layouts/dashboard/NotificationsPopover';
import AccountPopover from '../../pages/layouts/dashboard/AccountPopover';
import Typography from '../../pages/theme/overrides/Typography';

function Header({ isOpenSidebar, setOpen }) {
  const [pushData, setPushData] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    console.log('Got reply from service worker:', window);
    console.log('Got reply from service worker:', 'serviceWorker' in navigator);
    console.log('Got reply from service worker:', navigator);
    if ('serviceWorker' in navigator) {
      console.log('Got reply from service worker:', window);
      // Register the service worker

      // Listen to messages from service workers
      navigator.serviceWorker.addEventListener('message', (event) => {
        console.log('Got reply from service worker:', event.data);
        try {
          const eventData = JSON.parse(event.data.notification.body);

          setPushData(eventData);
        } catch (e) {
          setPushData({ customerName: 'N/A', applicationNo: 'N/A' });
        }
        const notificationContainer = document.getElementById('pushNotification');
        notificationContainer.classList.remove('displayHidden');
      });
    } else {
      console.log("This browser doesn't support service workers");
    }
  }, []);
  const rejectCall = () => {
    const notificationContainer = document.getElementById('pushNotification');
    notificationContainer.classList.add('displayHidden');
  };
  const acceptCall = () => {
    rejectCall();
    localStorage.setItem('callToken', pushData?.token);
    localStorage.setItem('channel', pushData?.channelName);
    localStorage.setItem('vkycAppnumber', pushData.applicationNo);
    navigate(`/booked-vkyc-call/video-kyc?t=${pushData?.token}&c=${pushData?.channelNamel}`);
  };
  return (
    <Box className="headerHolder">
      <Box id="pushNotification" className="pushNotification displayHidden">
        <Box class="notification-container">
          <Box class="notification">
            <Box class="notification-header">
              {pushData && pushData.customerName} VKYC Call
              <span class="close-btn" onClick={rejectCall}>
                &times;
              </span>
            </Box>
            <Box class="notification-body">
              <Stack style={{ marginBottom: '10px' }}>
                <b>Application No:</b>
                <p>{pushData && pushData.applicationNo}</p>
              </Stack>
              <Stack style={{ width: '100%', textAlign: 'right', display: 'block' }}>
                <button class="answer-btn" onClick={() => acceptCall(pushData)}>
                  Answer
                </button>
                <button class="reject-btn" onClick={rejectCall}>
                  Reject
                </button>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <IconButton
          sx={{ marginLeft: '-0.5rem' }}
          onClick={(e) => {
            if (isOpenSidebar === true) {
              setOpen(false);
            } else {
              setOpen(true);
            }
          }}
        >
          <Icon icon={menuFill} width={30} height={30} />
        </IconButton>
      </Box>
      <Box>
        <Stack direction="row" alignItems="center">
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </Box>
    </Box>
  );
}
export default Header;
