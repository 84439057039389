import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import CryptoJS from 'crypto-js';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Box
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import globalServices from '../../../services/globalServices';
import Validation from '../../../global/Validation';
import Loader from '../../../global/Loader';
import reportsServices from '../../../services/ReportsServices';
import DialogMsg from '../../../global/DialogMsg';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL_ADMIN = process.env.REACT_APP_BASE_ADMIN_URL;

// ----------------------------------------------------------------------

const { AdminUserData } = globalServices;

const { SetFCMToken } = reportsServices;

const authObj = {
  apiKey: 'AIzaSyBhTkJB4bUKXBhpK3jbihvDfV_PRwoUjhg',
  authDomain: 'onboarding-vkyc.firebaseapp.com',
  projectId: 'onboarding-vkyc',
  storageBucket: 'onboarding-vkyc.appspot.com',
  messagingSenderId: '917891499045',
  appId: '1:917891499045:web:eeae0370b8115bb6802bcc',
  measurementId: 'G-MFED9C2EDH'
};

export default function LoginForm() {
  const location = useLocation();

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState({});
  const [isSession, setSession] = useState(null);
  const [isInvalidCred, setInvalidCred] = useState(null);
  const [refresh, setRefresh] = useState('');

  // validation
  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');
  const [validationMsgTOS, setvalidationMsgTOS] = useState('');
  const [openloader, setOpenLoader] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState(null);

  const LoginSchema = Yup.object().shape({
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    text: Yup.string().required('user name is required'),
    password: Yup.string().required('Password is required')
  });

  // const handleClose = () => {
  //   setOpenValidation(false);
  //   window.open('https://itid.cattelecom.com/adfs/oauth2/logout');
  //   setTimeout(() => {
  //     window.location.href = Build_Url;
  //     // setRefresh(new Date());
  //   }, 500);
  // };

  const formik = useFormik({
    initialValues: {
      text: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      localStorage.clear();
      // // for demo purpose
      // if (values.text === 'admin' && values.password === 'admin') {
      //   // // navigate(`${BASE_URL}/dashboard/app`, { replace: true });
      //   // navigate('/dashboard', { replace: true });
      // } else {
      //   setInvalidCred(true);
      //   setErrorMessage('Please enter correct credentials');
      // }
      // code end here

      const credential = {};
      const base64 = btoa(unescape(encodeURIComponent(`${values.text}:${values.password}`)));
      const authToken = {
        userCode: base64
      };

      const AdminUserDataApiResponse = await AdminUserData(credential, authToken);
      if (AdminUserDataApiResponse.code === 100) {
        console.log('AdminUserDataApiResponse', AdminUserDataApiResponse);
        initFCM(1);
        setUserData(AdminUserDataApiResponse.data);
        AdminUserDataApiResponse.data.sessionStart = new Date();
        const cipherText = CryptoJS.AES.encrypt(
          JSON.stringify(AdminUserDataApiResponse.data),
          'adminUserData'
        ).toString();
        localStorage.setItem('adminUserDataValue', cipherText);
        localStorage.setItem('sessionId', AdminUserDataApiResponse.data?.sessionId);
        navigate('/kyc-logs', { replace: true });
      } else if (AdminUserDataApiResponse.code === 103) {
        setInvalidCred(true);
        setErrorMessage(AdminUserDataApiResponse.message);
      } else if (AdminUserDataApiResponse.code === 105) {
        // add code === 107 ,,when user not found
        setInvalidCred(true);
        setErrorMessage(AdminUserDataApiResponse.message);
      } else {
        setInvalidCred(true);
        setErrorMessage(AdminUserDataApiResponse.message); // new added by man
      }
      // navigate(`${BASE_URL}/dashboard/app`, { replace: true });
      // navigate(`${BASE_URL}/transaction-logs`, { replace: true });
      // navigate(`/kyc-logs`, { replace: true });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const setFcmTokenToApi = async (token, applicationNo) => {
    const credential = {
      userId: 'admin',
      fcmToken: token,
      organizationId: '1'
    };
    const setToken = await SetFCMToken(credential);
    console.log('setToken', setToken);
  };

  async function initFCM(applicationNo) {
    const firebase = window.firebase;
    const firebaseConfig = {
      ...authObj
    };
    console.log('firebase', firebase);

    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    } else {
      firebase.app(); // if already initialized, use that one
    }

    console.log('firebase', Notification.permission);

    if (Notification.permission === 'denied' || Notification.permission === 'default') {
      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          const messaging = firebase.messaging();
          console.log('firebase', messaging);

          try {
            const token = await messaging.getToken();
            console.log('firebase', token);
            setFcmTokenToApi(token, applicationNo);
          } catch (error) {
            alert('please provide permission for notification');
            initFCM(applicationNo);
          }
        }
      } catch (error) {
        console.log('firebase', error);
        alert('error: please provide permission for notification');
        initFCM(applicationNo);
      }
    } else {
      const messaging = firebase.messaging();
      console.log('firebase', await messaging.requestPermission());

      try {
        const token = await messaging.getToken();
        console.log('firebase', token);
        setFcmTokenToApi(token, applicationNo);
      } catch (error) {
        console.log('firebase', error);
        initFCM(applicationNo);
        // alert('error: please provide permission for notification');
      }
    }
  }

  return (
    <FormikProvider value={formik}>
      <Loader openloader={openloader} />
      <Validation
        openValidation={openValidation}
        validationMsg={validationMsg}
        // handleClose={handleClose}
        setOpenValidation={setOpenValidation}
      />
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {isSession && (
          <Typography color="error">Your session has expired. Please log in again.</Typography>
        )}

        {isInvalidCred && <Typography color="error">{ErrorMessage}</Typography>}

        <Typography className="formLabel">Email address*</Typography>
        <TextField
          fullWidth
          sx={{ marginBottom: '1.5rem' }}
          inputProps={{
            className: 'textInput textFieldLogin'
          }}
          className="frmHelperAlign"
          autoComplete="username"
          type="text"
          {...getFieldProps('text')}
          error={Boolean(touched.text && errors.text)}
          helperText={touched.text && errors.text}
          placeholder="Enter your email address"
        />
        <Typography className="formLabel">Password*</Typography>
        <TextField
          fullWidth
          autoComplete="current-password"
          sx={{ marginBottom: '1.5rem' }}
          type={showPassword ? 'text' : 'password'}
          {...getFieldProps('password')}
          inputProps={{
            className: 'textInput textFieldLogin'
          }}
          className="frmHelperAlign"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            )
          }}
          error={Boolean(touched.password && errors.password)}
          helperText={touched.password && errors.password}
          placeholder="Enter your password"
        />

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link>
        </Stack> */}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          className="mainBtn mainBtnLogin"
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
