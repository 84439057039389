import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  TableBody,
  Container,
  Typography,
  TableContainer,
  Grid,
  Tooltip,
  IconButton,
  Divider,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  Autocomplete,
  TextField,
  DialogActions,
  Button,
  Checkbox
} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import { FaFolderOpen, FaCommentAlt } from 'react-icons/fa';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import { GiSwapBag } from 'react-icons/gi';
import { FiEye } from 'react-icons/fi';
import CloseIcon from '@mui/icons-material/Close';
//
import { StyledTableRow, StyledTableCell } from '../utils/Styling';
import PendingToolbar from './PendingToolbar';

// components
import Page from '../Page';
import Label from '../Label';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
import { UserListHead, UserMoreMenu } from '../_dashboard/user';
import BrudCrumbs from '../BreadCrumbs';
import PageTitle from '../PageHeading';

export default function PendingList() {
  const navigate = useNavigate();
  const [sharePopUp, setSharePopUp] = useState(false);
  // Styled icon button in Action
  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    margin: '0 3px',
    [`&:hover`]: {
      color: theme.palette.primary.main
    }
  }));
  return (
    <Page title="Pending || Onboard">
      <Container maxWidth={false}>
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Pending" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Card>
            <PendingToolbar parent="pending" setSharePopUp={setSharePopUp} />
            <Scrollbar>
              <TableContainer
                sx={{
                  minWidth: 800,
                  height: '67.5vh',
                  overflow: 'auto'
                }}
              >
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell style={{ padding: '0' }}>
                        <Checkbox />
                      </StyledTableCell>
                      <StyledTableCell>Application Number</StyledTableCell>
                      <StyledTableCell>Submission Date</StyledTableCell>
                      <StyledTableCell>Customer Name</StyledTableCell>
                      <StyledTableCell>Location</StyledTableCell>
                      <StyledTableCell>Customer Type</StyledTableCell>
                      <StyledTableCell>KYC Mode</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell width={60}>Action</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell style={{ padding: '0' }}>
                        <Checkbox />
                      </StyledTableCell>
                      <StyledTableCell>176891</StyledTableCell>
                      <StyledTableCell>19th Jun 2023</StyledTableCell>
                      <StyledTableCell>Ramesh Mishra</StyledTableCell>
                      <StyledTableCell>Noida</StyledTableCell>
                      <StyledTableCell>New</StyledTableCell>
                      <StyledTableCell>Video KYC</StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="caption" className="statusGreen">
                          Approved
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Tooltip title="View" placement="top" arrow>
                          <StyledIconButton
                            className="squareIconButton actionButton"
                            onClick={(e) => navigate('view-pending')}
                          >
                            <FiEye size={14} />
                          </StyledIconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell style={{ padding: '0' }}>
                        <Checkbox />
                      </StyledTableCell>
                      <StyledTableCell>176892</StyledTableCell>
                      <StyledTableCell>17th Jun 2023</StyledTableCell>
                      <StyledTableCell>Mohit Tyagi</StyledTableCell>
                      <StyledTableCell>Delhi</StyledTableCell>
                      <StyledTableCell>Update</StyledTableCell>
                      <StyledTableCell>E-KYC</StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="caption" className="statusYellow">
                          Pending
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Tooltip title="View" placement="top" arrow>
                          <StyledIconButton
                            className="squareIconButton actionButton"
                            onClick={(e) => navigate('view-pending')}
                          >
                            <FiEye size={14} />
                          </StyledIconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            {/* Share popup start */}
            <Dialog open={sharePopUp} onClose={(e) => setSharePopUp(false)} fullWidth>
              <DialogTitle className="popupTitle">
                <Typography variant="h6">Share</Typography>
                <IconButton onClick={(e) => setSharePopUp(false)} size="small">
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                <Box mb={2}>
                  <Typography variant="body1" className="formLabel">
                    Name
                  </Typography>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    multiple
                    options={topName}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} fullWidth placeholder="Select Name" />
                    )}
                  />
                </Box>
                <Box>
                  <Typography variant="body1" className="formLabel">
                    Email
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{ className: 'textInput' }}
                    placeholder="Enter Email"
                    type="email"
                    defaultValue="rajeshkumar86@gmail.com"
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={(e) => setSharePopUp(false)}
                  variant="contained"
                  className="mainBtn"
                >
                  Share
                </Button>
              </DialogActions>
            </Dialog>
            {/* Share popup end */}
          </Card>
        </Box>
      </Container>
    </Page>
  );
}

// Top Name
const topName = [
  { label: 'Ramesh' },
  { label: 'Mohit' },
  { label: 'Satish' },
  { label: 'Vinod' },
  { label: 'Mukesh' },
  { label: 'Sanjay' },
  { label: 'Gaurav' },
  { label: 'Amit' },
  { label: 'Prashant' },
  { label: 'Sunil' },
  { label: 'Manoj' },
  { label: 'Mahendra' }
];
