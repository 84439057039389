import { useEffect, useState } from 'react';
// material
import {
  Container,
  Stack,
  Grid,
  Box,
  IconButton,
  TextField,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card
} from '@mui/material';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';
import { StyledTableRow, StyledTableCell, StyledIconButton } from '../utils/Styling';
// table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// icons

import { AiFillEdit, AiFillDelete } from 'react-icons/ai';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// components

import Page from '../Page';
import ConfigureHeader from './ConfigureHeader';
import PageTitle from '../PageHeading';
import BrudCrumbs from '../BreadCrumbs';
import configurationService from '../../services/configurationService';
import { userDataFromLocal } from '../utils/getUserDetails';
import Validation from '../../global/Validation';
import Loader from '../../global/Loader';
import CloseIcon from '@mui/icons-material/Close';
import Scrollbar from '../Scrollbar';
import DialogMsg from '../../global/DialogMsg';
import { fDate, fDateTime } from '../utils/formatTime';
import DeleteDialog from '../../global/DeleteDialog';

const { GetFAQLanguageList, GetFaqFromLanguage, SetFaqConfiguration, AddFaqToLanguage } =
  configurationService;

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({});

export default function FAQ() {
  const [getFAQLanguageList, setGetFAQLanguageList] = useState([]);
  const [getFaqFromLanguage, setGetFaqFromLanguage] = useState([]);
  const [language, setLanguage] = useState([]);
  const [currentlangId, setCurrentlangId] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedQuestionAnswer, setSelectedQuestionAnswer] = useState({});

  const [refreshComp, setRefreshComp] = useState({});
  const [editStep, setEditStep] = useState(false);

  // validation
  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');
  const [validationMsgTOS, setvalidationMsgTOS] = useState('');
  const [openloader, setOpenLoader] = useState(false);

  const [open, setOpen] = useState(false);

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const Input = styled('input')({
    display: 'none'
  });

  // Delete function
  const handleDeletion = () => {
    deleteFaq(selectedQuestionAnswer);
    // Close the dialog after deletion
    setOpenDeleteDialog(false);
  };
  // Close Delete function
  const closeDeleteHandler = () => {
    setOpenDeleteDialog(false);
  };

  // Error Dialog close handler
  const closeDialogMsgHandler = (e) => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpenValidation(false);
  };
  const fireSetFaqApi = async (e) => {
    const header = {
      userId: userDataFromLocal()?.userId,
      organizationId: userDataFromLocal()?.organizationId
    };
    // const header = {
    //   userId: 1,
    //   organizationId: 1
    // };
    const faqDetailsDots = [];
    getFaqFromLanguage.forEach((faqItem, index) => {
      // validation
      if (!faqItem.faqTitle) {
        setOpenValidation(true);
        setValidationMsg('Please write your Question');
        setOpenLoader(false);
        faqItem.isBlank = true;
        setEditStep(true);
        return;
      }
      if (!faqItem.faqAnswer) {
        setOpenValidation(true);
        setValidationMsg('Please write your Answer');
        setOpenLoader(false);
        setEditStep(true);
        faqItem.isBlank = true;
        return;
      }
      faqItem.isBlank = false;
      const obj = {
        faqId: faqItem.id,
        faqTitle: faqItem.faqTitle,
        faqAnswer: faqItem.faqAnswer,
        faqComments: faqItem.faqComments ? faqItem.faqComments : '',
        faqSeq: faqItem.faqSeq,
        // isActive: faqItem.isShow ? 1 : 0,
        isActive: 1,
        languageId: currentlangId
      };
      faqDetailsDots.push(obj);
    });

    // return if isBlank is true
    if (getFaqFromLanguage.filter((item) => item.isBlank === true).length > 0) {
      return;
    }
    setOpenLoader(true);
    const credential = {
      faqDetailsDtos: faqDetailsDots
    };
    const isFaqSaved = await SetFaqConfiguration(header, credential);

    if (isFaqSaved.code === 100) {
      setEditStep(false);
      setOpenLoader(false);
    } else if (isFaqSaved.code === 100) {
      setOpenLoader(false);
    }
  };

  // DARG AND DROP FUNCTIONALITY
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(getFaqFromLanguage, result.source.index, result.destination.index);

    setGetFaqFromLanguage(items);
  };

  const addNewFaq = async () => {
    setOpenLoader(true);
    const token = {
      userId: userDataFromLocal()?.userId,
      organizationId: userDataFromLocal()?.organizationId,
      languageId: currentlangId
    };
    // const token = {
    //   userId: 1403,
    //   organizationId: 1,
    //   languageId: 1
    // };
    const isAddFaqToLanguage = await AddFaqToLanguage({}, token);

    if (isAddFaqToLanguage.code === 100) {
      const headerCredential = {
        userId: userDataFromLocal()?.userId,
        organizationId: userDataFromLocal()?.organizationId,
        languageId: currentlangId
      };
      // const headerCredential = {
      //   userId: 1403,
      //   organizationId: 1,
      //   languageId: 1
      // };
      const GetFaqFromLanguageResponse = await GetFaqFromLanguage(headerCredential);
      if (GetFaqFromLanguageResponse.code === 100) {
        setOpenLoader(false);

        GetFaqFromLanguageResponse.data.forEach((item, index) => {
          item.isBlank = false;
          item.faqSeq = index + 1;
        });

        setGetFaqFromLanguage(GetFaqFromLanguageResponse.data);
      }
    }
  };

  const deleteFaq = async (object) => {
    const filtereddata = getFaqFromLanguage.filter((item) => item.id !== object.id);
    filtereddata.forEach((item, index) => (item.faqSeq = index + 1));
    setGetFaqFromLanguage(filtereddata);
    const header = {
      userId: userDataFromLocal()?.userId,
      organizationId: userDataFromLocal()?.organizationId
    };
    // const header = {
    //   userId: 1,
    //   organizationId: 1
    // };
    const faqDetailsDots = [];
    filtereddata.forEach((faqItem, index) => {
      faqItem.isBlank = false;
      const obj = {
        faqId: faqItem.id,
        faqTitle: faqItem.faqTitle,
        faqAnswer: faqItem.faqAnswer,
        faqComments: faqItem.faqComments ? faqItem.faqComments : '',
        faqSeq: faqItem.faqSeq,
        isActive: faqItem.isShow ? 1 : 0,
        languageId: currentlangId
      };
      faqDetailsDots.push(obj);
    });
    setOpenLoader(true);
    const credential = {
      faqDetailsDtos: faqDetailsDots
    };
    const isFaqSaved = await SetFaqConfiguration(header, credential);

    if (isFaqSaved.code === 100) {
      setOpenLoader(false);
    }
  };

  useEffect(() => {
    (async () => {
      setOpenLoader(true);
      const headerCredential = {
        userId: userDataFromLocal()?.userId,
        organizationId: userDataFromLocal()?.organizationId
      };
      // const headerCredential = {
      //   userId: 1,
      //   organizationId: 1
      // };
      const GetFAQLanguageListResponse = await GetFAQLanguageList(headerCredential);
      if (GetFAQLanguageListResponse.code === 100) {
        setOpenLoader(false);
        setGetFAQLanguageList(GetFAQLanguageListResponse.data);
      }
    })();
  }, []);

  return (
    <Page title="Configure">
      {/* header component   */}
      {/* <ConfigureHeader /> */}

      <Container maxWidth={false}>
        <Box pt={3} pb={3} mb={4}>
          <Loader openloader={openloader} />
          <Validation
            openValidation={openValidation}
            validationMsg={validationMsg}
            dialogTitle="Alert"
            handleClose={handleClose}
            setOpenValidation={setOpenValidation}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="FAQ" />
            <Box className="brudCrumbs">
              <BrudCrumbs />
            </Box>
          </Stack>
          <Card className="cardWrap" style={{ marginBottom: '15px' }}>
            <Scrollbar>
              <TableContainer
                sx={{
                  minWidth: 800,
                  overflow: 'auto',
                  borderTop: '1px solid #eaedf1'
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>LANGUAGE</StyledTableCell>
                      <StyledTableCell>MODIFIED ON</StyledTableCell>
                      <StyledTableCell width="125">ACTION</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getFAQLanguageList !== undefined && getFAQLanguageList.length > 0
                      ? getFAQLanguageList.map((lang, index) => {
                          console.log('lang', lang);
                          return (
                            <StyledTableRow key={index}>
                              <StyledTableCell>
                                {/* {lang.languageName} */}
                                {lang.languageName.toLowerCase() === 'thai'
                                  ? 'Thai'
                                  : lang.languageName.toLowerCase() === 'english'
                                  ? 'English'
                                  : lang.languageName.toLowerCase() === 'chinese'
                                  ? 'Chinese'
                                  : lang.languageName.toLowerCase() === 'cambodian'
                                  ? 'Cambodia'
                                  : lang.languageName.toLowerCase() === 'burmese'
                                  ? 'Myanmar'
                                  : 'English'}
                              </StyledTableCell>
                              <StyledTableCell>
                                {lang.lastUpdatedOn !== null ? fDateTime(lang.lastUpdatedOn) : ''}
                              </StyledTableCell>
                              <StyledTableCell>
                                <Tooltip title="view" placement="top" arrow>
                                  <StyledIconButton
                                    className="squareIconButton actionButton"
                                    onClick={(e) => {
                                      setEditStep(true);
                                      setLanguage(lang.languageName);
                                      setCurrentlangId(lang.languageId);
                                      (async () => {
                                        setOpenLoader(true);
                                        const headerCredential = {
                                          userId: userDataFromLocal()?.userId,
                                          organizationId: userDataFromLocal()?.organizationId,
                                          languageId: lang?.languageId
                                        };
                                        // const headerCredential = {
                                        //   userId: 1,
                                        //   organizationId: 1,
                                        //   languageId: 1
                                        // };
                                        const GetFaqFromLanguageResponse = await GetFaqFromLanguage(
                                          headerCredential
                                        );
                                        if (GetFaqFromLanguageResponse.code === 100) {
                                          setOpenLoader(false);

                                          GetFaqFromLanguageResponse.data.forEach((item, index) => {
                                            item.isBlank = false;
                                            item.faqSeq = index + 1;
                                          });

                                          // GetFaqFromLanguageResponse.data.forEach(
                                          //   (item, index) => (item.id = `item-${index}`)
                                          // );
                                          setGetFaqFromLanguage(GetFaqFromLanguageResponse.data);
                                        } else {
                                          // setEditStep(false);
                                          setOpenLoader(false);
                                          setOpen(true);
                                          setvalidationMsgTOS(GetFaqFromLanguageResponse.message);
                                        }
                                      })();
                                    }}
                                  >
                                    <AiFillEdit size={14} />
                                  </StyledIconButton>
                                </Tooltip>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                      : ''}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>

          {/* {editStep === 'editInstruction' ? <>Hello</> : ''} */}
          {editStep === true ? (
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} sm={12} md={12} marginBottom={3}>
                <Box className="tableHeadingBox displayFlex" mb={2}>
                  <Typography variant="h6">
                    Language :{' '}
                    {language.toLowerCase() === 'thai'
                      ? 'Thai'
                      : language.toLowerCase() === 'english'
                      ? 'English'
                      : language.toLowerCase() === 'chinese'
                      ? 'Chinese'
                      : language.toLowerCase() === 'cambodian'
                      ? 'Cambodia'
                      : language.toLowerCase() === 'burmese'
                      ? 'Myanmar'
                      : 'English'}
                  </Typography>
                  <Box>
                    <Button className="mainBtn" onClick={(e) => addNewFaq(e)}>
                      Add New
                    </Button>
                    <Button
                      className="mainBtn"
                      onClick={(e) => fireSetFaqApi(e)}
                      sx={{ marginLeft: '10px' }}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
                <Box className="cardOuter cardOuterGrayBorder cardNoHover">
                  <TableContainer sx={{ overflow: 'auto' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell width="50">SEQUENCE </StyledTableCell>
                          <StyledTableCell width="320">QUESTIONS</StyledTableCell>
                          <StyledTableCell width="684">ANSWERS</StyledTableCell>
                          <StyledTableCell>DELETE</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <TableBody
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {getFaqFromLanguage.map((QuestionAnswer, index) => (
                                <Draggable
                                  key={QuestionAnswer.id}
                                  draggableId={`item-${QuestionAnswer.id}`}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <StyledTableRow
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <StyledTableCell align="center" width="50">
                                        {index + 1}
                                      </StyledTableCell>
                                      <StyledTableCell width="320">
                                        <InputComponent
                                          mainArray={getFaqFromLanguage}
                                          fieldValue={QuestionAnswer.faqTitle}
                                          index={index}
                                          setArrayObject={setGetFaqFromLanguage}
                                          keyObj="faqTitle"
                                          setRefreshComp={setRefreshComp}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell width="684">
                                        <InputComponent
                                          mainArray={getFaqFromLanguage}
                                          fieldValue={QuestionAnswer.faqAnswer}
                                          index={index}
                                          setArrayObject={setGetFaqFromLanguage}
                                          keyObj="faqAnswer"
                                          setRefreshComp={setRefreshComp}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell
                                        onClick={(e) => {
                                          if (index === 0) {
                                            return true;
                                          }
                                          // deleteFaq(e, QuestionAnswer);
                                          setSelectedQuestionAnswer(QuestionAnswer);
                                          setOpenDeleteDialog(true);
                                        }}
                                      >
                                        <Tooltip title="Delete" placement="top" arrow>
                                          <StyledIconButton
                                            disabled={index === 0}
                                            className="squareIconButton actionButton"
                                          >
                                            <AiFillDelete size={14} />
                                          </StyledIconButton>
                                        </Tooltip>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </TableBody>
                          )}
                        </Droppable>
                      </DragDropContext>
                      {/* {getFaqFromLanguage.length > 0
                        ? getFaqFromLanguage.map((QuestionAnswer, index) => (
                            
                          ))
                        : ''} */}
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Grid>
          ) : (
            ''
          )}

          <DialogMsg
            openDialogeMsg={open}
            dialogMsg={validationMsgTOS}
            dialogTitle="Confirmation"
            buttonText="Ok"
            closeDialogMsgHandler={closeDialogMsgHandler}
          />

          {/* Delete Dialog box */}
          <DeleteDialog
            openDeleteDialog={openDeleteDialog}
            closeDeleteHandler={closeDeleteHandler}
            handleDelete={handleDeletion}
            dialogMsg="Are you sure you want to delete?"
            dialogTitle="Delete"
            buttonText="Delete"
          />
          {/* Delete Dialog box */}

          {/* <Dialog
            open={open}
            onClose={(e) => setOpen(null)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
          >
            <DialogTitle className="popupTitle">
              <Typography variant="h6">Confirmation</Typography>
              <IconButton onClick={(e) => setOpen(null)} size="small">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <DialogContentText id="alert-dialog-description">
                {validationMsgTOS}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(e) => {
                  // fireSetOrganizationapi(e, targetItem);
                  setOpen(null);
                }}
                className="mainBtn"
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog> */}
        </Box>
      </Container>
    </Page>
  );
}

const InputComponent = ({
  mainArray,
  fieldValue,
  index,
  setArrayObject,
  keyObj,
  setRefreshComp
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    // QuestionAnswer.faqAnswer
    setValue(fieldValue);
  }, [fieldValue]);
  return (
    <TextField
      fullWidth
      value={value}
      InputProps={{ className: 'textAreaOuter' }}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => {
        const prevObjectArray = mainArray;
        prevObjectArray[index][keyObj] = e.target.value;
        prevObjectArray[index].isChange = true;
        setArrayObject(prevObjectArray);
        setRefreshComp(new Date());
      }}
      multiline
      rows={4}
      placeholder="Write Answer"
    />
  );
};

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
