import { useState, useEffect } from 'react';
// material
import {
  Container,
  Stack,
  Grid,
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@mui/material';
import CryptoJS from 'crypto-js';
import Typography from '@mui/material/Typography';

import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { StyledTableRow, StyledTableCell } from '../utils/Styling';
// table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// icons

import CloseIcon from '@mui/icons-material/Close';
// components
import Page from '../Page';
import ConfigureHeader from './ConfigureHeader';
import PageTitle from '../PageHeading';
import BrudCrumbs from '../BreadCrumbs';

import configurationService from '../../services/configurationService';
import DialogMsgAction from '../../global/DialogMsgAction';

const { GetOrganizationProfileData, SetOrganizationProfileData } = configurationService;

export default function Setting() {
  const [loginDataDetails, setLoginDataDetails] = useState([]);
  const [getOrganizationProfileData, setGetOrganizationProfileData] = useState([]);
  const [organizationProfileDataResponse, setSetOrganizationProfileDataResponse] = useState([]);
  const [refreshComp, setRefreshComp] = useState([]);
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [field, setField] = useState('');
  const [openloader, setOpenLoader] = useState(false);

  const [checkEvent, setCheckEvent] = useState('');

  const [checked, setChecked] = useState(false); // for switch button

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const dialogMsgHandler = () => {
    fireSetOrganizationProfileDataApi(checkEvent, field);
    setOpenConfirmBox(false);
  };
  const handleCloseConfirmBox = () => {
    setOpenConfirmBox(false);
  };
  const handleOpenConfirmBox = () => {
    setOpenConfirmBox(true);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const fireSetOrganizationProfileDataApi = async (checkEvent, field) => {
    delete field.systemEnabled;
    field.systemEnable = checkEvent ? 'TRUE' : 'FALSE';

    field.isActive = checkEvent ? 1 : 0;
    const header = {
      userId: loginDataDetails.userId
    };
    const credential = [field];
    const SetOrganizationProfileDataResponse = await SetOrganizationProfileData(header, credential);
    setOpenLoader(true);
    if (SetOrganizationProfileDataResponse.code === 100) {
      setRefreshComp(new Date());
      setOpenLoader(false);
      setSetOrganizationProfileDataResponse(SetOrganizationProfileDataResponse.data);
    }
  };

  useEffect(() => {
    // const adminUserDataValue = localStorage.getItem('adminUserDataValue');
    const adminUserDataValue = {
      userId: 1,
      organizationId: 1,
      consentId: 1
    };
    if (adminUserDataValue !== null) {
      // const bytes = CryptoJS.AES.decrypt(adminUserDataValue, 'adminUserData');
      // const originalAdmiuserData = bytes.toString(CryptoJS.enc.Utf8);
      // const adminUserDate = JSON.parse(originalAdmiuserData);

      const adminUserDate = adminUserDataValue;

      setLoginDataDetails(adminUserDate);

      (async () => {
        const authToken = {
          // userId: adminUserDate.userId,
          // organizationId: adminUserDate.organizationId,
          userId: 1,
          organizationId: 1,
          requestType: 'M'
        };
        const GetOrganizationProfileDataResponse = await GetOrganizationProfileData(authToken);
        if (GetOrganizationProfileDataResponse.code === 100) {
          setGetOrganizationProfileData(GetOrganizationProfileDataResponse.data);
        }
      })();
    }
  }, [refreshComp]);

  return (
    <Page title="Configure">
      {/* header component   */}
      {/* <ConfigureHeader /> */}

      <Container maxWidth={false}>
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="SETTING" />
            <Box className="brudCrumbs">
              <BrudCrumbs />
            </Box>
          </Stack>
          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={6} md={6} marginBottom={3}>
              <Box className="cardOuter cardOuterGrayBorder cardNoHover">
                <TableContainer sx={{ overflow: 'auto' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>FEATURE REQUIREMENT</StyledTableCell>
                        <StyledTableCell width="80">&nbsp;</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getOrganizationProfileData !== undefined &&
                      getOrganizationProfileData.length > 0
                        ? getOrganizationProfileData
                            .filter(
                              (item, index) =>
                                item.profileCode === 'PREVIEW IMAGE WITH WATERMARK' ||
                                item.profileCode === 'BLACKLIST CHECK' ||
                                item.profileCode === '3-STEP-LOCK' ||
                                item.profileCode === 'CONNECTING TO OM' ||
                                item.profileCode === 'LANGUAGE-ENGLISH' ||
                                item.profileCode === 'LANGUAGE-THAI' ||
                                item.profileCode === 'LANGUAGE-CHINESE' ||
                                item.profileCode === 'LANGUAGE-CAMBODIA' ||
                                item.profileCode === 'LANGUAGE-MYANMAR'
                            )
                            .map((field, index) => {
                              console.log('row', field);
                              return (
                                <StyledTableRow key={index}>
                                  <StyledTableCell>{field.profileCode}</StyledTableCell>
                                  <StyledTableCell>
                                    <Switch
                                      checked={field.systemEnabled === 'TRUE'}
                                      onChange={(e) => {
                                        setOpenConfirmBox(true);
                                        setField(field);
                                        setCheckEvent(e.target.checked);
                                      }}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                      color="success"
                                      size="small"
                                      disabled={getIfDisaable(field, getOrganizationProfileData)}
                                    />
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })
                        : ''}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} marginBottom={3}>
              <Box className="cardOuter cardOuterGrayBorder cardNoHover">
                <TableContainer sx={{ overflow: 'auto' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>FIELDS FOR INSCEPTION</StyledTableCell>
                        <StyledTableCell width="80">&nbsp;</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getOrganizationProfileData !== undefined &&
                      getOrganizationProfileData.length > 0
                        ? getOrganizationProfileData
                            .filter(
                              (item, index) =>
                                item.profileCode === 'FIELD-TITLE' ||
                                item.profileCode === 'FIELD-GENDER' ||
                                item.profileCode === 'FIELD-FIRST_NAME' ||
                                item.profileCode === 'FIELD-LAST_NAME' ||
                                item.profileCode === 'FIELD-RELIGION' ||
                                item.profileCode === 'FIELD-DATE_OF_BIRTH' ||
                                item.profileCode === 'FIELD-DATE_OF_EXPIRY' ||
                                item.profileCode === 'FIELD-HOUSE_NO' ||
                                item.profileCode === 'FIELD-BUILDING_VILLAGE' ||
                                item.profileCode === 'FIELD-ALLEY' ||
                                item.profileCode === 'FIELD-ROAD' ||
                                item.profileCode === 'FIELD-PROVINCE' ||
                                item.profileCode === 'FIELD-DISTRICT' ||
                                item.profileCode === 'FIELD-SUBDISTRICT' ||
                                item.profileCode === 'FIELD-ZIPCODE'
                            )
                            .map((field, index) => {
                              console.log('row', field);
                              return (
                                <StyledTableRow key={index}>
                                  <StyledTableCell>{field.profileCode}</StyledTableCell>
                                  <StyledTableCell>
                                    <Switch
                                      checked={field.systemEnabled === 'TRUE'}
                                      onChange={(e) => {
                                        setOpenConfirmBox(true);
                                        setField(field);
                                        setCheckEvent(e.target.checked);
                                      }}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                      color="success"
                                      size="small"
                                    />
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })
                        : ''}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>

          <DialogMsgAction
            // openDialogeMsg={openDialoge}
            openDialogeMsg={openConfirmBox}
            dialogMsg="Are you sure ?"
            dialogTitle="Confirmation"
            buttonText="Confirm"
            buttonPrimary="Close"
            // targetItem={targetItem} // dialogMsgHandler argument item, which is required to fire function
            dialogMsgHandler={dialogMsgHandler}
            closeDialogMsgHandler={handleCloseConfirmBox}
          />

          {/* <Dialog
            open={openConfirmBox}
            onClose={handleCloseConfirmBox}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
          >
            <DialogTitle className="popupTitle">
              <Typography variant="h6">Confirmation</Typography>
              <IconButton onClick={handleCloseConfirmBox} size="small">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <DialogContentText id="alert-dialog-description">Are you sure ?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(e) => {
                  fireSetOrganizationProfileDataApi(checkEvent, field);
                  handleCloseConfirmBox(e);
                }}
                className="mainBtn"
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog> */}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
          >
            <DialogTitle className="popupTitle">
              <Typography variant="h6">Confirmation</Typography>
              <IconButton onClick={handleClose} size="small">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <DialogContentText id="alert-dialog-description">Are you sure ?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} className="mainBtn">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </Page>
  );
}

function getIfDisaable(field, getOrganizationProfileData) {
  const filteredData = getOrganizationProfileData.filter(
    (item) =>
      (item.profileCode === 'LANGUAGE-ENGLISH' && item.isActive === 1) ||
      (item.profileCode === 'LANGUAGE-THAI' && item.isActive === 1) ||
      (item.profileCode === 'LANGUAGE-CHINESE' && item.isActive === 1) ||
      (item.profileCode === 'LANGUAGE-CAMBODIA' && item.isActive === 1) ||
      (item.profileCode === 'LANGUAGE-MYANMAR' && item.isActive === 1)
  );
  if (filteredData.length === 1) {
    return field.profileCode === filteredData[0].profileCode;
  }
}
