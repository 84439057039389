import { useEffect, useState } from 'react';
// material
import { Container, Stack, Grid, Box, IconButton, TextField, Button } from '@mui/material';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
// icons

import CryptoJS from 'crypto-js';
// components
import Page from '../Page';
import ConfigureHeader from './ConfigureHeader';
import PageTitle from '../PageHeading';
import BrudCrumbs from '../BreadCrumbs';
import configurationService from '../../services/configurationService';

import Validation from '../../global/Validation';
import Loader from '../../global/Loader';

const { GetOrganizationProfileData, UpdateWatermark } = configurationService;

export default function Watermark() {
  const [organizationData, setOrganization] = useState({});
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  // validation
  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');

  const [openloader, setOpenLoader] = useState(false);

  const Input = styled('input')({
    display: 'none'
  });

  const handleClose = () => {
    setOpenValidation(false);
  };

  const handleUpdate = async () => {
    setOpenLoader(true);
    // const adminUserDataValue = localStorage.getItem('adminUserDataValue');
    const adminUserDataValue = {
      userId: 1,
      organizationId: 1
    };
    // const bytes = CryptoJS.AES.decrypt(adminUserDataValue, 'adminUserData');
    // const originalAdmiuserData = bytes.toString(CryptoJS.enc.Utf8);
    // const adminUserDate = JSON.parse(originalAdmiuserData);
    const adminUserDate = adminUserDataValue;
    if (!organizationData.defaultProfileValue) {
      setOpenValidation(true);
      setValidationMsg('Please enter your watermark');
      setOpenLoader(false);
      return;
    }
    const reqArray = {
      id: organizationData.profileId,
      oldWatermarkText: organizationData.defaultProfileValue,
      newWatermarkText: organizationData.customProfileValue
    };

    const header = {
      userId: adminUserDate.userId
    };
    const isDataSubmited = await UpdateWatermark(header, reqArray);
    if (isDataSubmited.code === 100) {
      setOpenLoader(false);

      getWaterMarkData();
    }
  };

  const getWaterMarkData = async () => {
    // const adminUserDataValue = localStorage.getItem('adminUserDataValue');
    const adminUserDataValue = {
      userId: 1,
      organizationId: 1
    };
    if (adminUserDataValue !== null) {
      // const bytes = CryptoJS.AES.decrypt(adminUserDataValue, 'adminUserData');
      // const originalAdmiuserData = bytes.toString(CryptoJS.enc.Utf8);
      // const adminUserDate = JSON.parse(originalAdmiuserData);
      const adminUserDate = adminUserDataValue;
      setOpenLoader(true);
      const obj2 = {
        requestType: 'a',
        // userId: adminUserDate.userId,
        // organizationId: adminUserDate.organizationId
        userId: 1,
        organizationId: 1
      };
      const GetOrganizationProfileDataResponse = await GetOrganizationProfileData(obj2);
      if (GetOrganizationProfileDataResponse.code === 100) {
        setOpenLoader(false);
        const waterData = GetOrganizationProfileDataResponse.data.filter(
          (data) => data.profileCode === 'WATER-MARK'
        )[0];
        console.log('GetOrganizationProfileDataResponse.data', waterData);
        // waterData.defaultProfileValue = '';
        setOrganization(waterData);
      }
    }
  };

  useEffect(() => {
    getWaterMarkData();
  }, []);
  return (
    <Page title="Configure">
      {/* header component   */}
      {/* <ConfigureHeader /> */}

      <Container maxWidth={false}>
        <Box pt={3} pb={3} mb={4}>
          <Loader openloader={openloader} />
          <Validation
            openValidation={openValidation}
            validationMsg={validationMsg}
            handleClose={handleClose}
            setOpenValidation={setOpenValidation}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="WATERMARK" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Box className="cardOuter cardOuterGrayBorder cardNoHover" px={2} py={3} mb={3}>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="body2" className="formLabel">
                  Existing Watermark Text
                </Typography>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput',
                    readOnly: true
                  }}
                  defaultValue="Self Registration Process"
                  value={organizationData.defaultProfileValue}
                  // value={organizationData.customProfileValue}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="body2" className="formLabel">
                  New Watermark Tex
                </Typography>
                <SetValueInput
                  organizationData={organizationData}
                  setOrganization={setOrganization}
                />
              </Grid>
            </Grid>
          </Box>
          <Box className="" textAlign="right">
            <Button className="mainBtn" onClick={(e) => handleUpdate()}>
              Update
            </Button>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}

function SetValueInput({ organizationData, setOrganization }) {
  const [refreshComp, setRefreshComp] = useState(null);
  useEffect(() => {}, [organizationData]);
  return (
    <>
      <TextField
        fullWidth
        type="text"
        inputProps={{
          className: 'textInput'
        }}
        value={
          ifObjectBlank(organizationData)
            ? `${IfKeyExits(organizationData.defaultProfileValue)}`
            : ''
        }
        onChange={(e) => {
          const value = e.target.value;

          const prevData = organizationData;
          prevData.defaultProfileValue = value;

          setOrganization(prevData);
          setRefreshComp(new Date());
        }}
      />
    </>
  );
}

const ifObjectBlank = (obj) => Object.keys(obj).length > 0;

const IfKeyExits = (objkey) => {
  if (objkey) {
    return objkey;
  }
  return '';
};
