import { useState } from 'react';
import {
  Table,
  Stack,
  TableBody,
  Container,
  Typography,
  TableContainer,
  Grid,
  Tooltip,
  IconButton,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
  Button,
  Checkbox,
  Select,
  MenuItem
} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import { useNavigate } from 'react-router-dom';
import { FiEye } from 'react-icons/fi';
import CloseIcon from '@mui/icons-material/Close';
//
import Scrollbar from '../../Scrollbar';
import { StyledTableRow, StyledTableCell } from '../../utils/Styling';
import SignDummy from './signDummy.png';
import SignDummy1 from './signDummy1.png';

function DecisionTabApproved() {
  const navigate = useNavigate();
  const [sharePopUp, setSharePopUp] = useState(false);
  const [selectRiskCategory, setSelectRiskCategory] = useState(' ');
  const [selectDecision, setSelectDecision] = useState(' ');
  const [selectYourDecision, setSelectYourDecision] = useState(' ');
  // Styled icon button in Action
  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    margin: '0 3px',
    [`&:hover`]: {
      color: theme.palette.primary.main
    }
  }));
  const [signUpload, setSignUpload] = useState(false);
  return (
    <>
      <Box className="viewLeadCard" mb={3} sx={{ height: 'calc(100% - 24px)' }}>
        <Grid item container spacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="">
              <Typography variant="body2" className="reviewLabel">
                Risk Category
              </Typography>
              <Typography variant="body2" className="reviewValue fontBold">
                Low
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="">
              <Typography variant="body2" className="reviewLabel">
                Next Review Date
              </Typography>
              <Typography variant="body2" className="reviewValue fontBold">
                30th July 2023
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="">
              <Typography variant="body2" className="reviewLabel">
                Decision
              </Typography>
              <Typography variant="body2" className="reviewValue fontBold">
                Approve
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="">
              <Typography variant="body2" className="reviewLabel">
                Narration
              </Typography>
              <Typography variant="body2" className="reviewValue fontBold">
                All details correct
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Signature
          </Typography>
          <Box className="viewLeadCard rightCard cardTopMinusMargin">
            <Box className="signBox">
              <img src={SignDummy} alt="" height="60px" />
            </Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              mb={0.5}
            >
              <Typography variant="body2" className="colorBlue">
                <strong>Rohit Sharma</strong>
              </Typography>
              <Typography variant="body2" textAlign="right">
                20 Feb, 2023
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Signature
          </Typography>
          <Box className="viewLeadCard rightCard cardTopMinusMargin">
            <Box className="signBox">
              <img src={SignDummy1} alt="" height="60px" />
            </Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              mb={0.5}
            >
              <Typography variant="body2" className="colorBlue">
                <strong>Komal Tyagi</strong>
              </Typography>
              <Typography variant="body2" textAlign="right">
                20 Feb, 2023
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* Sign popup start */}
      <Dialog open={signUpload} onClose={(e) => setSignUpload(false)} fullWidth>
        <DialogTitle className="popupTitle">
          <Typography variant="h6">Approve the Proposal</Typography>
          <IconButton onClick={(e) => setSignUpload(false)} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box mb={2}>
            <Typography variant="h6" className="formLabel">
              Your Name
            </Typography>
            <TextField
              fullWidth
              placeholder="Type Your Name"
              inputProps={{ className: 'textInput' }}
            />
          </Box>
          <Box mb={2}>
            <Typography variant="h6" className="formLabel">
              Font Type
            </Typography>
            <Select
              fullWidth
              inputProps={{
                className: `textInput customSelectBox dissabledMenu${selectYourDecision}`
              }}
              value={selectYourDecision}
              onChange={(e) => setSelectYourDecision(e.target.value)}
            >
              <MenuItem value=" " disabled>
                Select Font Type
              </MenuItem>
              <MenuItem value="Arial">Arial</MenuItem>
              <MenuItem value="Open Sans">Open Sans</MenuItem>
              <MenuItem value="Helvetica">Helvetica</MenuItem>
              <MenuItem value="Georgia">Georgia</MenuItem>
            </Select>
          </Box>
          <Button className="mainBtn otherBtn">Sign</Button>
          <Box className="signBox" mt={2} />
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => setSignUpload(false)} variant="contained" className="mainBtn">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* Sign popup end */}
    </>
  );
}
export default DecisionTabApproved;
