import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Divider,
  DialogActions,
  Button,
  Typography,
  Select,
  MenuItem,
  FormHelperText,
  TextField
} from '@mui/material';
import { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

function DialogDecision({
  openDialogDecision,
  dialogTitle,
  handleStatusChange,
  closeOpenDecisionDialog
}) {
  const [status, setStatus] = useState('APPROVED');
  const [reason, setReason] = useState('');
  const [error, setError] = useState('');

  const [selectedValue, setSelectedValue] = useState('Approve');

  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  return (
    <Box>
      <Dialog open={openDialogDecision} onClose={() => closeOpenDecisionDialog()} fullWidth>
        <DialogTitle className="dialog-title" style={{ textAlign: 'center' }}>
          <Typography variant="subtitle1">{dialogTitle}</Typography>
          <IconButton
            onClick={() => closeOpenDecisionDialog()}
            size="small"
            style={{ position: 'absolute', right: '10px', top: '5px' }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="radio-group"
              name="radio-group"
              value={status}
              onChange={handleChange}
            >
              <FormControlLabel value="APPROVED" control={<Radio />} label="Approve" />
              <FormControlLabel value="REJECTED" control={<Radio />} label="Reject" />
            </RadioGroup>
          </FormControl>
          {/* <Typography variant="body2" textAlign="center" mb={2}>
            Are you sure you want to approve the selected items?
          </Typography> */}
          {/* <Typography mb={1} style={{ textAlign: 'center' }}> */}
          {/* <Select
              fullWidth
              className="frmHelperAlign"
              inputProps={{
                className: `textInput customSelectBox dissabledMenu${selectDecision}`
              }}
              value={selectDecision}
              onChange={(e) => setSelectDecision(e.target.value)}
              error={selectDecision === ' ' && validationMsg}
            >
              <MenuItem value=" " disabled>
                Select Decision
              </MenuItem>
              <MenuItem value="approve">Approve</MenuItem>
              <MenuItem value="reject">Reject</MenuItem>
            </Select>
            <FormHelperText className="selectHelper" error>
              {selectDecision === ' ' && validationMsg ? 'Please select decision' : ''}
            </FormHelperText> */}

          {/* <Button
              className={`mainBtn mainBtnSec ${status == 'A' ? ' active' : ''}`}
              style={status == 'A' ? { backgroundColor: '#0d4689', color: '#fff' } : null}
              onClick={() => setStatus('A')}
            >
              Approved
            </Button>
            <Button
              className={`mainBtn mainBtnSec ml-1 ${status == 'R' ? ' active' : ''}`}
              style={status == 'R' ? { backgroundColor: '#0d4689', color: '#fff' } : null}
              onClick={() => setStatus('R')}
            >
              Not Approved
            </Button> */}
          {/* </Typography> */}
          {status === 'REJECTED' ? (
            <>
              <Typography variant="body2" className="formLabel" mt={2}>
                Reason for Rejection /On Hold
              </Typography>
              <TextField
                fullWidth
                type="text"
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Reason for Rejection /On Hold"
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
                onBlur={(e) => {
                  const inputValue = e.target.value;
                  const wordCount = inputValue.length;
                  console.log('wordCOunt', wordCount);

                  if (wordCount <= 200 && wordCount >= 5) {
                    setError('');
                  } else {
                    setError('Please write within the word limit of 55 to 200 words');
                  }
                }}
                // error={!!errors.auditor}
                // helperText={errors.auditor}
              />
              <span style={{ color: 'red', fontSize: '10px' }}>{error !== '' ? error : ''}</span>
            </>
          ) : (
            ''
          )}
        </DialogContent>
        <DialogActions disableSpacing={false} className="popupAction" style={{ padding: '15px' }}>
          <Button
            onClick={(e) => handleStatusChange(e, reason, status)}
            variant="contained"
            className="mainBtn"
            color="primary"
          >
            Submit
          </Button>
          {/* <Button
            onClick={() => closeOpenDecisionDialog()}
            variant="contained"
            className="mainBtn"
            color="primary"
          >
            Close
          </Button> */}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
export default DialogDecision;
