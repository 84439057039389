import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
// material
import { Box } from '@mui/material';
import 'simplebar/src/simplebar.css';
// import '../index.css';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', ...other }, ref) => {
  const [abcd, setAbcd] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    navigate(window.location.pathname.toLocaleLowerCase());
  }, []);
  return (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
