import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { Box, Container, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import Page from './Page';
// import { WatDatePicker } from 'thaidatepicker-react';
import { fDateApi } from './utils/formatTime';
import BrudCrumbs from './BreadCrumbs';
import PageTitle from './PageHeading';
import Loader from '../global/Loader';

// styles

const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL_ADMIN = process.env.REACT_APP_BASE_ADMIN_URL;

export default function Dashboard() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isFilterddata, setIsFilterddata] = useState(false);
  const [selectedFilterDate, setSelectedFilterDate] = useState('');
  const [totalNoOfPage, setTotalNoOfPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [searchAndFiter, setSearchAndFilter] = useState({
    applicationCode: '',
    documentType: '-',
    documentNumber: '',
    transactionDate: '',
    latestUpdate: '',
    pageIndex: 0,
    pageIndex: 10
  });
  const [getkpidata, setGetkpidata] = useState({});
  const [refresh, setRefresh] = useState(null);
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [editStep, setEditStep] = useState(false);
  const Input = styled('input')({
    display: 'none'
  });
  // Loader
  const [openLoader, setOpenLoader] = useState(false);
  // drawer
  const [drawerOpen, setDrawOpen] = useState(false);
  const [selectDate, setSelectDate] = useState(' ');
  const [currentDate, setCurrentDate] = useState(fDateApi(new Date(new Date().getTime())));
  const [prevDate, setPrevDate] = useState(
    fDateApi(new Date(new Date().getTime() - 15 * 24 * 60 * 60 * 1000))
  );
  const navigate = useNavigate();
  const [transactionLogsData, setTransactionLogsData] = useState([]);
  const [transactionLogsFilter, setTransactionLogsFilter] = useState([]);
  const [submenuAccess, setSubmenuAccess] = useState({});
  const [isPageAccess, setIsPageAccess] = useState(true);
  const [UpdateComp, setUpdateComp] = useState(null);
  const [isShowAll, setShowAll] = useState(false);
  const [PopUuText, setPopUuText] = useState('');
  const [openDialoge, setOpenDialoge] = useState(null);
  // const isPopUpOpen = Boolean(openDialoge);
  const [dialogMsg, setDialogMsg] = useState(''); // Dialog Message
  const [openDialogDecision, setOpenDialogDecision] = useState(false);

  // Handle open decision dialog
  const handleOpenDecisionDialog = () => {
    setOpenDialogDecision(true);
  };
  // Handle open decision dialog
  const closeOpenDecisionDialog = () => {
    setOpenDialogDecision(false);
    // setValidationMsg(false);
  };
  // Handle Decision Api calling
  const handleStatusChange = async () => {
    // if (selectDecision === ' ') {
    //   setValidationMsg(true);
    //   return;
    // }
    // setValidationMsg(false);
    try {
      setOpenLoader(true);
      const credential = {
        // decision: selectDecision
      };
      // const isDecisionUpdate = await DecisionUpdate(credential);
      // if (isDecisionUpdate.status === 'success') {
      // } else if (isDecisionUpdate.status === 'error') {
      //   setOpenAlertDialog(true);
      //   setDialogMsg(isDecisionUpdate.msg);
      // } else {
      //   setOpenAlertDialog(true);
      //   setDialogMsg(isDecisionUpdate.msg || 'An error occurred');
      // }
    } catch (error) {
      console.log(error);
      // setOpenAlertDialog(true);
      setDialogMsg('Something went wrong');
    } finally {
      setOpenLoader(false);
    }
  };

  // Error Dialog close handler
  const closeDialogMsgHandler = (e) => {
    setOpenDialoge(false);
    setDialogMsg('');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    setRefresh(new Date());
  };

  const Headtransaction = [
    'APPLICATION NUMBER	',
    'DOC REF ID',
    'DOC TYPE',
    'LATITUDE',
    'LONGITUDE',
    'ADDRESS',
    'CREATED ON',
    'UPDATED ON',
    'ACTION'
  ];

  const [csvReportObj, setCsvReportObj] = useState({
    data: [],
    headers: Headtransaction,
    filename: 'csvFile.csv'
  });

  if (isPageAccess) {
    return (
      <Page title="Transaction Log">
        {/* header component */}

        <Container maxWidth={false}>
          <Box pt={3} pb={3} mb={4}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className="pageHeading"
            >
              <PageTitle info="Dashboard" />
              <Box className="brudCrumbs">
                <BrudCrumbs />
              </Box>
            </Stack>

            <Loader openLoader={openLoader} />
          </Box>
        </Container>
      </Page>
    );
  }
}
