// ----------------------------------------------------------------------

import { userDataFromLocal } from '../utils/getUserDetails';

const account = {
  displayName: userDataFromLocal()?.userName,
  email: userDataFromLocal()?.emailAddress,
  photoURL: '/static/mock-images/avatars/avatar_default.jpg'
};

export default account;
