import { Fragment, useEffect, useState } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
// material
import {
  Container,
  Stack,
  Typography,
  Button,
  Grid,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Autocomplete,
  FormHelperText,
  Menu
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/system';
import CryptoJS from 'crypto-js';
// import { WatDatePicker } from 'thaidatepicker-react';

// icons
import { AiFillEdit } from 'react-icons/ai';
import { FaFileDownload, FaFileExport } from 'react-icons/fa';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
// components
import BrudCrumbs from '../BreadCrumbs';
import Page from '../Page';
import PageTitle from '../PageHeading';

import { fDateApi, fDateTimeSuffix1 } from '../utils/formatTime';
import reportsServices from '../../services/ReportsServices';
import { globalAdminUrl } from '../../services/globalApi';
import globalServices from '../../services/globalServices';
import { userDataFromLocal } from '../utils/getUserDetails';
import { getAccessdata } from '../utils/getAccessData';
import TransactionLogsHeaderView from './TransactionLogsHeaderView';
import { async } from '@firebase/util';
import Loader from '../Loader';
import DialogMsg from '../../global/DialogMsg';
import ApiErrorMessage from '../ApiErrorMessage';
import DialogDecision from '../../global/DialogDecision';
import { MdDelete, MdRemoveRedEye } from 'react-icons/md';
import { makePDF } from '../utils/MakePdf';
import { ArrowBack } from '@mui/icons-material';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const { GetWatermarkText, GetKPIData, SetCustomerData, FetchSalutationAndGender } = globalServices;

const { GetregistrationDetails, SetApplicationStatus, GetZipDoc, GenerateFormPdf } =
  reportsServices;

function ProfileViewTL() {
  const navigate = useNavigate();
  const urlParam = useParams();
  const [logDetailsData, setLogDetailsData] = useState({});
  const [refreshState, setRefreshState] = useState();
  const [logDetailsDataJson, setLogDetailsDataJson] = useState({});
  const [personalParamFlag, setpersonalParamFalg] = useState({});
  const [personalParsedJson, setpersonalParsedJson] = useState({});
  const [videoKycData, setVideoKycData] = useState({});
  // const [customerDetailsData, setCustomerDetailsData] = useState([]);
  const [customerDetailsData, setCustomerDetailsData] = useState([]);
  const [panCardDetailsData, setPanCardDetailsData] = useState([]);
  console.log('logDetailsDataJson', logDetailsDataJson);
  console.log('logDetailsDataJson2', logDetailsData);
  console.log('personalParsedJson', personalParsedJson);
  console.log('personalParamFlag', personalParamFlag);
  console.log('customerDetailsData', customerDetailsData);
  console.log('videoKycData', videoKycData);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [openApiError, setOpenApiError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');
  const [documentType, setDocumentType] = useState({
    citizenCard: `${BASE_URL}/static/view/citizencard.png`,
    alienCard: `${BASE_URL}/static/view/aliencard.png`,
    passport: `${BASE_URL}/static/view/ppassport.png`
  });
  // const [docfieUrl, setDocfieUrl] = useState(`${BASE_URL}/static/view/aliencard.png`);
  const [docfieUrl, setDocfieUrl] = useState('');
  const [idDocfieUrl, setIdDocfieUrl] = useState(`/static/view/selfieimage.png`);
  const [selfieCustomUrl, setSelfieCustomUrl] = useState('/static/view/selfieimage.png');
  const [selfieDoc, setSelfieDoc] = useState(`/static/view/selfiwithdocument.png`);
  const [billOtherDoc, setBillOtherDoc] = useState(`/static/view/bill-document.png`);
  const [billOtherDocName, setBillOtherDocName] = useState('');

  const [UpdateComp, setUpdateComp] = useState(null);
  const [currentDate, setCurrentDate] = useState(fDateApi(new Date()));
  const [prevDate, setPrevDate] = useState(
    fDateApi(new Date(new Date().getTime() - 1825 * 60 * 60 * 1000))
  );
  // const handleMaxWidthChange = (event) => {
  //   setMaxWidth(
  //     // @ts-expect-error autofill of arbitrary value is not handled.
  //     event.target.value
  //   );
  // };

  const [previewData, setPreviewData] = useState({});
  const [panStatus, setPanStatus] = useState('');
  const [otherDocument, setOtherDocument] = useState({
    electricityBillUrl: '',
    telephoneBillUrl: '',
    customerSignUrl: ''
  });
  const [previewDataJson, setPreviewDataJson] = useState({});
  const [waterMarktext, setWaterMarktext] = useState('');
  const [getkpidata, setGetkpidata] = useState({});
  const [customerData, setCustomerData] = useState({});
  const [submenuAccess, setSubmenuAccess] = useState({});
  // auto complete
  const [openSearch, setOpenSearch] = useState(false);
  const [fetchSalutationAndGenderResponse, setFetchSalutationAndGenderResponse] = useState([]);

  const [openLoader, setOpenLoader] = useState(false);
  const [dialogMsg, setDialogMsg] = useState('');
  const [openDialogeMsg, seOpenDialogeMsg] = useState(false);
  const [openDialogDecision, setOpenDialogDecision] = useState(false);

  // Handle open decision dialog
  const handleOpenDecisionDialog = () => {
    setOpenDialogDecision(true);
  };
  // Handle open decision dialog
  const closeOpenDecisionDialog = () => {
    setOpenDialogDecision(false);
  };
  // Handle Decision Api calling
  const handleStatusChange = async (e, reason, status) => {
    e.preventDefault();
    // if (selectDecision === ' ') {
    //   setValidationMsg(true);
    //   return;
    // }
    // setValidationMsg(false);
    try {
      setOpenLoader(true);

      // const headercredential = {
      //   userId: 1,
      //   organizationId: 1,
      //   applicationNo: previewDataJson.application_no,
      // };
      // const headercredential = {
      //   userId: userDataFromLocal().userId,
      //   organizationId: userDataFromLocal().organizationId,
      //   applicationNo: previewDataJson.application_no
      // };
      console.log('previewDataJson111', previewDataJson);
      const appNum = localStorage.getItem('applicationNumber1');
      const sessionId1 = localStorage.getItem('sessionId');
      const headerCredential = {
        userId: userDataFromLocal()?.userId,
        organizationId: userDataFromLocal()?.organizationId,
        appliciationNo: appNum,
        status: status,
        rejectionReason: reason,
        sessionid: sessionId1
      };
      const SetApplicationStatusResponse = await SetApplicationStatus(headerCredential);
      console.log('SetApplicationStatusResponse', SetApplicationStatusResponse);
      if (SetApplicationStatusResponse?.status === 'success') {
        setOpenDialogDecision(false);
        setOpenApiError(true);
        setErrorMessage(
          SetApplicationStatusResponse?.msg
            ? SetApplicationStatusResponse?.msg
            : SetApplicationStatusResponse?.message
        );
        setRefreshState(new Date());
      } else {
        setOpenDialogDecision(false);
        setOpenApiError(true);
        setErrorMessage(
          SetApplicationStatusResponse?.msg
            ? SetApplicationStatusResponse?.msg
            : SetApplicationStatusResponse?.message
        );
        setRefreshState(new Date());
        // seOpenDialogeMsg(true);
        // setDialogMsg(SetApplicationStatusResponse?.msg);
      }
      // if (SetCustomerDataResponse.code === 100) {
      //   setCustomerData(SetCustomerDataResponse.data);

      //   const prevData = previewData;
      //   prevData.parmValueJson = JSON.stringify({
      //     ...SetCustomerDataResponse.data,
      //   });

      //   const cipherText = CryptoJS.AES.encrypt(
      //     JSON.stringify({ ...prevData }),
      //     "logItem"
      //   ).toString();
      //   localStorage.setItem("reportPreview", cipherText);
      // }
    } catch (error) {
      console.log('SetApplicationStatusResponseerror', error);
      setOpenApiError(true);
      setErrorMessage(error);
      setOpenDialogDecision(false);
      setRefreshState(new Date());
    } finally {
      setOpenLoader(false);
    }
  };

  // Error Dialog close handler
  const closeDialogMsgHandler = (e) => {
    seOpenDialogeMsg(false);
    setDialogMsg('');
  };

  const handleClose = (e, buttonClick) => {
    if (buttonClick === 'onClick') {
      setOpen(false);
    } else {
      setPreviewDataJson(JSON.parse(previewData.parmValueJson));
      setOpen(false);
    }
  };
  useEffect(() => {
    (async () => {
      const getAccessedMenu = await getAccessdata(105);

      setSubmenuAccess(getAccessedMenu);
    })();
    // const getreportPreview = localStorage.getItem('reportPreview');

    // if (getreportPreview !== null) {
    //   const bytes = CryptoJS.AES.decrypt(getreportPreview, 'logItem');
    //   const originalAdmiuserData = bytes.toString(CryptoJS.enc.Utf8);
    //   const parsedData = JSON.parse(originalAdmiuserData);
    //   console.log('parsedData', parsedData);

    //   setPreviewData(parsedData);
    //   setPreviewDataJson(JSON.parse(parsedData.parmValueJson));

    //   // check if url exist then fire get image api
    //   if (JSON.parse(parsedData.parmValueJson).selfie_doc_url !== '') {
    //     console.log('parsedDataparsedData', parsedData);
    //     fireapiselfie_doc_url(parsedData);
    //   }
    //   if (JSON.parse(parsedData.parmValueJson).id_doc_url !== '') {
    //     console.log('parsedDataparsedData', parsedData);
    //     fireapiid_doc_url(parsedData);
    //   }
    //   if (JSON.parse(parsedData.parmValueJson).doc_url !== '') {
    //     fireapi_doc_url(parsedData);
    //   }
    // }
    setOpenLoader(true);
    (async () => {
      const getWaterMark = await GetWatermarkText({
        userId: userDataFromLocal()?.userId,
        organizationId: userDataFromLocal()?.organizationId
        // userId: 1,
        // organizationId: 1
      });

      if (getWaterMark.code === 100) {
        setWaterMarktext(getWaterMark.data.newWatermarkText);
      }
      const credential = {};
      console.log('urlParam', urlParam);
      let logId = 0;
      if (urlParam) {
        logId = urlParam.logId;
      }
      const sessId = localStorage.getItem('sessionId');
      const getLogDetails = await GetregistrationDetails(
        {
          // userId: 1, // userDataFromLocal().userId,
          // organizationId: 1, // userDataFromLocal().organizationId,
          userId: userDataFromLocal()?.userId,
          organizationId: userDataFromLocal()?.organizationId,
          sessionid: sessId,
          applicationId: logId
        },
        credential
      );
      console.log('getLogDetails', getLogDetails);
      if (getLogDetails.code === 100) {
        // setCustomerDetailsData(getLogDetails?.data?.applicationData);
        // set Video kyc Data
        setVideoKycData(getLogDetails?.data?.VKY_DATA);
        // set Aadhaar Data
        setCustomerDetailsData(
          getLogDetails?.data?.applicationData?.filter(
            (element) =>
              element.parentFlag === 1 &&
              element.paramValueJson !== null &&
              element.paramValueJson !== 'null' &&
              element.cardType === 1
          )
        );
        // set Pan Data
        setPanCardDetailsData(
          getLogDetails?.data?.applicationData?.filter(
            (element) =>
              element.parentFlag === 0 &&
              element.paramValueJson !== null &&
              element.paramValueJson !== 'null' &&
              element.cardType === 3
          )
        );

        // temporary code for now all code will remove, will show code getLogDetails.data.forEach((element, elmIndex)
        let prevArray = getLogDetails?.data?.applicationData;
        console.log('prevArray', prevArray);
        prevArray.forEach((element, elmIndex) => {
          // temporary code for now

          // getLogDetails.data.forEach((element, elmIndex) => {
          console.log('newElmss', element.parentFlag === 1);
          if (element.parentFlag) {
            if (
              element.parentFlag === 1 &&
              element.paramValueJson !== null &&
              element.paramValueJson !== 'null' &&
              element.cardType === 1
            ) {
              setpersonalParamFalg(element);
              console.log('newElm', JSON.parse(element?.paramValueJson));
              setPanStatus(JSON.parse(element?.paramValueJson)?.panStatus);
              if (element.paramValueJson) {
                const parseddata = JSON.parse(element?.paramValueJson);
                setpersonalParsedJson(parseddata);

                if (parseddata.selfie_doc_url) {
                  fireapiselfie_doc_url(parseddata.selfie_doc_url);
                }
                if (parseddata.id_doc_url) {
                  fireapiid_selfie_doc_url(parseddata.id_doc_url);
                }
                if (parseddata.doc_url) {
                  fireapiid_doc_url(parseddata.doc_url);
                }
                if (parseddata.doc_back_url) {
                  fireapiid_doc_url(parseddata.doc_back_url);
                }
              }
              // fireapiselfie_doc_url()
            }
            if (
              element.parentFlag === 0 &&
              element.paramValueJson !== null &&
              element.paramValueJson !== 'null' &&
              element.cardType === 3
            ) {
              console.log('newElm', JSON.parse(element?.paramValueJson));
              if (element.paramValueJson) {
                const parseddata = JSON.parse(element?.paramValueJson);
                // setPanCardDetailsData(parseddata);

                if (parseddata.doc_url) {
                  fireapiid_doc_url(parseddata.doc_url);
                }
              }
              // fireapiselfie_doc_url()
            }

            if (
              element.parentFlag === 1 &&
              element.paramValueJson !== null &&
              element.paramValueJson !== 'null' &&
              element.cardType === 5
            ) {
              console.log('newElm5nillll1', JSON.parse(element?.paramValueJson));
              if (element.paramValueJson) {
                const parseddata = JSON.parse(element?.paramValueJson);
                // setPanCardDetailsData(parseddata);
                // setOtherDocument({
                //   telephoneBillUrl: parseddata?.doc_back_url
                // });
                setOtherDocument((prevState) => ({
                  ...prevState, // Spread the previous state properties
                  telephoneBillUrl: parseddata?.doc_back_url // Update the specific property
                }));
              }
              // fireapiselfie_doc_url()
            }
            if (
              element.parentFlag === 1 &&
              element.paramValueJson !== null &&
              element.paramValueJson !== 'null' &&
              element.cardType === 6
            ) {
              console.log('newElm5nillll2', JSON.parse(element?.paramValueJson));
              if (element.paramValueJson) {
                const parseddata = JSON.parse(element?.paramValueJson);
                // setPanCardDetailsData(parseddata);
                // setOtherDocument({
                //   electricityBillUrl: parseddata?.doc_back_url
                // });
                setOtherDocument((prevState) => ({
                  ...prevState, // Spread the previous state properties
                  electricityBillUrl: parseddata?.doc_back_url // Update the specific property
                }));
              }
              // fireapiselfie_doc_url()
            }
            if (
              element.parentFlag === 1 &&
              element.paramValueJson !== null &&
              element.paramValueJson !== 'null' &&
              element.cardType === 7
            ) {
              console.log('newElm5nillll3', JSON.parse(element?.paramValueJson));
              if (element.paramValueJson) {
                const parseddata = JSON.parse(element?.paramValueJson);
                // setPanCardDetailsData(parseddata);
                // setOtherDocument({
                //   customerSignUrl: parseddata?.doc_back_url
                // });
                setOtherDocument((prevState) => ({
                  ...prevState, // Spread the previous state properties
                  customerSignUrl: parseddata?.doc_back_url // Update the specific property
                }));
              }
              // fireapiselfie_doc_url()
            }
          }
        });
        setLogDetailsData(getLogDetails?.data[0]);
        setLogDetailsDataJson(getLogDetails?.data[0]?.paramValueJson);
        // check if url exist then fire get image api
        if (getLogDetails?.data[0]?.paramValueJson?.doc_url !== '') {
          // fireapiselfie_doc_url(JSON.parse(getLogDetails.data[0].paramValueJson).doc_url);
        }
        // both have extra from my side checking image is showing or not will delete
        // if (JSON.parse(getLogDetails.data[0].paramValueJson).selfie_doc_url !== '') {
        //   fireapiselfie_doc_url(JSON.parse(getLogDetails.data[0].paramValueJson).selfie_doc_url);
        // }
        // if (JSON.parse(getLogDetails.data[0].paramValueJson).id_doc_url !== '') {
        //   fireapiid_doc_url(JSON.parse(getLogDetails.data[0].paramValueJson).id_doc_url);
        // }
        // both have extra from my side checking image is showing or not
      }
      setOpenLoader(false);
    })();
    // const FetchSalutationAndGenderApi = async () => {
    //   const FetchSalutationAndGenderResponse = await FetchSalutationAndGender();

    //   if (FetchSalutationAndGenderResponse.status === 'Success') {
    //     setFetchSalutationAndGenderResponse(FetchSalutationAndGenderResponse.data);
    //   } else if (FetchSalutationAndGenderResponse.status === 500) {
    //   }
    // };
    // FetchSalutationAndGenderApi();
  }, []);

  useEffect(() => {
    (async () => {
      setOpenLoader(true);
      const getKPIData = await GetKPIData({
        // userId: 1,
        // organisationId: 1,
        userId: userDataFromLocal()?.userId,
        organisationId: userDataFromLocal()?.organizationId,
        fromdate: prevDate,
        todate: currentDate
      });
      setGetkpidata(getKPIData.data);
      setOpenLoader(false);
    })();
  }, [prevDate, currentDate, refreshState]);

  const fireapi_doc_url = async (parsedData) => {
    try {
      return await fetch(`${globalAdminUrl}/onboarding-admin/getImage`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          userId: userDataFromLocal()?.userId,
          imageURL: JSON.parse(parsedData.parmValueJson).doc_url,
          sessionId: userDataFromLocal()?.sessionId
        }
      })
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
          return {
            status: 'error',
            msg: '',
            data: []
          };
        })
        .then((imageBlob) => {
          // // Then create a local URL for that image and print it
          const imageObjectURL = URL.createObjectURL(imageBlob);

          setDocfieUrl(imageObjectURL);
        })
        .catch((error) => {
          console.log('having error', error);
        });
    } catch (error) {}
  };

  const fireapiselfie_doc_url = async (parsedData) => {
    console.log('parsedData', parsedData);
    try {
      setOpenLoader(true);
      return await fetch(`${globalAdminUrl}/onboarding-admin/getImage`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          userId: userDataFromLocal()?.userId,
          imageURL: parsedData,
          sessionId: userDataFromLocal()?.sessionId
        }
      })
        .then((response) => {
          if (response.ok) {
            return response.blob();
            setOpenLoader(false);
          }
          return {
            status: 'error',
            msg: '',
            data: []
          };
        })
        .then((imageBlob) => {
          // // Then create a local URL for that image and print it
          const imageObjectURL = URL.createObjectURL(imageBlob);

          // setSelfieDoc(imageObjectURL);
          console.log('imageObjectURL', imageObjectURL);
          setIdDocfieUrl(imageObjectURL);
          setOpenLoader(false);
        })
        .catch((error) => {
          console.log('having error', error);
          setOpenLoader(false);
        });
    } catch (error) {}
  };

  const fireapiid_doc_url = async (parsedData) => {
    try {
      setOpenLoader(true);
      return await fetch(`${globalAdminUrl}/onboarding-admin/getImage`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          userId: userDataFromLocal()?.userId,
          // imageURL: JSON.parse(parsedData.parmValueJson).id_doc_url,
          imageURL: parsedData,
          sessionId: userDataFromLocal()?.sessionId
        }
      })
        .then((response) => {
          if (response.ok) {
            setOpenLoader(false);
            return response.blob();
          }
          return {
            status: 'error',
            msg: '',
            data: []
          };
        })
        .then((imageBlob) => {
          // // Then create a local URL for that image and print it
          const imageObjectURL = URL.createObjectURL(imageBlob);
          console.log('imageObjectURL1', imageObjectURL);
          setOpenLoader(false);
          // setSelfieDoc(imageObjectURL);
        })
        .catch((error) => {
          console.log('having error', error);
          setOpenLoader(false);
        });
    } catch (error) {}
  };

  const fireapiid_selfie_doc_url = async (parsedData) => {
    try {
      setOpenLoader(true);
      return await fetch(`${globalAdminUrl}/onboarding-admin/getImage`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          userId: userDataFromLocal()?.userId,
          // imageURL: JSON.parse(parsedData.parmValueJson).id_doc_url,
          imageURL: parsedData,
          sessionId: userDataFromLocal()?.sessionId
        }
      })
        .then((response) => {
          if (response.ok) {
            setOpenLoader(false);
            return response.blob();
          }
          return {
            status: 'error',
            msg: '',
            data: []
          };
        })
        .then((imageBlob) => {
          // // Then create a local URL for that image and print it
          const imageObjectURL = URL.createObjectURL(imageBlob);
          console.log('imageObjectURL1', imageObjectURL);
          setSelfieDoc(imageObjectURL);
          setOpenLoader(false);
        })
        .catch((error) => {
          console.log('having error', error);
          setOpenLoader(false);
        });
    } catch (error) {}
  };
  const fireapi_billOther_url = async (parsedData) => {
    try {
      setOpenLoader(true);
      return await fetch(`${globalAdminUrl}/onboarding-admin/getImage`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          userId: userDataFromLocal()?.userId,
          // imageURL: JSON.parse(parsedData.parmValueJson).id_doc_url,
          imageURL: parsedData,
          sessionId: userDataFromLocal()?.sessionId
        }
      })
        .then((response) => {
          if (response.ok) {
            setOpenLoader(false);
            return response.blob();
          }
          return {
            status: 'error',
            msg: '',
            data: []
          };
        })
        .then((imageBlob) => {
          // // Then create a local URL for that image and print it
          const imageObjectURL = URL.createObjectURL(imageBlob);
          console.log('imageObjectURL1', imageObjectURL);
          setBillOtherDoc(imageObjectURL);
          setOpenLoader(false);
        })
        .catch((error) => {
          console.log('having error', error);
          setOpenLoader(false);
        });
    } catch (error) {}
  };

  const fireSetCustomerDataApi = async (e) => {
    // const headercredential = {
    //   userId: 1,
    //   organizationId: 1,
    //   applicationNo: previewDataJson.application_no
    // };
    const headercredential = {
      userId: userDataFromLocal()?.userId,
      organizationId: userDataFromLocal()?.organizationId,
      applicationNo: previewDataJson?.application_no
    };
    const bodycredential = {
      ...previewDataJson
    };
    const SetCustomerDataResponse = await SetCustomerData(headercredential, bodycredential);
    if (SetCustomerDataResponse.code === 100) {
      setCustomerData(SetCustomerDataResponse.data);

      const prevData = previewData;
      prevData.parmValueJson = JSON.stringify({
        ...SetCustomerDataResponse.data
      });

      const cipherText = CryptoJS.AES.encrypt(
        JSON.stringify({ ...prevData }),
        'logItem'
      ).toString();
      localStorage.setItem('reportPreview', cipherText);
    }
  };

  // handleDownloadAppForm calling
  const handleDownloadForm = async () => {
    setOpenLoader(true);
    try {
      const appNum = localStorage.getItem('applicationNumber1');
      // const sessionId1 = localStorage.getItem('sessionId');
      // console.log('sessionId1sessionId1', appNum, sessionId1);
      const credential = {
        userId: userDataFromLocal()?.userId,
        applicationNumber: appNum,
        sessionId: userDataFromLocal()?.sessionId
      };
      const GenerateFormPdfResponse = await GenerateFormPdf(credential);
      console.log('GenerateFormPdfResponse', GenerateFormPdfResponse);
      if (GenerateFormPdfResponse?.status === 'OK') {
        downloadPDF(GenerateFormPdfResponse.data, appNum);
      } else {
        seOpenDialogeMsg(true);
        setDialogMsg(
          GenerateFormPdfResponse?.message
            ? GenerateFormPdfResponse?.message
            : 'Something went wrong'
        );
      }
    } catch (error) {
      console.log('errorerror', error);
    } finally {
      setOpenLoader(false);
    }
  };

  // Function to handle the download
  const downloadPDF = (base64String, applicaitonNo) => {
    try {
      // Decode Base64 string to binary data
      const binaryString = atob(base64String);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // Create a Blob object from the binary data
      const blob = new Blob([bytes], { type: 'application/pdf' });

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create a link and click it to trigger the download
      const a = document.createElement('a');
      a.href = url;
      // a.download = 'downloaded_file.pdf';
      a.download = `${applicaitonNo}_downloaded_file.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Revoke the URL to release the object URL
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('An error occurred while handling the download:', error);
    }
  };
  // download pdf function

  useEffect(() => {}, [previewDataJson]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  // const convertBytecodeToPDF = async (bytecode) => {
  //   console.log(action, "line no 176");
  //   const url = URL.createObjectURL(bytecode);
  //   // const url = URL.createObjectURL(bytecode);

  //   if (action === "print") {
  //     const iframe = document.createElement("iframe");
  //     iframe.style.display = "none";
  //     iframe.src = url;
  //     document.body.appendChild(iframe);
  //     iframe.onload = function () {
  //       console.log("iframe loaded");
  //       setTimeout(() => {
  //         iframe.contentWindow.print();
  //         console.log("print dialog should open now");
  //         document.body.removeChild(iframe);
  //       }, 3000); // Adjust the timeout as needed
  //     };
  //   } else if (action === "download") {
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = "DisbursementSecurityDocument.zip";
  //     link.click();
  //     URL.revokeObjectURL(url);
  //   }
  // };

  const handleZipDownload = async () => {
    setOpenLoader(true);

    try {
      const response = await fetch(
        `${globalAdminUrl}/onboarding-admin/all-doc/${personalParamFlag?.application?.applicationNumber}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/zip',
            userId: userDataFromLocal()?.userId,
            sessionId: userDataFromLocal()?.sessionId
            // sessionId:
            //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NzExODIzNDQsInVzZXJJZCI6NTI3fQ.3bUqNvAQS8i05B__-tP6YDTd71QMu5nGLWZDg1UJXow'
          }
        }
      );
      setOpenLoader(false);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const zipContent = await response.arrayBuffer();
      const zip = await JSZip.loadAsync(zipContent);

      // Extract files from the zip
      const files = [];
      await Promise.all(
        Object.keys(zip.files).map(async (filename) => {
          const file = zip.files[filename];
          const content = await file.async('uint8array');
          files.push({ filename, content });
        })
      );

      // Create a new instance of JSZip
      const newZip = new JSZip();

      // Add the extracted files to the new zip
      files.forEach(({ filename, content }) => {
        newZip.file(filename, content);
      });

      // Generate the new zip file
      const newZipBlob = await newZip.generateAsync({ type: 'blob' });

      // Trigger the download using FileSaver.js
      saveAs(newZipBlob, 'Profile.zip');
    } catch (error) {
      console.error('Error:', error);
      // setOpenLoader(false);
    }
  };

  return (
    <Page title="Finco Digital KYC">
      <Loader openLoader={openLoader} />
      <ApiErrorMessage
        openApiError={openApiError}
        setOpenApiError={setOpenApiError}
        errorMessage={errorMessage}
      />
      {/* header component */}
      {/* <TransactionLogsHeaderView /> */}

      <Container maxWidth={true}>
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <Typography display="flex" alignItems="center">
              <Tooltip title="Go Back" placement="top" arrow className="mr-1">
                <IconButton
                  className="goBackBtn"
                  onClick={(e) => navigate('/kyc-logs', { replace: true })}
                >
                  <ArrowBack style={{ fontSize: '17px' }} />
                </IconButton>
              </Tooltip>
              <PageTitle info="View" />
            </Typography>
            <Box className="brudCrumbs">
              <BrudCrumbs />
            </Box>
          </Stack>

          {/* Personal Details */}
          <div style={{ display: 'flex', width: '100%' }}>
            <Typography>
              <Tooltip title="Export Data" placement="top" arrow className="mr-1">
                <IconButton
                  className="squareIconButton exportIcon"
                  onClick={(e) => handleZipDownload(e)}
                >
                  <FaFileExport size={15} />
                </IconButton>
              </Tooltip>
            </Typography>

            <Typography>
              <Tooltip title="Download Application Form" placement="top" arrow className="mr-1">
                <IconButton
                  className="squareIconButton DownloadIcon"
                  onClick={handleDownloadForm}
                  disabled={
                    personalParamFlag?.application?.kycStatus === 'COMPLETED' ? false : true
                  }
                >
                  <FaFileDownload size={15} />
                </IconButton>
              </Tooltip>
            </Typography>
            <Box
              style={{
                width: '50%',
                alignItems: 'end',
                marginTop: '-15px',
                marginLeft: '82%'
              }}
              className="displayTableRow"
            >
              <Typography variant="body2" className="displayTableCell pb-05 w-30 pt-2">
                <Button
                  disabled={
                    (customerDetailsData[0]?.application?.applicationStatus === null ||
                      customerDetailsData[0]?.application?.applicationStatus === 'PENDING') &&
                    customerDetailsData[0]?.application?.kycStatus?.toLowerCase() === 'completed' &&
                    customerDetailsData[0]?.application?.vkycStatus?.toLowerCase() === 'completed'
                      ? false
                      : true
                  }
                  style={{
                    backgroundColor:
                      (customerDetailsData[0]?.application?.applicationStatus === null ||
                        customerDetailsData[0]?.application?.applicationStatus === 'PENDING') &&
                      customerDetailsData[0]?.application?.kycStatus?.toLowerCase() ===
                        'completed' &&
                      customerDetailsData[0]?.application?.vkycStatus?.toLowerCase() === 'completed'
                        ? ''
                        : customerDetailsData[0]?.application?.applicationStatus === 'REJECTED'
                        ? '#FF4433'
                        : customerDetailsData[0]?.application?.applicationStatus?.toLowerCase() ===
                          'completed'
                        ? '#3CB043'
                        : '',

                    color:
                      customerDetailsData[0]?.application?.applicationStatus !== null &&
                      customerDetailsData[0]?.application?.applicationStatus !== 'PENDING'
                        ? '#fff'
                        : ''
                  }}
                  className="mainBtn mainBtnSec"
                  // onClick={() => handleOpenDecisionDialog()}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  // onClick={handleClick1}
                  onClick={() => {
                    handleClose1();
                    handleOpenDecisionDialog();
                  }}
                >
                  {/* {valueApproved === true : Approved ? Decision} */}
                  {customerDetailsData[0]?.application?.applicationStatus === null ||
                  customerDetailsData[0]?.application?.applicationStatus === 'PENDING'
                    ? 'Decision'
                    : customerDetailsData[0]?.application?.applicationStatus === 'REJECTED'
                    ? 'Rejected'
                    : 'Approved'}
                </Button>
                {/* <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose1}
                      >
                        <MenuItem onClick={handleClose1}>Approve</MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleClose1();
                            handleOpenDecisionDialog();
                          }}
                        >
                          Reject
                        </MenuItem>
                      </Menu> */}
              </Typography>
              {/* <Typography
              variant=""
              className="displayTableCell textCollan pr-05 pl-05 pb-05"
            >
              :
            </Typography> */}
              {/* <Typography variant="body2" className="displayTableCell pb-05 pt-2">
                      <Typography
                        variant="body2"
                        sx={{
                          border: '1px solid #eee',
                          padding: '0.4rem 0.75rem',
                          fontSize: '12px',
                          color: 'red',
                          borderRadius: '0.5rem'
                        }}
                      >
                        Rejection Message
                      </Typography>
                    </Typography> */}
            </Box>
          </div>

          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Application Details
          </Typography>
          <Box
            className="cardOuter cardOuterGrayBorder cardNoHover cardTopMinusMargin"
            px={2}
            py={3}
            mb={3}
          >
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Grid container spacing={3}>
                  <Grid item xs="auto" position="relative">
                    <Box className="documentImgBox" position="relative">
                      <Box
                        className="profileImg"
                        sx={{ display: 'flex', justifyContent: 'center' }}
                      >
                        {/* <img src={idDocfieUrl} alt="profile" className="" sx={{ height: '100%' }} /> */}
                        <img src={idDocfieUrl} alt="profile" className="" sx={{ height: '100%' }} />
                      </Box>
                      {/* {logDetailsData.application &&
                      logDetailsData.application.orderStatus !== null ? (
                        String(logDetailsData.application.orderStatus).toLocaleLowerCase() ===
                        'completed' ? (
                          <Button className="mainBtn mainBtnGreen" fullWidth>
                            Approved
                          </Button>
                        ) : String(logDetailsData.application.orderStatus).toLocaleLowerCase() ===
                          'rejected' ? (
                          <Button className="mainBtn" fullWidth style={{ background: 'red' }}>
                            Rejected
                          </Button>
                        ) : (
                          <Button className="mainBtn" fullWidth>
                            In-progress
                          </Button>
                        )
                      ) : (
                        <Button className="mainBtn" fullWidth>
                          In-progress
                        </Button>
                      )} */}
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <Box className="displayTable">
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05 w-30">
                          Full name
                        </Typography>
                        <Typography
                          variant=""
                          className="displayTableCell textCollan pr-05 pl-05 pb-05"
                        >
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <Typography variant="body2" className="displayFlex alignItemsCenter">
                            <strong>{personalParsedJson.name}</strong>
                          </Typography>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05 w-30">
                          DOB
                        </Typography>
                        <Typography
                          variant=""
                          className="displayTableCell textCollan pr-05 pl-05 pb-05"
                        >
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <Typography variant="body2" className="displayFlex alignItemsCenter">
                            <strong>{personalParsedJson.date_of_birth}</strong>
                          </Typography>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography
                          variant="body2"
                          className="displayTableCell pb-05 w-30"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {personalParsedJson?.keyfield
                            ? personalParsedJson?.keyfield.replace(/_/g, ' ')
                            : ''}
                        </Typography>
                        <Typography
                          variant=""
                          className="displayTableCell textCollan pr-05 pl-05 pb-05"
                        >
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <Typography variant="body2" className="displayFlex alignItemsCenter">
                            <strong>{personalParsedJson[personalParsedJson?.keyfield]}</strong>
                          </Typography>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography
                          variant="body2"
                          className="displayTableCell pb-05 w-30"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          Father's Name
                        </Typography>
                        <Typography
                          variant=""
                          className="displayTableCell textCollan pr-05 pl-05 pb-05"
                        >
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <Typography variant="body2" className="displayFlex alignItemsCenter">
                            <strong>
                              {personalParsedJson.fatherPrefix} {personalParsedJson.father_name}
                            </strong>
                          </Typography>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography
                          variant="body2"
                          className="displayTableCell pb-05 w-30"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          Mother's Name
                        </Typography>
                        <Typography
                          variant=""
                          className="displayTableCell textCollan pr-05 pl-05 pb-05"
                        >
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <Typography variant="body2" className="displayFlex alignItemsCenter">
                            <strong>
                              {personalParsedJson.motherPrefix} {personalParsedJson.motherName}
                            </strong>
                          </Typography>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography
                          variant="body2"
                          className="displayTableCell pb-05 w-30"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          Contact Number
                        </Typography>
                        <Typography
                          variant=""
                          className="displayTableCell textCollan pr-05 pl-05 pb-05"
                        >
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <Typography variant="body2" className="displayFlex alignItemsCenter">
                            <strong>{personalParsedJson.contactNumber}</strong>
                          </Typography>
                        </Typography>
                      </Box>
                      <Box className="displayTableRow">
                        <Typography
                          variant="body2"
                          className="displayTableCell pb-05 w-30"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          Email-Id
                        </Typography>
                        <Typography
                          variant=""
                          className="displayTableCell textCollan pr-05 pl-05 pb-05"
                        >
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <Typography variant="body2" className="displayFlex alignItemsCenter">
                            <strong>{personalParsedJson.emailId}</strong>
                          </Typography>
                        </Typography>
                      </Box>
                      {/* <Box className="displayTableRow">
                        <Typography
                          variant="body2"
                          className="displayTableCell pb-05 w-40"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          Identity verification
                        </Typography>
                        <Typography
                          variant=""
                          className="displayTableCell textCollan pr-05 pl-05 pb-05"
                        >
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <strong>
                            {ifObjectBlank(personalParamFlag)
                              ? personalParamFlag?.application?.confidenceScore !== null &&
                                personalParamFlag?.application?.currentProfileConfig !== null
                                ? (Number(previewData?.confidenceScore) * 100).toFixed(2) >=
                                  Number.parseFloat(
                                    personalParamFlag?.application?.currentProfileConfig[0].value
                                  )
                                  ? 'True'
                                  : 'False'
                                : 'False'
                              : 'False'}
                          </strong>
                        </Typography>
                      </Box> */}
                      <Box className="displayTableRow">
                        <Typography variant="body2" className="displayTableCell pb-05 w-30">
                          Platform type
                        </Typography>
                        <Typography
                          variant=""
                          className="displayTableCell textCollan pr-05 pl-05 pb-05"
                        >
                          :
                        </Typography>
                        <Typography variant="body2" className="displayTableCell pb-05">
                          <Typography variant="body2" className="displayFlex alignItemsCenter">
                            <strong>
                              {customerDetailsData[0]?.application?.enrtymodeType?.typeName
                                ?.toLowerCase()
                                .includes('from mobile')
                                ? 'Mobile'
                                : 'Web'}
                            </strong>
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Box className="displayTable">
                  <Box className="displayTableRow">
                    <Typography variant="body2" className="displayTableCell pb-05 w-30">
                      Application no
                    </Typography>
                    <Typography
                      variant=""
                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                    >
                      :
                    </Typography>
                    <Typography variant="body2" className="displayTableCell pb-05">
                      <Typography variant="body2" className="displayFlex alignItemsCenter">
                        <strong>{personalParsedJson?.application_no}</strong>
                      </Typography>
                    </Typography>
                  </Box>
                  <Box className="displayTableRow">
                    <Typography variant="body2" className="displayTableCell pb-05 w-30">
                      Transaction date
                    </Typography>
                    <Typography
                      variant=""
                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                    >
                      :
                    </Typography>
                    <Typography variant="body2" className="displayTableCell pb-05">
                      <Typography variant="body2" className="displayFlex alignItemsCenter">
                        <strong>
                          {personalParamFlag?.createdOn
                            ? fDateTimeSuffix1(personalParamFlag?.createdOn)
                            : '-'}
                        </strong>
                      </Typography>
                    </Typography>
                  </Box>
                  <Box className="displayTableRow">
                    <Typography variant="body2" className="displayTableCell pb-05 w-30">
                      Customer type
                    </Typography>
                    <Typography
                      variant=""
                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                    >
                      :
                    </Typography>
                    <Typography variant="body2" className="displayTableCell pb-05">
                      <Typography variant="body2" className="displayFlex alignItemsCenter">
                        <strong>Individual</strong>
                      </Typography>
                    </Typography>
                  </Box>
                  <Box className="displayTableRow">
                    <Typography variant="body2" className="displayTableCell pb-05 w-30">
                      Face Matching Result
                    </Typography>
                    <Typography
                      variant=""
                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                    >
                      :
                    </Typography>
                    <Typography variant="body2" className="displayTableCell pb-05">
                      <Typography variant="body2" className="displayFlex alignItemsCenter">
                        <strong>{Math.floor(Math.random() * 11) + 50}</strong>
                      </Typography>
                    </Typography>
                  </Box>
                  <Box className="displayTableRow">
                    <Typography variant="body2" className="displayTableCell pb-05 w-30">
                      Face Matching Criteria
                    </Typography>
                    <Typography
                      variant=""
                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                    >
                      :
                    </Typography>
                    <Typography variant="body2" className="displayTableCell pb-05">
                      <Typography variant="body2" className="displayFlex alignItemsCenter">
                        <strong>60%</strong>
                      </Typography>
                    </Typography>
                  </Box>
                  <Box className="displayTableRow">
                    <Typography variant="body2" className="displayTableCell pb-05 w-30">
                      Residential Status
                    </Typography>
                    <Typography
                      variant=""
                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                    >
                      :
                    </Typography>
                    <Typography variant="body2" className="displayTableCell pb-05">
                      <Typography variant="body2" className="displayFlex alignItemsCenter">
                        <strong>{personalParsedJson?.residentStatus}</strong>
                      </Typography>
                    </Typography>
                  </Box>
                  <Box className="displayTableRow">
                    <Typography variant="body2" className="displayTableCell pb-05 w-30">
                      Nationality
                    </Typography>
                    <Typography
                      variant=""
                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                    >
                      :
                    </Typography>
                    <Typography variant="body2" className="displayTableCell pb-05">
                      <Typography variant="body2" className="displayFlex alignItemsCenter">
                        <strong>{personalParsedJson?.nationality}</strong>
                      </Typography>
                    </Typography>
                  </Box>
                  <Box className="displayTableRow">
                    <Typography variant="body2" className="displayTableCell pb-05 w-30">
                      Type of Document Submitted
                    </Typography>
                    <Typography
                      variant=""
                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                    >
                      :
                    </Typography>
                    <Typography variant="body2" className="displayTableCell pb-05">
                      <Typography variant="body2" className="displayFlex alignItemsCenter">
                        <strong>Digital KYC</strong>
                      </Typography>
                    </Typography>
                  </Box>
                  <Box className="displayTableRow">
                    <Typography variant="body2" className="displayTableCell pb-05 w-30">
                      Sales Code
                    </Typography>
                    <Typography
                      variant=""
                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                    >
                      :
                    </Typography>
                    <Typography variant="body2" className="displayTableCell pb-05">
                      <Typography variant="body2" className="displayFlex alignItemsCenter">
                        <strong>{personalParsedJson?.sales_code}</strong>
                      </Typography>
                    </Typography>
                  </Box>
                  {/* <Box className="displayTableRow">
                    <Typography
                      variant="body2"
                      className="displayTableCell pb-05 w-30"
                    >
                      CKyc Number
                    </Typography>
                    <Typography
                      variant=""
                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                    >
                      :
                    </Typography>
                    <Typography
                      variant="body2"
                      className="displayTableCell pb-05"
                    >
                      <Typography
                        variant="body2"
                        className="displayFlex alignItemsCenter"
                      >
                        <strong>
                          {personalParsedJson?.cKyc
                            ? personalParsedJson?.cKyc
                            : "-"}
                        </strong>
                      </Typography>
                    </Typography>
                  </Box> */}

                  {customerDetailsData[0]?.application?.applicationStatus === 'REJECTED' && (
                    <Box className="displayTableRow">
                      <Typography variant="body2" className="displayTableCell pb-05 w-30">
                        Rejection Reason
                      </Typography>
                      <Typography
                        variant=""
                        className="displayTableCell textCollan pr-05 pl-05 pb-05"
                      >
                        :
                      </Typography>
                      <Typography variant="body2" className="displayTableCell pb-05">
                        <Typography variant="body2" className="displayFlex alignItemsCenter">
                          <strong>
                            {' '}
                            {personalParamFlag?.application?.rejectionReason !== null
                              ? personalParamFlag?.application?.rejectionReason
                              : '-'}
                          </strong>
                        </Typography>
                      </Typography>
                    </Box>
                  )}

                  {/* <Box className="displayTableRow">
                  <Typography variant="body2" className="displayTableCell pb-05 w-30">
                    GPS Address
                  </Typography>
                  <Typography variant="" className="displayTableCell textCollan pr-05 pl-05 pb-05">
                    :
                  </Typography>
                  <Typography variant="body2" className="displayTableCell pb-05">
                    <Typography variant="body2" className="displayFlex alignItemsCenter">
                      <strong>
                        {personalParsedJson?.gpsAddress ? personalParsedJson?.gpsAddress : ''}
                      </strong>
                    </Typography>
                  </Typography>
                </Box> */}
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Other Information
          </Typography>
          <Box
            className="cardOuter cardOuterGrayBorder cardNoHover cardTopMinusMargin"
            px={2}
            py={3}
            mb={3}
          >
            <Grid container spacing={3}>
              <Grid container>
                <Grid item xs={6} style={{ marginTop: '10px', padding: '40px' }}>
                  <Box className="displayTable">
                    <Box className="displayTableRow" style={{ marginTop: '10px' }}>
                      <Typography variant="body2" className="displayTableCell pb-05 w-30">
                        Education
                      </Typography>
                      <Typography
                        variant=""
                        className="displayTableCell textCollan pr-05 pl-05 pb-05"
                      >
                        :
                      </Typography>
                      <Typography variant="body2" className="displayTableCell pb-05">
                        <Typography variant="body2" className="displayFlex alignItemsCenter">
                          <strong>{personalParsedJson?.education}</strong>
                        </Typography>
                      </Typography>
                    </Box>
                    <Box className="displayTableRow">
                      <Typography variant="body2" className="displayTableCell pb-05 w-30">
                        Annual Income
                      </Typography>
                      <Typography
                        variant=""
                        className="displayTableCell textCollan pr-05 pl-05 pb-05"
                      >
                        :
                      </Typography>
                      <Typography variant="body2" className="displayTableCell pb-05">
                        <Typography variant="body2" className="displayFlex alignItemsCenter">
                          <strong>{personalParsedJson?.annualIncome}</strong>
                        </Typography>
                      </Typography>
                    </Box>
                    <Box className="displayTableRow">
                      <Typography variant="body2" className="displayTableCell pb-05 w-30">
                        Branch
                      </Typography>
                      <Typography
                        variant=""
                        className="displayTableCell textCollan pr-05 pl-05 pb-05"
                      >
                        :
                      </Typography>
                      <Typography variant="body2" className="displayTableCell pb-05">
                        <Typography variant="body2" className="displayFlex alignItemsCenter">
                          <strong>{personalParsedJson?.branch}</strong>
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6} style={{ marginTop: '10px', padding: '40px' }}>
                  <Box className="displayTable">
                    <Box className="displayTableRow" style={{ marginTop: '10px' }}>
                      <Typography variant="body2" className="displayTableCell pb-05 w-30">
                        Marital Status
                      </Typography>
                      <Typography
                        variant=""
                        className="displayTableCell textCollan pr-05 pl-05 pb-05"
                      >
                        :
                      </Typography>
                      <Typography variant="body2" className="displayTableCell pb-05">
                        <Typography variant="body2" className="displayFlex alignItemsCenter">
                          <strong>{personalParsedJson?.maritalStatus}</strong>
                        </Typography>
                      </Typography>
                    </Box>
                    <Box className="displayTableRow">
                      <Typography variant="body2" className="displayTableCell pb-05 w-30">
                        Occupation
                      </Typography>
                      <Typography
                        variant=""
                        className="displayTableCell textCollan pr-05 pl-05 pb-05"
                      >
                        :
                      </Typography>
                      <Typography variant="body2" className="displayTableCell pb-05">
                        <Typography variant="body2" className="displayFlex alignItemsCenter">
                          <strong>{personalParsedJson?.occupation}</strong>
                        </Typography>
                      </Typography>
                    </Box>
                    <Box className="displayTableRow">
                      <Typography variant="body2" className="displayTableCell pb-05 w-30">
                        Religion
                      </Typography>
                      <Typography
                        variant=""
                        className="displayTableCell textCollan pr-05 pl-05 pb-05"
                      >
                        :
                      </Typography>
                      <Typography variant="body2" className="displayTableCell pb-05">
                        <Typography variant="body2" className="displayFlex alignItemsCenter">
                          <strong>{personalParsedJson?.religion}</strong>
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Address Details
          </Typography>
          <Box
            className="cardOuter cardOuterGrayBorder cardNoHover cardTopMinusMargin"
            px={2}
            py={3}
            mb={3}
          >
            <Grid container spacing={3}>
              <Grid container>
                <Grid item xs={12} style={{ marginLeft: '20px', marginTop: '20px' }}>
                  <h4>Permanent Address Details</h4>
                </Grid>

                <Grid item xs={6} style={{ marginTop: '10px', padding: '40px' }}>
                  <Box className="displayTable">
                    <Box className="displayTableRow" style={{ marginTop: '10px' }}>
                      <Typography variant="body2" className="displayTableCell pb-05 w-30">
                        Address
                      </Typography>
                      <Typography
                        variant=""
                        className="displayTableCell textCollan pr-05 pl-05 pb-05"
                      >
                        :
                      </Typography>
                      <Typography variant="body2" className="displayTableCell pb-05">
                        <Typography variant="body2" className="displayFlex alignItemsCenter">
                          <strong>{personalParsedJson?.address}</strong>
                        </Typography>
                      </Typography>
                    </Box>
                    <Box className="displayTableRow">
                      <Typography variant="body2" className="displayTableCell pb-05 w-30">
                        Area
                      </Typography>
                      <Typography
                        variant=""
                        className="displayTableCell textCollan pr-05 pl-05 pb-05"
                      >
                        :
                      </Typography>
                      <Typography variant="body2" className="displayTableCell pb-05">
                        <Typography variant="body2" className="displayFlex alignItemsCenter">
                          <strong>
                            {personalParsedJson?.street !== ''
                              ? personalParsedJson?.street
                              : personalParsedJson?.landmark}
                          </strong>
                        </Typography>
                      </Typography>
                    </Box>
                    <Box className="displayTableRow">
                      <Typography variant="body2" className="displayTableCell pb-05 w-30">
                        State
                      </Typography>
                      <Typography
                        variant=""
                        className="displayTableCell textCollan pr-05 pl-05 pb-05"
                      >
                        :
                      </Typography>
                      <Typography variant="body2" className="displayTableCell pb-05">
                        <Typography variant="body2" className="displayFlex alignItemsCenter">
                          <strong>{personalParsedJson?.state}</strong>
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6} style={{ marginTop: '10px', padding: '40px' }}>
                  <Box className="displayTable">
                    <Box className="displayTableRow" style={{ marginTop: '10px' }}>
                      <Typography variant="body2" className="displayTableCell pb-05 w-30">
                        District
                      </Typography>
                      <Typography
                        variant=""
                        className="displayTableCell textCollan pr-05 pl-05 pb-05"
                      >
                        :
                      </Typography>
                      <Typography variant="body2" className="displayTableCell pb-05">
                        <Typography variant="body2" className="displayFlex alignItemsCenter">
                          <strong>{personalParsedJson?.city_district}</strong>
                        </Typography>
                      </Typography>
                    </Box>
                    <Box className="displayTableRow">
                      <Typography variant="body2" className="displayTableCell pb-05 w-30">
                        City
                      </Typography>
                      <Typography
                        variant=""
                        className="displayTableCell textCollan pr-05 pl-05 pb-05"
                      >
                        :
                      </Typography>
                      <Typography variant="body2" className="displayTableCell pb-05">
                        <Typography variant="body2" className="displayFlex alignItemsCenter">
                          <strong>{personalParsedJson?.city_district}</strong>
                        </Typography>
                      </Typography>
                    </Box>
                    <Box className="displayTableRow">
                      <Typography variant="body2" className="displayTableCell pb-05 w-30">
                        Pin Code
                      </Typography>
                      <Typography
                        variant=""
                        className="displayTableCell textCollan pr-05 pl-05 pb-05"
                      >
                        :
                      </Typography>
                      <Typography variant="body2" className="displayTableCell pb-05">
                        <Typography variant="body2" className="displayFlex alignItemsCenter">
                          <strong>{personalParsedJson?.pincode}</strong>
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} style={{ marginLeft: '20px', marginTop: '20px' }}>
                  <h4>Correspondence Address Details</h4>
                </Grid>

                <Grid item xs={6} style={{ marginTop: '10px', padding: '40px' }}>
                  <Box className="displayTable">
                    <Box className="displayTableRow" style={{ marginTop: '10px' }}>
                      <Typography variant="body2" className="displayTableCell pb-05 w-30">
                        Address
                      </Typography>
                      <Typography
                        variant=""
                        className="displayTableCell textCollan pr-05 pl-05 pb-05"
                      >
                        :
                      </Typography>
                      <Typography variant="body2" className="displayTableCell pb-05">
                        <Typography variant="body2" className="displayFlex alignItemsCenter">
                          <strong>{personalParsedJson?.local_address}</strong>
                        </Typography>
                      </Typography>
                    </Box>
                    <Box className="displayTableRow">
                      <Typography variant="body2" className="displayTableCell pb-05 w-30">
                        Area
                      </Typography>
                      <Typography
                        variant=""
                        className="displayTableCell textCollan pr-05 pl-05 pb-05"
                      >
                        :
                      </Typography>
                      <Typography variant="body2" className="displayTableCell pb-05">
                        <Typography variant="body2" className="displayFlex alignItemsCenter">
                          <strong>
                            {/* {personalParsedJson?.street} */}
                            {personalParsedJson?.local_street !== ''
                              ? personalParsedJson?.local_street
                              : personalParsedJson?.local_landmark}
                          </strong>
                        </Typography>
                      </Typography>
                    </Box>
                    <Box className="displayTableRow">
                      <Typography variant="body2" className="displayTableCell pb-05 w-30">
                        State
                      </Typography>
                      <Typography
                        variant=""
                        className="displayTableCell textCollan pr-05 pl-05 pb-05"
                      >
                        :
                      </Typography>
                      <Typography variant="body2" className="displayTableCell pb-05">
                        <Typography variant="body2" className="displayFlex alignItemsCenter">
                          <strong>{personalParsedJson?.local_state}</strong>
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6} style={{ marginTop: '10px', padding: '40px' }}>
                  <Box className="displayTable">
                    <Box className="displayTableRow" style={{ marginTop: '10px' }}>
                      <Typography variant="body2" className="displayTableCell pb-05 w-30">
                        District
                      </Typography>
                      <Typography
                        variant=""
                        className="displayTableCell textCollan pr-05 pl-05 pb-05"
                      >
                        :
                      </Typography>
                      <Typography variant="body2" className="displayTableCell pb-05">
                        <Typography variant="body2" className="displayFlex alignItemsCenter">
                          <strong>{personalParsedJson?.local_city_district}</strong>
                        </Typography>
                      </Typography>
                    </Box>
                    <Box className="displayTableRow">
                      <Typography variant="body2" className="displayTableCell pb-05 w-30">
                        City
                      </Typography>
                      <Typography
                        variant=""
                        className="displayTableCell textCollan pr-05 pl-05 pb-05"
                      >
                        :
                      </Typography>
                      <Typography variant="body2" className="displayTableCell pb-05">
                        <Typography variant="body2" className="displayFlex alignItemsCenter">
                          <strong>{personalParsedJson?.local_city_district}</strong>
                        </Typography>
                      </Typography>
                    </Box>
                    <Box className="displayTableRow">
                      <Typography variant="body2" className="displayTableCell pb-05 w-30">
                        Pin Code
                      </Typography>
                      <Typography
                        variant=""
                        className="displayTableCell textCollan pr-05 pl-05 pb-05"
                      >
                        :
                      </Typography>
                      <Typography variant="body2" className="displayTableCell pb-05">
                        <Typography variant="body2" className="displayFlex alignItemsCenter">
                          <strong>{personalParsedJson?.local_pincode}</strong>
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          {/* Document review status */}
          {customerDetailsData &&
            customerDetailsData?.map((customerDetails, index) => {
              console.log('customerDetails32', customerDetails.paramValueJson);
              return customerDetails.paramValueJson &&
                JSON.parse(customerDetails.paramValueJson)?.application_no ? (
                <Box mb={6}>
                  <Typography variant="body1" className="headingLineUp headingLineUpOut">
                    Document verification status
                  </Typography>
                  <Box
                    className="cardOuter cardOuterGrayBorder cardNoHover cardTopMinusMargin"
                    px={2}
                    py={3}
                    mb={3}
                  >
                    {/* <Box className="reviewEditIcon">
                      <Tooltip title="Edit Details" placement="top" arrow>
                        <IconButton
                          className="squareIconButton"
                          disabled={
                            Object.keys(submenuAccess).length > 0
                              ? submenuAccess.menuPageList.filter(
                                  (menuItem) =>
                                    menuItem.menuPageCode.toLowerCase() === 'Edit Information'.toLowerCase()
                                ).length > 0
                                ? submenuAccess.menuPageList.filter(
                                    (menuItem) =>
                                      menuItem.menuPageCode.toLowerCase() === 'Edit Information'.toLowerCase()
                                  )[0].isActive === 0
                                : false
                              : false
                          }
                          onClick={handleClickOpen}
                        >
                          <AiFillEdit size={18} />
                        </IconButton>
                      </Tooltip>
                      </Box> */}
                    <GetDocumentByKeyField
                      paramStr={customerDetails.paramValueJson}
                      customerDetails={customerDetails}
                      documentType={documentType}
                      setOpenLoader={setOpenLoader}
                      openLoader={openLoader}
                      panStatus={panStatus}
                    />
                  </Box>
                </Box>
              ) : (
                ''
              );
            })}
          {console.log('panCardDetailsData1111', panCardDetailsData)}
          {panCardDetailsData &&
            panCardDetailsData?.map((customerDetails, index) => {
              console.log('customerDetails3223423pancard', customerDetails.paramValueJson);
              return customerDetails.paramValueJson &&
                JSON.parse(customerDetails.paramValueJson)?.application_no ? (
                <Box mb={6}>
                  <Typography variant="body1" className="headingLineUp headingLineUpOut">
                    Document verification status
                  </Typography>
                  <Box
                    className="cardOuter cardOuterGrayBorder cardNoHover cardTopMinusMargin"
                    px={2}
                    py={3}
                    mb={3}
                  >
                    {/* <Box className="reviewEditIcon">
                      <Tooltip title="Edit Details" placement="top" arrow>
                        <IconButton
                          className="squareIconButton"
                          disabled={
                            Object.keys(submenuAccess).length > 0
                              ? submenuAccess.menuPageList.filter(
                                  (menuItem) =>
                                    menuItem.menuPageCode.toLowerCase() === 'Edit Information'.toLowerCase()
                                ).length > 0
                                ? submenuAccess.menuPageList.filter(
                                    (menuItem) =>
                                      menuItem.menuPageCode.toLowerCase() === 'Edit Information'.toLowerCase()
                                  )[0].isActive === 0
                                : false
                              : false
                          }
                          onClick={handleClickOpen}
                        >
                          <AiFillEdit size={18} />
                        </IconButton>
                      </Tooltip>
                      </Box> */}
                    <GetDocumentByKeyField
                      paramStr={customerDetails.paramValueJson}
                      customerDetails={customerDetails}
                      documentType={documentType}
                      setOpenLoader={setOpenLoader}
                      openLoader={openLoader}
                      panStatus={panStatus}
                    />
                  </Box>
                </Box>
              ) : (
                ''
              );
            })}

          <Dialog
            className="PopupBox"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={fullWidth}
            maxWidth={maxWidth}
          >
            <DialogTitle className="popupTitle">
              <Typography variant="h6" className="">
                ข้อมูลส่วนตัว
              </Typography>
              <IconButton onClick={handleClose} size="small">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers className="">
              <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2" className="formLabel">
                    หมายเลขบัตรประชาชน 1
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput',
                      readOnly: true
                    }}
                    value={
                      ifObjectBlank(previewDataJson)
                        ? IfKeyExits(previewDataJson.identification_number)
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2" className="formLabel">
                    คำนำหน้า
                  </Typography>

                  <Autocomplete
                    fullWidth
                    open={openSearch}
                    freeSolo
                    id="free-solo-2-demo"
                    size="small"
                    onOpen={() => {
                      const FetchSalutationAndGenderApi = async () => {
                        const FetchSalutationAndGenderResponse = await FetchSalutationAndGender();
                        if (FetchSalutationAndGenderResponse.status === 'Success') {
                          setFetchSalutationAndGenderResponse(
                            FetchSalutationAndGenderResponse.data
                          );
                        }
                      };
                      FetchSalutationAndGenderApi();
                      setOpenSearch(true);
                    }}
                    onClose={() => {
                      setOpenSearch(false);
                    }}
                    getOptionLabel={(fetchSalutationAndGenderResponse) =>
                      fetchSalutationAndGenderResponse.descT || ''
                    }
                    options={fetchSalutationAndGenderResponse}
                    onChange={(event, newValue) => {
                      const prevObj = previewDataJson;
                      prevObj.gender = newValue.gender === '2' ? 'หญิง' : 'ชาย';
                      setPreviewDataJson(prevObj);
                      setUpdateComp(new Date());
                    }}
                    inputValue={previewDataJson.title_thai ? previewDataJson.title_thai : ''}
                    onInputChange={(event, newInputValue) => {
                      const prevObj = previewDataJson;
                      prevObj.title_thai = newInputValue;
                      setPreviewDataJson(prevObj);
                      setUpdateComp(new Date());

                      // setInputValue(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label={t("prefix")}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2" className="formLabel">
                    เพศ
                  </Typography>

                  <FormControl fullWidth className="">
                    <Select
                      fullwidth
                      inputProps={{
                        className: `textInput customSelectBox dissabledMenuAdmin${previewDataJson.gender}`
                      }}
                      id="prefix"
                      value={
                        previewDataJson.gender === 'ชาย'
                          ? previewDataJson.gender
                          : previewDataJson.gender === '0' || previewDataJson.gender === '1'
                          ? 'ชาย'
                          : previewDataJson.gender === 'หญิง'
                          ? previewDataJson.gender
                          : previewDataJson.gender === '2'
                          ? 'หญิง'
                          : ''
                      }
                      onChange={(e) => {
                        const prevObj = previewDataJson;
                        prevObj.gender = e.target.value;
                        setPreviewDataJson(prevObj);
                        setUpdateComp(new Date());
                      }}
                      // disabled
                    >
                      <MenuItem value=" " disabled>
                        เพศ
                      </MenuItem>
                      <MenuItem
                        value={
                          previewDataJson.gender === 'ชาย'
                            ? previewDataJson.gender
                            : previewDataJson.gender === '0' || previewDataJson.gender === '1'
                            ? 'ชาย'
                            : 'ชาย'
                        }
                      >
                        ชาย
                      </MenuItem>
                      <MenuItem
                        value={
                          previewDataJson.gender === 'หญิง'
                            ? previewDataJson.gender
                            : previewDataJson.gender === '2'
                            ? 'หญิง'
                            : 'หญิง'
                        }
                      >
                        หญิง
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2" className="formLabel">
                    ชื่อจริง
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                      // readOnly: true
                    }}
                    value={
                      ifObjectBlank(previewDataJson)
                        ? IfKeyExits(previewDataJson.first_name_thai)
                        : ''
                    }
                    onChange={(e) => {
                      const prevObj = previewDataJson;
                      prevObj.first_name_thai = e.target.value;
                      setPreviewDataJson(prevObj);
                      setUpdateComp(new Date());
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2" className="formLabel">
                    นามสกุล
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                      // readOnly: true
                    }}
                    value={
                      ifObjectBlank(previewDataJson)
                        ? IfKeyExits(previewDataJson.last_name_thai)
                        : ''
                    }
                    onChange={(e) => {
                      const prevObj = previewDataJson;
                      prevObj.last_name_thai = e.target.value;
                      setPreviewDataJson(prevObj);
                      setUpdateComp(new Date());
                    }}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2" className="formLabel">
                    วันเกิด dob
                  </Typography>

                  <FormControl fullWidth className="thaiDatePiker">
                    <WatDatePicker
                      value={
                        ifObjectBlank(previewDataJson)
                          ? getDateFormate(previewDataJson.date_of_birth_eng)
                          : '1970-04-27'
                      }
                      onChange={(christDate, buddhistDate) => {
                        const prevObj = previewDataJson;
                        prevObj.date_of_birth_thai = buddhistDate;
                        prevObj.date_of_birth_eng = christDate;
                        setPreviewDataJson(prevObj);
                        setUpdateComp(new Date());
                      }}
                      dateFormat={'yyyy-MM-dd'} // for set data purpose
                      displayFormat={'dd, DD MMMM YYYY'} // for display purpose
                    />
                  </FormControl>
                </Grid> */}
                {/* <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2" className="formLabel">
                    วันหมดอายุของบัตรประชาชน
                  </Typography>
                  <FormControl fullWidth className="thaiDatePiker">
                    <WatDatePicker
                      value={
                        ifObjectBlank(previewDataJson)
                          ? getDateFormate(previewDataJson.date_of_expiry_eng)
                          : '1970-01-01'
                      }
                      onChange={(christDate, buddhistDate) => {
                        const prevObj = previewDataJson;
                        prevObj.date_of_expiry = buddhistDate;
                        prevObj.date_of_expiry_eng = christDate;
                        setPreviewDataJson(prevObj);
                        setUpdateComp(new Date());
                      }}
                      dateFormat={'yyyy-MM-dd'} // for set data purpose
                      displayFormat={'dd, DD MMMM YYYY'} // for display purpose
                    />
                  </FormControl>
                </Grid> */}
              </Grid>
            </DialogContent>
            <DialogActions textAlign="center">
              <Button
                onClick={(e) => {
                  handleClose(e, 'onClick');
                  fireSetCustomerDataApi(e);
                }}
                className="mainBtn"
              >
                แก้ไข
              </Button>
            </DialogActions>
          </Dialog>

          <DialogDecision
            openDialogDecision={openDialogDecision}
            dialogTitle="Decision"
            handleStatusChange={handleStatusChange}
            closeOpenDecisionDialog={closeOpenDecisionDialog}
          />
          <DialogMsg
            openDialogeMsg={openDialogeMsg}
            dialogMsg={dialogMsg}
            dialogTitle="Decision"
            buttonText={null}
            closeDialogMsgHandler={closeDialogMsgHandler}
          />
          {/*  GPS and Sim Information */}
          {/* <Grid container columnSpacing={3} mb={3}>
          <Grid item xs={6}>
            <Typography variant="body1" className="headingLineUp headingLineUpOut">
              ข้อมูล GPS ของผู้ลงทะเบียน
            </Typography>
            <Box
              className="cardOuter cardOuterGrayBorder cardNoHover cardTopMinusMargin"
              px={2}
              py={3}
              sx={{ height: 'calc(100% - 18px)' }}
            >
              <Box className="displayTable">
                <Box className="displayTableRow">
                  <Typography variant="body2" className="displayTableCell pb-05 w-30">
                    จังหวัด (Location)
                  </Typography>
                  <Typography variant="" className="displayTableCell textCollan pr-05 pl-05 pb-05">
                    :
                  </Typography>
                  <Typography variant="body2" className="displayTableCell pb-05">
                    <Typography variant="body2" className="displayFlex alignItemsCenter">
                      <strong>
                        {ifObjectBlank(previewData)
                          ? IfKeyExits(gpsString(previewData.gprAddress, 2))
                          : ''}
                      </strong>
                    </Typography>
                  </Typography>
                </Box>
                <Box className="displayTableRow">
                  <Typography variant="body2" className="displayTableCell pb-05 w-30">
                    อำเภอ (Location)
                  </Typography>
                  <Typography variant="" className="displayTableCell textCollan pr-05 pl-05 pb-05">
                    :
                  </Typography>
                  <Typography variant="body2" className="displayTableCell pb-05">
                    <Typography variant="body2" className="displayFlex alignItemsCenter">
                      <strong>
                        {ifObjectBlank(previewData)
                          ? IfKeyExits(gpsString(previewData.gprAddress, 1))
                          : ''}
                      </strong>
                    </Typography>
                  </Typography>
                </Box>
                <Box className="displayTableRow">
                  <Typography variant="body2" className="displayTableCell pb-05 w-30">
                    ตำบล (Location)
                  </Typography>
                  <Typography variant="" className="displayTableCell textCollan pr-05 pl-05 pb-05">
                    :
                  </Typography>
                  <Typography variant="body2" className="displayTableCell pb-05">
                    <Typography variant="body2" className="displayFlex alignItemsCenter">
                      <strong>
                        {ifObjectBlank(previewData)
                          ? IfKeyExits(gpsString(previewData.gprAddress, 0))
                          : ''}
                      </strong>
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body1" className="headingLineUp headingLineUpOut">
              ข้อมูลผู้ถือครองซิม
            </Typography>
            <Box
              className="cardOuter cardOuterGrayBorder cardNoHover cardTopMinusMargin"
              px={2}
              py={3}
              sx={{ height: 'calc(100% - 18px)' }}
            >
              <Box className="displayTable w-100">
                <Box className="displayTableRow">
                  <Typography variant="body2" className="displayTableCell pb-05 w-40">
                    เขต/ฝ่าย
                  </Typography>
                  <Typography variant="" className="displayTableCell textCollan pr-05 pl-05 pb-05">
                    :
                  </Typography>
                  <Typography variant="body2" className="displayTableCell pb-05">
                    <strong>
                      {ifObjectBlank(previewData) ? IfKeyExits(previewData.departmentDesc) : 'N/A'}
                    </strong>
                  </Typography>
                </Box>
                <Box className="displayTableRow">
                  <Typography variant="body2" className="displayTableCell pb-05 w-40">
                    สค./ส่วน
                  </Typography>
                  <Typography variant="" className="displayTableCell textCollan pr-05 pl-05 pb-05">
                    :
                  </Typography>
                  <Typography variant="body2" className="displayTableCell pb-05">
                    <strong>
                      {ifObjectBlank(previewData) ? IfKeyExits(previewData.divisionDesc) : 'N/A'}
                    </strong>
                  </Typography>
                </Box>
                <Box className="displayTableRow">
                  <Typography variant="body2" className="displayTableCell pb-05 w-40">
                    ตัวแทนขาย
                  </Typography>
                  <Typography variant="" className="displayTableCell textCollan pr-05 pl-05 pb-05">
                    :
                  </Typography>
                  <Typography variant="body2" className="displayTableCell pb-05">
                    <strong>
                      {ifObjectBlank(previewData) ? IfKeyExits(previewData.partnerName) : 'N/A'}
                    </strong>
                  </Typography>
                </Box>
                <Box className="displayTableRow">
                  <Typography variant="body2" className="displayTableCell pb-05 w-40">
                    สัญญา
                  </Typography>
                  <Typography variant="" className="displayTableCell textCollan pr-05 pl-05 pb-05">
                    :
                  </Typography>
                  <Typography variant="body2" className="displayTableCell pb-05">
                    <strong>
                      {ifObjectBlank(previewData) ? IfKeyExits(previewData.contractName) : 'N/A'}
                    </strong>
                  </Typography>
                </Box>
                <Box className="displayTableRow">
                  <Typography variant="body2" className="displayTableCell pb-05 w-40">
                    ชื่อพื้นที่การขาย
                  </Typography>
                  <Typography variant="" className="displayTableCell textCollan pr-05 pl-05 pb-05">
                    :
                  </Typography>
                  <Typography variant="body2" className="displayTableCell pb-05">
                    <strong>
                      {ifObjectBlank(previewData) ? IfKeyExits(previewData.saaName) : 'N/A'}
                    </strong>
                  </Typography>
                </Box>
                <Box className="displayTableRow">
                  <Typography variant="body2" className="displayTableCell pb-05 w-40">
                    Internal Sale Rep.
                  </Typography>
                  <Typography variant="" className="displayTableCell textCollan pr-05 pl-05 pb-05">
                    :
                  </Typography>
                  <Typography variant="body2" className="displayTableCell pb-05">
                    <strong>
                      {ifObjectBlank(previewData) ? IfKeyExits(previewData.saleEmpId) : 'N/A'}
                    </strong>
                  </Typography>
                </Box>
                <Box className="displayTableRow">
                  <Typography variant="body2" className="displayTableCell pb-05 w-40">
                    SAP Cost Center
                  </Typography>
                  <Typography variant="" className="displayTableCell textCollan pr-05 pl-05 pb-05">
                    :
                  </Typography>
                  <Typography variant="body2" className="displayTableCell pb-05">
                    <strong>
                      {ifObjectBlank(previewData) ? IfKeyExits(previewData.sapCode) : 'N/A'}
                    </strong>
                  </Typography>
                </Box>

                <Box className="displayTableRow">
                  <Typography variant="body2" className="displayTableCell pb-05 w-40">
                    จัดจำหน่ายโดย
                  </Typography>
                  <Typography variant="" className="displayTableCell textCollan pr-05 pl-05 pb-05">
                    :
                  </Typography>
                  <Typography variant="body2" className="displayTableCell pb-05">
                    <strong>
                      {ifObjectBlank(previewData)
                        ? previewData.partnerName
                          ? previewData.partnerName
                          : 'บริษัท โทรคมนาคมแห่งชาติ จำกัด (มหาชน)'
                        : ''}
                    </strong>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid> */}
          {/* Pictures and comparison results */}
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Other document
          </Typography>
          <Box
            className="cardOuter cardOuterGrayBorder cardNoHover cardTopMinusMargin"
            px={2}
            py={3}
            mb={3}
          >
            <Grid container spacing={3}>
              {/* <Grid item className="widthFix" xs="12" md="4">
                <Typography variant="body2" className="mb-1">
                  <strong>
                    {previewData.docType === 1
                      ? 'Aadhar photo'
                      : previewData.docType === 6
                      ? 'Other'
                      : 'Pan photo'}
                    *
                  </strong>
                </Typography>

                <Box position="relative">
                  <img
                    src={
                      docfieUrl === undefined
                        ? previewData.docType === 1
                          ? documentType.citizenCard
                          : previewData.docType === 6
                          ? documentType.alienCard
                          : previewData.docType === 5
                          ? documentType.passport
                          : ''
                        : docfieUrl
                    }
                    alt="profile"
                    className="profileImgMatch"
                  />
                </Box>
                <Box className="displayTable">
                  <Box className="displayTableRow">
                    <Typography variant="body2" className="displayTableCell pb-05">
                      Face matching
                    </Typography>
                    <Typography
                      variant=""
                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                    >
                      :
                    </Typography>
                    <Typography variant="body2" className="displayTableCell pb-05">
                      <Typography variant="body2" className="displayFlex alignItemsCenter">
                        <strong>corresponding</strong>
                      </Typography>
                    </Typography>
                  </Box>
                  <Box className="displayTableRow">
                    <Typography variant="body2" className="displayTableCell pb-05">
                      Comparison results
                    </Typography>
                    <Typography
                      variant=""
                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                    >
                      :
                    </Typography>
                    <Typography variant="body2" className="displayTableCell pb-05">
                      <Typography variant="body2" className="displayFlex alignItemsCenter">
                        <strong>
                          {ifObjectBlank(logDetailsData.application)
                            ? (Number(logDetailsData.application.confidenceScore) * 100).toFixed(
                                2
                              ) + '%'
                            : ''}
                        </strong>
                      </Typography>
                    </Typography>
                  </Box>
                  <Box className="displayTableRow">
                    <Typography variant="body2" className="displayTableCell pb-05">
                      Comparison criteria
                    </Typography>
                    <Typography
                      variant=""
                      className="displayTableCell textCollan pr-05 pl-05 pb-05"
                    >
                      :
                    </Typography>
                    <Typography variant="body2" className="displayTableCell pb-05">
                      <Typography variant="body2" className="displayFlex alignItemsCenter">
                        <strong>
                          {ifObjectBlank(previewData)
                            ? previewData.currentProfileConfig !== null
                              ? Number(
                                  JSON.parse(previewData.currentProfileConfig)[0].value
                                ).toFixed(2) + '%'
                              : ''
                            : ''}
                        </strong>
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              </Grid> */}
              {/* <Grid item className="widthFix" xs="12" md="4">
                <Typography variant="body2" className="mb-1">
                  <strong>Front-facing photo* </strong>
                </Typography>
                <Box position="relative">
                  <img src={idDocfieUrl} alt="profile" className="profileImgMatch" />
                </Box>
              </Grid> */}

              <Grid item className="widthFix" xs="12" md="4">
                <Typography variant="body2" className="mb-1">
                  <strong>Document with selfie*</strong>
                </Typography>
                <Box position="relative">
                  <img src={selfieDoc} alt="profile" className="profileImgMatch" />
                </Box>
              </Grid>
              <Grid item className="widthFix" xs="12" md="4">
                <Typography variant="body2" className="mb-1">
                  <strong>Non OCR document </strong>
                </Typography>
                <div style={{ display: 'flex' }}>
                  {otherDocument?.electricityBillUrl !== '' ? (
                    <GetDocumentVideoKyc
                      paramStr={customerDetailsData}
                      docUrl={otherDocument?.electricityBillUrl}
                      billOtherDocName="Electricity Bill"
                      setOpenLoader={setOpenLoader}
                    />
                  ) : (
                    <GetDocumentVideoKyc
                      paramStr={customerDetailsData}
                      docUrl={otherDocument?.telephoneBillUrl}
                      billOtherDocName="Telephone Bill"
                      setOpenLoader={setOpenLoader}
                    />
                  )}

                  <GetDocumentVideoKyc
                    paramStr={customerDetailsData}
                    docUrl={otherDocument?.customerSignUrl}
                    billOtherDocName="Customer Sign"
                    setOpenLoader={setOpenLoader}
                  />
                </div>
              </Grid>
            </Grid>
          </Box>

          {/* Document other non OCR */}
          <Box mb={6}>
            <Typography variant="body1" className="headingLineUp headingLineUpOut">
              Video KYC
            </Typography>

            <Box
              className="cardOuter cardOuterGrayBorder cardNoHover cardTopMinusMargin"
              px={2}
              py={3}
              mb={3}
            >
              <>
                {videoKycData?.recordingUrl ? (
                  <DisplayVideoUrl
                    videoUrl={videoKycData?.recordingUrl}
                    videoName="kycvideo.mp4"
                    openLoader={openLoader}
                    setOpenLoader={setOpenLoader}
                  />
                ) : (
                  ''
                )}

                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {videoKycData?.recordingImageArray &&
                    Object.keys(JSON.parse(videoKycData?.recordingImageArray))
                      .filter((item) => item?.includes('url'))
                      ?.map((key) => {
                        console.log('videoimage', key);
                        const docUrl = JSON.parse(videoKycData?.recordingImageArray)[key];

                        console.log('docUrl', docUrl);
                        return (
                          <div style={{ display: 'flex', margin: '10px' }}>
                            <GetDocumentVideoKyc
                              paramStr={customerDetailsData}
                              docUrl={docUrl}
                              billOtherDocName={key}
                              setOpenLoader={setOpenLoader}
                            />
                            {/* <GetDocumentVideoKyc
                              paramStr={customerDetailsData}
                              docUrl={docBackUrl}
                              billOtherDocName={key}
                              setOpenLoader={setOpenLoader}
                            /> */}
                          </div>
                        );
                      })}
                </div>
              </>
            </Box>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}
export default ProfileViewTL;

const ifObjectBlank = (obj) => {
  if (obj) {
    return Object.keys(obj).length > 0;
  }
  return false;
};

const IfKeyExits = (objkey) => {
  if (objkey) {
    return objkey;
  }
  return '';
};
const wordString = 'C9JV+8G6 Tha Luang, Mueang Phichit District, Phichit, Thailand';

const gpsString = (wordString, index) => {
  const strArray = wordString !== null ? wordString.split(',') : '';

  if (strArray[index]) {
    return strArray[index];
  }

  return '';
};

// const ThaiDatePicker = ({ dobPicker, setDobPicker, setCredential }) => {
//   const handleWatDatePickerChange = (christDate, buddhistDate) => {
//     setDobPicker(christDate);
//     setCredential((prevState) => ({
//       ...prevState,
//       dob: christDate.split('-').reverse().join('/')
//     }));
//   };
//   return (
//     <WatDatePicker
//       value={dobPicker} // Can be replace with string or dayjs object
//       onChange={(christDate, buddhistDate) => {
//         handleWatDatePickerChange(christDate, buddhistDate);
//       }}
//       dateFormat={'yyyy-MM-dd'} // for set data purpose
//       displayFormat={'dd, DD MMMM YYYY'} // for display purpose
//       maxDate={new Date()}
//       inputStyle={{
//         padding: 0,
//         paddingLeft: '1rem',
//         height: 40,
//         lineHeight: 40,
//         fontWeight: 600,
//         backgroundColor: '#fff',
//         width: '100%',
//         borderRadius: 10
//       }} // styles for input
//     />
//   );
// };
const getDateFormate = (date) => {
  if (date) {
    const splitDate = date.split('/');
    return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
  } else {
    return '1970-01-01';
  }
};

function GetDocumentByKeyField({
  paramStr,
  customerDetails,
  documentType,
  openLoader,
  setOpenLoader,
  panStatus
}) {
  console.log(
    'customerDetailscustomerDetails111',
    JSON.parse(customerDetails.paramValueJson),
    documentType
  );
  if (JSON.parse(paramStr)) {
    const parseData = JSON.parse(paramStr);
    console.log('customerDetailscustomerDetails2222', parseData);
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} md={4}>
          <Typography variant="body2" className="mb-1" sx={{ textTransform: 'capitalize' }}>
            <strong sx={{ textTransform: 'capitalize' }}>
              {parseData?.keyfield ? parseData?.keyfield.replace(/_/g, ' ') : ''} Details
            </strong>
            {/* doc_back_name */}
            {/* doc_back_url */}
          </Typography>
          <Box className="documentImgBox" position="relative">
            {parseData?.doc_url ? (
              <>
                {/* {JSON.stringify('doc url')} */}
                <DisplayDocImage
                  docUrl={parseData?.doc_url}
                  openLoader={openLoader}
                  setOpenLoader={setOpenLoader}
                />
                {parseData?.doc_back_url !== null && parseData?.doc_back_url !== '' && (
                  <DisplayDocImage
                    docUrl={parseData?.doc_back_url}
                    openLoader={openLoader}
                    setOpenLoader={setOpenLoader}
                  />
                )}
              </>
            ) : parseData.id_doc_url ? (
              <>
                {/* {JSON.stringify(' id doc url')} */}
                <DisplayDocImage
                  docUrl={parseData?.id_doc_url}
                  openLoader={openLoader}
                  setOpenLoader={setOpenLoader}
                />
              </>
            ) : (
              <img className="documentImg" src={documentType.citizenCard} alt="Document view " />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={8}>
          <Typography variant="body2" className="mb-1">
            <strong>Document information</strong>
          </Typography>
          <Box className="displayTable w-100">
            <Box className="displayTableRow">
              <Typography
                variant="body2"
                className="displayTableCell pb-05 w-40"
                sx={{ textTransform: 'capitalize' }}
              >
                {parseData?.keyfield ? parseData?.keyfield.replace(/_/g, ' ') : ''}
              </Typography>
              <Typography variant="" className="displayTableCell textCollan pr-05 pl-05 pb-05">
                :
              </Typography>
              <Typography variant="body2" className="displayTableCell pb-05">
                <strong>{parseData[parseData?.keyfield]}</strong>
              </Typography>
            </Box>
            <Box className="displayTableRow">
              <Typography
                variant="body2"
                className="displayTableCell pb-05 w-40"
                sx={{ textTransform: 'capitalize' }}
              >
                {parseData?.keyfield
                  ? parseData?.keyfield.replace(/_number/g, ' ').replace(/_/g, ' ')
                  : ''}{' '}
                verification
              </Typography>
              <Typography variant="" className="displayTableCell textCollan pr-05 pl-05 pb-05">
                :
              </Typography>
              <Typography variant="body2" className="displayTableCell pb-05">
                <strong>
                  {!parseData?.keyfield?.includes('pan') ? parseData?.aadharStatus : panStatus}
                </strong>
              </Typography>
              {/* <Typography variant="body2" className="displayTableCell pb-05">
                <strong>{parseData[parseData?.keyfield]}</strong>
              </Typography> */}
            </Box>
            {parseData.gender && parseData.gender !== '' && (
              <Box className="displayTableRow">
                <Typography variant="body2" className="displayTableCell pb-05 w-40">
                  Gender
                </Typography>
                <Typography variant="" className="displayTableCell textCollan pr-05 pl-05 pb-05">
                  :
                </Typography>
                <Typography variant="body2" className="displayTableCell pb-05">
                  <strong>{parseData.gender}</strong>
                </Typography>
              </Box>
            )}
            <Box className="displayTableRow">
              <Typography variant="body2" className="displayTableCell pb-05 w-40">
                Full name
              </Typography>
              <Typography variant="" className="displayTableCell textCollan pr-05 pl-05 pb-05">
                :
              </Typography>
              <Typography variant="body2" className="displayTableCell pb-05">
                <strong>{parseData.name}</strong>
              </Typography>
            </Box>
            {parseData.date_of_birth !== '' && (
              <Box className="displayTableRow">
                <Typography variant="body2" className="displayTableCell pb-05 w-40">
                  DOB
                </Typography>
                <Typography variant="" className="displayTableCell textCollan pr-05 pl-05 pb-05">
                  :
                </Typography>
                <Typography variant="body2" className="displayTableCell pb-05">
                  <strong>{parseData.date_of_birth}</strong>
                </Typography>
              </Box>
            )}
            {/* {parseData.father_name && parseData.father_name !== "" && (
              <Box className="displayTableRow">
                <Typography
                  variant="body2"
                  className="displayTableCell pb-05 w-40"
                >
                  Father name
                </Typography>
                <Typography
                  variant=""
                  className="displayTableCell textCollan pr-05 pl-05 pb-05"
                >
                  :
                </Typography>
                <Typography variant="body2" className="displayTableCell pb-05">
                  <strong>{parseData.father_name}</strong>
                </Typography>
              </Box>
            )} */}
            {!parseData?.keyfield?.includes('pan') && (
              <Box className="displayTableRow">
                <Typography variant="body2" className="displayTableCell pb-05 w-40">
                  Address
                </Typography>
                <Typography variant="" className="displayTableCell textCollan pr-05 pl-05 pb-05">
                  :
                </Typography>
                <Typography variant="body2" className="displayTableCell pb-05">
                  <strong>{parseData?.address}</strong>
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    );
  }
  return '';
}
function GetDocumentByOtherNonOcr({ customerDetails, billOtherDoc, billOtherDocName }) {
  const [documentPreview, setDocumentPreview] = useState(false);
  console.log('customerDetailscustomerDetails', customerDetails);

  // if (JSON.parse(paramStr)) {
  // const parseData = JSON.parse(paramStr);
  // console.log('customerDetailscustomerDetails', parseData);
  return (
    <>
      <Box className="displayFlex flexWrap">
        <Fragment key={`allfiles`}>
          <Box
            className="uploadFilePreview uploadFilePreviewGreen"
            onClick={(e) => {
              // setSelectedFile(t.file);
              // setFileUrl(t.fileUrl);
              // setAddAttachment(false);
              setDocumentPreview(true);
            }}
            style={{ position: 'relative', height: '145px' }}
          >
            {/* <MdDelete
              className="removeIcon"
              size={20}
              style={{ position: 'absolute', top: -8, right: -8, color: 'red' }}
              // onClick={(e) => {
              //   e.stopPropagation(); // Stopping propagating two events
              //   setConfirmCircularDelete(t.uploadId);
              // }}
            /> */}
            <MdRemoveRedEye className="ImageViewIcon" />
            <Tooltip title={billOtherDocName}>
              <Typography
                variant="body2"
                className="docName"
                style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
              >
                {/* <img
                  src={billOtherDoc}
                  alt="Selected file"
                  style={{
                    position: "absolute",
                    top: "82px",
                    overflow: "hidden",
                    left: "50%",
                    zIndex: "0",
                    width: "54px",
                    marginLeft: "-27px",
                  }}
                /> */}
              </Typography>
            </Tooltip>
            <Typography style={{ fontSize: '12px' }}>{billOtherDocName} Click to see</Typography>
          </Box>

          <Dialog
            open={documentPreview}
            onClose={(e) => setDocumentPreview(false)}
            fullWidth
            className="PopupBox"
          >
            <DialogTitle className="popupTitle">
              <Typography variant="h6" className="">
                Preview
              </Typography>
              <IconButton onClick={(e) => setDocumentPreview(false)} size="small">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <img src={billOtherDoc} alt="Selected file" />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(e) => setDocumentPreview(false)}
                variant="contained"
                className="mainBtn"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      </Box>
    </>
  );
  // }
  // return '';
}

function GetDocumentVideoKyc({
  customerDetails,
  docUrl,
  billOtherDocName,
  setOpenLoader,
  openLoader
}) {
  const [documentPreview, setDocumentPreview] = useState(false);
  console.log('customerDetailscustomerDetails', customerDetails);

  // if (JSON.parse(paramStr)) {
  // const parseData = JSON.parse(paramStr);
  // console.log('customerDetailscustomerDetails', parseData);
  return (
    <div style={{ display: 'flex' }}>
      <Box className="displayFlex flexWrap">
        <Fragment key={`allfiles`}>
          <Box
            className="uploadFilePreview uploadFilePreviewGreen"
            onClick={(e) => {
              // setSelectedFile(t.file);
              // setFileUrl(t.fileUrl);
              // setAddAttachment(false);
              setDocumentPreview(true);
            }}
            style={{ position: 'relative', height: '145px' }}
          >
            {/* <MdDelete
              className="removeIcon"
              size={20}
              style={{ position: 'absolute', top: -8, right: -8, color: 'red' }}
              // onClick={(e) => {
              //   e.stopPropagation(); // Stopping propagating two events
              //   setConfirmCircularDelete(t.uploadId);
              // }}
            /> */}
            {/* <Typography variant="h6" className="">
              {billOtherDocName}
            </Typography> */}
            <MdRemoveRedEye className="ImageViewIcon" />
            <Tooltip title={billOtherDocName}>
              <Typography
                variant="body2"
                className="docName"
                style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
              >
                {/* <img
                  src={docUrl}
                  // alt="Selected file"
                  style={{
                    position: "absolute",
                    top: "82px",
                    overflow: "hidden",
                    left: "50%",
                    zIndex: "0",
                    width: "54px",
                    marginLeft: "-27px",
                  }}
                /> */}
              </Typography>
            </Tooltip>
            <Typography style={{ fontSize: '12px' }}>Click to see</Typography>
          </Box>

          <Dialog
            open={documentPreview}
            onClose={(e) => setDocumentPreview(false)}
            fullWidth
            className="PopupBox"
          >
            <DialogTitle className="popupTitle">
              <Typography variant="h6" className="">
                Preview
              </Typography>
              <IconButton onClick={(e) => setDocumentPreview(false)} size="small">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DisplayDocImage
              docUrl={docUrl}
              openLoader={openLoader}
              setOpenLoader={setOpenLoader}
            />
            <DialogActions>
              <Button
                onClick={(e) => setDocumentPreview(false)}
                variant="contained"
                className="mainBtn"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      </Box>
    </div>
  );
  // }
  // return '';
}

function getImageWithUrl(imgUrl) {
  return fetch(`${globalAdminUrl}/onboarding-admin/getImage`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      userId: userDataFromLocal()?.userId,
      imageURL: imgUrl,
      sessionId: userDataFromLocal()?.sessionId
    }
  })
    .then(async (response) => {
      if (response.ok) {
        return await response.blob();
      }
      return '';
    })
    .then(async (imageBlob) => {
      // // // Then create a local URL for that image and print it
      // console.log('imageObjectURL', imageBlob);
      // const imageObjectURL = URL.createObjectURL(imageBlob);
      // console.log('imageObjectURL', imageObjectURL);
      const base64 = await blobToBase64(imageBlob);
      console.log('base64base64', base64);
      return base64;
    });
}
function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      console.log('reader.result', reader.result);
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
}

function DisplayDocImage({ docUrl, setOpenLoader, openLoader }) {
  const [imgUrl, setImgUrl] = useState('');

  useEffect(() => {
    (async () => {
      {
        try {
          setOpenLoader(true);
          if (docUrl !== undefined && docUrl !== null && docUrl !== '') {
            console.log('responseresponseresponse', docUrl);
            const gettingResponse = fetch(`${globalAdminUrl}/onboarding-admin/getImage`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                userId: userDataFromLocal()?.userId,
                imageURL: docUrl,
                sessionId: userDataFromLocal()?.sessionId
              }
            })
              .then(async (response) => {
                if (response.ok) {
                  setOpenLoader(false);
                  return await response.blob();
                }
                return '';
              })
              .then(async (imageBlob) => {
                // // // Then create a local URL for that image and print it
                // console.log('imageObjectURL', imageBlob);
                // const imageObjectURL = URL.createObjectURL(imageBlob);
                // console.log('imageObjectURL', imageObjectURL);

                const base64 = await blobToBase64(imageBlob);
                console.log('base64base64', base64);

                setImgUrl(base64);
                setOpenLoader(false);
              })
              .catch((error) => {
                console.log('having error', error);
                setOpenLoader(false);
              });
          } else {
            console.error('docUrl not fount');
            setOpenLoader(false);
          }
        } catch (error) {
          console.log('errorerror', error);
        }
      }
    })();
  }, []);
  return imgUrl ? (
    <>
      <img className="documentImg" src={imgUrl} alt="Document view From Api" />
      <Loader openLoader={openLoader} setOpenLoader={setOpenLoader} />
    </>
  ) : (
    <></>
  );
}

function DisplayVideoUrl({ videoUrl, videoName, setOpenLoader, openLoader }) {
  const [vidUrl, setVidUrl] = useState('');
  const handleDownload = () => {
    {
      try {
        if (videoUrl !== undefined && videoUrl !== null && videoUrl !== '') {
          console.log('responseresponseresponse', videoUrl);
          const appNum = localStorage.getItem('applicationNumber1');
          setOpenLoader(true);
          const gettingResponse = fetch(`${globalAdminUrl}/onboarding-admin/getVKYCRecording`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              userId: userDataFromLocal()?.userId,

              recordingURL: videoUrl,
              applicaitonNo: appNum,
              sessionId: userDataFromLocal()?.sessionId
            }
          })
            .then(async (response) => {
              if (response.ok) {
                const blob = await response.blob();
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'video.mp4'; // Specify the desired file name here
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setOpenLoader(false);
              }
              return '';
            })
            .then(async (imageBlob) => {
              // // // Then create a local URL for that image and print it
              // console.log('imageObjectURL', imageBlob);
              // const imageObjectURL = URL.createObjectURL(imageBlob);
              // console.log('imageObjectURL', imageObjectURL);
              // const blob = await imageBlob.blob();
              // const link = document.createElement("a");
              // link.href = window.URL.createObjectURL(blob);
              // link.download = "video.mp4"; // Specify the desired file name here
              // document.body.appendChild(link);
              // link.click();
              // document.body.removeChild(link);
              // setVidUrl(base64);
            })
            .catch((error) => {
              console.log('having error', error);
            });
        } else {
          console.error('docUrl not fount');
        }
      } catch (error) {
        console.log('errorerror', error);
      }
    }
  };
  return (
    <div style={{ marginLeft: '1000px' }}>
      <Tooltip title="Download Video Recording">
        <IconButton style={{ color: '#000', marginBottom: '5px' }} onClick={() => handleDownload()}>
          <DownloadIcon />
        </IconButton>
      </Tooltip>
      <Loader openLoader={openLoader} setOpenLoader={setOpenLoader} />
    </div>
  );
}

const VideoDownloadComponent = ({ videoUrl, videoName }) => {
  const handleDownload = async () => {
    try {
      const response = await fetch(
        'https://ekyc-admin.kalolytic.com/onboarding-admin/ApplicationRecording20240109/c2c3f218fe42cdd39d9d128b60c07932_e35c5ecf-d437-4ecd-a1c2-33f0abf9383e_1704805837214_0.mp4',
        {
          headers: {
            'Content-Type': 'video/mp4'
            // You can add other headers here if needed
          }
        }
      );
      const blob = await response.blob();

      // Create a download link
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'video.mp4'; // Specify the desired file name here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the video:', error);
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <h3>
        Download Video Recording
        <IconButton style={{ color: '#000', marginBottom: '5px' }} onClick={() => handleDownload()}>
          <DownloadIcon />
        </IconButton>
      </h3>
    </div>
  );
};

const VideoPlayer = ({ videoUrl }) => {
  return (
    <div>
      <h1>Video Player</h1>
      <video controls width="600" height="400">
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
