import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { domainUrl } from "../services/globalApi";

function ApiErrorMessage({
  openApiError,
  setOpenApiError,
  errorMessage,
  sessionTimeout,
  sanctioned,
  login,
  setRefershState,
  success,
  sentUrl,
  successTrue,
  setSuccessTrue,
  addSuccess,
  setRefreshComp,
  userTokenExpire
}) {
  const navigate = useNavigate();
  // const myState = useSelector((state) => state?.disburseReducer);
  // const myDispatch = useDispatch();
  const handleClose = () => {
    if (success === 'true') {
      setOpenApiError(false);
      navigate(`/cards?u${sentUrl}`);
    }
    if (addSuccess === 'success') {
      setOpenApiError(false);
      setRefreshComp(new Date());
    }
    // if (sessionTimeout === "sessiontimeout") {
    //   window.open(`${domainUrl}`, `_self`);
    // }
    if (userTokenExpire === 'true') {
      localStorage.clear();
      navigate('/');
    }

    if (sanctioned === 'sanctioned') {
      window.location.reload();
    }
    if (login === 'login') {
      setRefershState(true);
    }
    // if (myState?.errorMsg !== null) {
    //   myDispatch({
    //     type: "ERROR_MESSAGE",
    //     payload: { data: null, loader: false },
    //   });
    // }
    else {
      setOpenApiError(false);
    }
  };
  return (
    <Box>
      <Dialog
        open={openApiError}
        onClose={(e) => {
          setOpenApiError(false);
          // if (myState?.errorMsg) {
          //   myDispatch({
          //     type: "ERROR_MESSAGE",
          //     payload: { data: null, loader: false },
          //   });
          // }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => handleClose(e)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
export default ApiErrorMessage;
