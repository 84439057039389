import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
// material
import {
  Stack,
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton
} from '@mui/material';
import { Box } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CloseIcon from '@mui/icons-material/Close';
import add from '@iconify/icons-ic/add';
// components
import Page from '../Page';
import BrudCrumbs from '../BreadCrumbs';
import PageTitle from '../PageHeading';
import dummyImg from './dummyImg.jpg';

export default function UpdateApproved() {
  const navigate = useNavigate();
  const [selectGender, setSelectGender] = useState(' ');
  const [selectMaritalStatus, setSelectMaritalStatus] = useState(' ');
  const [selectResidentialStatus, setSelectResidentialStatus] = useState(' ');
  const [selectOccupation, setSelectOccupation] = useState(' ');
  const [documentPreview, setDocumentPreview] = useState(false);
  const [documentUpload, setDocumentUpload] = useState(false);
  const [signUpload, setSignUpload] = useState(false);
  const [selectYourDecision, setSelectYourDecision] = useState(' ');

  return (
    <Page title="Update Approved || Onboaring">
      <Container maxWidth={false}>
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Update Approved" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Basic Details
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
            <Grid spacing={2} container mb={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="formLabel">
                  Name
                </Typography>
                <TextField
                  inputProps={{ className: 'textInput' }}
                  fullWidth
                  placeholder="Type Name"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="formLabel">
                  Father Name
                </Typography>
                <TextField
                  inputProps={{ className: 'textInput' }}
                  fullWidth
                  placeholder="Type Father Name"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="formLabel">
                  Mother Name
                </Typography>
                <TextField
                  inputProps={{ className: 'textInput' }}
                  fullWidth
                  placeholder="Type Mother Name"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="formLabel">
                  Spouse Name
                </Typography>
                <TextField
                  inputProps={{ className: 'textInput' }}
                  fullWidth
                  placeholder="Type Spouse Name"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="formLabel">
                  Date of Birth
                </Typography>
                <TextField
                  inputProps={{ className: 'textInput' }}
                  fullWidth
                  placeholder="Select DOB"
                  type="text"
                  onFocus={(e) => (e.target.type = 'date')}
                  onBlur={(e) => (e.target.type = 'text')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="formLabel">
                  Gender
                </Typography>
                <Select
                  fullWidth
                  inputProps={{
                    className: `textInput dissabledMenu${selectGender}`
                  }}
                  value={selectGender}
                  onChange={(e) => setSelectGender(e.target.value)}
                >
                  <MenuItem value=" " disabled>
                    Select Gender
                  </MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="formLabel">
                  Marital Status
                </Typography>
                <Select
                  fullWidth
                  inputProps={{
                    className: `textInput dissabledMenu${selectMaritalStatus}`
                  }}
                  value={selectMaritalStatus}
                  onChange={(e) => setSelectMaritalStatus(e.target.value)}
                >
                  <MenuItem value=" " disabled>
                    Select Marital Status
                  </MenuItem>
                  <MenuItem value="Married">Married</MenuItem>
                  <MenuItem value="Unmarried">Unmarried</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="formLabel">
                  Residential Status
                </Typography>
                <Select
                  fullWidth
                  inputProps={{
                    className: `textInput dissabledMenu${selectResidentialStatus}`
                  }}
                  value={selectResidentialStatus}
                  onChange={(e) => setSelectResidentialStatus(e.target.value)}
                >
                  <MenuItem value=" " disabled>
                    Select Residential Status
                  </MenuItem>
                  <MenuItem value="Resident Individual">Resident Individual</MenuItem>
                  <MenuItem value="Non Resident Indian">Non Resident Indian</MenuItem>
                  <MenuItem value="Foreign National">Foreign National</MenuItem>
                  <MenuItem value="Person of Indian Origin">Person of Indian Origin</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="formLabel">
                  Occupation
                </Typography>
                <Select
                  fullWidth
                  inputProps={{
                    className: `textInput dissabledMenu${selectOccupation}`
                  }}
                  value={selectOccupation}
                  onChange={(e) => setSelectOccupation(e.target.value)}
                >
                  <MenuItem value=" " disabled>
                    Select Occupation
                  </MenuItem>
                  <MenuItem value="Business">Business</MenuItem>
                  <MenuItem value="Salaried">Salaried</MenuItem>
                  <MenuItem value="Self Employed">Self Employed</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="formLabel">
                  Mobile Number
                </Typography>
                <TextField
                  inputProps={{ className: 'textInput' }}
                  fullWidth
                  placeholder="Type Mobile Number"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="formLabel">
                  Email ID
                </Typography>
                <TextField
                  inputProps={{ className: 'textInput' }}
                  fullWidth
                  placeholder="Type Email ID"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="formLabel">
                  Residential Address
                </Typography>
                <TextField
                  inputProps={{ className: 'textInput' }}
                  fullWidth
                  placeholder="Type Residential Address"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="formLabel">
                  Business Address
                </Typography>
                <TextField
                  inputProps={{ className: 'textInput' }}
                  fullWidth
                  placeholder="Type Business Address"
                />
              </Grid>
            </Grid>
          </Box>
          <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Document Upload
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs>
                <Box className="displayFlex flexWrap">
                  <Box
                    className="uploadFilePreview uploadFilePreviewGreen"
                    onClick={(e) => setDocumentPreview(true)}
                  >
                    <RemoveRedEyeIcon className="ImageViewIcon" />
                    <Typography variant="body2" className="docName">
                      Address Proof
                    </Typography>
                  </Box>
                  <Box
                    className="uploadFilePreview uploadFilePreviewGreen"
                    onClick={(e) => setDocumentPreview(true)}
                  >
                    <RemoveRedEyeIcon className="ImageViewIcon" />
                    <Typography variant="body2" className="docName">
                      Identity Proof
                    </Typography>
                  </Box>
                </Box>
                {/* document preview popup */}
                <Dialog
                  open={documentPreview}
                  onClose={(e) => setDocumentPreview(false)}
                  fullWidth
                  className="PopupBox"
                >
                  <DialogTitle className="popupTitle">
                    <Typography variant="h6" className="">
                      Preview
                    </Typography>
                    <IconButton onClick={(e) => setDocumentPreview(false)} size="small">
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent dividers>
                    <img src={dummyImg} alt="Dummy" className="" />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={(e) => setDocumentPreview(false)}
                      variant="contained"
                      className="mainBtn"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid xs="auto" item sx={{ flexGrow: '1' }}>
                <Button
                  variant="contained"
                  onClick={(e) => setDocumentUpload(true)}
                  className="addBtn addBtnSec"
                >
                  <Icon icon={add} width={32} />
                  <Typography variant="h6">Add</Typography>
                </Button>
                {/* upload popup */}
                <Dialog
                  open={documentUpload}
                  className="PopupBox"
                  onClose={(e) => setDocumentUpload(false)}
                  fullWidth
                >
                  <DialogTitle className="popupTitle">
                    <Typography variant="h6" className="">
                      Add Document
                    </Typography>
                    <IconButton onClick={(e) => setDocumentUpload(false)} size="small">
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent dividers>
                    <Typography variant="body2" className="formLabel">
                      Name of The Document
                    </Typography>
                    <TextField
                      fullWidth
                      placeholder="Type Name of The Document"
                      inputProps={{ className: 'textInput' }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      component="label"
                      className="mainBtn"
                      onClick={(e) => setDocumentUpload(false)}
                    >
                      Upload Document
                      <input hidden accept="image/*" multiple type="file" />
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" className="headingLineUp headingLineUpOut">
                Signature
              </Typography>
              <Box className="viewLeadCard rightCard cardTopMinusMargin">
                <Box
                  className="signBox"
                  onClick={(e) => setSignUpload(true)}
                  sx={{ cursor: 'pointer' }}
                >
                  <Typography variant="subtitle1">Please Sign Here</Typography>
                </Box>
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <Typography variant="body1" className="headingLineUp headingLineUpOut">
                Signature
              </Typography>
              <Box className="viewLeadCard rightCard cardTopMinusMargin">
                <Box className="signBox">
                  <img src={SignDummy} alt="" height="60px" />
                </Box>
                <Box
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  mb={0.5}
                >
                  <Typography variant="caption" className="sanctionedSign" />
                  <Typography variant="body2" textAlign="right">
                    20 Feb, 2023
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body2" className="colorBlue">
                    <strong>Rohit Sharma</strong>
                  </Typography>
                  <Typography variant="body2">Credit Manager</Typography>
                </Box>
              </Box>
            </Grid> */}
          </Grid>

          {/* Sign popup start */}
          <Dialog open={signUpload} onClose={(e) => setSignUpload(false)} fullWidth>
            <DialogTitle className="popupTitle">
              <Typography variant="h6">Approve the Proposal</Typography>
              <IconButton onClick={(e) => setSignUpload(false)} size="small">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <Box mb={2}>
                <Typography variant="h6" className="formLabel">
                  Your Name
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Type Your Name"
                  inputProps={{ className: 'textInput' }}
                />
              </Box>
              <Box mb={2}>
                <Typography variant="h6" className="formLabel">
                  Font Type
                </Typography>
                <Select
                  fullWidth
                  inputProps={{
                    className: `textInput customSelectBox dissabledMenu${selectYourDecision}`
                  }}
                  value={selectYourDecision}
                  onChange={(e) => setSelectYourDecision(e.target.value)}
                >
                  <MenuItem value=" " disabled>
                    Select Font Type
                  </MenuItem>
                  <MenuItem value="Arial">Arial</MenuItem>
                  <MenuItem value="Open Sans">Open Sans</MenuItem>
                  <MenuItem value="Helvetica">Helvetica</MenuItem>
                  <MenuItem value="Georgia">Georgia</MenuItem>
                </Select>
              </Box>
              <Button className="mainBtn otherBtn">Sign</Button>
              <Box className="signBox" mt={2} />
            </DialogContent>
            <DialogActions>
              <Button onClick={(e) => setSignUpload(false)} variant="contained" className="mainBtn">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
          {/* Sign popup end */}
          <Box className="textAlignRight">
            <Button className="mainBtn" onClick={(e) => navigate('/kyc')}>
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}
