import globalServices from '../../services/globalServices';
import { userDataFromLocal } from './getUserDetails';
import CryptoJS from 'crypto-js';
import { useState } from 'react';

const { GetRolesGroup, RefreshToken } = globalServices;

const REACT_APP_BASE_ADMIN_URL = process.env.REACT_APP_BASE_ADMIN_URL;
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

export async function getAccessdata(pageId) {
  const userDetils = userDataFromLocal();
  // check for interval id frist in local storage
  // if (localStorage.getItem('intervalId') !== undefined) {
  //   // setTimeout(() => {
  //   clearInterval(localStorage.getItem('intervalId'));
  //   localStorage.removeItem('intervalId');
  //   // },);
  // }

  console.log('userDetilsuserDetilsAccess3', userDetils);

  // // //fire refresh api token if time is more then expiresTime
  // // console.log('inside setinterval');
  // const RefreshTokenCredentials = {
  //   expiredToken: userDataFromLocal().sessionId,
  //   userId: userDataFromLocal().userId,
  //   organizationId: userDataFromLocal().organizationId
  // };

  // const RefreshTokenApi = async () => {
  //   const RefreshTokenApiResponse = await RefreshToken(RefreshTokenCredentials);
  //   if (RefreshTokenApiResponse.code === 100) {
  //     console.log('userDetilsuserDetilsAccess3 entering ');
  //     // set new token then update localStorage
  //     userDetils.sessionId = RefreshTokenApiResponse.data.sessionId;
  //     const cipherText = CryptoJS.AES.encrypt(
  //       JSON.stringify(userDetils),
  //       'adminUserData'
  //     ).toString();
  //     localStorage.setItem('adminUserDataValue', cipherText);
  //   }
  // };

  // const intervalId = setInterval(() => {
  //   const currentTime = new Date();
  //   const userSesion = new Date(userDetils.sessionStart);
  //   const diffMs = userSesion - currentTime; // milliseconds between currentTime & userSesion
  //   const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
  //   let totalSecSpend = -Number(diffMins);
  //   const compareMin = 2;

  //   const userexpiresTime = new Date(userDetils.expiresTime).getTime() - 300000;
  //   console.log('userexpiresTime', Number(totalSecSpend), Number(compareMin));
  //   console.log('userexpiresTime', Number(currentTime.getTime()) > Number(userexpiresTime));
  //   console.log('userexpiresTime', Number(currentTime.getTime()), Number(userexpiresTime));

  //   if (currentTime.getTime() > userexpiresTime) {
  //     RefreshTokenApi();
  //     totalSecSpend = 0;
  //     console.log('wqeweew');
  //   }
  //   if (Number(totalSecSpend) > Number(compareMin)) {
  //     // clear localStroage and navigate to login screen
  //     if (userDetils.userTypeId == 2) {
  //       localStorage.removeItem('adminUserDataValue');
  //       window.location = `${REACT_APP_BASE_URL}/external/?session=true`;
  //     } else {
  //       localStorage.removeItem('adminUserDataValue');
  //       window.location = `${REACT_APP_BASE_URL}/${REACT_APP_BASE_ADMIN_URL}/?session=true`;
  //     }
  //   }

  //   // else {
  //   //   // update time from start if this function call
  //   //   userDetils.sessionStart = new Date();
  //   //   const cipherText = CryptoJS.AES.encrypt(
  //   //     JSON.stringify(userDetils),
  //   //     'adminUserData'
  //   //   ).toString();
  //   //   localStorage.setItem('adminUserDataValue', cipherText);
  //   // }
  // }, 2000);

  // localStorage.setItem('intervalId', intervalId);
  // console.log('intervalid', intervalId);

  // (Object.keys(userDetils).length > 0) code will be visible if api is ok
  // if (Object.keys(userDetils).length > 0) {
  const authToken1 = {
    userId: 1,
    roleId: 1108
    // userId: userDetils.userId,
    // roleId: userDetils.menuPageRoleMapping.roleId
  };
  console.log('userDetilsuserDetilsAccess', authToken1);
  const getRolesGroupApi = await GetRolesGroup(authToken1);
  console.log('userDetilsuserDetilsAccess', getRolesGroupApi);
  if (getRolesGroupApi.code === 100) {
    const filtredData = getRolesGroupApi.data.filter((menu) => menu.id === pageId);
    console.log('userDetilsuserDetilsAccess', filtredData);
    return filtredData[0];
  }
  return [];
  // }
  return [];
}

export async function getAccessAlldata() {
  const userDetils = userDataFromLocal();

  const authToken1 = {
    userId: userDetils.userId,
    roleId: userDetils.menuPageRoleMapping.roleId
  };
  console.log('userDetilsuserDetilsAccess', authToken1);
  const getRolesGroupApi = await GetRolesGroup(authToken1);
  console.log('userexpire sTime', getRolesGroupApi);
  if (getRolesGroupApi.code === 100) {
    console.log();
    // const newTime = userDataFromLocal();
    // console.log('comparingTime', new Date(newTime.sessionStart).getTime());
    // userDataFromLocal().sessionStart = new Date();
    // const cipherText = CryptoJS.AES.encrypt(JSON.stringify(userDetils), 'adminUserData').toString();
    // localStorage.setItem('adminUserDataValue', cipherText);
    // console.log('userDetilsuserDetilsAccess', filtredData);
    return getRolesGroupApi.data;
  }
  return [];
}
