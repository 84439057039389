import { Icon } from '@iconify/react';
import { CSVLink } from 'react-csv';
import searchFill from '@iconify/icons-eva/search-fill';

import { MdFilterAlt } from 'react-icons/md';
import { FaFileExport } from 'react-icons/fa';
// import export from '@iconify/icons-ic/export';
// material
import { styled } from '@mui/material/styles';
import { Box, Toolbar, Tooltip, IconButton, OutlinedInput, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// styles
import { RootStyle, SearchStyle } from '../utils/Styling';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function TransactionLogsToolbar({
  numSelected,
  filterName,
  onFilterName,
  parent,
  setDrawOpen,
  csvReportObj,
  submenuAccess
}) {
  const navigate = useNavigate();

  return (
    <RootStyle
      sx={{
        // minHeight: 45,
        borderBottom: '1px solid #eaedf1'
      }}
    >
      <Box>
        {/* <Tooltip title="Export Data" placement="top" arrow className="mr-1">
          <IconButton
            className="squareIconButton exportIcon"
            disabled={
              Object.keys(submenuAccess).length > 0
                ? submenuAccess.menuPageList.filter(
                    (menuItem) => menuItem.menuPageCode.toLowerCase() === 'Export'.toLowerCase()
                  ).length > 0
                  ? submenuAccess.menuPageList.filter(
                      (menuItem) => menuItem.menuPageCode.toLowerCase() === 'Export'.toLowerCase()
                    )[0].isActive === 0
                  : false
                : false
            }
          >
            <CSVLink
              {...csvReportObj}
              className="csvLink"
              style={{ lineHeight: 0.8, color: 'inherit' }}
            >
              <FaFileExport size={15} />
            </CSVLink>
          </IconButton>
        </Tooltip> */}
        <Tooltip title="Apply Filter" placement="top" arrow>
          <IconButton className="squareIconButton" onClick={(e) => setDrawOpen(true)}>
            <MdFilterAlt size={18} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder="
          Search by document number"
          classes={{
            root: 'searchHolder',
            input: 'searchInput'
          }}
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      </Box>
    </RootStyle>
  );
}
