import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, Box } from '@mui/material';
// layouts
import AuthLayout from './layouts/AuthLayout';
// components
import Page from './Page';
import { MHidden } from './@material-extend';
import { LoginForm } from './authentication/login';

const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_ADMIN_IMAGE_URL = process.env.REACT_APP_BASE_ADMIN_IMAGE_URL;

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    background: '#F4F8F4',
    height: '100vh'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  // minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 0)
}));

// ----------------------------------------------------------------------

Login.propTypes = {
  sx: PropTypes.object
};

export default function Login({ sx }) {
  return (
    <RootStyle title="Login | Admin">
      <Container maxWidth="sm">
        {/* sx={{ overflow: 'auto' }} */}
        <ContentStyle className="loginBox">
          <Stack sx={{ mb: 3 }}>
            <Box
              component="img"
              // src="../static/logo/main-logo.svg"
              src="/static/logo/main-logo.svg"
              sx={{ height: 40, ...sx }}
              mb={3}
            />
            <Typography variant="h4" gutterBottom>
              Login
            </Typography>
            {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Don't have an account? Sign
            </Typography> */}
          </Stack>
          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
