import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
function Validation({
  openValidation,
  validationMsg,
  handleClose,
  dialogTitle,
  setOpenValidation,
  setDisableButton,
  buttonText
}) {
  const { t } = useTranslation();
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={openValidation}
      onClose={handleClose}
      classes={{
        paper: 'popUpOuter'
      }}
    >
      <DialogTitle className="dialog-title" style={{ textAlign: 'center' }}>
        <Typography variant="subtitle1">{dialogTitle}</Typography>
        <IconButton
          onClick={(e) => handleClose()}
          size="small"
          style={{ position: 'absolute', right: '10px', top: '5px' }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers className="popUpContentB">
        <Typography variant="body1" textAlign="center">
          {validationMsg}
        </Typography>
      </DialogContent>

      <DialogActions
        className="popUpFoot popUpFootCenter"
        style={{ marginRight: '10px', marginBottom: '10px' }}
      >
        <Button className="mainBtn" onClick={(e) => handleClose()}>
          {setDisableButton ? 'Allow access and Reload' : buttonText ? buttonText : 'try again'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Validation;
