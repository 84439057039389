import { Typography } from '@mui/material';
import { Box } from '@mui/system';

function Footer() {
  return (
    <Box className="footerBox">
      <Typography variant="body2" className="footerContent">
        Copyright © 2024{' '}
        <a href="http://kalolytic.com/" target="_blank">
          Kalolytic
        </a>
        . All rights reserved.
      </Typography>
    </Box>
  );
}

export default Footer;
