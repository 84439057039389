import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Stack,
  Grid,
  Box,
  IconButton,
  TextField,
  Tooltip,
  Button,
  FormControl,
  TablePagination,
  Card,
  CardContent
} from '@mui/material';

import { styled } from '@mui/material/styles';

// table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { FiMoreHorizontal } from 'react-icons/fi';

import CryptoJS from 'crypto-js';
// components
import Page from '../Page';
// import { WatDatePicker } from 'thaidatepicker-react';
import TransactionLogsHeader from './TransactionLogsHeader';
import PageTitle from '../PageHeading';
import BrudCrumbs from '../BreadCrumbs';
import TransactionLogsToolbar from './TransactionLogsToolbar';

import reportsServices from '../../services/ReportsServices';
import { fDateApi, fDateTimeStamp, fDateTimeSuffix1 } from '../utils/formatTime';
import Loader from '../Loader';
import NoAccess from '../NoAccessComp';
import { CalendarToday, VideoCall } from '@mui/icons-material';
import DialogMsg from '../../global/DialogMsg';

// styles
import { StyledTableRow, StyledTableCell, StyledIconButton } from '../utils/Styling';

const { SetFCMToken } = reportsServices;

const authObj = {
  apiKey: 'AIzaSyBhTkJB4bUKXBhpK3jbihvDfV_PRwoUjhg',
  authDomain: 'onboarding-vkyc.firebaseapp.com',
  projectId: 'onboarding-vkyc',
  storageBucket: 'onboarding-vkyc.appspot.com',
  messagingSenderId: '917891499045',
  appId: '1:917891499045:web:eeae0370b8115bb6802bcc',
  measurementId: 'G-MFED9C2EDH'
};

const setFcmTokenToApi = async (token, applicationNo) => {
  const credential = {
    userId: 'admin',
    fcmToken: token,
    organizationId: '1'
  };
  const setToken = await SetFCMToken(credential);
  console.log('setToken', setToken);
};

async function initFCM(applicationNo) {
  const firebase = window.firebase;
  const firebaseConfig = {
    ...authObj
  };
  console.log('firebase', firebase);

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app(); // if already initialized, use that one
  }

  console.log('firebase', Notification.permission);

  if (Notification.permission === 'denied' || Notification.permission === 'default') {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        const messaging = firebase.messaging();
        console.log('firebase', messaging);

        try {
          const token = await messaging.getToken();
          console.log('firebase', token);
          setFcmTokenToApi(token, applicationNo);
        } catch (error) {
          alert('please provide permission for notification');
          initFCM(applicationNo);
        }
      }
    } catch (error) {
      console.log('firebase', error);
      alert('error: please provide permission for notification');
      initFCM(applicationNo);
    }
  } else {
    const messaging = firebase.messaging();
    console.log('firebase', await messaging.requestPermission());

    try {
      const token = await messaging.getToken();
      console.log('firebase', token);
      setFcmTokenToApi(token, applicationNo);
    } catch (error) {
      console.log('firebase', error);
      initFCM(applicationNo);
      // alert('error: please provide permission for notification');
    }
  }
}

const { GenerateTokenForEKYC, ListForAgent } = reportsServices;

// function createData(name, code, population, size) {
//   const density = population / size;
//   return { name, code, population, size, density };
// }

// const rows = [
//   createData('India', 'IN', 1324171354, 3287263),
//   createData('China', 'CN', 1403500365, 9596961),
//   createData('Italy', 'IT', 60483973, 301340),
//   createData('United States', 'US', 327167434, 9833520),
//   createData('Canada', 'CA', 37602103, 9984670),
//   createData('Australia', 'AU', 25475400, 7692024),
//   createData('Germany', 'DE', 83019200, 357578),
//   createData('Ireland', 'IE', 4857000, 70273),
//   createData('Mexico', 'MX', 126577691, 1972550),
//   createData('Japan', 'JP', 126317000, 377973),
//   createData('France', 'FR', 67022000, 640679),
//   createData('United Kingdom', 'GB', 67545757, 242495),
//   createData('Russia', 'RU', 146793744, 17098246),
//   createData('Nigeria', 'NG', 200962417, 923768),
//   createData('Brazil', 'BR', 210147125, 8515767)
// ];
export default function BookedVkycCall({ data }) {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isFilterddata, setIsFilterddata] = useState(false);
  const [selectedFilterDate, setSelectedFilterDate] = useState('');

  const [totalNoOfPage, setTotalNoOfPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [searchAndFiter, setSearchAndFilter] = useState({
    applicationCode: '',
    documentType: '-',
    documentNumber: '',
    transactionDate: '',
    latestUpdate: '',
    pageIndex: 0,
    pageIndex: 10
  });

  //   mobileNumber: '',
  //   nameSurname: '',
  //   documentType: '-',
  //   documentNumber: '',
  //   birthday: '',
  //   typeOfRegistration: '-',
  //   status: '-',
  //   note: '',
  //   orderId: '',
  //   orderStatus: '-',
  //   orderNote: '',
  //   transactionDate: '',
  //   latestUpdate: '',
  //   packageName: '',
  //   contactMobileNumber: '',
  //   contactEmail: '',
  //   // new keys
  //   departmentDesc: '',
  //   divisionDesc: '',
  //   partnerName: '',
  //   contractName: '',
  //   saaName: '',
  //   districtWeir: '',
  //   augSection: '',
  //   salesRepresentative: '',
  //   contract: '',
  //   salesArea: '',
  //   province: '',
  //   district: '',
  //   subDistrict: '',
  //   zipCode: '',
  //   provinceLocation: '',
  //   districtLocation: '',
  //   subDistrictLocation: '',
  //   pageIndex: 0,
  //   pageSize: 10,
  //   reportType: 'ALL'
  // });
  const [getkpidata, setGetkpidata] = useState({});
  const [refresh, setRefresh] = useState(null);

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const [editStep, setEditStep] = useState(false);
  const Input = styled('input')({
    display: 'none'
  });
  // Loader
  const [openLoader, setOpenLoader] = useState(false);
  // drawer
  const [currentDate, setCurrentDate] = useState(fDateApi(new Date(new Date().getTime())));
  const [prevDate, setPrevDate] = useState(
    fDateApi(new Date(new Date().getTime() - 15 * 24 * 60 * 60 * 1000))
  );

  const [isPageAccess, setIsPageAccess] = useState(true);
  const [allListAgent, setAllListAgent] = useState([]);
  const [isShowAll, setShowAll] = useState(false);
  const [PopUuText, setPopUuText] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [openDialogeMsg, setOpenDialogeMsg] = useState(false);
  const [isPermissionGranted, setIsPermissionGranted] = useState(false);

  const [openDialoge, setOpenDialoge] = useState(null);
  // const isPopUpOpen = Boolean(openDialoge);
  const [dialogMsg, setDialogMsg] = useState(''); // Dialog Message
  const [reloadOnce, setReloadOnce] = useState(false); // Reload 1 time

  // Error Dialog close handler
  const closeDialogMsgHandler = (e) => {
    setOpenDialoge(false);
    setOpenDialogeMsg(false); // we can close openDialogeMsg too, with the same closeDialogMsgHandler function
    setDialogMsg('');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    setRefresh(new Date());
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allListAgent.length) : 0;

  useEffect(() => {
    (async () => {
      const header = {
        agentId: '100',
        countryId: 1,
        languageId: 1,
        organizationId: 1
      };
      const getAgentList = await ListForAgent(header, {});
      console.log('getAgentList', getAgentList);
      if (getAgentList.status === 'Success') {
        setAllListAgent(getAgentList.data);
      }
    })();
  }, []);

  const getTocken = async (applicationNumber) => {
    console.log('appId', applicationNumber);
    const header = {
      // application_no: userData?.applicationNo
      applicationNo: applicationNumber
    };
    const generateToken = await GenerateTokenForEKYC(header, {});
    console.log('generateToken', generateToken);
    if (generateToken.status === 'Success') {
      localStorage.setItem('callToken', generateToken?.data?.token);
      localStorage.setItem('channel', generateToken?.data?.channel);
      navigate(
        `/booked-vkyc-call/video-kyc?t=${generateToken?.data?.token}&c=${generateToken?.data?.channel}`
      );
      // startAcall(generateToken?.data?.token, generateToken?.data?.channel);
      initFCM(applicationNumber);
    } else if (generateToken.status === 'Failed') {
      setIsDialogOpen(true);
      setOpenDialogeMsg(true);
      setDialogMsg(generateToken.message);
    } else {
      setIsDialogOpen(true);
      setOpenDialogeMsg(true);
      setDialogMsg('Something went wrong');
    }
  };

  // Click to open and redirect to video
  function handleClickVdo(applicationNumber) {
    getTocken(applicationNumber);
    localStorage.setItem('vkycAppnumber', applicationNumber);
  }

  //Check camera is allow or disable
  navigator.permissions &&
    navigator.permissions.query({ name: 'camera' }).then((cameraStatus) => {
      console.log('navigator2', cameraStatus.state);
      if (cameraStatus.state === 'granted') {
        setIsPermissionGranted(true);
      } else if (cameraStatus.state === 'denied') {
        // alert('1');
        // setIsDialogOpen(true);
        // setOpenDialogeMsg(true);
        // setDialogMsg('Camera access is not enabled, please allow camera.');
      } else if (cameraStatus.state === 'prompt') {
      }
    });

  if (isPageAccess) {
    return (
      <Page title="vKYC">
        {/* header component */}
        <TransactionLogsHeader
          getkpidata={getkpidata}
          setPrevDate={setPrevDate}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          prevDate={prevDate}
          setRefresh={setRefresh}
        />
        <Container maxWidth={false}>
          <Box pt={3} pb={3} mb={4}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className="pageHeading"
            >
              <PageTitle info="vKYC" />
              <Box className="brudCrumbs">
                <BrudCrumbs />
              </Box>
            </Stack>
            <Card className="cardWrap">
              {/* table toolbar */}
              {/* <TransactionLogsToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                parent="partners"
                setDrawOpen={setDrawOpen}
                csvReportObj={csvReportObj}
                submenuAccess={submenuAccess}
              /> */}

              {/* table start */}
              <TableContainer sx={{ overflow: 'auto', minWidth: 800, height: '68vh' }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className="fixedHeader">
                    <TableRow>
                      <StyledTableCell>Application number</StyledTableCell>
                      <StyledTableCell>Customer</StyledTableCell>
                      <StyledTableCell>Doc reference id </StyledTableCell>
                      <StyledTableCell>Created on</StyledTableCell>
                      <StyledTableCell>Call</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {allListAgent.length > 0 &&
                      allListAgent.map((agentItem, index) => {
                        // const { id, name, role, status, company, avatarUrl, isVerified } = row;
                        const isItemSelected = selected.indexOf(agentItem.applicationNumber) !== -1;

                        return (
                          <StyledTableRow
                            hover
                            key={index}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            {/* don't remove the code, below code is usefull when text length is exceed then cut the text.
                             <StyledTableCell
                              sx={{ cursor: 'pointer' }}
                              onClick={(e) => {
                                setPopUuText(agentItem.applicationNumber);
                                setOpenDialoge(e.target);
                              }}
                            >
                              {agentItem?.applicationNumber !== null
                                ? agentItem.applicationNumber.substr(
                                    agentItem.applicationNumber.length - 12
                                  )
                                : 'N/A'}
                              <br />
                              <FiMoreHorizontal size={14} />
                            </StyledTableCell> 
                            /* don't remove the code, below code is usefull when text length is exceed then cut the text.
                            */}
                            <StyledTableCell>
                              {agentItem?.applicationNumber !== null
                                ? agentItem.applicationNumber
                                : 'N/A'}
                            </StyledTableCell>
                            <StyledTableCell>
                              {agentItem.customerId !== null ? agentItem.customerId : 'N/A'}
                            </StyledTableCell>
                            <StyledTableCell>
                              {agentItem.docRefId !== null ? agentItem.docRefId : 'N/A'}
                            </StyledTableCell>

                            <StyledTableCell>
                              {agentItem?.createdOn !== null
                                ? fDateTimeStamp(agentItem?.createdOn)
                                : 'N/A'}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Tooltip title="Click to call" placement="top" arrow>
                                <StyledIconButton
                                  className="squareIconButton actionButton"
                                  style={{ padding: '3px' }}
                                  onClick={() => {
                                    if (isPermissionGranted === true) {
                                      handleClickVdo(agentItem.applicationNumber);
                                    } else {
                                      setIsDialogOpen(true);
                                      setOpenDialogeMsg(true);
                                      setDialogMsg(
                                        'Camera access is not enabled, please allow camera.'
                                      );
                                    }
                                  }}
                                  // onClick={() => {
                                  //   getTocken(agentItem.applicationNumber);
                                  //   localStorage.setItem(
                                  //     'vkycAppnumber',
                                  //     agentItem.applicationNumber
                                  //   );
                                  // }}
                                >
                                  <VideoCall style={{ width: '25px', height: '20px' }} />
                                </StyledIconButton>
                              </Tooltip>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={getPerpageOp(totalNoOfPage)}
                component="div"
                count={totalNoOfPage}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
            <DialogMsg
              openDialogeMsg={openDialoge}
              dialogMsg={PopUuText}
              dialogTitle="Application Number"
              buttonText={null}
              closeDialogMsgHandler={closeDialogMsgHandler}
            />

            {isDialogOpen === true ? (
              <DialogMsg
                openDialogeMsg={openDialogeMsg}
                dialogMsg={dialogMsg}
                dialogTitle="Message"
                buttonText="Close"
                closeDialogMsgHandler={closeDialogMsgHandler}
              />
            ) : (
              ''
            )}

            <Loader openLoader={openLoader} />
          </Box>
        </Container>
      </Page>
    );
  }
  return <NoAccess />;
}

function getPerpageOp(totalNoOfPage) {
  const optionArray = [10];
  if (totalNoOfPage > 10) {
    optionArray.push(50);
  }
  if (totalNoOfPage > 50) {
    optionArray.push(100);
  }
  if (totalNoOfPage > 100) {
    optionArray.push(150);
  }
  if (totalNoOfPage > 250) {
    optionArray.push(totalNoOfPage);
  } else if (totalNoOfPage < 250 && totalNoOfPage > 150) {
    optionArray.push(250);
  }

  return optionArray;
}
