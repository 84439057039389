import React, { useEffect, useState } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';

// ADMIN COMPONENT ALIAS STAFF
import Login from './components/pages/Login';
// import DashboardApp from './components/pages/admin/pages/DashboardApp';
import DashboardLayout from './components/pages/layouts/dashboard/DashboardLayout';
// import TransactionOrder from './components/pages/admin/pages/TransactionOrder/TransactionOrder';
// import ProfileViewTO from './components/pages/admin/pages/TransactionOrder/ProfileViewTO';
import TransactionLogs from './components/pages/TransactionLogs/TransactionLogs';
import ProfileViewTL from './components/pages/TransactionLogs/ProfileViewTL';
import Configure from './components/pages/Configure/Configure';
import Setting from './components/pages/Configure/Setting';
import ScreenLanguage from './components/pages/Configure/ScreenLanguage';
import Instruction from './components/pages/Configure/Instruction';
import TermsOfUse from './components/pages/Configure/TermsOfUse';
import Consent from './components/pages/Configure/Consent';
import FAQ from './components/pages/Configure/FAQ';
import FaceMatching from './components/pages/Configure/FaceMatching';
import Watermark from './components/pages/Configure/Watermark';
import ValidationConfig from './components/pages/Configure/ValidationConfig';
import Notification from './components/pages/Configure/Notification';
import TermsOfService from './components/pages/Configure/TermsOfService';
import MasterData from './components/pages/MasterData/MasterData';
// import Users from './components/pages/admin/pages/Users---/Users';
// import AddUser from './components/pages/admin/pages/Users---/AddUser';
import ManageAccess from './components/pages/ManageAccess/ManageAccess';
import AddEditManageAccess from './components/pages/ManageAccess/AddEditManageAccess';
// import EditManageAccess from './components/pages/ManageAccess/EditManageAccess';
import AlertList from './components/pages/Alert/AlertList';
import ApprovedList from './components/pages/Approved/ApprovedList';
import ViewAlert from './components/pages/Alert/ViewAlert';
import ViewApproved from './components/pages/Approved/ViewApproved';
import UpdateApproved from './components/pages/Approved/UpdateApproved';
import AddEditUser from './components/pages/Users/AddEditUser';
import UserList from './components/pages/Users/UserList';
import PendingList from './components/pages/Pending/PendingList';
import AddPending from './components/pages/Pending/AddPending';
import ViewPending from './components/pages/Pending/ViewPending';
import Dashboard from './components/pages/Dashboard';
import BookedVkycCall from './components/pages/TransactionLogs/BookedVkycCall';
import VideoKyc from './components/pages/TransactionLogs/Videokyc';
import UserListPage from './components/pages/Users/UserList';
import { userDataFromLocal } from './components/pages/utils/getUserDetails';
import ApiErrorMessage from './components/pages/ApiErrorMessage';
// import PendingTab from './components/pages/admin/pages/Pending/PendingTab';
// import SrpThaiContactUs from './components/pages/thai/SrpThaiContactUs';
// import Home2 from './components/pages/Home2';

// session timeout code
const sessionId = userDataFromLocal().sessionId;
const isLogin = sessionId;
// console.log('sessionIdsessionId', sessionId);

const base64UrlDecode = (base64Url) => {
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return decodeURIComponent(atob(base64));
};
// session timeout code end

function Router() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  // Session time out state
  const [userTokenExpire, setUserTokenExpire] = useState(false);
  const [openApiError, setOpenApiError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [filterDate, setFilterDate] = useState({
    fromDate: '',
    toDate: ''
  });

  // console.log('filterDate', filterDate);

  // Session time out code
  useEffect(() => {
    // if (isLogin && isLogin.jwt) {
    if (isLogin) {
      const [header, payload, signature] = isLogin.split('.');
      // console.log('payloaddd', payload, header, signature);
      const decodedPayloadObject = JSON.parse(base64UrlDecode(payload));
      // console.log('base64UrlDecode', decodedPayloadObject);
      const expireTimeToken = decodedPayloadObject ? decodedPayloadObject?.exp : undefined;
      // const convertTime = new Date(expireTimeToken * 998.95);
      // const convertTime = new Date(expireTimeToken * 1000);
      const convertTime = new Date(Date.now() + 4000000);
      console.log('base64UrlDecode', convertTime, new Date(expireTimeToken));

      const timeoutId = setTimeout(() => {
        if (
          new Date() >= convertTime &&
          isLogin !== undefined &&
          isLogin !== null &&
          window.location.pathname !== '/'
        ) {
          setUserTokenExpire('true');
          setOpenApiError(true);
          setErrorMessage('Session Timeout, please login again');
        }
      }, convertTime - new Date());

      // Clear the timeout when the component unmounts
      return () => clearTimeout(timeoutId);
    }
  }, [isLogin]);
  // Session time out code

  const routes = useRoutes([
    // ADMIN ROUTING
    {
      path: '/',
      element: <Login />
    },
    {
      // path: 'login',
      path: `${BASE_URL}/login`,
      element: <Login />
    },
    // {
    //   path: '/external',
    //   element: <Login />
    // },
    // {
    //   path: '',
    //   element: <Login />
    // },
    {
      path: '/dashboard',
      // path: `${BASE_URL}/dashboard`,
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Dashboard /> }
        // { path: '', element: <DashboardApp /> },
        // { path: 'app', element: <DashboardApp setFilterDate={setFilterDate} /> },
        // { path: '*', element: <Navigate to={`${BASE_URL}/srp/th/selfregistration`} replace /> }
        // { path: '*', element: <Navigate to={`${BASE_URL}`} replace /> }
      ]
    },
    // {
    //   // path: '/transaction-order',
    //   path: `${BASE_URL}/transaction-order`,
    //   element: <DashboardLayout />,
    //   children: [
    //     { path: '', element: <TransactionOrder /> },
    //     { path: 'view', element: <ProfileViewTO /> },
    //     // { path: '*', element: <Navigate to={`${BASE_URL}/srp/th/selfregistration`} replace /> }
    //     { path: '*', element: <Navigate to={`${BASE_URL}`} replace /> }
    //   ]
    // },
    {
      // path: '/transaction-logs',
      // path: `${BASE_URL}/transaction-logs`,
      path: `/kyc-logs`,
      element: <DashboardLayout />,
      children: [
        { path: '', element: <TransactionLogs data="ALL" /> },
        { path: 'view', element: <ProfileViewTL data="ALL" /> },
        { path: 'view/:logId', element: <ProfileViewTL data="ALL" /> },
        // { path: '*', element: <Navigate to={`${BASE_URL}/srp/th/selfregistration`} replace /> }
        { path: '*', element: <Navigate to={`${BASE_URL}`} replace /> }
      ]
    },
    {
      // path: '/transaction-logs',
      // path: `${BASE_URL}/transaction-logs`,
      path: `/approved-kyc`,
      element: <DashboardLayout />,
      children: [
        { path: '', element: <TransactionLogs data="COMPLETED" /> },
        { path: 'view', element: <ProfileViewTL data="COMPLETED" /> },
        { path: 'view/:logId', element: <ProfileViewTL data="COMPLETED" /> },
        // { path: '*', element: <Navigate to={`${BASE_URL}/srp/th/selfregistration`} replace /> }
        { path: '*', element: <Navigate to={`${BASE_URL}`} replace /> }
      ]
    },
    {
      // path: '/transaction-logs',
      // path: `${BASE_URL}/transaction-logs`,
      path: `/rejected-kyc`,
      element: <DashboardLayout />,
      children: [
        { path: '', element: <TransactionLogs data="REJECTED" /> },
        { path: 'view', element: <ProfileViewTL data="REJECTED" /> },
        { path: 'view/:logId', element: <ProfileViewTL data="REJECTED" /> },
        // { path: '*', element: <Navigate to={`${BASE_URL}/srp/th/selfregistration`} replace /> }
        { path: '*', element: <Navigate to={`${BASE_URL}`} replace /> }
      ]
    },
    {
      // path: '/transaction-logs',
      // path: `${BASE_URL}/transaction-logs`,
      path: `/booked-vkyc-call`,
      element: <DashboardLayout />,
      children: [
        { path: '', element: <BookedVkycCall data="REJECTED" /> },
        { path: 'video-kyc', element: <VideoKyc data="REJECTED" /> },
        { path: 'view/:logId', element: <ProfileViewTL data="REJECTED" /> },
        // { path: '*', element: <Navigate to={`${BASE_URL}/srp/th/selfregistration`} replace /> }
        { path: '*', element: <Navigate to={`${BASE_URL}`} replace /> }
      ]
    },
    {
      // path: '/configure',
      path: `${BASE_URL}/configure`,
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Configure /> },
        { path: 'setting', element: <Setting /> },
        { path: 'screen-language', element: <ScreenLanguage /> },
        { path: 'instruction', element: <Instruction /> },
        { path: 'terms-of-use', element: <TermsOfUse /> },
        { path: 'consent', element: <Consent /> },
        { path: 'faq', element: <FAQ /> },
        { path: 'face-matching', element: <FaceMatching /> },
        { path: 'watermark', element: <Watermark /> },
        { path: 'validation', element: <ValidationConfig /> },
        { path: 'notification', element: <Notification /> },
        { path: 'terms-of-service', element: <TermsOfService /> },
        // { path: '*', element: <Navigate to={`${BASE_URL}/srp/th/selfregistration`} replace /> }
        { path: '*', element: <Navigate to={`${BASE_URL}`} replace /> }
      ]
    },
    {
      // path: '/master-data',
      path: `${BASE_URL}/configure`,
      element: <DashboardLayout />,
      children: [
        { path: '', element: <MasterData /> },
        // { path: '*', element: <Navigate to={`${BASE_URL}/srp/th/selfregistration`} replace /> }
        { path: '*', element: <Navigate to={`${BASE_URL}`} replace /> }
      ]
    },
    {
      // path: '/users',
      path: `/users`,
      element: <DashboardLayout />,
      children: [
        // { path: '', element: <Users /> },
        // { path: 'add', element: <AddUser /> },
        // { path: 'edit/:userId', element: <AddUser /> },
        // { path: '*', element: <Navigate to={`${BASE_URL}`} replace /> }
        { path: '', element: <UserListPage /> },
        { path: 'add', element: <AddEditUser /> },
        { path: 'edit/:rowId', element: <AddEditUser /> }
      ]
    },
    {
      // path: '/manage-access',
      path: `${BASE_URL}/manage-access`,
      element: <DashboardLayout />,
      children: [
        { path: '', element: <ManageAccess /> },
        { path: 'add', element: <AddEditManageAccess /> },
        { path: 'edit/:rowId', element: <AddEditManageAccess /> },
        // { path: '*', element: <Navigate to={`${BASE_URL}/srp/th/selfregistration`} replace /> }
        { path: '*', element: <Navigate to={`${BASE_URL}`} replace /> }
        // { path: 'edit', element: <EdiUser /> }
      ]
    },
    // {
    //   path: `${BASE_URL}/pending`,
    //   element: <DashboardLayout />,
    //   children: [{ path: '', element: <PendingTab /> }]
    // },
    {
      path: `${BASE_URL}/pending`,
      element: <DashboardLayout />,
      children: [
        { path: '', element: <PendingList /> },
        { path: 'add-pending', element: <AddPending /> },
        { path: 'view-pending', element: <ViewPending /> }
      ]
    },
    {
      path: `${BASE_URL}/alert`,
      element: <DashboardLayout />,
      children: [
        { path: '', element: <AlertList /> },
        { path: 'view-alert', element: <ViewAlert /> }
      ]
    },
    {
      path: `${BASE_URL}/approved`,
      element: <DashboardLayout />,
      children: [
        { path: '', element: <ApprovedList /> },
        { path: 'view-approved', element: <ViewApproved /> },
        { path: 'update-approved', element: <UpdateApproved /> }
      ]
    },
    {
      // path: '/manage-access',
      path: `${BASE_URL}/approved`,
      element: <DashboardLayout />,
      children: [{ path: '', element: <ManageAccess /> }]
    }
    // { path: '*', element: <Navigate to={`${BASE_URL}/srp/th/selfregistration`} replace /> }
  ]);

  return (
    <>
      {routes}
      {/* Session time out code */}
      <ApiErrorMessage
        openApiError={openApiError}
        setOpenApiError={setOpenApiError}
        errorMessage={errorMessage}
        userTokenExpire={userTokenExpire}
      />
      {/* Session time out code end */}
    </>
  );
}
export default Router;
