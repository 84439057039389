import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  TableBody,
  Container,
  Typography,
  TableContainer,
  Grid,
  Tooltip,
  IconButton,
  Divider,
  Tabs,
  Tab,
  Checkbox,
  TablePagination,
  TableRow
} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import { FaFolderOpen, FaCommentAlt } from 'react-icons/fa';
import { MdArrowDropDown, MdArrowDropUp, MdModeEdit } from 'react-icons/md';
import { GiSwapBag } from 'react-icons/gi';
import { FiEye, FiTrash2 } from 'react-icons/fi';
import CloseIcon from '@mui/icons-material/Close';
import CryptoJS from 'crypto-js';
//
import { StyledTableRow, StyledTableCell, StyledIconButton } from '../utils/Styling';

// components
import Page from '../Page';
import Label from '../Label';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
import { UserListHead, UserMoreMenu } from '../_dashboard/user';

import UserListToolbar from './UserListToolbar';
import { fDate } from '../utils/formatTime';
import { userDataFromLocal } from '../utils/getUserDetails';
import Validation from '../../global/Validation';
import BrudCrumbs from '../BreadCrumbs';
import PageTitle from '../PageHeading';
import DeleteDialog from '../../global/DeleteDialog';
import Loader from '../../global/Loader';

// importing APi Services
import globalServices from '../../services/globalServices';
import DialogMsgAction from '../../global/DialogMsgAction';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';

// Destructuring services from servces
const { UserListApi, GetDefaultData, UpdateUserData } = globalServices;

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function UserListPage() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const [getdefaultdata, setGetdefaultdata] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [updateUserData, setUpdateUserData] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  // const [openDialogeMsg, setOpenDialogeMsg] = useState(false);
  const [dialogMsg, setDialogMsg] = useState('');

  // drawer
  const [drawerOpen, setDrawOpen] = useState(false);

  const [loginDataDetails, setLoginDataDetails] = useState([]);
  // const [userList, setUserList] = useState([]);
  const [userList, setUserList] = useState([]);

  // validation
  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');
  const [openloader, setOpenLoader] = useState(false);
  const [targetItem, setTargetItem] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [refresh, setRefresh] = useState('');
  const [transactionLogsFilter, setTransactionLogsFilter] = useState([]);
  const [csvReportObj, setCsvReportObj] = useState({
    data: [
      ['avcd1', 'asas', 'asas', 'asasas', 'asaas', 'asas', 'asasa'],
      ['avcd2', 'asas', 'asas', 'asasas', 'asaas', 'asas', 'asasa'],
      ['avcd3', 'asas', 'asas', 'asasas', 'asaas', 'asas', 'asasa']
    ],
    headers: ['User ID', 'real name', 'surname', 'Email', 'User Type', 'Access type'],
    filename: 'user-list.csv'
  });

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const Input = styled('input')({
    display: 'none'
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;
  const handleClose = () => {
    setOpenValidation(false);
  };
  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeletion = () => {
    fireUserDeleteApi(targetItem);
    handleCloseDelete();
  };

  const FiredUserListApiresp = async (adminUserDate) => {
    try {
      setOpenLoader(true);
      // const localStorageSessionId1 = localStorage.getItem('sessionId');
      // const sessionId1 = localStorageSessionId1 ? localStorageSessionId1 : '';
      const hdrCredential = {
        userid: userDataFromLocal().userId,
        organizationId: userDataFromLocal().organizationId
        // userid: 1,
        // organizationId: 1
      };
      const FiredUserListApi = await UserListApi(hdrCredential);
      // setOpenLoader(false);
      if (FiredUserListApi.code === 100) {
        // setOpenLoader(false);

        setUserList(FiredUserListApi.data);
        setTransactionLogsFilter(FiredUserListApi.data);
        const token = {
          userid: userDataFromLocal().userId
        };
        const FiredGetdefaultdataApi = await GetDefaultData(token);
        if (FiredGetdefaultdataApi.code === 100) {
          setGetdefaultdata(FiredGetdefaultdataApi.data);
        }
        //  csv file array
        const csvData = [];
        FiredUserListApi.data.forEach((element) => {
          const csvObj = [
            String(element.userId ? element.userId : 'N/A'),
            String(element.userFirstName ? element.userFirstName : 'N/A'),
            String(element.userFirstName ? element.userFirstName : 'N/A'),
            String(element.emailAddress ? element.emailAddress : 'N/A'),
            FiredGetdefaultdataApi.data?.userType.length > 0
              ? FiredGetdefaultdataApi.data.userType.filter(
                  (utype) => Number(utype.id) === Number(element.userTypeId)
                )[0].typeName == 'INTERNAL_USER'
                ? 'Internal'
                : 'External'
              : '',
            String(element.roleName ? element.roleName : 'N/A'),
            String(element.lastUpdatedOn ? fDate(element.lastUpdatedOn) : '')
          ];
          csvData.push(csvObj);
        });
        setCsvReportObj({
          ...csvReportObj,
          data: csvData
        });
      }
    } catch (error) {
      // console.log('error', error);
    } finally {
      setOpenLoader(false);
    }
  };

  // filter
  const handleFilterByName = (e) => {
    const keyword = e.target.value;
    setFilterName(keyword);

    if (keyword !== '') {
      const results = transactionLogsFilter.filter((user) => {
        return (
          user.userFirstName?.toLowerCase().includes(keyword.toLowerCase()) ||
          user.emailAddress?.toLowerCase().includes(keyword.toLowerCase()) ||
          user.userLastName?.toLowerCase().includes(keyword.toLowerCase()) ||
          user.userId?.toString().includes(keyword.toString())
        );
      });

      // csv file
      //  csv file array
      const csvData = [];
      results.forEach((element) => {
        const csvObj = [
          String(element?.userId ? element.userId : 'N/A'),
          String(element?.userFirstName ? element?.userFirstName : 'N/A'),
          String(element?.userFirstName ? element?.userFirstName : 'N/A'),
          String(element?.emailAddress ? element?.emailAddress : 'N/A'),
          String(
            getdefaultdata?.userType?.length > 0
              ? getdefaultdata?.userType?.filter(
                  (utype) => Number(utype?.id) === Number(element?.userTypeId)
                )[0]?.typeName == 'INTERNAL_USER'
                ? 'In-house system administrator'
                : 'Administrators outside the organization'
              : ''
          ),
          String(element?.roleName ? element?.roleName : 'N/A'),
          String(element?.lastUpdatedOn ? fDate(element?.lastUpdatedOn) : '')
        ];
        csvData.push(csvObj);
      });
      setCsvReportObj({
        ...csvReportObj,
        data: csvData
      });
      setUserList(results);
    } else {
      setCsvReportObj({
        ...csvReportObj
      });
      setUserList(transactionLogsFilter);
    }
  };

  const fireUserDeleteApi = async (item) => {
    try {
      // setOpenLoader(true);
      const credential = {
        roleId: item?.roleId === undefined || item?.roleId === null ? '' : item.roleId,
        userLastName: item.userLastName,
        organizationId: item.organizationId,
        departmentId: item.departmentId === null ? '' : item.departmentId,
        userFirstName: item.userLastName,
        userMiddleName: item.userMiddleName === null ? '' : item.userMiddleName,
        userName: item.userName,
        emailAddress: item.emailAddress,
        userTypeId: item.userTypeId,
        contactNumber: item.contactNumber === null ? '' : item.contactNumber,
        mobileNumber: item.mobileNumber === null ? '' : item.mobileNumber,
        // password: "string",
        dob: item.dob === null ? '' : item.dob,
        requestType: 'D'
      };

      const header = {
        userid: userDataFromLocal().userId,
        organizationId: userDataFromLocal().organizationId
      };
      const FiredUpdateUserDataApi = await UpdateUserData(header, credential);
      if (FiredUpdateUserDataApi.code === 100) {
        // setOpenLoader(false);
        setRefresh(new Date());

        setUpdateUserData(FiredUpdateUserDataApi.data);
      } else {
        // setOpenLoader(false);
      }
    } catch (error) {
      // console.log('error', error);
    } finally {
      // setOpenLoader(false);
    }
  };

  // dialog open
  const dialogMsgHandler = () => {
    setOpenDialog(true);
  };
  // dialog close
  const handleCloseConfirmBox = () => {
    setOpenDialog(false);
  };

  // userList Api
  useEffect(() => {
    const adminUserDataValue = localStorage.getItem('adminUserDataValue');
    if (adminUserDataValue !== null) {
      const bytes = CryptoJS.AES.decrypt(adminUserDataValue, 'adminUserData');
      const originalAdmiuserData = bytes.toString(CryptoJS.enc.Utf8);

      const adminUserDate = JSON.parse(originalAdmiuserData);
      // const adminUserDate = adminUserDataValue;

      setLoginDataDetails(adminUserDate);

      // getting user list
      FiredUserListApiresp(adminUserDate);
    }
  }, [refresh]);

  // userList Access type Api
  const getmapid = async (id, item) => {};

  return (
    <Page title="Users">
      {/* header component */}
      {/* <UsersHeader /> */}
      <Container maxWidth={false}>
        <Loader openloader={openloader} />
        <Validation
          openValidation={openValidation}
          validationMsg={validationMsg}
          handleClose={handleClose}
          setOpenValidation={setOpenValidation}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="pageHeading"
        >
          <PageTitle info="Users" />
          <Box>
            <BrudCrumbs />
          </Box>
        </Stack>

        <Card>
          {/* table toolbar */}
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            parent="users"
            setDrawOpen={setDrawOpen}
            // csvReport={csvReport}
            // csvReportObj={csvReportObj}
          />
          {/* table start */}
          <Scrollbar>
            <TableContainer
              sx={{
                minWidth: 800,
                height: '59.5vh',
                overflow: 'auto'
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>USER ID</StyledTableCell>
                    <StyledTableCell>REAL NAME</StyledTableCell>
                    <StyledTableCell>SURNAME</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>USER TYPE</StyledTableCell>
                    <StyledTableCell>ACCESS TYPE</StyledTableCell>
                    <StyledTableCell>LAST UPDATED DATE</StyledTableCell>
                    <StyledTableCell width="40">CORRECT</StyledTableCell>
                    <StyledTableCell width="40">DELETE</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {userList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .sort((a, b) => new Date(b.lastUpdatedOn) - new Date(a.lastUpdatedOn))
                    .map((item, index) => {
                      const isItemSelected = selected.indexOf(item.userId) !== -1;
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell>{item.userId ? item.userId : 'N/A'}</StyledTableCell>
                          <StyledTableCell>
                            {item.userFirstName ? item.userFirstName : 'N/A'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {item.userLastName ? item.userLastName : 'N/A'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {item.emailAddress ? item.emailAddress : 'N/A'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {getdefaultdata?.userType?.length > 0
                              ? getdefaultdata?.userType?.filter(
                                  (utype) => Number(utype.id) === Number(item.userTypeId)
                                )[0]?.typeName == 'INTERNAL_USER'
                                ? 'In-house system administrator'
                                : 'Administrators outside the organization'
                              : ''}
                          </StyledTableCell>
                          <StyledTableCell>{item.roleName ? item.roleName : 'N/A'}</StyledTableCell>
                          <StyledTableCell>
                            {item.lastUpdatedOn ? fDate(item.lastUpdatedOn) : ''}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            <Tooltip title="Edit" placement="top" arrow>
                              <StyledIconButton
                                className="squareIconButton actionButton"
                                onClick={(e) => {
                                  const UserData = CryptoJS.AES.encrypt(
                                    JSON.stringify(item),
                                    'UserData'
                                  ).toString();

                                  localStorage.setItem(
                                    'e13ehdnf06b-72p6-4581-86a3-c0a465e63d75-UserData',
                                    JSON.stringify(UserData)
                                  );
                                  navigate(`/users/edit/${item.userId}`);
                                }}
                              >
                                <AiFillEdit size={14} />
                              </StyledIconButton>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Tooltip title="Delete" placement="top" arrow>
                              <StyledIconButton
                                className="squareIconButton actionButton"
                                onClick={(e) => {
                                  setTargetItem(item);
                                  setOpenDeleteDialog(true);
                                }}
                              >
                                <AiFillDelete size={14} />
                              </StyledIconButton>
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={userList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <DialogMsgAction
          openDialogeMsg={openDialog}
          dialogMsg={dialogMsg}
          dialogTitle="Confirmation"
          buttonText="Next"
          buttonPrimary="Close"
          dialogMsgHandler={dialogMsgHandler}
          closeDialogMsgHandler={handleCloseConfirmBox}
        />

        <DeleteDialog
          openDeleteDialog={openDeleteDialog}
          dialogMsg="Are you sure you want to delete?"
          dialogTitle="Confirmation"
          buttonText="Delete"
          handleDelete={handleDeletion}
          closeDeleteHandler={handleCloseDelete}
        />

        {/* <Dialog
          open={openDeleteDialog}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle className="popupTitle">
            <Typography variant="h6">Confirmation</Typography>
            <IconButton onClick={handleCloseDelete} size="small">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                fireUserDeleteApi(targetItem);
                handleCloseDelete(e);
              }}
              className="mainBtn"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog> */}
      </Container>
    </Page>
  );
}
