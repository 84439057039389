import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Link, Typography, IconButton } from '@mui/material';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { userDataFromLocal } from '../../components/pages/utils/getUserDetails';
import { ArrowBack } from '@mui/icons-material';
import { useState } from 'react';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function BrudCrumbs() {
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumbs();
  const [url, setUrl] = useState();

  let backButton = null;

  console.log('match', breadcrumbs);
  const breadcrumbItems = breadcrumbs.map(({ match, breadcrumb }, index) => {
    if (window.location.pathname !== `${BASE_URL}/staff/dashboard/app`) {
      return (
        <>
          {match.pathname === '/dev' || match.pathname === '/uat' ? (
            ''
          ) : (
            <>
              {console.log('match', match.pathname, match, breadcrumbs.length)}
              {/* {setUrl(match.pathname)} */}
              <span key={match.pathname}>
                {index < breadcrumbs.length - 1 ? (
                  <Link underline="none" variant="subtitle2" component={RouterLink}>
                    {index === 0 ? 'Home' : breadcrumb}
                  </Link>
                ) : (
                  <Typography variant="body2" component="span">
                    {breadcrumb}
                  </Typography>
                )}

                {index < breadcrumbs.length - 1 ? <>&nbsp; / &nbsp;</> : ''}
              </span>
            </>
          )}
        </>
      );
    }
    return '';
  });

  // Conditionally render the IconButton only once
  if (breadcrumbs.length > 0) {
    const { match } = breadcrumbs[breadcrumbs.length - 1];
    backButton = (
      <IconButton
        className="goBackBtn"
        onClick={(e) => navigate(match.pathname, { replace: true })} // Use match.url instead of match.pathname
      >
        <ArrowBack style={{ fontSize: '17px' }} />
      </IconButton>
    );
  }

  return (
    <>
      {/* {backButton} */}
      {breadcrumbItems}
    </>
  );
}
