import { Button, Menu, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { userDataFromLocal } from './utils/getUserDetails';
import globalServices from '../services/globalServices';

const REACT_APP_BASE_ADMIN_URL = process.env.REACT_APP_BASE_ADMIN_URL;
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const { LogoutUser } = globalServices;

function UserComp() {
  const [userDetails, setUserDetils] = useState('');
  const [openUserMenu, setUserMenu] = useState(null);
  const isMenuOpen = Boolean(openUserMenu);
  const navigate = useNavigate();

  const logoutUser = () => {
    if (userDataFromLocal().userTypeId === 1) {
      window.open('https://itid.cattelecom.com/adfs/oauth2/logout');
      localStorage.removeItem('adminUserDataValue');
      setTimeout(() => {
        navigate(`${REACT_APP_BASE_URL}/${REACT_APP_BASE_ADMIN_URL}`);
      }, 500);
    } else if (userDataFromLocal().userTypeId === 2) {
      (async () => {
        const logoutCredential = {};
        const header = {
          userId: userDataFromLocal().userId,
          organizationId: userDataFromLocal().organizationId
        };
        const LogoutUserApiResponse = await LogoutUser(logoutCredential, header);
        if (LogoutUserApiResponse.code === 100) {
          if (userDataFromLocal().userTypeId == 2) {
            navigate(`${REACT_APP_BASE_URL}/external`);
          } else {
            navigate(`${REACT_APP_BASE_URL}/${REACT_APP_BASE_ADMIN_URL}/`);
          }
          setTimeout(() => {
            localStorage.removeItem('adminUserDataValue');
          }, 500);
        }
      })();
    } else {
      navigate(`${REACT_APP_BASE_URL}/login`);
    }
  };

  useEffect(() => {
    setUserDetils(userDataFromLocal());
  }, []);
  return (
    <>
      <Button className="superAdminnBtn" onClick={(e) => setUserMenu(e.currentTarget)}>
        {userDetails.userFirstName ? userDetails.userFirstName : 'admin'}
        {/* {userDetails.userLastName} */}
      </Button>

      <Menu
        id="zoomMenu"
        className="globalZoomMenuSlider globalGrayscale"
        elevation={0}
        getContentAnchorEl={openUserMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        anchorEl={openUserMenu}
        open={isMenuOpen}
        onClose={(e) => setUserMenu(null)}
      >
        <Box style={{ width: 300 }}>
          <Box style={{ padding: '10px 15px', margin: '5px', border: '1px solid #ddd' }}>
            Hello {console.log('userDetails', userDetails)}
            {Object.keys(userDetails).length > 0
              ? `${userDetails.userFirstName}  ${userDetails.userLastName}`
              : 'Admin'}
          </Box>

          <MenuItem onClick={(e) => logoutUser()}>Logout</MenuItem>
        </Box>
      </Menu>
    </>
  );
}
export default UserComp;
