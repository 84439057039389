import { format, formatDistanceToNow } from "date-fns";
import moment from "moment";
import "moment/locale/th";

// ----------------------------------------------------------------------
export function fDateTime5(date) {
  return format(new Date(date), "yyyy-MM-dd#  (h:mm:ss a)").replace("#", "");
}

export function fDateTimeStamp(date) {
  const dateTime = new Date(date);

  // Set the time zone to GMT+5:30
  dateTime.setTime(dateTime.getTime() + 5.5 * 60 * 60 * 1000);

  // Format the date
  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, "0");
  const day = String(dateTime.getDate()).padStart(2, "0");
  const formattedDateString = `${year}-${month}-${day}`;

  // Format the time
  const hours = String(dateTime.getHours()).padStart(2, "0");
  const minutes = String(dateTime.getMinutes()).padStart(2, "0");
  const seconds = String(dateTime.getSeconds()).padStart(2, "0");
  const ampm = dateTime.getHours() >= 12 ? "PM" : "AM";
  const formattedTimeString = `${hours}:${minutes}:${seconds} ${ampm}`;

  // Combine date and time
  const formattedDateTimeString = `${formattedDateString} (${formattedTimeString})`;
  return formattedDateTimeString;
}

export function fDate(date) {
  return format(new Date(date), "dd MMMM yyyy");
}

export function fDateTime(date) {
  return format(new Date(date), "dd MMM yyyy HH:mm");
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}
export function fDateTimeSuffix1(date) {
  return format(new Date(date), "dd/MM/yyyy HH:mm:ss");
}

export function fDateApi(date) {
  return format(new Date(date), "yyyy-MM-dd");
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function getThaidate(date) {
  const m = moment(date, "DDMMMMYYYY, HH:mm:ss", "en");
  return m.locale("th").format("DD MMM, YYYY"); // Mar 31, 2017, 16:56:51
}
export function getEngdate(date) {
  const m = moment(date, "DDMMMMYYYY, HH:mm:ss", "th");
  return m.locale("en").format("dd/MM/yyyy"); //
}
