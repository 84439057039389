import useApiServices from './useApiServices';
import { globalAdminUrl } from './globalApi';
import { globalSRP } from './globalApi';
//
/**
 * @module PartnerServiceService
 * @returns  it return All DataSource Apis
 */

const globalServices = {
  // common apis
  GetDefaultData: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/onboarding-admin/getdefaultdata`, token),
  AdminUserData: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/onboarding-admin/userLogin`, credential, token),
  UserLogin: (accessToken) =>
    useApiServices().getApiQueryString(
      `${globalAdminUrl}/onboarding-admin/userlogin?${accessToken}`
    ),
  RefreshToken: (credential, token) =>
    useApiServices().getApi(`${globalAdminUrl}/onboarding-admin/refreshToken`, credential, token),
  // useApiServices().postApi(`${globalAdminUrl}/onboarding-admin/refreshToken`, credential, token),
  LogoutUser: (credential, token) =>
    useApiServices().postApi(`${globalAdminUrl}/onboarding-admin/logoutUser`, credential, token),
  GetFaq: (token) => useApiServices().getApi(`${globalAdminUrl}/onboarding-admin/getfaq`, token),
  SetCustomerData: (header, credential) =>
    useApiServices().postHeaderBodyApi(
      `${globalAdminUrl}/onboarding-admin/setCustomerData`,
      header,
      credential
    ),
  FetchSalutationAndGender: () =>
    useApiServices().getApi(`${globalAdminUrl}/onboarding-admin/fetchSalutationAndGender`),

  // Manage access page
  GetRoles: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/onboarding-admin/getroles`, token),
  GetRolesGroup: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/onboarding-admin/getrolesgroup`, token),
  SetRolesGroup: (header, credential) =>
    useApiServices().postHeaderBodyApi(
      `${globalAdminUrl}/onboarding-admin/setrolegroup`,
      header,
      credential
    ),

  // configuration page
  GetConfiguration: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/onboarding-admin/getconfiguration`, token),
  GetConfigurationDetails: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/onboarding-admin/getConfigurationDetails`, token),
  SetConfiguration: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/onboarding-admin/setconfiguration`, token),
  // faq
  GetFAQLanguageList: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/onboarding-admin/getFAQLanguageList`, token),
  // Watermark text
  GetWatermarkText: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/onboarding-admin/getWatermarkText`, token),

  // UserList Api
  UserListApi: (hdrCredential) =>
    useApiServices().getApi(`${globalAdminUrl}/onboarding-admin/userList`, hdrCredential),
  GetRolesAccess: (hdrCredential) =>
    useApiServices().getApi(`${globalAdminUrl}/onboarding-admin/getroles`, hdrCredential),
  GetInternalUserList: (header) =>
    useApiServices().getApi(`${globalAdminUrl}/onboarding-admin/getInternalUserList`, header),
  GetInternalUserDetails: (header) =>
    useApiServices().getApi(`${globalAdminUrl}/onboarding-admin/getInternalUserDetails`, header),
  SearchInternalUserList: (header, body) =>
    useApiServices().postHeaderBodyApi(
      `${globalAdminUrl}/onboarding-admin/searchInternalUserList`,
      header,
      body
    ),

  UpdateUserData: (header, credential) =>
    useApiServices().postHeaderBodyApi(
      `${globalAdminUrl}/onboarding-admin/updateUserData`,
      header,
      credential
    ),

  Getdashboarddata: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/onboarding-admin/getdashboarddata`, token),
  GetKPIData: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/onboarding-admin/getKPIData`, token),
  GetDailyTransactionStatus: (token) =>
    useApiServices().getApi(`${globalAdminUrl}/onboarding-admin/getDailyTransactionStatus`, token)
};

export default globalServices;
